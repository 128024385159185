import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Card, CardBody, Col, FormGroup } from 'reactstrap';
import { stateMappings } from '../redux/stateMappings';

interface IProps {
  isAuthenticated: boolean;
}
const SentEmail: FC<IProps> = ({ isAuthenticated }) => {
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();
  const error = searchParams.get('error');
  // use this as a flag to determine if isAuthenticated changed from true to false or it was allways false
  const [didMount, setDidMount] = useState<boolean>(false);
  const [seconds, setSeconds] = useState(15);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => {
        if (seconds <= 0) {
          navigate('/');
          return 0;
        }
        return seconds - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (didMount && !isAuthenticated) navigate('/login');
    setDidMount(true);
  }, [isAuthenticated]);

  return (
    <Card className="card-sent-email">
      <CardBody>
        <div className="d-flex justify-content-center">
          <Col xs="12" sm="6" lg="3">
            <FormGroup style={{ padding: '80px 0px' }}>
              <p className="reset-password-text text-center">
                {!!error ? 'Something Went Wrong' : 'Thank you!'}
              </p>
              <p className="text-center">
                <img className="logo" src={require('../assets/images/envelope.png')} alt="logo" />
              </p>
              {!!error ? (
                <p className="text-center" style={{ color: '#526c88', fontSize: '19px' }}>
                  The email address you supplied has generated notifications of Permanent Bounces
                  from your domain server in the past. The system is attempting to send a password
                  reset. If you do not receive the email please reach out to{' '}
                  <a
                    style={{ cursor: 'pointer', color: 'blue' }}
                    onClick={() => (window.location.href = 'mailto:support@tracebacks.org')}
                  >
                    support@tracebacks.org{' '}
                  </a>
                  from the email address in question.
                </p>
              ) : (
                <p className="text-center" style={{ color: '#526c88', fontSize: '19px' }}>
                  If the email address you supplied is found in our system, you will receive a
                  password reset link. This link will expire in 30 minutes.
                </p>
              )}
              <p className="text-center" style={{ color: '#526c88', fontSize: '19px' }}>
                You will be redirected back in {seconds}s
              </p>
            </FormGroup>
          </Col>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    isAuthenticated: sm.isAuthenticated
  };
};

export default connect(mapStateToProps)(SentEmail);
