import {
  GET_REPUTATION_HOPS_HIGH,
  GET_REPUTATION_HOPS_INTERNATIONAL,
  GET_REPUTATION_HOPS_LOW,
  SET_REPUTATION_ERROR_MESSAGE,
  SET_REPUTATION_LOADING_STATUS
} from './actionEnum';

export const getReputationHopsLow = (response: any) => ({
  type: GET_REPUTATION_HOPS_LOW,
  payload: response
});

export const getReputationHopsInternational = (response: any) => ({
  type: GET_REPUTATION_HOPS_INTERNATIONAL,
  payload: response
});

export const getReputationHopsHigh = (response: any) => ({
  type: GET_REPUTATION_HOPS_HIGH,
  payload: response
});

export const setLoadingStatus = (candidate: boolean) => ({
  type: SET_REPUTATION_LOADING_STATUS,
  payload: candidate
});

export const setErrorMessage = (message: string) => ({
  type: SET_REPUTATION_ERROR_MESSAGE,
  payload: message
});
