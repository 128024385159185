import React, { FC } from 'react';
import { quarterValueMapper } from '../AdminDashboard/Insights/columns';

interface IProps {
  subTitle: string;
  name: string;
  value: number;
}

const colorQuarter = (quarterMappedValue: number) => {
  switch (quarterMappedValue) {
    case 5:
      return '#C90000';
    case 4:
      return '#EB2F01';
    case 3:
      return '#E36B02';
    case 2:
      return '#E89700';
    case 1:
      return '#EDC200';
    default:
      return '';
  }
};
const QuarterCard: FC<IProps> = ({ subTitle, name, value }) => {
  const mappedValue = quarterValueMapper(value);
  const colorQ = colorQuarter(mappedValue);
  return (
    <div className="cardQuarter">
      <div className="d-flex justify-content-center p-0">
        {value ? (
          <div className="position-relative">
            <div
              className="halfDonutChart"
              style={{
                ['--percentage' as string]: mappedValue * 20,
                ['--fill' as string]: colorQ
              }}
            ></div>
            <div style={{ position: 'absolute', top: '60px', left: '64px' }}>
              <div
                className="dot h1"
                style={{
                  backgroundColor: colorQ,
                  color: 'white'
                }}
              >
                <span className="mt-1">{mappedValue}</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="quarterText h3 mt-5">NA</div>
        )}
      </div>
      <div className={'d-flex justify-content-center ' + (name === '-' ? 'mt-2 h1' : 'mt-4 h5')}>
        {name.replace('_', ' ')}
      </div>
      <div className="d-flex justify-content-center">{`${subTitle}`}</div>
    </div>
  );
};

export default QuarterCard;
