import { format, sub } from 'date-fns';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CardBody, CardTitle } from 'reactstrap';
import CustomDataTable from '../components/CustomDataTable';
import ProviderExpandableRow from '../components/ProviderExpandableRow';
import { userTypes } from '../enum/userTypes';
import { Configuration } from '../interfaces/configuration';
import { Pagination, defaultPagination } from '../interfaces/pagination';
import { ProviderSummaryRow } from '../interfaces/provider';
import { PersonalInfo } from '../interfaces/user';
import { getFilter } from '../lib/FilterElement';
import { LabelSummary } from '../lib/campaign';
import { campaignColumns } from '../lib/dataTableUtils/campaignSummaryColumns';
import { providerColumns } from '../lib/dataTableUtils/providerColumns';
import {
  getGovCampaignFollowersSummaries,
  getGovFollowersSummaries
} from '../redux/govFollower/thunk';
import { getProviderList } from '../redux/provider/thunks';
import { stateMappings } from '../redux/stateMappings';
import UpdateNewActivityButton from './UpdateNewActivityButton';

interface IProps {
  getProviderList: Function;
  getGovFollowersSummaries: Function;
  getGovCampaignFollowersSummaries: Function;
  govFollowersSummaries: ProviderSummaryRow[];
  govCampaignFollowersSummaries: LabelSummary[];
  providers: ProviderSummaryRow[];
  user: PersonalInfo;
  paginationTotalRows: number;
  configuration: Configuration;
}

const HomePageCustomTables: React.FC<IProps> = ({
  providers,
  getProviderList,
  getGovFollowersSummaries,
  getGovCampaignFollowersSummaries,
  govCampaignFollowersSummaries,
  govFollowersSummaries,
  user,
  paginationTotalRows,
  configuration
}) => {
  const startDate = format(sub(new Date(), { months: 1 }), 'yyyy-MM-dd');
  const endDate = format(new Date(), 'yyyy-MM-dd');
  const filterElements = getFilter({
    isItgMember: true,
    reputations: [
      { label: 'Yellow', value: 2 },
      { label: 'Orange', value: 3 }
    ],
    startDate,
    endDate
  });

  const [itgProvidersPaginationParams, setItgProvidersPaginationParams] = useState({
    ...defaultPagination(),
    sort: 'ps.score',
    order: 'desc',
    pageSize: 1000
  });
  const [followingPaginationParams, setfollowingPaginationParams] = useState({
    ...defaultPagination(),
    sort: 'ps.score',
    order: 'desc',
    pageSize: 1000
  });
  const [followingCampaignPaginationParams, setfollowingCampaignPaginationParams] = useState({
    ...defaultPagination(),
    sort: 'labels.created_date',
    order: 'desc',
    pageSize: 1000
  });

  useEffect(() => {
    if (userTypes.Admin === user.roleType || userTypes.GovUser == user.roleType) {
      getGovFollowersSummaries(followingPaginationParams);
      getProviderList(itgProvidersPaginationParams, filterElements);
      getGovCampaignFollowersSummaries(followingCampaignPaginationParams);
    }
  }, []);

  useEffect(() => {
    if (userTypes.Admin === user.roleType || userTypes.GovUser == user.roleType) {
      getProviderList(itgProvidersPaginationParams, filterElements);
    }
  }, [itgProvidersPaginationParams]);

  useEffect(() => {
    if (userTypes.Admin === user.roleType || userTypes.GovUser == user.roleType) {
      getGovFollowersSummaries(followingPaginationParams);
    }
  }, [followingPaginationParams]);

  useEffect(() => {
    if (userTypes.GovUser == user.roleType) {
      getGovCampaignFollowersSummaries(followingCampaignPaginationParams);
    }
  }, [followingCampaignPaginationParams]);

  const updatePagination = (params: Pagination) => {
    setItgProvidersPaginationParams({ ...itgProvidersPaginationParams, ...params });
  };
  const updateFollowingPagination = (params: Pagination) => {
    setfollowingPaginationParams({ ...followingPaginationParams, ...params });
  };
  const updateFollowingCampaignPagination = (params: Pagination) => {
    setfollowingCampaignPaginationParams({ ...followingCampaignPaginationParams, ...params });
  };

  return (
    <Fragment>
      {(userTypes.Admin === user.roleType || userTypes.GovUser == user.roleType) && (
        <div className="ps-5 pe-5 overflow-auto">
          <div className="d-flex justify-content-between pt-5">
            <h4>Following</h4>
            <UpdateNewActivityButton emailType="followersActivityEmail" />
          </div>
          <CustomDataTable
            tableData={govFollowersSummaries}
            columns={providerColumns(user.roleType, true)}
            noDataText="No Provider Followed"
            defaultSortFieldId={followingPaginationParams.sort}
            defaultPage={followingPaginationParams.page}
            defaultPageSize={followingPaginationParams.pageSize}
            paginationTotalRows={paginationTotalRows}
            updatePaginationParams={updateFollowingPagination}
            useExpandableRows={configuration.reputationVisible}
            useExpandableRowsComponent={({ data }) => (
              <ProviderExpandableRow
                data={data}
                includeLinks
                startDate={startDate}
                endDate={endDate}
              />
            )}
          />
        </div>
      )}

      {userTypes.Admin === user.roleType && (
        <CardBody className="card-body-provider ps-5 pe-5 pt-5 overflow-auto">
          <CardTitle tag="h4">ITG Members</CardTitle>
          <CustomDataTable
            tableData={providers}
            columns={providerColumns(user.roleType)}
            noDataText="No ITG Members"
            defaultSortFieldId={itgProvidersPaginationParams.sort}
            defaultPage={itgProvidersPaginationParams.page}
            defaultPageSize={itgProvidersPaginationParams.pageSize}
            paginationTotalRows={paginationTotalRows}
            updatePaginationParams={updatePagination}
            useExpandableRows={configuration.reputationVisible}
            useExpandableRowsComponent={({ data }) => (
              <ProviderExpandableRow
                data={data}
                includeLinks
                startDate={startDate}
                endDate={endDate}
              />
            )}
          />
        </CardBody>
      )}

      {userTypes.GovUser === user.roleType && (
        <CardBody className="card-body-provider ps-5 pe-5 pt-5 overflow-auto">
          <CardTitle tag="h4">Following - Campaigns</CardTitle>
          <CustomDataTable
            tableData={govCampaignFollowersSummaries}
            columns={campaignColumns}
            noDataText="No Campaign Folowed"
            defaultSortFieldId={followingCampaignPaginationParams.sort}
            defaultPage={followingCampaignPaginationParams.page}
            defaultPageSize={followingCampaignPaginationParams.pageSize}
            paginationTotalRows={paginationTotalRows}
            updatePaginationParams={updateFollowingCampaignPagination}
          />
        </CardBody>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    user: sm.user,
    govFollowersSummaries: sm.govFollowers.govFollowersSummaries,
    govCampaignFollowersSummaries: sm.govFollowers.govCampaignFollowersSummaries,
    providers: sm.provider.providers,
    paginationTotalRows: sm.provider.meta.TotalCount,
    configuration: sm.appinfo.configuration
  };
};

const mapActionsToProps = {
  getGovFollowersSummaries,
  getGovCampaignFollowersSummaries,
  getProviderList
};

export default connect(mapStateToProps, mapActionsToProps)(HomePageCustomTables);
