import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import Breadcrumbs from '../components/Breadcrumbs';
import TracebackEditor from '../components/traceback/tracebackEditor';
import TracebackView from '../components/traceback/tracebackView';
import { userTypes } from '../enum/userTypes';
import { Traceback } from '../interfaces/traceback';
import { PersonalInfo } from '../interfaces/user';
import { logOut } from '../lib/apiRequest';
import { stateMappings } from '../redux/stateMappings';
import { editTracebackObject, getTracebackObject } from '../redux/traceback/thunks';

type IProps = {
  getTracebackObject: Function;
  editTracebackObject: Function;
  traceback: Traceback;
  statuses: { [key: number]: string };
  attachments: any[];
  createdBy: string;
  error: string;
  user: PersonalInfo;
};

const TracebackDetail: React.FC<IProps> = ({
  getTracebackObject,
  editTracebackObject,
  traceback,
  statuses,
  attachments,
  createdBy,
  error,
  user
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isViewMode, setIsViewMode] = useState(true);
  const [isDelete, setIsDelete] = useState(false);
  const [archiveTracebackTip, setArchiveTracebackTip] = useState(
    !localStorage.getItem('archiveTracebackTip')
  );

  useEffect(() => {
    if (user.roleType !== userTypes.Admin) {
      logOut();
    }
    getTracebackObject(Number(id) || 0);
  }, []);

  useEffect(() => {
    if (error) navigate('/404');
  }, [error]);

  const handleClickEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setViewMode();
  };

  const archiveConfirmModalToggle = () => {
    setIsDelete(!isDelete);
  };

  const setViewMode = () => {
    setIsViewMode(!isViewMode);
  };

  const handleArchiveTracebackTooltipBtnClick = () => {
    localStorage.setItem('archiveTracebackTip', 'SET');
    setArchiveTracebackTip(false);
  };

  const archiveTraceback = () => {
    editTracebackObject(
      {
        ...traceback,
        status: 5
      },
      attachments,
      false
    );

    navigate('/tracebacks');
  };

  return (
    <Fragment>
      <Breadcrumbs
        title="tracebacks"
        destination="tracebacks"
        detail={`Traceback ${traceback.tracebackId || id}`}
        modify={''}
        modifyFunction={archiveConfirmModalToggle}
        tooltipContents={
          <Fragment>
            <p>
              If a mistake has been made or you need to archive this Traceback for some other
              reason, this is the spot to do that.
            </p>
            <p>The Traceback will remain visible but won't be active any longer.</p>
            <p>
              The Traceback will always appear at the end of the Main Tracebacks section, reached by
              going to the last page.
            </p>
          </Fragment>
        }
        isModifyTooltip={archiveTracebackTip}
        handleModifyTooltipBtnClick={handleArchiveTracebackTooltipBtnClick}
        isAdmin={user.roleType === userTypes.Admin}
      />
      <Card className="telecom-card">
        <CardHeader className="telecom-card-header">
          {`Traceback ${traceback.tracebackId || id}`}
        </CardHeader>
        <CardBody>
          <div className="d-flex justify-content-center">
            <Col xs="12" sm="11" lg="10">
              {isViewMode ? (
                <TracebackView traceback={traceback} tracebackStatuses={statuses} user={user} />
              ) : (
                <TracebackEditor
                  traceback={traceback}
                  tracebackStatuses={statuses}
                  createdBy={createdBy}
                  tracebackServer={editTracebackObject}
                  tAttachments={attachments}
                />
              )}
            </Col>
          </div>
          {isViewMode && (
            <div className="d-flex justify-content-center">
              <Button className="telecom-btn" onClick={handleClickEdit}>
                Edit
              </Button>
            </div>
          )}
        </CardBody>
      </Card>

      <Modal
        centered
        isOpen={isDelete}
        className="submit-confirm-modal"
        toggle={archiveConfirmModalToggle}
      >
        <ModalHeader toggle={archiveConfirmModalToggle} />
        <ModalBody className="delete-modal-body">
          <h5 className="traceback-sub-title">Archive this Traceback?</h5>
          <p className="telecom-text mb-2">Archiving this traceback is final.</p>
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <Button
            className="btn-default telecom-btn"
            color="light"
            onClick={archiveConfirmModalToggle}
          >
            Cancel
          </Button>
          <Button
            className="telecom-btn"
            style={{ background: '#d61827' }}
            onClick={archiveTraceback}
          >
            Proceed
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    traceback: sm.traceback.traceback,
    statuses: sm.traceback.statuses,
    attachments: sm.traceback.attachments,
    createdBy: sm.user.name,
    error: sm.traceback.error,
    user: sm.user
  };
};

const mapActionsToProps = {
  getTracebackObject,
  editTracebackObject
};

export default connect(mapStateToProps, mapActionsToProps)(TracebackDetail);
