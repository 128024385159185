import React, { Fragment } from 'react';
import { Label } from 'reactstrap';
import { DateFormat } from '../../enum/DateFormat';
import { userTypes } from '../../enum/userTypes';
import { TfHop } from '../../interfaces/tfhop';
import { Traceforward } from '../../interfaces/traceforward';
import { PersonalInfo } from '../../interfaces/user';
import { getClientFormattedDate, getElapsedTime } from '../../lib/utilities';

interface IProps {
  tfhop: TfHop;
  traceforward: Traceforward;
  user: PersonalInfo;
}

const TraceforwardDetailsShortRow: React.FC<IProps> = ({ tfhop, traceforward, user }) => {
  return user.roleType === userTypes.Admin && tfhop.status !== 1 ? (
    <Fragment>
      <h5 className="p-0 traceforward-sub-title mb-3">Traceforward Details</h5>
      <div className="d-flex flex-row justify-content-around mb-3">
        <div style={{ marginLeft: '25px' }}>
          <Label className="telecom-label">Date & Time Created</Label>
          {getClientFormattedDate(traceforward.create_date, DateFormat.MediumBoth)}
        </div>
        <div>
          <Label className="telecom-label">Total Time Elapsed</Label>
          {getElapsedTime(traceforward.elapsed)}
        </div>
        {traceforward.completed && traceforward.completed !== '0001-01-01T00:00:00Z' && (
          <div>
            <Label className="telecom-label">Date & Time Completed</Label>
            {getClientFormattedDate(traceforward.completed, DateFormat.MediumBoth)}
          </div>
        )}
      </div>
      {tfhop.isCampaignLowVolume && (
        <div className="mb-3" style={{ padding: '0 10px' }}>
          Low Volume call - evidence shows that this is a targeted call campaign...
        </div>
      )}
    </Fragment>
  ) : null;
};

export default TraceforwardDetailsShortRow;
