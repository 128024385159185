import { CampaignAttributes, emptyCampaignAttributes } from './campaign';
import { NoteType } from './hop';
import { Provider, emptyProvider } from './provider';
import { StirShakenReponse } from './stirShaken';

export interface Requestor {
  requestorId: number;
  name: string;
}

export interface Traceback {
  active: boolean;
  audioName: string;
  audioUrl: string;
  calledTN: string;
  callerName: string;
  campaignDescription: string;
  callingTN: string;
  campaignId: number;
  campaignName: string;
  comments: [];
  completed: string;
  create_date: string;
  createdBy: string;
  creatorProvider: string;
  dnc: string;
  dno: boolean;
  dnoType?: string;
  elapsed: number;
  lastHopId: number;
  lastProvider: Provider;
  numberOfHops: number;
  providerId: number;
  status: number;
  strikeExempt: boolean;
  isInternational: boolean;
  termCarrierId: string;
  termCarrierOCN: string;
  termLineType: string;
  termState: string;
  timeZone: string;
  tracebackId: number;
  tracebackTime: string;
  update_date: string;
  updateFlag: number;
  requestors: Requestor[];
  source: string;
  stirShaken: StirShakenReponse;
  hopsStirShaken: StirShakenReponse[];
  hopsStirShakenFlag: string[];
  lastHopRMDFlag: string;
  campaignAttributes: CampaignAttributes;
  notes: NoteType[];
  downstreamProvider?: Provider;
  downstramHopRMDFlag?: string;
  representativeAudio?: boolean;
  calledCountry: string;
  callingCountry: string;
  associateProvider?: boolean;
}

export interface TracebackPostData {
  audioName: string;
  audioUrl: string;
  audioUrlPath: string;
  calledTN: string;
  callingTN: string;
  campaignId: number;
  campaignName: string;
  create_date: string;
  createdBy: string;
  tracebackTime: string;
}

export const emptyTraceback = (): Traceback => ({
  active: false,
  audioName: '',
  audioUrl: '',
  calledTN: '',
  callerName: '',
  callingTN: '',
  campaignId: 0,
  campaignName: '',
  campaignDescription: '',
  comments: [],
  completed: '',
  create_date: '',
  createdBy: '',
  creatorProvider: '',
  dnc: '',
  dno: false,
  elapsed: 0,
  lastHopId: 0,
  lastProvider: emptyProvider(),
  numberOfHops: 0,
  providerId: 0,
  status: 0,
  strikeExempt: false,
  termCarrierId: '',
  termCarrierOCN: '',
  termLineType: '',
  termState: '',
  timeZone: '',
  tracebackId: 0,
  tracebackTime: '',
  update_date: '',
  updateFlag: 0,
  requestors: [],
  source: '',
  stirShaken: {
    status: 0,
    objectId: 0,
    compact: false,
    token: '',
    attest: '',
    dest: {},
    hopID: 0,
    hopSequence: 0,
    hopCompletedDate: '',
    iat: '',
    orig: '',
    origid: '',
    errorMessage: null,
    isCallSigner: false,
    issuer: undefined,
    signer: undefined,
    create_date: '',
    isIssuerValid: false,
    certificateErrors: null,
    signerIsDomestic: false,
    signerIsITGMember: false
  },
  hopsStirShaken: [],
  hopsStirShakenFlag: [],
  lastHopRMDFlag: '',
  campaignAttributes: emptyCampaignAttributes(),
  notes: [],
  isInternational: false,
  calledCountry: '',
  callingCountry: ''
});

export const spreadsheetTracebackStatus = new Map<number, string>([
  [1, 'Pending'],
  [2, 'Accepted']
]);

export const tracebackStatus = new Map<number, string>([
  [1, 'Open'],
  [2, 'Complete'],
  [3, 'Not Found'],
  [4, 'No Response'],
  [5, 'Archived']
]);

export interface SpreadsheetTraceback {
  id: number;
  create_date: string;
  update_date: string;
  status: number;
  incidentName: string;
  incidentDescription: string;
  natureOfRequest: string;
  legalViolations: string;
  dialedNumber?: string;
  callingNumber?: string;
  callTime?: string;
  timeZoneCallTime?: string;
  contentTraceback: string;
  recordingType: string;
  requestId: number;
  tracebackError: string;
  tracebackId: number;
  campaignId?: number;
  requestors?: Requestor[];
  liveOrPrerecorded: string;
  calledPartyType: string;
}

export interface SubmitterDetails {
  id: number;
  userId: number;
  numberOfSpreadSheetTracebacks: number;
  requestingOrganization: string;
  dateOfRequest: string;
  contactName: string;
  contactTitle: string;
  contactEmail: string;
  contactPhone: string;
  certificationText: string;
  certificationValid: boolean;
}
