import React, { Fragment, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import { OnChangeValue } from 'react-select';
import { Button, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { addCommentObject, editCommentWithAttachments } from '../redux/comment/thunks';
import CustomCreatable from './CustomCreatable';
import DropZone from './DropZone';
import InputFormGroup from './inputFormGroup';

interface IProps {
  isEdit?: boolean;
  comment?: any;
}

interface IError {
  callDate: string;
  spokeWith: string;
  selectedTracebacks: string;
  callSummary: string;
}

interface OptionType {
  label: string;
  value: any;
}

const TracebackCommentEditor: React.FC<IProps> = ({ isEdit = false, comment }) => {
  const navigate = useNavigate();

  //form related state
  const [callDate, setCallDate] = useState<Date | null | undefined>(null);
  const [providerId, setProviderId] = useState(-1);
  const [spokeWith, setSpokeWith] = useState('');
  const [selectedTracebacks, setSelectedTracebacks] = useState<OptionType[]>([]);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [callSummary, setCallSummary] = useState('');

  //misc state
  const [errors, setErrors] = useState<IError>({
    callDate: '',
    spokeWith: '',
    selectedTracebacks: '',
    callSummary: ''
  });

  //event handlers
  const handleDateChange = (date: Date | null) => {
    setCallDate(date);
    setErrors({ ...errors, callDate: '' });
  };

  const handleTracebacksChange = (option: OnChangeValue<OptionType, boolean>) => {
    const selectedOption = option as OptionType[];
    setSelectedTracebacks(selectedOption);
    setErrors({ ...errors, selectedTracebacks: '' });
  };

  const handleRemoveFile = (file: any) => {
    const filteredAttachments = attachments.filter((item: any) => item.name !== file.name);
    setAttachments(filteredAttachments);
  };

  const handleAddFile = (files: any[]) => {
    const filelist = files.filter(
      (file) => attachments && !attachments.map((item: any) => item.name).includes(file.name)
    );

    setAttachments([...attachments, ...filelist]);
  };

  const handleStringChange = (e: any) => {
    if (e.target.name === 'spokeWith') {
      setSpokeWith(e.target.value);
    } else if (e.target.name === 'callSummary') {
      setCallSummary(e.target.value);
    }
    setErrors({ ...errors, [e.target.name]: '' });
  };

  //validation
  const isFormValid = () => {
    let errors: IError = {
      callDate: '',
      selectedTracebacks: '',
      spokeWith: '',
      callSummary: ''
    };
    let hasErrors = false;

    if (spokeWith.trim() === '') {
      errors.spokeWith = 'Enter the contact name';
      hasErrors = true;
    }

    if (!selectedTracebacks || selectedTracebacks.length === 0) {
      errors.selectedTracebacks = 'Enter traceback Id';
      hasErrors = true;
    }

    if (!callDate) {
      errors.callDate = 'Please provide a valid date';
      hasErrors = true;
    }

    if (callSummary.trim() === '') {
      errors.callSummary = 'Please provide a call summary';
      hasErrors = true;
    } else if (callSummary.length <= 10) {
      errors.callSummary = 'Call summary must be longer than 10 characters.';
      hasErrors = true;
    }

    setErrors(errors);
    return !hasErrors;
  };

  //handle submit / cancel
  const addComment = async () => {
    let tracebacks = [];

    if (selectedTracebacks) {
      tracebacks = selectedTracebacks.map((item: OptionType) => item.value);
    }

    const commentObject = {
      commentType: 'tracebacks',
      relatedObjectId: providerId,
      callDate,
      spokeWith,
      callRecordTracebacks: tracebacks.join(','),
      contentText: callSummary,
      relatedObjectIds: tracebacks.map(Number)
    };

    addCommentObject(commentObject, attachments).then(() => {
      navigate('/comments');
    });
  };

  const editComment = async () => {
    let tracebacks = [];
    if (selectedTracebacks) {
      tracebacks = selectedTracebacks.map((item: OptionType) => item.value);
    }

    const updatedComment = {
      ...comment,
      callDate,
      spokeWith,
      callRecordTracebacks: tracebacks.join(','),
      contentText: callSummary,
      relatedProviderId: providerId
    };

    await editCommentWithAttachments(updatedComment, attachments);

    navigate('/comments');
  };

  const handleCancel = () => {
    navigate('/comments');
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!isFormValid()) return;

    if (isEdit) {
      editComment();
    } else {
      await addComment();
    }
  };

  useEffect(() => {
    if (!isEdit || !comment) return;

    if (comment.callRecordTracebacks !== '') {
      const tracebacksArray = comment.callRecordTracebacks.split(',');
      const tracebacksOptions = tracebacksArray.map((tb: string) => {
        return { label: tb, value: tb };
      }) as OptionType[];
      setSelectedTracebacks(tracebacksOptions);
    }

    if (comment.attachments && comment.attachments.length) {
      const convertStateToFileType = comment.attachments.map(
        (attachment: any) => new File(['test'], attachment.fileName)
      );
      setAttachments(convertStateToFileType);
    }

    setCallDate(new Date(comment.callDate));
    setSpokeWith(comment.spokeWith);
    setCallSummary(comment.contentText);
  }, [comment]);

  return (
    <Fragment>
      <Form className="form-traceback traceback-add">
        <FormGroup>
          <h5 className="col-md-12 p-0 traceback-sub-title">Comment Details</h5>
          <Row style={{ justifyContent: 'left' }} className="mb-2">
            <Col md="5">
              <Label className="telecom-label">
                Call Date
                <i className="fa fa-asterisk asterisk" />
              </Label>
              <DatePicker
                maxDate={new Date()}
                selected={callDate}
                onChange={handleDateChange}
                placeholderText="&#xf133; mm/dd/yyyy"
              />
              <Label className="text-red ps-2">{errors.callDate}</Label>
            </Col>
          </Row>

          <Row style={{ justifyContent: 'left' }} className="mb-2">
            <Col md="5">
              <Label className="telecom-label">
                Spoke with
                <i className="fa fa-asterisk asterisk" />
              </Label>
              <InputFormGroup
                style={{ marginBottom: '0' }}
                isReadonly={false}
                inputName="spokeWith"
                inputId="spoke"
                inputClassName="input-traceback"
                inputValue={spokeWith}
                inputPlaceholder=""
                inputOnChange={handleStringChange}
                inputAutoComplete="off"
              />
              <Label className="text-red ps-2">{errors.spokeWith}</Label>
            </Col>
          </Row>

          <Row style={{ justifyContent: 'left' }}>
            <Col md="5">
              <Label className="telecom-label">
                About Traceback <i className="fa fa-asterisk asterisk" />
              </Label>
              <CustomCreatable
                onChange={handleTracebacksChange}
                placeholder="Enter Traceback IDs"
                value={selectedTracebacks}
                classNamePrefix={'customselect'}
                className={'customselect-large'}
              />
              <Label className="text-red ps-2">{errors.selectedTracebacks}</Label>
            </Col>
            <Col md="5">
              <Label className="telecom-label" style={{ textAlign: 'left' }}>
                Attachment
              </Label>
              <DropZone
                attachments={attachments}
                removeFile={handleRemoveFile}
                addFile={handleAddFile}
                alignment={'right'}
              />
            </Col>
          </Row>

          <Row style={{ justifyContent: 'left' }}>
            <Col md="10">
              <Label className="telecom-label mt-3">
                Call Summary
                <i className="fa fa-asterisk asterisk" />
              </Label>
              <p className=" mt-1 ps-2">Your summary should be at least 10 characters</p>
              <InputFormGroup
                style={{ marginBottom: '0' }}
                isReadonly={false}
                isTextarea
                inputName="callSummary"
                inputId="callSummary"
                inputClassName="input-comment"
                inputStyle={{}}
                inputValue={callSummary}
                inputOnChange={handleStringChange}
                inputPlaceholder=""
                inputAutoComplete="off"
              />
              <Label className="text-red ps-2">{errors.callSummary}</Label>
            </Col>
          </Row>

          <div style={{ textAlign: 'center', marginTop: '25px' }}>
            <Button className="btn-default telecom-btn" onClick={handleCancel} color="light">
              Cancel
            </Button>
            <Button className="telecom-btn" onClick={(e) => handleSubmit(e)}>
              Save
            </Button>
          </div>
        </FormGroup>
      </Form>
    </Fragment>
  );
};

export default TracebackCommentEditor;
