import React, { Fragment, useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { userTypes } from '../enum/userTypes';
import { Pagination, defaultPagination } from '../interfaces/pagination';
import { Provider, ProviderSummaryRow, UpstreamSummaryRow } from '../interfaces/provider';
import { PersonalInfo } from '../interfaces/user';
import {
  FilterElement,
  addCondition,
  addConditionGroup,
  newConditionalFilterElement,
  newLeafFilterElement,
  simplifyFilterElement
} from '../lib/FilterElement';
import {
  decodeQueryParams,
  encodeQueryParams,
  getFromLocalStorage,
  saveToLocalStorage
} from '../lib/history-utils';
import { getProviderUpstreamSummaryObject } from '../redux/provider/thunks';
import { stateMappings } from '../redux/stateMappings';
import CustomDataTable from './CustomDataTable';
import ProviderPanel from './ProviderPanel';

interface IProps {
  getProviderUpstreamSummaryObject: Function;
  paginationTotalRows: number;
  provider: Provider;
  startDate: string | undefined;
  endDate: string | undefined;
  providerId: number;
  upstreamSummary: UpstreamSummaryRow[];
  user: PersonalInfo;
}

const providerColumns = (user: PersonalInfo): TableColumn<ProviderSummaryRow>[] => [
  {
    name: '',
    width: '20px',
    cell: () => <div />
  },
  {
    name: 'Provider',
    selector: () => 'name',
    sortable: true,
    grow: 2,
    cell: (row: ProviderSummaryRow) => (
      <ProviderPanel
        provider={row.provider}
        hyperlink={user.roleType === userTypes.Admin}
        includeReputation
        rmdFlag={row.summary && !!row.summary.numOfHopsWithRMDFlag && 'multipleRMD'}
      />
    )
  },
  {
    name: 'Total Hops',
    selector: () => 'numberOfHops',
    sortable: true,
    grow: 1,
    cell: (row: ProviderSummaryRow) =>
      row.summary && row.summary.numberOfHops ? (
        user.roleType === userTypes.Admin ? (
          <div className="table-cell blue center">
            <a href={`/hops?providerId=${row.provider?.providerId}`}>{row.summary.numberOfHops}</a>
          </div>
        ) : (
          <div className="table-cell gray center">{row.summary.numberOfHops}</div>
        )
      ) : (
        <div className="table-cell gray center">
          <span>0</span>
        </div>
      )
  },
  {
    name: 'Open',
    selector: () => 'numOfOpenHops',
    grow: 1,
    sortable: true,
    cell: (row: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {row.summary && row.summary.numOfOpenHops ? row.summary.numOfOpenHops : 0}
      </span>
    )
  },
  {
    name: 'Transit',
    selector: () => 'numOfTransitHops',
    grow: 1,
    sortable: true,
    omit: true,
    cell: (row: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {row.summary && row.summary.numOfTransitHops ? row.summary.numOfTransitHops : 0}
      </span>
    )
  },
  {
    name: 'POE',
    selector: () => 'numOfUSPoEHops',
    grow: 1,
    cell: (row: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {(row.summary && row.summary.numOfUSPoEHops) || 0}
      </span>
    )
  },
  {
    name: 'Orig',
    selector: () => 'numOfOriginHops',
    grow: 1,
    sortable: true,
    cell: (row: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {row.summary && row.summary.numOfOriginHops ? row.summary.numOfOriginHops : 0}
      </span>
    )
  },
  {
    name: 'No Resp',
    selector: () => 'numOfNoResponseHops',
    grow: 1,
    sortable: true,
    cell: (row: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {row.summary && row.summary.numOfNoResponseHops ? row.summary.numOfNoResponseHops : 0}
      </span>
    )
  },
  {
    name: (
      <div>
        <i className="fa-solid fa-arrow-down" /> No Resp
      </div>
    ),
    selector: () => 'numOfDownstreamNoResponseHops',
    grow: 1,
    sortable: true,
    cell: (row: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {row.summary && row.summary.numOfDownstreamNoResponseHops
          ? row.summary.numOfDownstreamNoResponseHops
          : 0}
      </span>
    )
  },
  {
    name: 'Not Found',
    selector: () => 'numOfNotFoundHops',
    grow: 1,
    sortable: true,
    cell: (row: ProviderSummaryRow) => (
      <span className="table-cell gray center">
        {row.summary && row.summary.numOfNotFoundHops ? row.summary.numOfNotFoundHops : 0}
      </span>
    )
  },
  {
    name: ' ',
    width: '50px',
    cell: () => <div />
  }
];

const COMPONENT = 'upstreamProviderSummaryPanel';

const UpstreamProviderSummaryPanel: React.FC<IProps> = ({
  provider,
  upstreamSummary,
  user,
  paginationTotalRows,
  startDate,
  endDate,
  getProviderUpstreamSummaryObject,
  providerId
}) => {
  const searchParams = getFromLocalStorage(COMPONENT) || '';
  const decodedParams = decodeQueryParams(COMPONENT, searchParams);

  const [paginationParams, setPaginationParams] = useState<Pagination>(
    decodedParams.paginationParams || {
      ...defaultPagination(),
      sort: 'name'
    }
  );
  const [isNotDomestic, setIsNotDomestic] = useState<boolean>(!!decodedParams.isNotDomestic);
  const [filterElements, setFilterElements] = useState<FilterElement>(
    newConditionalFilterElement('')
  );

  const getFilter = () => {
    let filterElements = newConditionalFilterElement('AND');
    if (isNotDomestic) {
      addCondition(filterElements, newLeafFilterElement('isNotDomestic', 'EQ', '1'));
    }

    if (startDate || endDate) {
      addConditionGroup('AND', filterElements, [
        { key: startDate ? 'startDate' : '', value: startDate },
        { key: endDate ? 'endDate' : '', value: endDate }
      ]);
    }

    return simplifyFilterElement(filterElements);
  };

  useEffect(() => {
    setPaginationParams({
      ...paginationParams,
      page: 1
    });
    setFilterElements(getFilter());
  }, [startDate, endDate, isNotDomestic]);

  useEffect(() => {
    if (filterElements.category)
      getProviderUpstreamSummaryObject(paginationParams, filterElements, providerId);
    saveToLocalStorage(
      COMPONENT,
      encodeQueryParams(COMPONENT, { paginationParams, isNotDomestic }, {})
    );
  }, [filterElements, paginationParams]);
  return (
    <Fragment>
      <Row className="pb-2">
        <div className="d-flex flex-column">
          <p className="text-summary mb-0">{`${
            provider ? provider.name : ''
          }'s Upstream Provider Summary`}</p>
          <p className="text-navy" style={{ paddingLeft: '2rem' }}>
            This table shows the providers who you have identified as an upstream provider in
            response to tracebacks. The chart displays the number of occurrences in which each of
            those providers is the Point of Entry, Origin, non-responsive, or was unable to find the
            call. The information presented only includes the tracebacks where you identified the
            listed providers; the providers may also have appeared in these positions for other
            tracebacks as well.
          </p>
        </div>

        <Col>
          <div className="d-flex justify-content-end ms-2">
            <label className="form-label checkbox-label">
              International
              <input
                type="checkbox"
                onChange={() => {
                  setIsNotDomestic((value) => !value);
                }}
                value="isNotDomestic"
                checked={isNotDomestic}
              />
              <span className="checkmark" />
            </label>
          </div>
        </Col>
      </Row>
      <CustomDataTable
        columns={providerColumns(user)}
        defaultSortFieldId={paginationParams.sort}
        defaultSortAsc={paginationParams.order === 'asc'}
        defaultPage={paginationParams.page}
        defaultPageSize={paginationParams.pageSize}
        tableData={upstreamSummary}
        pagination={true}
        paginationTotalRows={paginationTotalRows}
        updatePaginationParams={setPaginationParams}
      />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    provider: sm.provider.provider,
    upstreamSummary: sm.provider.upstreamSummary,
    paginationTotalRows: sm.provider.meta.TotalCount,
    user: sm.user
  };
};

const mapActionsToProps = {
  getProviderUpstreamSummaryObject
};

export default connect(mapStateToProps, mapActionsToProps)(UpstreamProviderSummaryPanel);
