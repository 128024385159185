import React, { Fragment } from 'react';
import { TableColumn } from 'react-data-table-component';
import { SpreadsheetTraceback, spreadsheetTracebackStatus } from '../../interfaces/traceback';
import { processTracebackError } from '../utilities';

export const spreadsheetTracebackColumn = (
  selected: number[],
  updateSelectedRows: (checked: boolean, index: number) => void,
  setEditRow: (id: number) => void,
  campaignNames: { name: string; campaignId: number }[]
): TableColumn<SpreadsheetTraceback>[] => [
  {
    name: 'Status',
    sortable: false,
    grow: 1,
    cell: (spreadsheetTraceback: SpreadsheetTraceback) => (
      <div className="table-cell gray center">
        {spreadsheetTracebackStatus.get(spreadsheetTraceback.status)}
      </div>
    )
  },
  {
    name: 'Calling Number',
    selector: () => 'callingNumber',
    sortable: true,
    grow: 2,
    cell: (spreadsheetTraceback: SpreadsheetTraceback) => (
      <div className="table-cell gray center">{spreadsheetTraceback?.callingNumber}</div>
    )
  },
  {
    name: 'Called Number',
    selector: () => 'dialedNumber',
    sortable: true,
    grow: 2,
    cell: (spreadsheetTraceback: SpreadsheetTraceback) => (
      <div className="table-cell gray center">{spreadsheetTraceback.dialedNumber}</div>
    )
  },
  {
    name: 'Call Time',
    selector: () => 'callTime',
    sortable: true,
    grow: 3,
    cell: (spreadsheetTraceback: SpreadsheetTraceback) => (
      <div className="table-cell gray center">{spreadsheetTraceback.callTime}</div>
    )
  },
  {
    name: 'TimeZone',
    sortable: false,
    grow: 1,
    cell: (spreadsheetTraceback: SpreadsheetTraceback) => (
      <div className="table-cell gray center">{spreadsheetTraceback.timeZoneCallTime}</div>
    )
  },
  {
    name: 'Campaign',
    sortable: false,
    grow: 3,
    cell: (spreadsheetTraceback: SpreadsheetTraceback) => (
      <div className="table-cell center">
        {`${
          (campaignNames || []).find(
            (c: { name: string; campaignId: number }) =>
              c.campaignId === spreadsheetTraceback.campaignId
          )?.name || 'No campaign'
        }`}
      </div>
    )
  },
  {
    name: '',
    sortable: false,
    grow: 4,
    cell: (spreadsheetTraceback: SpreadsheetTraceback) => {
      return !spreadsheetTraceback.tracebackId ? (
        <Fragment>
          {spreadsheetTraceback.tracebackError && (
            <span className="table-cell center text-danger w-75">
              {processTracebackError(spreadsheetTraceback.tracebackError)}
            </span>
          )}
          <span data-tag="allowRowEvents" className="table-cell text-center">
            <a
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setEditRow(spreadsheetTraceback.id);
              }}
            >
              <i className="fa-solid fa-pencil blue"></i>
            </a>
          </span>
          <span className="table-cell gray center me-1">
            <input
              type="checkbox"
              defaultChecked={selected.some((select: number) => select === spreadsheetTraceback.id)}
              onChange={(e) => updateSelectedRows(e.currentTarget.checked, spreadsheetTraceback.id)}
            />
          </span>
        </Fragment>
      ) : (
        <div className="table-cell center">
          Traceback created: #{spreadsheetTraceback.tracebackId}
        </div>
      );
    }
  }
];
