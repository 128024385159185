import React, { Fragment, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CardBody, Col, Label, Nav, NavItem, Row, TabContent, TabPane } from 'reactstrap';
import rollup_cal from '../../../assets/images/rollup_cal.svg';
import subpoena from '../../../assets/images/subpoena.svg';
import CustomSelect, { SelectOption } from '../../CustomSelect';
import InputFormGroup from '../../inputFormGroup';
import SixtyDaysReport from '../Reporting/60daysReport/SixtyDaysReport';
import CampaignReport from '../Reporting/CampaignReport';
import IPReport from '../Reporting/IPReport';
import PartnerReport from '../Reporting/PartnerReport';
import PartnerSourceReport from '../Reporting/PartnerSourceReport';
import ProviderReport from '../Reporting/ProviderReport';
import QuarterlyReport from '../Reporting/QuarterlyReport';
import QueryReport from '../Reporting/QueryReport';
import ReportsArchive from '../Reporting/ReportsArchive/ReportsArchive';
import ReputationReport from '../Reporting/ReputationReport';
import RollupReport from '../Reporting/Rollup/RollupReport';
import SubpoenaReport from '../Reporting/SubpoenaReport';
import TfSubpoenaReport from '../Reporting/TfSubpoenaReport';

const NavReporting = () => {
  const { hash } = useLocation();
  const [reportName, setReportName] = useState('');
  const [narrativeIntro, setNarrativeIntro] = useState('');
  const [zendeskTicketId, setZendeskTicketId] = useState('');
  const [activeTab, setActiveTab] = useState(
    hash && hash !== '#reporting' ? hash.slice(1) : 'individual-reporting'
  );
  const [reportType, setReportType] = useState<SelectOption>({ value: '', label: '' });

  const handleChange = (e: React.FormEvent<HTMLInputElement>, key: string) => {
    switch (key) {
      case 'reportName':
        setReportName(e.currentTarget.value);
        break;
      case 'narrativeIntro':
        setNarrativeIntro(e.currentTarget.value);
        break;
      case 'zenDesk':
        setZendeskTicketId(e.currentTarget.value.replace(/[^\d#]/g, ''));
        break;
    }
  };

  const changeReportType = (e: any) => {
    setReportType(e);
  };

  return (
    <CardBody>
      <Label className="traceback-heading mb-3" style={{ fontSize: '36px' }}>
        Reporting
      </Label>
      <CardBody>
        <div className="padding-5">
          <Fragment>
            <Row>
              <Col md="3" className="ps-2">
                <Label className="telecom-label ">Name This Report (optional)</Label>
              </Col>
              <Col md="3" className="ps-2">
                <Label className="telecom-label">Zendesk Ticket# (optional)</Label>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md="3">
                <InputFormGroup
                  style={{ margin: 0 }}
                  isReadonly={false}
                  inputName="reportName"
                  inputId="reportName"
                  inputValue={reportName}
                  inputPlaceholder=""
                  inputOnChange={(option) => handleChange(option, 'reportName')}
                  inputAutoComplete="off"
                />
              </Col>
              <Col md="3">
                <InputFormGroup
                  inputValue={zendeskTicketId}
                  inputOnChange={(option) => handleChange(option, 'zenDesk')}
                  isReadonly={false}
                  inputName="reportName"
                  inputId="reportName"
                  inputPlaceholder="Enter Zendesk Ticket ID"
                  inputAutoComplete="off"
                />
              </Col>
            </Row>
            <Label className="telecom-label ps-0">Narrative Intro (optional)</Label>
            <Row className="mb-4">
              <Col md="10">
                <InputFormGroup
                  isReadonly={false}
                  isTextarea
                  inputName="narrativeIntro"
                  inputId="narrativeIntro"
                  inputValue={narrativeIntro}
                  inputOnChange={(option) => handleChange(option, 'narrativeIntro')}
                  inputPlaceholder="Explain what the focus has been for this time period and why"
                  inputAutoComplete="off"
                />
              </Col>
            </Row>
          </Fragment>
          <Nav tabs className="tab-list">
            <NavItem className="nav-item-horizontal">
              <a
                href="admin-dashboard#rollup-reporting"
                className={'tab-list-item ' + (activeTab === 'rollup-reporting' ? ' active' : '')}
                onClick={() => setActiveTab('rollup-reporting')}
              >
                <img src={rollup_cal} alt="rollup" /> Create a Rollup Report
              </a>
            </NavItem>
            <NavItem>
              <a
                href="admin-dashboard#individual-reporting"
                className={
                  'tab-list-item' + (activeTab === 'individual-reporting' ? ' active' : '')
                }
                onClick={() => {
                  setActiveTab('individual-reporting');
                  setReportType({ value: '', label: '' });
                }}
              >
                <img src={subpoena} alt="reports" /> Individual Reports and Subpoenas
              </a>
            </NavItem>
            <NavItem>
              <a
                href="admin-dashboard#60days-report"
                className={'tab-list-item' + (activeTab === '60days-report' ? ' active' : '')}
                onClick={() => {
                  setActiveTab('60days-report');
                  setReportType({ value: '', label: '' });
                }}
              >
                <img src={rollup_cal} alt="60daysreport" /> 60 days Report
              </a>
            </NavItem>
            <NavItem>
              <a
                href="admin-dashboard#reportarchive"
                className={'tab-list-item' + (activeTab === 'reportarchive' ? ' active' : '')}
                onClick={() => {
                  setActiveTab('reportarchive');
                  setReportType({ value: '', label: '' });
                }}
              >
                <img src={rollup_cal} alt="60daysreport" /> Reports
              </a>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            {activeTab.includes('rollup-reporting') && (
              <TabPane tabId="rollup-reporting">
                <RollupReport reportName={reportName} narrativeIntro={narrativeIntro} />
              </TabPane>
            )}
            {activeTab.includes('individual-reporting') && (
              <Fragment>
                <TabPane tabId="individual-reporting">
                  <Col className="mt-4 mb-4 ps-0" md="2">
                    <div className="telecom-label ps-0">Select Report Type *</div>
                    <CustomSelect
                      selectedOption={reportType}
                      setSelectedOption={changeReportType}
                      className={'customselect-small'}
                      getOptions={reportTypes}
                      placeholder={'Select Report Type'}
                      typeLabel={''}
                    />
                  </Col>
                  {reportTypes
                    .find((v) => v.value === reportType.value)
                    ?.func(reportName, narrativeIntro, zendeskTicketId)}
                </TabPane>
              </Fragment>
            )}
            {activeTab.includes('60days-report') && (
              <TabPane tabId="60days-report">
                <SixtyDaysReport />
              </TabPane>
            )}
            {activeTab.includes('reportarchive') && (
              <TabPane tabId="reportarchive">
                <ReportsArchive />
              </TabPane>
            )}
          </TabContent>
        </div>
      </CardBody>
    </CardBody>
  );
};

const reportTypes = [
  {
    label: 'Campaign',
    value: 'campaign',
    func: (reportName: string, narrativeIntro: string, zendeskTicketId: string) => (
      <CampaignReport reportName={reportName} narrativeIntro={narrativeIntro} />
    )
  },
  {
    label: 'Provider',
    value: 'provider',
    func: (reportName: string, narrativeIntro: string, zendeskTicketId: string) => (
      <ProviderReport reportName={reportName} narrativeIntro={narrativeIntro} />
    )
  },
  {
    label: 'Status',
    value: 'reputation',
    func: (reportName: string, narrativeIntro: string, zendeskTicketId: string) => (
      <ReputationReport reportName={reportName} narrativeIntro={narrativeIntro} />
    )
  },
  {
    label: 'Subpoena',
    value: 'subpoena',
    func: (reportName: string, narrativeIntro: string, zendeskTicketId: string) => (
      <SubpoenaReport
        reportName={reportName}
        narrativeIntro={narrativeIntro}
        zendeskTicketId={zendeskTicketId}
      />
    )
  },
  {
    label: 'Query',
    value: 'query',
    func: (reportName: string, narrativeIntro: string, zendeskTicketId: string) => (
      <QueryReport reportName={reportName} narrativeIntro={narrativeIntro} />
    )
  },
  {
    label: 'Partner',
    value: 'partner',
    func: (reportName: string, narrativeIntro: string, zendeskTicketId: string) => <PartnerReport />
  },
  {
    label: 'IP Report',
    value: 'ipReport',
    func: (reportName: string, narrativeIntro: string, zendeskTicketId: string) => <IPReport />
  },
  {
    label: 'Quarterly Report',
    value: 'quarterly',
    func: (reportName: string, narrativeIntro: string, zendeskTicketId: string) => (
      <QuarterlyReport />
    )
  },
  {
    label: 'Partner Source',
    value: 'Source',
    func: (reportName: string, narrativeIntro: string, zendeskTicketId: string) => (
      <PartnerSourceReport />
    )
  },
  {
    label: 'TfSubpoena',
    value: 'tfsubpoena',
    func: (reportName: string, narrativeIntro: string, zendeskTicketId: string) => (
      <TfSubpoenaReport
        reportName={reportName}
        narrativeIntro={narrativeIntro}
        zendeskTicketId={zendeskTicketId}
      />
    )
  }
];

export default NavReporting;
