import React from 'react';
import { HoverCardContent, HoverCardRoot, HoverCardTrigger } from './HoverCard';

interface IProps {
  columnType: string;
  tooltipVisible: boolean;
  children: React.ReactNode;
}

const descriptions = [
  { label: 'Tracebacks', value: 'All tracebacks during select period' },
  { label: 'Hops', value: 'All Hops during selected period' },
  { label: 'POE', value: 'Point of Entry to U.S. network (i.e., gateway provider)' },
  { label: 'DownStreamOrigin', value: 'Next provider down from the identified origin ' },
  { label: 'Origin', value: 'U.S. or Non-U.S. Provider that placed call on phone network' },
  { label: 'DownStreamNoResp', value: 'Next Provider down from the identifiend No Response' },
  { label: 'NoResp', value: 'Provider that failed to respond to traceback request' },
  { label: 'NotFound', value: 'Provider did not find records related to traceback request' },
  {
    label: '60DayStatus',
    value:
      'Simple mechanism to alert providers to relative traceback status in current 60-day window, which may not align with filters; intended as tool and should not be viewed as dispositive of provider complicity.'
  },
  {
    label: 'Campaigns',
    value: `Number of individual campaigns associated with provider's tracebacks in select time period; note that multiple campaigns can be associated with similar content (e.g., there are numerous Social Security impersonation campaigns).`
  },
  {
    label: 'LastActive',
    value: 'Last traceback identifying provider in select time period.'
  },
  {
    label: 'noOrgRMD',
    value: 'US origin not listed in RMD'
  },
  {
    label: 'intermediateOrgRMD',
    value: 'US origin listed as Intermediate Only in RMD'
  },
  {
    label: 'noPoeRMD',
    value: 'US Point of Entry not listed in RMD'
  },
  {
    label: 'noDownPoeRMD',
    value: 'Providers may only accept traffic from US Point of Entry providers which are in the RMD'
  },
  {
    label: 'noFpdRMD',
    value: 'Foreign Point of Departure not listed in RMD'
  },
  {
    label: 'noDownFpdRMD',
    value: 'US providers may only accept traffic from Non-US providers which are in the RMD'
  },
  {
    label: 'noPoeFpdRMD',
    value:
      'US Point of Entry not listed in RMD / US providers may only accept traffic from Non-US providers which are in the RMD'
  },
  {
    label: 'multipleRMD',
    value:
      'US Origin, POE or FPD and not listed in RMD, or US Origin and listed as Intermediate Only'
  },
  {
    label: 'notSignedByORG',
    value:
      'The ITG did not receive signing information identifying the originating U.S.-based provider for this traceback.'
  },
  {
    label: 'noSignerInCallPath',
    value:
      'The ITG received signing information identifying a provider that did not appear in the completed tracebacks call path.'
  },
  {
    label: 'elapsed',
    value:
      'All voice service providers are required to respond to traceback requests within 24 hours.  47 CFR § 64.1200(n)(1); see also 47 CFR § 64.6305(d)(2)(iii).'
  }
];

const ColumnTooltip: React.FC<IProps> = ({ columnType, children, tooltipVisible }) => {
  const description = descriptions.find((item) => item.label === columnType);
  if (tooltipVisible) {
    return (
      <HoverCardRoot openDelay={150}>
        <HoverCardTrigger asChild={true}>
          <span style={{ color: 'white' }}>{children}</span>
        </HoverCardTrigger>
        <HoverCardContent className="hoverCardGovWidth" sideOffset={5}>
          {description?.value}
        </HoverCardContent>
      </HoverCardRoot>
    );
  }
  return <span style={{ color: 'white' }}>{children}</span>;
};

export default ColumnTooltip;
