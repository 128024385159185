import { Pagination } from 'reactstrap';
import { ChartFilterElements } from '../../interfaces/charts';
import { StirShakenSearchRequest, StirShakenSigner } from '../../interfaces/stirShaken';
import { ApiRequest, buildParams } from '../../lib/apiRequest';

export const getStirShakensApiCall = async (params: Pagination, req: StirShakenSearchRequest) =>
  ApiRequest.request({
    method: 'POST',
    url:
      `stir_shaken/search?` +
      buildParams({
        ...params
      }),
    data: req
  });

export const updateStirShakenApiCall = async (payload: StirShakenSigner) =>
  ApiRequest.request({
    method: 'POST',
    url: `stir_shaken/updateSigners`,
    data: payload
  });

export const getAttestationStatsApiCall = async (filterElements: ChartFilterElements) =>
  ApiRequest.request({
    method: 'POST',
    url: `stir_shaken/attestationStats`,
    data: filterElements
  });

export const getOriginStatsApiCall = async (filterElements: ChartFilterElements) =>
  ApiRequest.request({
    method: 'POST',
    url: `stir_shaken/originStats`,
    data: filterElements
  });

export const regenerateIssuerListApiCall = async (data: string) =>
  ApiRequest.request({
    method: 'POST',
    url: `stir_shaken/issuersList`,
    data
  });
