import { refreshToken } from './apiRequest';

let tokenInterval;

/**
 * When the user signs in
 */
export function start(options = {}) {
  const { interval = 1 * 60 * 1000, signOut } = options;

  const timedRefresh = attemptRefresh({ signOut });

  tokenInterval = setInterval(timedRefresh, interval);

  return tokenInterval;
}

export function stop() {
  if (typeof tokenInterval === 'undefined') {
    console.error(`sessionManager has not been started`);
    return;
  }

  console.log('Stopping session refresh interval');
  clearInterval(tokenInterval);
}

const fn = () => {};

function attemptRefresh({ signOut = fn } = {}) {
  return async function refreshTimer() {
    try {
      const resp = await refreshToken();

      if (!resp) {
        signOut();
        stop();
      }
    } catch (error) {
      signOut();
      stop();
    }
  };
}
