import React, { useEffect } from 'react';
import { Fragment } from 'react';
import { ChartFilterElements } from '../../interfaces/charts';
import { Chart } from 'react-chartjs-2';
import { stateMappings } from '../../redux/stateMappings';
import { getAttestationStats } from '../../redux/stirShaken/thunks';
import { connect } from 'react-redux';
import { dataAttestationVolume, setChartLegendPosition } from '../../lib/chart/data';

interface IProps {
  height?: number;
  width?: number;
  legendPosition: string;
  chartFilters: ChartFilterElements;
  attestationStats: Object;
  getAttestationStats: Function;
}
const options = {
  responsive: true,
  scales: {
    x: {
      stacked: true
    },
    y: {
      stacked: true
    }
  }
};
const AttestationHighVolumeCampaigns: React.FC<IProps> = ({
  attestationStats,
  getAttestationStats,
  chartFilters,
  height,
  legendPosition
}) => {
  const dataAttestationChart = dataAttestationVolume(attestationStats);
  useEffect(() => {
    getAttestationStats(chartFilters);
  }, [chartFilters.labelId, chartFilters.startDate, chartFilters.endDate, chartFilters.sourceId]);
  return (
    <Fragment>
      {dataAttestationChart && Object.values(attestationStats).length > 0 ? (
        <Chart
          type="bar"
          options={setChartLegendPosition(legendPosition, options)}
          data={dataAttestationChart}
          height={height}
        />
      ) : (
        <h5 className="pt-5">No Data Available</h5>
      )}
    </Fragment>
  );
};
const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    attestationStats: sm.stirShaken.attestationStats
  };
};

const mapActionsToProps = {
  getAttestationStats
};

export default connect(mapStateToProps, mapActionsToProps)(AttestationHighVolumeCampaigns);
