import React, { FC, Fragment, useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { DebounceInput } from 'react-debounce-input';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  Button,
  CardBody,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { userTypes } from '../../../enum/userTypes';
import { Pagination, defaultPagination } from '../../../interfaces/pagination';
import { PersonalInfo } from '../../../interfaces/user';
import {
  FilterElement,
  addCondition,
  newConditionalFilterElement,
  newLeafFilterElement,
  simplifyFilterElement
} from '../../../lib/FilterElement';
import {
  decodeQueryParams,
  encodeQueryParams,
  getFromLocalStorage,
  saveToLocalStorage
} from '../../../lib/history-utils';
import { resendContactEmailApiCall } from '../../../redux/provider/apiCalls';
import { stateMappings } from '../../../redux/stateMappings';
import { deleteUserApiCall } from '../../../redux/user/apiCalls';
import { getUsers } from '../../../redux/user/thunks';
import CustomDataTable from '../../CustomDataTable';
import EmailValidationStatus from '../../EmailValidationStatus';
import ProvidersSelect from '../../ProvidersSelect';
import { SelectOption } from '../../CustomSelect';
import Select from 'react-select';

interface IProp {
  users: PersonalInfo[];
  getUsers: Function;
  paginationTotalRows: number;
}

const userRole = (statusCode: number) => {
  switch (statusCode) {
    case userTypes.DnoUser:
      return 'DNO';
    case userTypes.PartnerUser:
      return 'Partner';
    case userTypes.Provider:
      return 'Provider';
    case userTypes.ProviderManager:
      return 'Provider Manager';
    case userTypes.GovUser:
      return 'Gov';
    case userTypes.Admin:
      return 'Admin';
  }
};

const setFiltersFromHistory = ({ filterElements }: any) => {
  let providerId = 0;
  let isActive = false;
  let name = '';
  let email = '';
  let status;

  if (!filterElements) return { providerId, isActive, name, email };
  const filterableArray = !Array.isArray(filterElements.conditionals)
    ? [filterElements]
    : filterElements.conditionals;

  filterableArray.forEach(
    (condition: { conditionals: any; name: any; value: any; label: string }) => {
      const { conditionals, value } = condition;
      switch (condition.name) {
        case 'active':
          isActive = true;
          break;
        case 'userRole':
          if (value === 'gov') {
            providerId = -1;
          }
          break;
        case 'providerId':
          providerId = Number(value);
          break;
        case 'name':
          name = value;
          break;
        case 'email':
          email = value;
          break;
        case 'status':
          status = value;
          break;
        case undefined:
          if (Array.isArray(conditionals)) {
            conditionals.forEach((condition: { name: string; value: string }) => {
              switch (condition.name) {
                case 'active':
                  isActive = true;
                  break;
                case 'userRole':
                  if (value === 'gov') {
                    providerId = -1;
                  }
                  break;
                case 'providerId':
                  providerId = Number(condition.value);
                  break;
                case 'name':
                  name = condition.value;
                  break;
                case 'email':
                  email = condition.value;
                  break;
                case 'status':
                  status = condition.value;
                  break;
              }
            });
          }
          break;
      }
    }
  );
  return { providerId, isActive, name, email, status };
};

const getFilter = (
  providerId: number,
  isActive: boolean,
  email: string,
  name: string,
  userStatus?: number
): FilterElement => {
  let filterElements = newConditionalFilterElement('AND');

  if (providerId) {
    if (providerId === -1) {
      addCondition(filterElements, newLeafFilterElement('userRole', 'EQ', 'gov'));
    } else if (providerId === -2) {
      addCondition(filterElements, newLeafFilterElement('userRole', 'EQ', 'dno'));
    } else if (providerId === -3) {
      addCondition(filterElements, newLeafFilterElement('userRole', 'EQ', 'partner'));
    } else {
      addCondition(filterElements, newLeafFilterElement('providerId', 'EQ', providerId.toString()));
    }
  }

  if (isActive) addCondition(filterElements, newLeafFilterElement('active', 'EQ', '1'));
  if (email) addCondition(filterElements, newLeafFilterElement('email', 'LIKE', email.trim()));
  if (name) addCondition(filterElements, newLeafFilterElement('name', 'LIKE', name.trim()));
  if (userStatus)
    addCondition(filterElements, newLeafFilterElement('status', 'EQ', userStatus.toString()));

  return simplifyFilterElement(filterElements);
};

const getUserStatuses: SelectOption[] = [
  { label: 'Accepted', value: '0' },
  { label: 'Pending', value: '1' },
  { label: 'Rejected', value: '2' }
];

const Users: FC<IProp> = ({ users, getUsers, paginationTotalRows }) => {
  const navigate = useNavigate();
  const searchParams = getFromLocalStorage('usersHistory') || '';
  const savedSearchDetails = decodeQueryParams('usersHistory', searchParams);

  const historyFilters = setFiltersFromHistory(savedSearchDetails);

  const [paginationParams, setPaginationParams] = useState({
    ...defaultPagination(),
    sort: 'name',
    order: 'asc'
  });
  const [filterElements, setFilterElements] = useState<FilterElement>(
    newConditionalFilterElement('')
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isResendOpen, setIsResendOpen] = useState(false);
  const [userId, setUserId] = useState(0);
  const [userName, setUserName] = useState('');
  const [providerId, setProviderId] = useState(historyFilters.providerId);
  const [isActive, setIsActive] = useState(historyFilters.isActive);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(true);
  const [nameSearch, setNameSearch] = useState(historyFilters.name);
  const [emailSearch, setEmailSearch] = useState(historyFilters.email);
  const [userStatus, setUserStatus] = useState<number | undefined>(historyFilters.status);

  const columns: TableColumn<PersonalInfo>[] = [
    {
      name: '',
      width: '20px',
      cell: () => <div />
    },
    {
      name: 'Name',
      selector: () => 'u.name',
      sortable: true,
      grow: 2,
      cell: (user: PersonalInfo) => (
        <div className="table-cell">
          <span>{user.name}</span>
        </div>
      )
    },
    {
      name: 'Email',
      sortable: true,
      selector: () => 'email',
      grow: 3,

      cell: (user: PersonalInfo) => (
        <div className="table-cell">
          <span>{user.email}</span>
        </div>
      )
    },
    {
      name: 'Phone',
      sortable: true,
      selector: () => 'u.phone_number',
      grow: 1,
      cell: (user: PersonalInfo) => (
        <div style={{ textAlign: 'center' }}>
          {user.phoneNumber.toString() === '' ? (
            <i className="fa-solid fa-xmark" style={{ color: 'red', paddingLeft: 40 }}></i>
          ) : (
            <span>{user.phoneNumber}</span>
          )}
        </div>
      )
    },
    {
      name: 'Company',
      sortable: true,
      selector: () => 'p.name',
      grow: 3,
      cell: (user: PersonalInfo) => (
        <div className="table-cell" style={{ textAlign: 'center' }}>
          {user.companyName ? (
            <span>{user.companyName}</span>
          ) : (
            <i className="fa-solid fa-xmark" style={{ color: 'red', paddingLeft: 10 }}></i>
          )}
        </div>
      )
    },
    {
      name: 'Role',
      sortable: true,
      selector: () => 'u.role',
      grow: 2,
      cell: (user: PersonalInfo) => {
        return (
          <div className="table-cell" style={{ textAlign: 'center' }}>
            {userRole(user.roleType)}
          </div>
        );
      }
    },
    {
      name: <div>Email Validation</div>,
      grow: 2,
      cell: (user: PersonalInfo) => {
        return (
          <div className="table-cell" style={{ textAlign: 'center' }}>
            <EmailValidationStatus emailValidation={user.emailValidation} />
          </div>
        );
      }
    },
    {
      name: <div>Traceback Emails</div>,
      grow: 1,
      cell: (user: PersonalInfo) => (
        <div>
          {user.isTBEmail ? (
            <i className="fa-solid fa-check" style={{ color: 'green' }}></i>
          ) : (
            <i className="fa-solid fa-xmark" style={{ color: 'red', paddingLeft: 10 }}></i>
          )}
        </div>
      )
    },
    {
      name: <div>Escalation Emails</div>,
      grow: 1,
      cell: (user: PersonalInfo) => (
        <div>
          {user.isEscalationEmail ? (
            <i className="fa-solid fa-check" style={{ color: 'green' }}></i>
          ) : (
            <i className="fa-solid fa-xmark" style={{ color: 'red', paddingLeft: 10 }}></i>
          )}
        </div>
      )
    },
    {
      name: 'Active',
      grow: 1,
      cell: (user: PersonalInfo) => (
        <div>
          {user.isActive ? (
            <i className="fa-solid fa-check" style={{ color: 'green', paddingLeft: 30 }}></i>
          ) : (
            <i className="fa-solid fa-xmark" style={{ color: 'red', paddingLeft: 40 }}></i>
          )}
        </div>
      )
    },
    {
      name: 'Resend Invite',
      grow: 1,
      cell: (user: PersonalInfo) => (
        <div onClick={() => populateModalInfo(user.name, user.email)} style={{ paddingLeft: 25 }}>
          {user.isActive ? (
            <i
              className="fa-solid fa-paper-plane"
              style={{ color: '#0f73b2', cursor: 'pointer' }}
            ></i>
          ) : null}
        </div>
      )
    },
    {
      name: 'Edit',
      grow: 1,
      cell: (user: PersonalInfo) => (
        <a href={`/users/user/${user.id}`} style={{ paddingLeft: 35, cursor: 'pointer' }}>
          <i className="fa-solid fa-pencil blue"></i>
        </a>
      )
    },
    {
      name: 'Delete',
      grow: 1,
      cell: (user: PersonalInfo) => (
        <div style={{ paddingLeft: 35 }}>
          {user.isActive ? (
            <i
              onClick={() => selectUser(user.id, user.name)}
              className="fa-solid fa-trash-can"
              style={{ color: 'red', cursor: 'pointer' }}
            ></i>
          ) : null}
        </div>
      )
    }
  ];

  const selectUser = (id: number, name: string) => {
    setIsModalOpen(true);
    setUserId(id);
    setUserName(name);
  };

  const populateModalInfo = (name: string, email: string) => {
    toggleResendModal();
    setUserName(name);
    setEmail(email);
  };

  const resendEmail = async () => {
    setLoading(true);
    await resendContactEmailApiCall(email);
    setLoading(false);
    toggleResendModal();
  };

  useEffect(() => {
    if (complete) {
      setComplete(false);
      return;
    }
    getData();
  }, [paginationParams, filterElements]);

  useEffect(() => {
    setPaginationParams({ ...paginationParams, page: 1 });
    setFilterElements(() => getFilter(providerId, isActive, emailSearch, nameSearch, userStatus));
  }, [providerId, isActive, nameSearch, emailSearch, userStatus]);

  const getData = () => {
    const searchParams = encodeQueryParams('usersHistory', { filterElements }, {});
    saveToLocalStorage('usersHistory', searchParams);
    getUsers(paginationParams, filterElements);
  };

  const toggleModal = () => {
    setIsModalOpen((v) => !v);
  };

  const toggleResendModal = () => {
    setIsResendOpen((v) => !v);
  };

  const updatePagination = (params: Pagination) => {
    setPaginationParams({ ...paginationParams, ...params });
  };

  const deleteUser = async () => {
    await deleteUserApiCall(userId);
    window.location.reload();
  };

  const handleChange = (e: any, option: string) => {
    switch (option) {
      case 'provider':
        setProviderId(e.value);
        break;
      case 'isActive':
        setIsActive((v) => !v);
        break;
      case 'name':
        setNameSearch(e.value);
        break;
      case 'email':
        setEmailSearch(e.value);
        break;
      case 'status':
        setUserStatus(e.value);
        break;
    }
  };

  const clearFilters = () => {
    setIsActive(true);
    setProviderId(0);
    setEmailSearch('');
    setNameSearch('');
    setUserStatus(undefined);
  };

  return (
    <Fragment>
      <CardBody>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="col-10 d-flex align-items-center">
            <Label className="traceback-heading me-3 col-2" style={{ fontSize: '36px' }}>
              Users
            </Label>
            <div className="col-2 me-1">
              <DebounceInput
                type={'text'}
                className={'form-control'}
                placeholder={'Search by Name'}
                onChange={(event) => handleChange(event.target, 'name')}
                value={nameSearch}
                minLength={2}
                debounceTimeout={1000}
              />
            </div>
            <div className="col-2 me-1">
              <DebounceInput
                type={'text'}
                className={'form-control'}
                placeholder={'Search by Email'}
                onChange={(event) => handleChange(event.target, 'email')}
                value={emailSearch}
                minLength={2}
                debounceTimeout={1000}
              />
            </div>
            <div className="col-3 me-3">
              <ProvidersSelect
                addAllItem
                className={'customselect-large'}
                addGovProvider
                onChange={(e) => handleChange(e, 'provider')}
                option={providerId}
                includeInactive
              />
            </div>
            <div className="col-1 me-3">
              <Select
                classNamePrefix="customselect"
                className="customselect-large"
                value={getUserStatuses.find((s) => s.value === userStatus) || null}
                onChange={(e) => handleChange(e, 'status')}
                options={getUserStatuses}
                placeholder="User Status"
              />
            </div>
            <input
              style={{ width: '24px', height: '24px' }}
              type="checkbox"
              checked={isActive}
              onChange={(e) => handleChange(e, 'isActive')}
            />
            <Label className="telecom-label ps-2">Active</Label>
            <button type="button" className="btn btn-link" onClick={() => clearFilters()}>
              Clear
            </button>
          </div>
          <div className="col-2 d-flex align-items-center justify-content-end">
            <Button
              color="primary"
              className="telecom-btn"
              onClick={() => navigate('/admin-dashboard#users/add')}
            >
              Add User
            </Button>
          </div>
        </div>
        <Row>
          <Col lg="2">
            <Row></Row>
          </Col>
          <Col></Col>
        </Row>
      </CardBody>

      <CardBody className="card-body-traceback">
        <CustomDataTable
          tableData={users}
          columns={columns}
          defaultSortAsc={paginationParams.order === 'asc'}
          defaultSortFieldId={paginationParams.sort}
          defaultPage={paginationParams.page}
          defaultPageSize={paginationParams.pageSize}
          paginationTotalRows={paginationTotalRows}
          pagination={true}
          updatePaginationParams={updatePagination}
        />
      </CardBody>

      <Modal centered className="submit-confirm-modal" isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Mark user as inactive</ModalHeader>
        <ModalBody>Do you want to make the user {userName} inactive ?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={deleteUser}>
            Submit
          </Button>

          <Button color="primary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal centered className="modal-template" isOpen={isResendOpen} toggle={toggleResendModal}>
        <ModalHeader>Resend confirmation email</ModalHeader>
        <ModalBody>
          Are you sure that you want to resend the confirmation email to the following contact
          <br />
          <div style={{ paddingTop: '15px' }}>
            <b>Name:</b>
            {' ' + userName}
            <br />
            <b>Email:</b>
            {' ' + email}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => resendEmail()} color="primary">
            {loading ? 'Loading...' : 'Send'}
          </Button>{' '}
          <Button color="danger" onClick={() => toggleResendModal()}>
            Cancel
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    users: sm.users.users,
    paginationTotalRows: sm.users.meta.TotalCount
  };
};

const mapActionsToProps = { getUsers };

export default connect(mapStateToProps, mapActionsToProps)(Users);
