export const GET_PARTNERS = 'GET_PARTNERS';
export const GET_ALL_PARTNERS = 'GET_ALL_PARTNERS';
export const GET_PARTNER = 'GET_PARTNER';
export const GET_ALL_PARTNER_STATS = 'GET_ALL_PARTNER_STATS';
export const CREATE_EVENT = 'CREATE_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const SET_PARTNERS_LOADING_STATUS = 'SET_PARTNERS_LOADING_STATUS';
export const SET_PARTNERS_ERROR_MESSAGE = 'SET_PARTNERS_ERROR_MESSAGE';
export const RESET_REDIRECT_ERROR = 'RESET_REDIRECT_ERROR';
