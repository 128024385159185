import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { getNotificationsNewCommentsList } from '../../redux/comment/thunks';
import { stateMappings } from '../../redux/stateMappings';

import { useLocation } from 'react-router';
import { Col, Row } from 'reactstrap';

import IconCounterDisplay from '../IconCounterDisplay';
import AddHopComment, { directedToValues } from './AddHopComment';
import HopComments from './HopComments';

import { NotificationNewComment } from '../../interfaces/comment';
import { Hop } from '../../interfaces/hop';
import { PersonalInfo } from '../../interfaces/user';

interface IProps {
  hop: Hop;
  isCallSourcePopulated: boolean;
  user: PersonalInfo;
  getNotificationsNewCommentsList: Function;
  newCommentsNotificationList: NotificationNewComment[];
  notificationsCount: number;
}

const HopCommentsSection: React.FC<IProps> = ({
  hop,
  isCallSourcePopulated,
  user,
  getNotificationsNewCommentsList,
  newCommentsNotificationList,
  notificationsCount
}) => {
  const { hash } = useLocation();
  const [visibleComments, setVisibleComments] = useState(
    hash !== '' || hop.comments?.length || hop.relatedComments?.length ? true : false
  );

  const handleCommentsDropDown = () => {
    setVisibleComments(!visibleComments);
  };

  useEffect(() => {
    getNotificationsNewCommentsList('hop', hop.hopId);
  }, [
    newCommentsNotificationList.length,
    hop.comments?.length,
    hop.relatedComments?.length,
    notificationsCount
  ]);

  return (
    <div>
      <div className="d-flex align-items-center pb-3 ">
        <h5 className="p-0 m-0 pe-1" onClick={handleCommentsDropDown} role="button">
          <i
            className={`${
              visibleComments ? 'fa-solid fa-angle-down' : 'fa-solid fa-angle-right'
            } pe-2`}
            style={{ color: '#08335d' }}
          ></i>
          Comments
        </h5>
        {newCommentsNotificationList.length > 0 && (
          <IconCounterDisplay count={newCommentsNotificationList.length} />
        )}
      </div>

      <Row className="me-3">
        <Col>
          <div
            className={`${
              visibleComments ? 'd-flex' : 'd-none'
            } flex-column highlighted-background me-0 ms-n3`}
          >
            {directedToValues(
              user.roleType,
              hop.hopProvider.name,
              hop.upstreamProvider ? hop.upstreamProvider.name : '',
              hop.downstreamProvider ? hop.downstreamProvider.name : '',
              hop.comments.some((comment) => comment.directedTo === 'oldUpstream')
            ).map((directedTo) => (
              <HopComments
                key={`hopSection-${directedTo.value}`}
                hop={hop}
                isCallSourcePopulated={isCallSourcePopulated}
                directedTo={directedTo}
              />
            ))}
            <div className="d-flex justify-content-center mb-5">
              <Col className="col-10 hop-detail-form-dark pb-3">
                <AddHopComment
                  isCallSourcePopulated={isCallSourcePopulated}
                  hopID={hop.hopId}
                  hopProviderName={hop.hopProvider.name}
                  upstreamProviderName={hop.upstreamProvider ? hop.upstreamProvider.name : ''}
                  downstreamProviderName={hop.downstreamProvider ? hop.downstreamProvider.name : ''}
                />
              </Col>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    newCommentsNotificationList: sm.comment.newCommentsNotificationList,
    notificationsCount: sm.notification.notificationsCount
  };
};

const mapActionToProps = {
  getNotificationsNewCommentsList
};
export default connect(mapStateToProps, mapActionToProps)(HopCommentsSection);
