import {
  GET_PARTNERS,
  GET_ALL_PARTNERS,
  GET_PARTNER,
  GET_ALL_PARTNER_STATS,
  SET_PARTNERS_ERROR_MESSAGE,
  SET_PARTNERS_LOADING_STATUS,
  CREATE_EVENT,
  DELETE_EVENT,
  UPDATE_EVENT,
  RESET_REDIRECT_ERROR
} from './actionEnum';

export const getPartnersList = (response: any) => ({
  type: GET_PARTNERS,
  payload: response
});

export const getAllPartners = (response: any) => ({
  type: GET_ALL_PARTNERS,
  payload: response
});

export const getPartnerStats = (response: any) => ({
  type: GET_PARTNER,
  payload: response
});

export const getAllPartnerStatsAction = (response: any) => ({
  type: GET_ALL_PARTNER_STATS,
  payload: response
});

export const createEventAction = (response?: any) => ({
  type: CREATE_EVENT,
  payload: response
});

export const updateEventAction = () => ({
  type: UPDATE_EVENT
});

export const deleteEventAction = () => ({
  type: DELETE_EVENT
});

export const setLoadingStatus = (candidate: boolean) => ({
  type: SET_PARTNERS_LOADING_STATUS,
  payload: candidate
});

export const setErrorMessage = (message: string) => ({
  type: SET_PARTNERS_ERROR_MESSAGE,
  payload: message
});

export const resetRedirectAndError = () => ({
  type: RESET_REDIRECT_ERROR
});
