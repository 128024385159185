import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Button, Card, CardBody, CardHeader, Col } from 'reactstrap';
import Breadcrumb from '../components/Breadcrumbs';
import CustomDataTable from '../components/CustomDataTable';
import PartnersExpandableRow from '../components/PartnersExpandableRow';
import { Pagination, defaultPagination } from '../interfaces/pagination';
import {
  addConditionGroup,
  newConditionalFilterElement,
  simplifyFilterElement
} from '../lib/FilterElement';
import {
  decodeQueryParams,
  encodeQueryParams,
  getFromLocalStorage,
  saveToLocalStorage
} from '../lib/history-utils';
import { dateOptions, getDateFromPeriod } from '../lib/utilities';
import { getPartners, resetErrorAndRedirect } from '../redux/partners/thunks';
import { stateMappings } from '../redux/stateMappings';
import { partnerSummaryTableColumns } from '../lib/dataTableUtils/partnerTables';

interface IProps {
  partnersSummary: any;
  paginationMeta: any;
  getPartners: Function;
  resetErrorAndRedirect: Function;
}

const Partners: React.FC<IProps> = ({
  partnersSummary,
  paginationMeta,
  getPartners,
  resetErrorAndRedirect
}) => {
  const navigate = useNavigate();
  const searchParams = getFromLocalStorage('partners') || '';
  const savedSearchDetails = decodeQueryParams('partners', searchParams);

  const [paginationParams, setPaginationParams] = useState(
    savedSearchDetails.paginationParams || {
      ...defaultPagination(),
      sort: 'requestorName',
      order: 'asc'
    }
  );
  const [selectedPeriod, setSelectedPeriod] = useState(
    savedSearchDetails.period || {
      value: 1,
      label: 'Last 30 Days'
    }
  );
  const [dates, setDates] = useState(getDateFromPeriod(selectedPeriod));

  useEffect(() => {
    resetErrorAndRedirect();
  }, []);

  const getData = async () => {
    const filterElements = getFilterElement();

    const searchParams = encodeQueryParams(
      'partners',
      {
        filterElements,
        paginationParams: paginationParams,
        period: selectedPeriod
      },
      {}
    );
    saveToLocalStorage('partners', searchParams);

    getPartners(paginationParams, filterElements);
  };

  const handlePaginationChange = (params: Pagination) => {
    const newParams = { ...paginationParams, ...params };
    setPaginationParams(newParams);
  };

  const handlePeriodChange = (e: any) => {
    setSelectedPeriod(e);
    setDates(getDateFromPeriod(e));

    setPaginationParams({
      ...paginationParams,
      page: 1
    });
  };

  const getFilterElement = () => {
    const { startDate, endDate } = dates;
    let filterElements = newConditionalFilterElement('AND');

    if (startDate || endDate) {
      addConditionGroup('AND', filterElements, [
        { key: startDate ? 'startDate' : '', value: startDate },
        { key: endDate ? 'endDate' : '', value: endDate }
      ]);
    }

    return simplifyFilterElement(filterElements);
  };

  useEffect(() => {
    getData();
  }, [selectedPeriod, paginationParams]);

  return (
    <Fragment>
      <Breadcrumb title="Partners" className="table-breadcrumbs" />
      <Card className="table-card">
        <CardHeader className="card-header-provider">
          <div
            className="d-flex"
            style={{ padding: '0 12px', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Col md={3}>
              <Select
                className="customselect-small"
                classNamePrefix="customselect"
                value={selectedPeriod}
                onChange={handlePeriodChange}
                options={dateOptions}
                isSearchable={false}
              />
            </Col>
            <Button color="primary" onClick={() => navigate('/partners/event/add')}>
              Add Event
            </Button>
          </div>
        </CardHeader>
        <CardBody className="card-body-hop">
          <CustomDataTable
            tableData={partnersSummary}
            columns={partnerSummaryTableColumns}
            pagination={true}
            paginationTotalRows={paginationMeta.TotalCount}
            defaultSortFieldId={paginationParams.sort}
            defaultSortAsc={paginationParams.order === 'asc'}
            defaultPage={paginationParams.page}
            defaultPageSize={paginationParams.pageSize}
            updatePaginationParams={handlePaginationChange}
            useExpandableRows={true}
            useExpandableRowsComponent={({ data }) =>
              data ? (
                <PartnersExpandableRow
                  data={data}
                  key={`PartnersExpandableRow-${data.requestorId}`}
                  startDate={dates.startDate}
                  endDate={dates.endDate}
                />
              ) : null
            }
          />
        </CardBody>
      </Card>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    partnersSummary: sm.partners.partnersSummary,
    paginationMeta: sm.partners.partnersPaginationMeta
  };
};

const mapActionsToProps = {
  getPartners,
  resetErrorAndRedirect
};

export default connect(mapStateToProps, mapActionsToProps)(Partners);
