import { Provider } from './actionEnum';

const meta = {
  TotalCount: 0,
  PageSize: 0,
  CurrentPage: 0,
  TotalPages: 1
};

const defaultState: any = {
  providers: [],
  providersCountries: [],
  allProvidersList: [],
  provider: {},
  providerContacts: [],
  attachments: [],
  providerTypes: [],
  meta,
  loading: 0,
  error: '',
  upstreamSummary: [],
  reputationLevels: [],
  providerStats: {},
  allProvidersStats: {},
  providerNRStats: [],
  providerORGStats: [],
  providerNames: [],
  providerActionsStats: [],
  providerAverageDailyTrafficStats: [],
  isNrProvider: false,
  newProvidersList: [],
  countNewProviders: 0,
  nrProvidersList: [],
  countNrProviders: 0,
  nfProvidersList: [],
  countNfProviders: 0,
  providersIpInfo: [],
  states: [],
  providerStirShakenStats: {}
};

const reducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case Provider.GET_PROVIDER_LIST:
      return { ...state, allProvidersList: action.payload.data };

    case Provider.GET_PROVIDERS:
      return {
        ...state,
        providers: action.payload.data,
        meta: action.payload.paginationMeta || meta
      };

    case Provider.GET_PROVIDER:
      return {
        ...state,
        provider: action.payload.data.data
      };

    case Provider.GET_PROVIDERS_COUNTRIES:
      return {
        ...state,
        providersCountries: action.payload
      };

    case Provider.ADD_PROVIDER:
      return { ...state };

    case Provider.EDIT_PROVIDER:
      return { ...state, provider: action.payload.data };

    case Provider.ADD_ATTACHMENTS:
      return { ...state };

    case Provider.GET_ATTACHMENTS:
      return { ...state, attachments: action.payload.data };

    case Provider.GET_PROVIDER_CONTACTS:
      return { ...state, providerContacts: action.payload.data };

    case Provider.SET_PROVIDER_CONTACTS:
      return { ...state, providerContacts: action.payload.data };

    case Provider.GET_PROVIDER_TYPES:
      return { ...state, providerTypes: action.payload.data };

    case Provider.SET_PROVIDER_LOADING_STATUS:
      if (action.payload) return { ...state, loading: state.loading + 1 };
      else return { ...state, loading: state.loading === 0 ? state.loading : state.loading - 1 };

    case Provider.SET_PROVIDER_ERROR_MESSAGE:
      return { ...state, error: action.payload };

    case Provider.GET_PROVIDER_SUMMARY_LOW:
      return { ...state, summaryLow: action.payload.data };
    case Provider.GET_PROVIDER_SUMMARY_HIGH:
      return { ...state, summaryHigh: action.payload.data };
    case Provider.GET_PROVIDER_SUMMARY_INTERNATIONAL:
      return { ...state, summaryInternational: action.payload.data };

    case Provider.GET_PROVIDER_UPSTREAMSUMMARY:
      //TODO: Need to figure out if this meta thing is still needed -- SEEMS ODD
      return {
        ...state,
        upstreamSummary: action.payload.data,
        meta: action.payload.paginationMeta || meta
      };

    case Provider.GET_PROVIDER_STATS:
      return { ...state, providerStats: action.payload.data };
    case Provider.GET_PROVIDER_NAMES:
      return { ...state, providerNames: action.payload.data };
    case Provider.GET_ALL_PROVIDERS_STATS:
      return { ...state, allProvidersStats: action.payload.data };

    case Provider.GET_PROVIDERS_NR_STATS:
      return { ...state, providerNRStats: action.payload.data };

    case Provider.GET_PROVIDERS_ORG_STATS:
      return { ...state, providerORGStats: action.payload.data };

    case Provider.GET_PROVIDER_ACTIONS_STATS:
      return { ...state, providerActionsStats: action.payload.data };

    case Provider.GET_AVERAGE_DAILY_TRAFFIC_STATS:
      return { ...state, providerAverageDailyTrafficStats: action.payload.data };

    case Provider.GET_PROVIDER_NR:
      return { ...state, isNrProvider: action.payload.isNrProvider };

    case Provider.GET_NEW_PROVIDERS_INFO:
      return {
        ...state,
        newProvidersList: action.payload.data || [],
        countNewProviders: action.payload.count
      };

    case Provider.GET_NR_PROVIDERS_INFO:
      return {
        ...state,
        nrProvidersList: action.payload.data || [],
        countNrProviders: action.payload.count
      };
    case Provider.GET_NF_PROVIDERS_INFO:
      return {
        ...state,
        nfProvidersList: action.payload.data || [],
        countNfProviders: action.payload.count
      };
    case Provider.GET_PROVIDERS_IP_INFO:
      return {
        ...state,
        providersIpInfo: action.payload.data || []
      };

    case Provider.GET_PROVIDERS_STATES:
      return { ...state, states: action.payload.data };

    case Provider.GET_PROVIDER_STIRSHAKEN_STATS:
      return { ...state, providerStirShakenStats: action.payload.data };

    default:
      return state;
  }
};

export default reducer;
