import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import Select, { OnChangeValue } from 'react-select';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { DateFormat } from '../../enum/DateFormat';
import { Traceback } from '../../interfaces/traceback';
import { DnoToolTip } from '../../lib/dno';
import { callingNumberIsAllowed, validatePhoneNumber } from '../../lib/phoneNumber';
import { validUrlRegex } from '../../lib/regex';
import {
  getApiFormattedDate,
  getClientFormattedDate,
  getElapsedTime,
  isValidDateTime
} from '../../lib/utilities';
import { stateMappings } from '../../redux/stateMappings';
import { getCurrentTraceback } from '../../redux/traceback/apiCalls';
import CampaignsSelect from '../CampaignsSelect';
import { SelectOption } from '../CustomSelect';
import CustomToolTip from '../CustomToolTip';
import DropZone from '../DropZone';
import ProvidersSelect from '../ProvidersSelect';
import InputError from '../inputError';
import InputFormGroup from '../inputFormGroup';
import CommentComponent from '../shared/CommentComponent';
import RequestorPopover from '../shared/RequestorPopover';

interface Error {
  campaignName: string;
  calledTN: string;
  callingTN: string;
  audioUrl: string;
  tracebackTime: string;
  //   comment: string;
  //   termCarrierOCN: string;
  //   requestor: string;
}

const defaultError = (): Error => ({
  campaignName: '',
  calledTN: '',
  callingTN: '',
  audioUrl: '',
  tracebackTime: ''
  //   comment: '',
  //   termCarrierOCN: '',
  //   requestor: ''
});
const getError = (
  isAdd: boolean,
  campaignID: number,
  calledTN: string,
  callingTN: string,
  tracebackTime: string,
  audioUrl: string,
  acceptWrongNumberFormat: boolean
): Error => {
  return {
    campaignName: !campaignID ? 'Please provide a valid campaign name.' : '',
    // only check for errors on this fields if is add traceback => edit traceback can't change this yet
    calledTN: !isAdd
      ? ''
      : !calledTN
        ? 'Please provide a valid called number.'
        : !acceptWrongNumberFormat && !validatePhoneNumber(calledTN)
          ? 'Please provide a valid called number.'
          : '',
    callingTN: !isAdd
      ? ''
      : !acceptWrongNumberFormat &&
          !validatePhoneNumber(callingTN) &&
          !callingNumberIsAllowed(callingTN)
        ? 'Please provide a valid calling number.'
        : '',
    tracebackTime: !isAdd
      ? ''
      : !tracebackTime
        ? 'Please provide a valid date and time (YYYY-MM-DD HH:MM).'
        : !isValidDateTime(tracebackTime)
          ? 'Please provide a valid date and time (YYYY-MM-DD HH:MM).'
          : '',
    audioUrl: !isAdd
      ? ''
      : !audioUrl
        ? ''
        : !validUrlRegex.test(audioUrl)
          ? 'Please provide a valid audio url.'
          : ''
  };
};

interface TracebackError {
  message: string;
  associateProvider: boolean;
}

interface IProps {
  traceback: Traceback;
  createdBy: string;
  isAdd?: boolean;
  tracebackStatuses?: { [key: number]: string };
  tAttachments?: any[];
  tracebackError?: TracebackError;
  tracebackServer: Function;
}

const TracebackEditor: React.FC<IProps> = ({
  traceback,
  createdBy,
  isAdd,
  tracebackStatuses,
  tAttachments,
  tracebackError,
  tracebackServer
}) => {
  const navigate = useNavigate();

  const [campaignId, setCampaignId] = useState(traceback.campaignId);
  const [strikeExempt, setStrikeExempt] = useState(traceback.strikeExempt);
  const [calledTN, setCalledTN] = useState(traceback.calledTN);
  const [callingTN, setCallingTN] = useState(traceback.callingTN);
  const [audioUrl, setAudioUrl] = useState(traceback.audioUrl);
  const [tracebackTime, setTracebackTime] = useState(traceback.tracebackTime);
  const [termCarrierOCN, setTermCarrierOCN] = useState(traceback.termCarrierOCN);
  const [requestors, setRequestors] = useState<SelectOption[]>(
    traceback && traceback.requestors
      ? traceback.requestors.map((v) => ({ value: v.requestorId, label: v.name }))
      : []
  );
  const [providerId, setProviderId] = useState(traceback.providerId);

  const [comment, setComment] = useState('');
  const [attachments, setAttachments] = useState<File[]>([]);
  const [audioAttachments, setAudioAttachments] = useState<File[]>([]);

  const [error, setError] = useState(defaultError());
  const [alreadyExistingModal, setAlreadyExistingModal] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [identicalTracebacks, setIdenticalTracebacks] = useState([]);

  const [acceptWrongNumberFormat, setAcceptWrongNumberFormat] = useState(false);
  const [isPhoneNumberWrong, setIsPhoneNumberWrong] = useState(false);

  useEffect(() => {
    if (
      error.campaignName ||
      error.calledTN ||
      error.callingTN ||
      error.audioUrl ||
      error.tracebackTime
    )
      setIsSubmit(false);
  }, [error]);

  useEffect(() => {
    setIsPhoneNumberWrong(
      (!!calledTN && !validatePhoneNumber(calledTN)) ||
        (!!callingTN && !validatePhoneNumber(callingTN))
    );
  }, [error.calledTN, error.callingTN]);

  useEffect(() => {
    if (acceptWrongNumberFormat)
      setError(
        getError(
          !!isAdd,
          campaignId,
          calledTN,
          callingTN,
          tracebackTime,
          audioUrl,
          acceptWrongNumberFormat
        )
      );
  }, [acceptWrongNumberFormat]);

  const handleCampaignChange = (selectedOption: OnChangeValue<SelectOption, boolean>) => {
    setCampaignId((selectedOption as SelectOption).value);
    setError((v) => ({ ...v, campaignName: '' }));
  };
  const handleStrikeExemptChange = () => {
    setStrikeExempt((v) => !v);
  };
  const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { name, value } = event.currentTarget;
    switch (name) {
      case 'calledTN': {
        setError((v) => ({ ...v, calledTN: '' }));
        setCalledTN(value);
        break;
      }
      case 'callingTN': {
        setError((v) => ({ ...v, callingTN: '' }));
        setCallingTN(value);
        break;
      }
      case 'audioUrl': {
        setError((v) => ({ ...v, audioUrl: '' }));
        setAudioUrl(value);
        break;
      }
      case 'comment': {
        setComment(value);
        setError((v) => ({ ...v, comment: '' }));
        break;
      }
      case 'tracebackTime': {
        setError((v) => ({ ...v, tracebackTime: '' }));
        setTracebackTime(value);
        break;
      }

      case 'termCarrierOCN': {
        setError((v) => ({ ...v, termCarrierOCN: '' }));
        setTermCarrierOCN(value);
        break;
      }
      default:
        break;
    }
  };

  const removeFile = (file: File) => {
    setAttachments((v) => v.filter((item) => item.name !== file.name));
  };
  const removeAudioFile = (file: File) => {
    setAudioAttachments((v) => v.filter((item) => item.name !== file.name));
  };
  const addFile = (files: File[]) => {
    setAttachments((v) => {
      return [...v, ...files.filter((file) => !v.find((item) => item.name === file.name))];
    });
  };
  //TODO: Adjust addAudioFile to allow only one
  const addAudioFile = (files: File[]) => {
    setAudioAttachments((v) => {
      return [...v, ...files.filter((file) => !v.find((item) => item.name === file.name))];
    });
    setAudioUrl('');
  };

  const toggleExistingModal = () => {
    setAlreadyExistingModal((v) => !v);
  };

  const handleCancelClick = (e: any) => {
    e.preventDefault();
    if (isAdd) navigate('/tracebacks');
    else window.location.reload();
  };

  const checkForExistingTraceback = async (e: any) => {
    const error = getError(
      !!isAdd,
      campaignId,
      calledTN,
      callingTN,
      tracebackTime,
      audioUrl,
      acceptWrongNumberFormat
    );
    setError(error);
    if (
      error.campaignName ||
      error.calledTN ||
      error.callingTN ||
      error.audioUrl ||
      error.tracebackTime
    )
      return;

    const {
      data: { data }
    } = await getCurrentTraceback(calledTN, callingTN, getApiFormattedDate(tracebackTime));

    setIdenticalTracebacks(data);

    if (data && data.length > 0) setAlreadyExistingModal(true);
    else submitForm(e);
  };

  const submitForm = (e: any) => {
    e.preventDefault();
    setAlreadyExistingModal(false);
    const error = getError(
      !!isAdd,
      campaignId,
      calledTN,
      callingTN,
      tracebackTime,
      audioUrl,
      acceptWrongNumberFormat
    );
    setError(error);
    if (
      error.campaignName ||
      error.calledTN ||
      error.callingTN ||
      error.audioUrl ||
      error.tracebackTime
    )
      return;

    const t: Traceback = {
      ...traceback,
      //TODO: This SHOULD NOT be needed... is it?
      create_date: isAdd ? getApiFormattedDate(new Date().toISOString()) : traceback.create_date,
      createdBy: isAdd ? createdBy : traceback.createdBy,

      tracebackTime: getApiFormattedDate(tracebackTime),
      campaignId: campaignId,
      strikeExempt: strikeExempt,
      calledTN: calledTN,
      callingTN: callingTN,
      audioUrl: audioUrl,
      termCarrierOCN: termCarrierOCN,
      requestors: requestors.map((v) => ({ name: v.label, requestorId: v.value })),
      providerId: providerId,
      associateProvider: tracebackError?.associateProvider
    };
    tracebackServer(t, comment, attachments, audioAttachments);
  };

  const systemComments = (traceback.comments || []).filter(
    (comment: any) => comment.isSystemComment
  );

  return (
    <Fragment>
      {isAdd ? (
        <div>
          <Form className={'form-traceback traceback-add'}>
            <Row className="justify-content-start">
              <FormGroup className="col-md-5 mb-0">
                <label className="label-bold">
                  Campaign Name
                  <i className="fa fa-asterisk asterisk" />
                </label>
                <CampaignsSelect
                  value={campaignId}
                  onChange={handleCampaignChange}
                  selectClass="customselect-large"
                  placeholder="Select..."
                />
                <InputError className="telecom-input-error">{error.campaignName}</InputError>
              </FormGroup>
            </Row>
            <FormGroup>
              <h5 className="col-md-11 p-0 traceback-sub-title">Call Details</h5>
              <Row>
                <Col md="5">
                  <label className="label-bold">
                    <span
                      className="fa fa-phone"
                      style={{
                        color: 'RED',
                        marginRight: '5px'
                      }}
                    />
                    Called Number
                    <i className="fa fa-asterisk asterisk" />
                  </label>
                  <InputFormGroup
                    inputName="calledTN"
                    inputId="calledTN"
                    inputClassName="input-traceback"
                    inputValue={calledTN}
                    inputPlaceholder="2025558387"
                    inputOnChange={handleInputChange}
                    inputAutoComplete="off"
                    errorMessage={error.calledTN}
                  />
                </Col>
                <Col md="5">
                  <label className="label-bold">
                    <span
                      className="fa fa-phone"
                      style={{
                        color: 'green',
                        marginRight: '5px'
                      }}
                    />
                    Calling Number
                    <i className="fa fa-asterisk asterisk" />
                  </label>
                  <InputFormGroup
                    inputName="callingTN"
                    inputId="callingTN"
                    inputClassName="input-traceback"
                    inputValue={callingTN}
                    inputPlaceholder="6175553380"
                    inputOnChange={handleInputChange}
                    inputAutoComplete="off"
                    errorMessage={error.callingTN}
                  />
                </Col>
              </Row>
              {isPhoneNumberWrong && (
                <Row className="d-flex justify-content-center">
                  <Col md="5">
                    <FormGroup>
                      <label className="form-label checkbox-label">
                        Are the phone numbers correct?
                        <input
                          type="checkbox"
                          onChange={() => setAcceptWrongNumberFormat((v) => !v)}
                          checked={acceptWrongNumberFormat}
                        />
                        <span className="checkmark" />
                      </label>
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <Row>
                <Col md="5">
                  <label className="label-bold">
                    Date & Time
                    <i className="fa fa-asterisk asterisk" />
                  </label>
                  <InputFormGroup
                    inputName="tracebackTime"
                    inputId="tracebackTime"
                    inputClassName="input-traceback"
                    inputValue={tracebackTime}
                    inputPlaceholder="2020-03-07 19:55"
                    inputOnChange={handleInputChange}
                    inputAutoComplete="off"
                    errorMessage={error.tracebackTime}
                  />
                </Col>
                <Col md="5">
                  <label className="label-bold">Provider</label>
                  <ProvidersSelect
                    noneSelectedByDefault={true}
                    className={'customselect-large'}
                    onChange={(option: any) => {
                      setProviderId(option.value);
                    }}
                    placeholder="Choose Existing Provider..."
                    isSearchable={true}
                    option={providerId}
                  />
                </Col>
                <Col md="5">
                  <label className="label-bold">Audio URL</label>
                  {/*
                                        <InputFormGroup
                                            inputName="audioUrl"
                                            inputId="audioUrl"
                                            inputClassName="input-traceback"
                                            inputValue={audioUrl}
                                            inputOnChange={handleInputChange}
                                            inputAutoComplete="off"
                                            inputPlaceholder="https://example.com/sample.wav"
                                            errorMessage={error.audioUrl}
                                            isReadonly={this.state.isAudioUploaded}
                                        />
                                        */}
                  <DropZone
                    attachments={audioAttachments}
                    removeFile={removeAudioFile}
                    addFile={addAudioFile}
                    noPopover
                    audiofile
                  />
                </Col>
                <Col md="5">
                  <label className="label-bold">OCN</label>
                  <InputFormGroup
                    inputName="termCarrierOCN"
                    inputId="termCarrierOCN"
                    inputClassName="input-traceback"
                    inputValue={termCarrierOCN}
                    inputOnChange={handleInputChange}
                    inputAutoComplete="off"
                  />
                </Col>
              </Row>
            </FormGroup>
            <h5 className="col-md-11 p-0 traceback-sub-title">Additional Details</h5>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label className="label-bold">Comments</label>
                  <p className="telecom-text mb-0">Add any special comments about this Traceback</p>
                  <InputFormGroup
                    containerClassName="col-md-11 p-0"
                    isTextarea
                    inputName="comment"
                    inputId="comment"
                    inputClassName="input-textarea"
                    inputValue={comment}
                    inputOnChange={handleInputChange}
                    inputPlaceholder=""
                    inputAutoComplete="off"
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <label className="label-bold">Select traceback requestor</label>
                <RequestorPopover
                  values={requestors}
                  setValues={setRequestors}
                  radioType={true}
                  popoverHeaderMessage="Select one or more requestors"
                />
              </Col>
            </Row>
            <FormGroup>
              <Fragment>
                <DropZone attachments={attachments} removeFile={removeFile} addFile={addFile} />
              </Fragment>
            </FormGroup>
          </Form>
          <Modal
            centered
            isOpen={alreadyExistingModal}
            className="already-existing-modal"
            toggle={toggleExistingModal}
          >
            <ModalHeader toggle={toggleExistingModal}>
              Identical Traceback(s) already exist !
            </ModalHeader>
            <ModalBody>
              Here is the list of identical traceback(s):
              <ul style={{ margin: '24px auto' }}>
                {identicalTracebacks &&
                  identicalTracebacks.map((traceback) => (
                    <li>
                      <a href={`/tracebacks/traceback/${traceback}`} target="_blank">
                        {traceback}
                      </a>
                    </li>
                  ))}
              </ul>
            </ModalBody>
            <ModalFooter style={{ margin: 'auto' }}>
              <div className="d-flex flex-row justify-content-center mt-2">
                <Button
                  className="btn-default telecom-btn"
                  color="light"
                  onClick={toggleExistingModal}
                >
                  Cancel
                </Button>
                <Button className="telecom-btn ms-4" onClick={(e) => submitForm(e)}>
                  Submit Anyway
                </Button>
              </div>
            </ModalFooter>
          </Modal>
          <div className="d-flex flex-row justify-content-center mt-2">
            <Button className="btn-default telecom-btn" onClick={handleCancelClick} color="light">
              Cancel
            </Button>
            <Button className="telecom-btn ms-4" onClick={(e) => checkForExistingTraceback(e)}>
              Submit
            </Button>
          </div>
          <div
            style={{
              color: 'RED',
              textAlign: 'center',
              margin: '20px',
              fontSize: '1.5em'
            }}
          >
            {tracebackError?.message}
          </div>
        </div>
      ) : (
        <Form className={'form-traceback traceback-edit'}>
          <Col md="11">
            <Row className="justify-content-start">
              <Col>
                <label className="label-bold">Status</label>
                <p className="telecom-text mb-0" style={{ paddingTop: '5px' }}>
                  {tracebackStatuses && tracebackStatuses[traceback.status]
                    ? tracebackStatuses[traceback.status]
                    : 'None'}
                </p>
              </Col>
              <Col md="4">
                <label className="label-bold">
                  Campaign Name
                  <i className="fa fa-asterisk asterisk" />
                </label>
                <CampaignsSelect
                  value={campaignId}
                  onChange={handleCampaignChange}
                  selectClass={'customselect-large'}
                />
                <InputError className="telecom-input-error">{error.campaignName}</InputError>
              </Col>
              <Col>
                <div className="label-bold">Source</div>
                <p className="telecom-text blue" style={{ paddingTop: '5px' }}>
                  {traceback.source !== '' ? traceback.source : 'None'}
                </p>
              </Col>
              <Col>
                <label className="label-bold center">Hops</label>
                <p className="telecom-text blue center mb-0" style={{ paddingTop: '5px' }}>
                  {traceback.numberOfHops}
                </p>
              </Col>
              <Col>
                <label className="label-bold center">Elapsed Time</label>
                <p className="telecom-text mb-0 center" style={{ paddingTop: '5px' }}>
                  {getElapsedTime(traceback.elapsed) || 'UTC'}
                </p>
              </Col>
            </Row>
          </Col>
          <FormGroup>
            <h5 className="col-md-11 p-0 traceback-sub-title">Call Details</h5>
            <Row>
              <Col md="5">
                <label className="label-bold">Called Number</label>
                <p className="telecom-text " style={{ paddingTop: '5px' }}>
                  <span
                    className="fa fa-phone"
                    style={{
                      color: 'RED',
                      marginRight: '5px'
                    }}
                  />
                  {calledTN}
                </p>
              </Col>
              <Col md="5">
                <label className="label-bold">Calling Number</label>
                <p className="telecom-text " style={{ paddingTop: '5px' }}>
                  <span
                    className="fa fa-phone"
                    style={{
                      color: 'GREEN',
                      marginRight: '5px'
                    }}
                  />
                  {callingTN}
                </p>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <label className="label-bold">Date & Time of Call</label>
                <p className="telecom-text " style={{ paddingTop: '5px' }}>
                  {getClientFormattedDate(tracebackTime, DateFormat.LongBoth)}
                </p>
              </Col>
              <Col md="5">
                <label className="label-bold">Audio URL</label>
                {audioUrl ? (
                  <Fragment>
                    <audio controls>
                      <source src={audioUrl} />
                    </audio>
                    <a href={audioUrl} className="telecom-text " style={{ paddingTop: '5px' }}>
                      {audioUrl}
                    </a>
                  </Fragment>
                ) : (
                  <p className="telecom-text " style={{ paddingTop: '5px' }}>
                    {'None'}
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <label className="label-bold">Terminating Line Type</label>
                <p className="telecom-text mb-0" style={{ paddingTop: '5px' }}>
                  {traceback.termLineType}
                </p>
              </Col>
              <Col md="5">
                <label className="label-bold">Do Not Call Registry</label>
                <p className="telecom-text mb-0" style={{ paddingTop: '5px' }}>
                  {traceback.dnc || 'None'}
                </p>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <label className="label-bold">Terminating State</label>
                <p className="telecom-text mb-0" style={{ padding: '5px' }}>
                  {traceback.termState}
                </p>
              </Col>
              <Col md="5">
                <label className="label-bold">Caller Name</label>
                <p className="telecom-text mb-0" style={{ paddingTop: '5px' }}>
                  {traceback.callerName}
                </p>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <label className="label-bold">Do Not Origin Registry</label>
                <CustomToolTip
                  message={
                    DnoToolTip.find((v) => v.type === traceback.dnoType)?.description ||
                    traceback.dnoType ||
                    ''
                  }
                  tooltipVisible={!!traceback.dnoType}
                >
                  {' '}
                  <p className="telecom-text mb-0" style={{ paddingTop: '5px' }}>
                    {traceback.dno ? 'Industry DNO' : 'Not Listed'}
                  </p>
                </CustomToolTip>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <h5 className="col-md-11 p-0 traceback-sub-title">Terminating Provider Details</h5>
            <Row>
              <Col md="5">
                <label className="label-bold">Terminating Carrier ID</label>
                <p className="telecom-text mb-0" style={{ paddingTop: '5px' }}>
                  {traceback.termCarrierId}
                </p>
              </Col>
              <Col md="5">
                <label className="label-bold">Terminating Carrier OCN</label>
                <p className="telecom-text mb-0" style={{ paddingTop: '5px' }}>
                  {traceback.termCarrierOCN}
                </p>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <h5 className="col-md-11 p-0 traceback-sub-title">System Comments</h5>
            {traceback.comments && traceback.comments.length > 0 ? (
              <Fragment>
                {systemComments.map((item: any, index: number) => (
                  <span key={index}>
                    <Row style={{ padding: '0 10px' }}>
                      <Col md="12">
                        <p className="mb-0">
                          <span
                            style={{
                              fontWeight: 'bold',
                              paddingRight: '5px',
                              paddingLeft: '10px'
                            }}
                          >
                            {`Date/Time: `}
                          </span>
                          {getClientFormattedDate(item.create_date, DateFormat.LongBoth)}
                          <span
                            style={{
                              fontWeight: 'bold',
                              paddingRight: '5px',
                              paddingLeft: '10px'
                            }}
                          >
                            {`From: `}
                          </span>
                          {item.user}
                          <span
                            style={{
                              fontWeight: 'bold',
                              paddingRight: '5px',
                              paddingLeft: '10px'
                            }}
                          >
                            {`Provider: `}
                          </span>
                          {item.providerName}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ padding: '0 10px' }}>
                      <Col md="12">
                        <p>
                          <span style={{ fontWeight: 'bold' }}>{`Comment: `}</span>
                          {item.contentText}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ padding: '0 10px' }}>{/*attachments here */}</Row>
                  </span>
                ))}
              </Fragment>
            ) : (
              <div>
                <span>None</span>
              </div>
            )}
          </FormGroup>
          <FormGroup>
            <h5 className="col-md-11 p-0 traceback-sub-title">User Comments + Attachments</h5>
            <CommentComponent input={traceback.comments} includeUser />
          </FormGroup>
          <FormGroup>
            <h5 className="col-md-11 p-0 traceback-sub-title">Additional Details</h5>
            <Row>
              <Col md="4">
                <label className="label-bold">Strike Exempt</label>
                {strikeExempt !== null && strikeExempt !== undefined ? (
                  <Select
                    className={'customselect-small traceback-strike-exempt'}
                    classNamePrefix="customselect"
                    value={{
                      label: strikeExempt ? 'Yes' : 'No',
                      value: strikeExempt ? 1 : 0
                    }}
                    onChange={handleStrikeExemptChange}
                    options={[
                      { label: 'Yes', value: 1 },
                      { label: 'No', value: 0 }
                    ]}
                    isSearchable={false}
                  />
                ) : (
                  <Select
                    className={'customselect-small traceback-strike-exempt'}
                    classNamePrefix="customselect"
                    onChange={handleStrikeExemptChange}
                    options={[
                      { label: 'Yes', value: 1 },
                      { label: 'No', value: 0 }
                    ]}
                  />
                )}
              </Col>
              <Col md="4">
                <label className="label-bold">Traceback Created</label>
                <p className="telecom-text mb-0" style={{ paddingTop: '5px' }}>
                  {getClientFormattedDate(traceback.create_date, DateFormat.MediumBoth)}
                </p>
              </Col>
              <Col md="4">
                <label className="label-bold">Traceback Created By</label>
                <p className="telecom-text mb-0" style={{ paddingTop: '5px' }}>
                  {traceback.createdBy}
                </p>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <label className="label-bold">Traceback Requestor</label>
                <p className="telecom-text mb-0" style={{ paddingTop: '5px' }}>
                  <RequestorPopover
                    values={requestors}
                    setValues={setRequestors}
                    radioType={true}
                    popoverHeaderMessage="Select one or more requestors"
                  />
                </p>
              </Col>
              <Col md="4">
                <label className="label-bold">Creator's Provider</label>
                <p className="telecom-text mb-0" style={{ paddingTop: '5px' }}>
                  {traceback.creatorProvider || 'None'}
                </p>
              </Col>
              <Col md="4">
                <label className="label-bold">Traceback No.</label>
                <p className="telecom-text mb-0" style={{ paddingTop: '5px' }}>
                  {traceback.tracebackId}
                </p>
              </Col>
              <div className="ms-3 mt-4 w-50">
                <div className="label-bold">Campaign description</div>
                <p className="telecom-text">{traceback.campaignDescription}</p>
              </div>
            </Row>
            <Row>
              <Col md="12">
                <label className="label-bold">Comments</label>
                <InputFormGroup
                  containerClassName="col-md-11 p-0"
                  isTextarea
                  inputName="comment"
                  inputId="comment"
                  inputClassName="input-textarea"
                  inputValue={comment}
                  inputOnChange={handleInputChange}
                  inputPlaceholder=""
                  inputAutoComplete="off"
                />
              </Col>
            </Row>
          </FormGroup>
          <label className="label-bold">Attachments</label>
          {tAttachments &&
            tAttachments.map((attachment: any, index: number) => (
              <a
                key={`trackbace-attached-${index}`}
                href={attachment.urlPath}
                target="_self"
                className="telecom-text d-block ps-2"
              >
                {attachment.fileName}
              </a>
            ))}
          <FormGroup>
            <Fragment>
              <DropZone attachments={attachments} removeFile={removeFile} addFile={addFile} />
            </Fragment>
          </FormGroup>
          <div className="d-flex justify-content-center mt-4">
            <Button className="btn-default telecom-btn" onClick={handleCancelClick} color="light">
              Cancel
            </Button>
            <Button disabled={isSubmit} className="telecom-btn ms-4" onClick={submitForm}>
              Save
            </Button>
          </div>
        </Form>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    tracebackError: sm.traceback.error.startsWith(
      'failedToFindProviderByOCN: Unexpected OCN length'
    )
      ? {
          message:
            'Failed to find provider by OCN because:' +
            sm.traceback.error.substring('failedToFindProviderByOCN: '.length) +
            '.',
          associateProvider: false
        }
      : sm.traceback.error.startsWith('failedToFindProviderByOCN')
        ? {
            message: `Failed to find provider by OCN for called number. Please select a provider for "${sm.traceback.error.substring('failedToFindProviderByOCN: OcnDoesNotExistError['.length).split(']:')[0]}" and try again.`,
            associateProvider: true
          }
        : sm.traceback.error.startsWith('failedToFindProviderByNnid')
          ? {
              message: `Failed to find provider by nnid for called number. Please select a provider for "${sm.traceback.error.substring('failedToFindProviderByNnid: '.length)}" and try again.`,
              associateProvider: true
            }
          : { message: sm.traceback.error, associateProvider: false }
  };
};

export default connect(mapStateToProps)(TracebackEditor);
