import {
  GET_REPUTATION_HOPS_HIGH,
  GET_REPUTATION_HOPS_INTERNATIONAL,
  GET_REPUTATION_HOPS_LOW,
  SET_REPUTATION_ERROR_MESSAGE,
  SET_REPUTATION_LOADING_STATUS
} from './actionEnum';

const defaultState: any = {
  highHops: [],
  lowHops: [],
  internationalHops: [],
  loading: 0,
  error: ''
};

const reducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case GET_REPUTATION_HOPS_HIGH:
      return {
        ...state,
        highHops: action.payload.data || []
      };

    case GET_REPUTATION_HOPS_LOW:
      return {
        ...state,
        lowHops: action.payload.data || []
      };
    case GET_REPUTATION_HOPS_INTERNATIONAL:
      return {
        ...state,
        internationalHops: action.payload.data || []
      };

    case SET_REPUTATION_LOADING_STATUS:
      if (action.payload) return { ...state, loading: state.loading + 1 };
      else return { ...state, loading: state.loading === 0 ? state.loading : state.loading - 1 };

    case SET_REPUTATION_ERROR_MESSAGE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};

export default reducer;
