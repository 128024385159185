import { convertToTimeZone } from 'date-fns-timezone';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { Button, Card, CardHeader, Label } from 'reactstrap';
import { AccessLogEntry } from '../../../interfaces/accessLogEntry';
import { Pagination, defaultPagination } from '../../../interfaces/pagination';
import { newConditionalFilterElement, newLeafFilterElement } from '../../../lib/FilterElement';
import {
  decodeQueryParams,
  encodeQueryParams,
  getFromLocalStorage,
  saveToLocalStorage
} from '../../../lib/history-utils';
import { validateIpAddress } from '../../../lib/regex';
import { getAccessLogsList } from '../../../redux/accesslogs/thunks';
import { stateMappings } from '../../../redux/stateMappings';
import ProvidersSelect from '../../ProvidersSelect';
import InputFormGroup from '../../inputFormGroup';
import IPHistoryBasic from './IPHistoryBasic';

interface IProps {
  paginationTotalRows: number;
  accessLogs: AccessLogEntry[];
  getAccessLogsList: Function;
}

const setFiltersFromHistory: any = (savedSearchDetails: any) => {
  let filterableArray = [];
  const { filterElements } = savedSearchDetails;
  let providerId = 0;
  let startDate;
  let endDate;
  let ipAddress = '';

  if (!filterElements) {
    return {};
  }

  if (!Array.isArray(filterElements.conditionals)) {
    filterableArray.push(filterElements);
  } else {
    filterableArray = filterElements.conditionals;
  }

  filterableArray.forEach(
    (condition: {
      comparator: string;
      conditionals: any;
      name: string;
      value: any;
      label?: string;
    }) => {
      const { conditionals, name, value, comparator } = condition;

      switch (name) {
        case 'providerId':
          providerId = Number(value);
          break;
        case 'origin':
          ipAddress = value;
          break;
        case 'userRole':
          if (value === 'gov') {
            providerId = -1;
          }
          break;
        case 'accessDate':
          if (comparator === 'GE') {
            startDate = new Date(value);
          } else if (comparator === 'LE') {
            endDate = new Date(value);
          }
          break;

        case undefined:
          if (Array.isArray(conditionals)) {
            conditionals.forEach(
              (condition: { comparator: string; name: string; value: string }) => {
                if (condition.name === 'accessDate' && condition.comparator === 'GE') {
                  startDate = new Date(condition.value);
                }
              }
            );
          }

        default:
          break;
      }
    }
  );

  return { providerId, startDate, endDate, ipAddress };
};

const PAGENAME = 'ipHistory';

const IPHistory: React.FC<IProps> = ({ paginationTotalRows, accessLogs, getAccessLogsList }) => {
  const searchParams = getFromLocalStorage(PAGENAME) || '';
  const savedSearchDetails = decodeQueryParams(PAGENAME, searchParams);

  const [selectedProvider, setSelectedProvider] = useState<number>(
    setFiltersFromHistory(savedSearchDetails).providerId
  );

  const [ipAddress, setIpAddress] = useState<string>(
    setFiltersFromHistory(savedSearchDetails).ipAddress
  );
  const isIPAddressValid = !ipAddress ? true : validateIpAddress(ipAddress);

  const [filterElements, setFilterElements] = useState<any>({});
  const now = new Date();
  const [startDate, setStartDate] = useState<Date>(
    setFiltersFromHistory(savedSearchDetails).startDate ||
      new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0))
  );
  const [endDate, setEndDate] = useState<Date | null>(
    setFiltersFromHistory(savedSearchDetails).endDate ||
      new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59))
  );
  const [paginationParams, setPaginationParams] = useState<Pagination>(
    savedSearchDetails.paginationParams || {
      ...defaultPagination(),
      sort: 'accessDate',
      order: 'desc'
    }
  );

  useEffect(() => {
    accessLogs.length = 0;
  }, []);

  useEffect(() => {
    setFilterElements(getFilter);
  }, [selectedProvider, startDate, endDate, ipAddress]);

  useEffect(() => {
    const defaultSearchParams: Pagination = { ...paginationParams, page: 1 };

    const searchParams = encodeQueryParams(
      PAGENAME,
      { paginationParams: defaultSearchParams, filterElements },
      {}
    );

    saveToLocalStorage(PAGENAME, searchParams);

    if (accessLogs.length !== 0) {
      getAccessLogsList(paginationParams, filterElements);
    }
  }, [paginationParams]);

  useEffect(() => {
    const defaultSearchParams: Pagination = { ...paginationParams, page: 1 };

    const searchParams = encodeQueryParams(
      PAGENAME,
      { paginationParams: defaultSearchParams, filterElements },
      {}
    );

    saveToLocalStorage(PAGENAME, searchParams);
  }, [filterElements]);

  const searchUsers = () => {
    if (!isIPAddressValid) return;
    const pagParams = { ...paginationParams, page: 1 };
    setPaginationParams(pagParams);

    if (accessLogs.length === 0) {
      getAccessLogsList(pagParams, filterElements);
    }
  };

  const handleChange = (e: any, key: string) => {
    switch (key) {
      case 'providerId':
        setSelectedProvider(e.value);
        break;
      case 'ipAddress':
        setIpAddress(e.target.value);
        break;
      case 'startDate':
        if (e) {
          let date = new Date(Date.parse(e));
          setStartDate(
            new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0))
          );
        } else {
          setStartDate(e);
        }
        break;
      case 'endDate':
        if (e) {
          let date = new Date(Date.parse(e));
          setEndDate(
            new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59))
          );
        } else {
          setEndDate(e);
        }
        break;
    }
  };

  const getFilter = () => {
    let filterElement = newConditionalFilterElement('AND');

    filterElement.conditionals = [];

    if (startDate) {
      filterElement.conditionals?.push(
        newLeafFilterElement('accessDate', 'GE', startDate.toISOString())
      );
    }

    if (endDate) {
      filterElement.conditionals?.push(
        newLeafFilterElement('accessDate', 'LE', endDate.toISOString())
      );
    }

    if (selectedProvider) {
      if (selectedProvider === -1) {
        filterElement.conditionals?.push(newLeafFilterElement('userRole', 'EQ', 'gov'));
      } else {
        filterElement.conditionals?.push(
          newLeafFilterElement('providerId', 'EQ', selectedProvider.toString())
        );
      }
    }

    if (ipAddress) {
      filterElement.conditionals?.push(newLeafFilterElement('origin', 'EQ', ipAddress));
    }

    if (filterElement.conditionals) {
      if (filterElement.conditionals?.length > 0) {
        return filterElement;
      } else {
        return {};
      }
    }
  };

  const updatePagination = (params: Pagination) => {
    if (
      paginationParams.page === params.page &&
      paginationParams.order === params.order &&
      paginationParams.sort === params.sort &&
      paginationParams.pageSize === params.pageSize
    ) {
      return;
    }
    setPaginationParams({ ...paginationParams, ...params });
  };

  const clearFilters = () => {
    const now = new Date();
    setSelectedProvider(0);
    setStartDate(new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0)));
    setEndDate(new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59)));
    setIpAddress('');
    setPaginationParams({ ...defaultPagination(), sort: 'accessDate', order: 'desc' });
    accessLogs.length = 0;
  };

  return (
    <Card>
      <CardHeader>
        <Label className="telecom-label mb-3" style={{ fontSize: '20px' }}>
          History
        </Label>
        <div className="ps-3">
          <Label className="telecom-label ps-0">Provide The Date Range *</Label>
          <div className="d-flex  justify-content-between">
            <div className="d-flex mb-4 flex-row col-9">
              <div className="col-2">
                <DatePicker
                  maxDate={endDate ? convertToTimeZone(endDate, { timeZone: 'GMT' }) : new Date()}
                  selected={
                    startDate ? convertToTimeZone(startDate, { timeZone: 'GMT' }) : undefined
                  }
                  onChange={(option) => handleChange(option, 'startDate')}
                  placeholderText="&#xf133; mm/dd/yyyy"
                  clearButtonTitle={'Clear'}
                  dateFormat="MM/dd/yyyy"
                  isClearable
                />
              </div>
              <span className="ms-2 me-2">-</span>
              <div className="col-2">
                <DatePicker
                  maxDate={new Date()}
                  selected={endDate ? convertToTimeZone(endDate, { timeZone: 'GMT' }) : undefined}
                  minDate={
                    startDate ? convertToTimeZone(startDate, { timeZone: 'GMT' }) : undefined
                  }
                  onChange={(option) => handleChange(option, 'endDate')}
                  placeholderText="&#xf133; mm/dd/yyyy"
                  clearButtonTitle={'Clear'}
                  isClearable
                />
              </div>
              <div className="ms-2 col-3">
                <ProvidersSelect
                  onChange={(option) => handleChange(option, 'providerId')}
                  option={selectedProvider}
                  addAllItem={true}
                  addGovProvider
                  includeInactive
                />
              </div>
              <div className="ms-2 col-3">
                <InputFormGroup
                  inputClassName="csd-search"
                  inputPlaceholder="Enter IP Address"
                  inputValue={ipAddress}
                  inputOnChange={(option) => handleChange(option, 'ipAddress')}
                  valid={isIPAddressValid && !!ipAddress}
                  invalid={!isIPAddressValid}
                  errorMessage={!isIPAddressValid ? 'This is not a valid IP Address ' : ''}
                />
              </div>
            </div>
            <div className="ms-2 row-1">
              <Button color="primary" onClick={searchUsers}>
                <i className="fa fa-search" style={{ paddingRight: '10px' }} />
                Search
              </Button>
              <button
                type="button"
                className="btn btn-link"
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  clearFilters();
                }}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </CardHeader>
      <IPHistoryBasic
        key={`ipHB${paginationParams.pageSize}`}
        updatePagination={updatePagination}
        paginationParams={paginationParams}
        paginationTotalRows={paginationTotalRows}
        accessLogs={accessLogs}
      />
    </Card>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  const sm = stateMappings(state);

  return {
    accessLogs: sm.accessLogs.accessLogs,
    paginationTotalRows: sm.accessLogs.meta.TotalCount
  };
};

const mapActionsToProps = {
  getAccessLogsList
};

export default connect(mapStateToProps, mapActionsToProps)(IPHistory);
