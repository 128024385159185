import React, { FC, useEffect, useState } from 'react';

import CallSourceDetailsRow from './CallSourceDetailsRow';
import CallSourceRow from './CallSourceRow';

import { connect } from 'react-redux';
import { Hop, HopDispute } from '../../interfaces/hop';
import { Traceback } from '../../interfaces/traceback';
import { PersonalInfo } from '../../interfaces/user';
import { getHopDisputeObject } from '../../redux/hop/thunks';
import { stateMappings } from '../../redux/stateMappings';
import { attributesExistAndAllowed } from './Dispute';

interface IProps {
  hop: Hop;
  updateHopObject: Function;
  traceback: Traceback;
  isCallSourcePopulated: boolean;
  setIsCallSourcePopulated: Function;
  user: PersonalInfo;
  dispute: HopDispute | null;
  getHopDisputeObject: Function;
}

const CallSourceSection: FC<IProps> = ({
  hop,
  updateHopObject,
  traceback,
  user,
  setIsCallSourcePopulated,
  isCallSourcePopulated,
  dispute,
  getHopDisputeObject
}) => {
  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    getHopDisputeObject(hop.hopId);
  }, []);

  useEffect(() => {
    setVisible(
      hop.status === 1 ||
        hop.status === 7 ||
        (!dispute &&
          hop.isAllowedToDispute &&
          attributesExistAndAllowed(traceback.campaignAttributes))
    );
  }, [dispute, hop, traceback]);

  return (
    <div className="callSourceSection">
      <div className="d-flex align-items-center  pb-3">
        <h5 className="p-0 m-0" onClick={toggleVisibility} role="button">
          <i
            className={`${visible ? 'fa-solid fa-angle-down' : 'fa-solid fa-angle-right'} pe-2`}
            style={{ color: '#08335d' }}
          ></i>
          Call Source
        </h5>
        {hop.status === 1 && (
          <div className="d-flex align-items-center ps-1">
            <i
              className="fa-solid fa-circle-exclamation text-danger"
              style={{ fontSize: '24px' }}
            ></i>
            <span className="ps-1">this is required</span>
          </div>
        )}
      </div>

      <div className={`${visible ? 'd-flex' : 'd-none'} flex-column ps-5`}>
        {hop.status === 1 && (
          <CallSourceRow
            hop={hop}
            traceback={traceback}
            user={user}
            updateHopObject={updateHopObject}
            onChange={() => {
              setIsCallSourcePopulated(isCallSourcePopulated);
            }}
            campaignAttribute={traceback.campaignAttributes}
          />
        )}
        <CallSourceDetailsRow hop={hop} traceback={traceback} updateHopObject={updateHopObject} />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return { dispute: sm.hop.hopDispute };
};

const mapActionsToProps = { getHopDisputeObject };

export default connect(mapStateToProps, mapActionsToProps)(CallSourceSection);
