import React, { Dispatch, Fragment, SetStateAction, useState } from 'react';
import { RegionDropdown } from 'react-country-region-selector';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import { CallSource } from '../../interfaces/callSource';
import { CampaignAttributes, DisputeInfo } from '../../interfaces/campaign';
import { SelectOption } from '../CustomSelect';
import InputError from '../inputError';
import InputFormGroup from '../inputFormGroup';
import CountryDropdown from './CountryDropdown';
import Dispute from './Dispute';

interface IProps {
  currentCallSource: callSourceTypes;
  callSource: CallSource;
  customerErrors: any;
  setCallSource: React.Dispatch<React.SetStateAction<CallSource>>;
  campaignAttribute: CampaignAttributes;
  disputeInfo: DisputeInfo;
  setDisputeInfo: Dispatch<SetStateAction<DisputeInfo>>;
}

export enum callSourceTypes {
  isCustomer = 'isCustomer',
  isUpstream = 'isUpstream',
  isCallRecordNotProvided = 'isCallRecordNotProvided'
}

const customerTypes: SelectOption[] = [
  {
    value: 1,
    label: 'Trial account'
  },
  {
    value: 2,
    label: 'Customer less than 3 months'
  },
  {
    value: 3,
    label: 'Customer 3 - 12 months'
  },
  {
    value: 4,
    label: 'Customer more than 1 year'
  }
];

const actionsTaken: SelectOption[] = [
  {
    value: 1,
    label: 'No action taken'
  },
  {
    value: 2,
    label: 'Asked calling party for information'
  },
  {
    value: 3,
    label: 'Warned calling party'
  },
  {
    value: 4,
    label: 'Terminated calling party service'
  }
];

const averageDailyTraffic: SelectOption[] = [
  {
    value: 1,
    label: 'Less than 10.000'
  },
  {
    value: 2,
    label: '10.001 - 100.000'
  },
  {
    value: 3,
    label: '100.001 - 500.000'
  },
  {
    value: 4,
    label: 'More than 500.000'
  }
];

const CallSourceCustomerForm: React.FC<IProps> = ({
  currentCallSource,
  callSource,
  customerErrors,
  setCallSource,
  campaignAttribute,
  disputeInfo,
  setDisputeInfo
}) => {
  const [customerType, setCustomerType] = useState<SelectOption | null>(null);
  const [actionTaken, setActionTaken] = useState<SelectOption | null>(null);
  const [averageTraffic, setAverageTraffic] = useState<SelectOption | null>(null);

  const handleCallSourceInputChange = (e: any) => {
    if (e !== null && e.currentTarget) {
      const { name, value } = e.currentTarget;

      switch (name) {
        case 'businessName': {
          setCallSource((v) => ({ ...v, businessName: value }));
          break;
        }
        case 'addressLine1': {
          setCallSource((v) => ({ ...v, addressLine1: value }));
          break;
        }
        case 'contactName': {
          setCallSource((v) => ({ ...v, contactName: value }));
          break;
        }
        case 'country': {
          setCallSource((v) => ({ ...v, country: value }));
          break;
        }
        case 'city': {
          setCallSource((v) => ({ ...v, city: value }));
          break;
        }
        case 'state': {
          setCallSource((v) => ({ ...v, region: value }));
          break;
        }
        case 'contactPhone': {
          setCallSource((v) => ({ ...v, contactPhone: value }));
          break;
        }
        case 'contactEmail': {
          setCallSource((v) => ({ ...v, contactEmail: value }));
          break;
        }
        case 'ipAddress': {
          setCallSource((v) => ({ ...v, ipAddress: value }));
          break;
        }
        case 'postalCode': {
          setCallSource((v) => ({ ...v, postalCode: value }));
          break;
        }
        case 'stepsTaken': {
          setCallSource((v) => ({ ...v, stepsTaken: value }));
          break;
        }
        default:
          break;
      }
    } else if (e !== null && e.label) {
      if (e.label === e.value) {
        setCallSource((v) => ({ ...v, country: e.label }));
      } else setCallSource((v) => ({ ...v, region: e.label }));
    } else {
      setCallSource((v) => ({ ...v, country: '', region: '' }));
    }
  };
  const setCallSourceFunction = (label: String) => {
    setCallSource((v: any) => ({ ...v, country: label }));
  };
  return (
    <Fragment>
      <Row
        className={
          currentCallSource !== callSourceTypes.isCustomer
            ? 'me-5 ms-5 mt-3 is-disable'
            : 'me-5 ms-5 mt-3'
        }
      >
        <Col lg="4">
          <Label className="telecom-label">
            Calling Party Business Name
            <i className="fa fa-asterisk asterisk" />
          </Label>
          <InputFormGroup
            isReadonly={false}
            inputName="businessName"
            inputId="businessName"
            inputClassName="input-hop"
            inputValue={callSource.businessName}
            inputPlaceholder=""
            inputOnChange={handleCallSourceInputChange}
            inputAutoComplete="off"
            valid={!!callSource.businessName}
          />
          {customerErrors.businessName && (
            <InputError className="telecom-input-error">{customerErrors.businessName}</InputError>
          )}
        </Col>
        <Col lg="4">
          <Label className="telecom-label">
            Calling Party Country
            <i className="fa fa-asterisk asterisk" />
          </Label>
          <CountryDropdown
            className={'country-dropdown'}
            name={'hopdetail-country-dropdown'}
            value={callSource.country}
            setCountry={setCallSourceFunction}
            extraOption
          />
          {customerErrors.country && (
            <InputError className="telecom-input-error">{customerErrors.country}</InputError>
          )}
        </Col>
        <Col lg="4">
          <Label className="telecom-label">Address</Label>
          <InputFormGroup
            isReadonly={false}
            inputName="addressLine1"
            inputId="addressLine1"
            inputClassName="input-hop"
            inputValue={callSource.addressLine1}
            inputPlaceholder=""
            inputOnChange={handleCallSourceInputChange}
            inputAutoComplete="off"
          />
          {customerErrors.addressLine1 && (
            <InputError className="telecom-input-error">{customerErrors.addressLine1}</InputError>
          )}
        </Col>
      </Row>
      <div className={currentCallSource !== callSourceTypes.isCustomer ? 'is-disable' : ''}>
        <Row className="me-5 ms-5">
          <Col lg="4">
            <Label className="telecom-label">
              Contact Email
              <i className="fa fa-asterisk asterisk" />
            </Label>
            <InputFormGroup
              invalid={!!customerErrors.contactEmail && callSource.contactEmail !== ''}
              valid={!customerErrors.contactEmail && callSource.contactEmail !== ''}
              isReadonly={false}
              inputName="contactEmail"
              inputId="contactEmail"
              inputClassName="input-hop"
              inputValue={callSource.contactEmail}
              inputPlaceholder=""
              inputOnChange={handleCallSourceInputChange}
              inputAutoComplete="off"
            />
            {customerErrors.contactEmail && (
              <InputError className="telecom-input-error">{customerErrors.contactEmail}</InputError>
            )}
          </Col>
          <Col lg="4" className={customerErrors.ipAddress ? '' : 'ip-form-group'}>
            <Label className="telecom-label">
              Calling Party IP Address
              <i className="fa fa-asterisk asterisk" />
            </Label>
            <InputFormGroup
              invalid={!!customerErrors.ipAddress}
              valid={!!callSource.ipAddress}
              isReadonly={false}
              inputName="ipAddress"
              inputId="ipAddress"
              inputClassName="input-hop"
              inputValue={callSource.ipAddress}
              inputPlaceholder="0.0.0.0"
              inputOnChange={handleCallSourceInputChange}
              inputAutoComplete="off"
              errorMessage={customerErrors.ipAddress}
              inputErrorClassName="telecom-input-error"
            />
            {!customerErrors.ipAddress && (
              <div className="text-end fst-italic fw-lighter text-muted small mb-3 pb-3">
                The use of IP 0.0.0.0 is allowed.
              </div>
            )}
          </Col>
          <Col lg="4">
            <Label className="telecom-label">City</Label>
            <InputFormGroup
              isReadonly={false}
              inputName="city"
              inputId="city"
              inputClassName="input-hop"
              inputValue={callSource.city}
              inputPlaceholder=""
              inputOnChange={handleCallSourceInputChange}
              inputAutoComplete="off"
            />
            {customerErrors.city && (
              <InputError className="telecom-input-error">{customerErrors.city}</InputError>
            )}
          </Col>
        </Row>
        <Row className="me-5 ms-5">
          <Col lg="4">
            <Label className="telecom-label">Contact Phone</Label>
            <InputFormGroup
              isReadonly={false}
              inputName="contactPhone"
              inputId="contactPhone"
              inputClassName="input-hop"
              inputValue={callSource.contactPhone}
              inputPlaceholder=""
              inputOnChange={handleCallSourceInputChange}
              inputAutoComplete="off"
            />
            {customerErrors.contactPhone && (
              <InputError className="telecom-input-error">{customerErrors.contactPhone}</InputError>
            )}
          </Col>
          <Col lg="4">
            <Label className="telecom-label">Calling Party Customer Type</Label>
            <Select
              className="customselect-large"
              classNamePrefix="customselect"
              options={customerTypes}
              value={customerType}
              onChange={(e: any) => {
                setCallSource((v) => ({ ...v, customerType: e.label }));
                setCustomerType(e);
              }}
            />
          </Col>
          <Col lg="4">
            <Label className="telecom-label">State / Region</Label>
            <RegionDropdown
              country={callSource.country}
              value={callSource.region}
              classes={'region-dropdown'}
              name={'hopdetail-region-dropdown'}
              onChange={(e: any) => {
                setCallSource((v) => ({ ...v, region: e }));
              }}
            />
            {customerErrors.region && (
              <InputError className="telecom-input-error">{customerErrors.region}</InputError>
            )}
          </Col>
        </Row>
        <Row className="me-5 ms-5">
          <Col lg="4">
            <Label className="telecom-label">Contact Name</Label>
            <InputFormGroup
              isReadonly={false}
              inputName="contactName"
              inputId="contactName"
              inputClassName="input-hop"
              inputValue={callSource.contactName}
              inputPlaceholder=""
              inputOnChange={handleCallSourceInputChange}
              inputAutoComplete="off"
            />
            {customerErrors.contactName && (
              <InputError className="telecom-input-error">{customerErrors.contactName}</InputError>
            )}
          </Col>
          <Col lg="4">
            <Label className="telecom-label">Calling Party Average Daily Traffic</Label>
            <Select
              className="customselect-large"
              classNamePrefix="customselect"
              value={averageTraffic}
              options={averageDailyTraffic}
              onChange={(e: any) => {
                setCallSource((v) => ({ ...v, averageTraffic: e.label }));
                setAverageTraffic(e);
              }}
            />
          </Col>
          <Col lg="4">
            <Label className="telecom-label">Zip / Postal Code</Label>
            <InputFormGroup
              isReadonly={false}
              inputName="postalCode"
              inputId="postalCode"
              inputClassName="input-hop"
              inputValue={callSource.postalCode}
              inputPlaceholder=""
              inputOnChange={handleCallSourceInputChange}
              inputAutoComplete="off"
            />
            {customerErrors.postalCode && (
              <InputError className="telecom-input-error">{customerErrors.postalCode}</InputError>
            )}
          </Col>
        </Row>
        <Row className="me-5 ms-5">
          <Col lg="4">
            <Label className="telecom-label">
              Action Taken
              <i className="fa fa-asterisk asterisk" />
            </Label>
            <Select
              className="customselect-large"
              classNamePrefix="customselect"
              options={actionsTaken}
              value={actionTaken}
              onChange={(e: any) => {
                setCallSource((v) => ({ ...v, actionTaken: e.label }));
                setActionTaken(e);
              }}
            />
            {customerErrors.actionTaken && (
              <InputError className="telecom-input-error">{customerErrors.actionTaken}</InputError>
            )}
            {campaignAttribute.isAllowDispute &&
              Object.values(campaignAttribute).filter((v) => !!v).length > 1 && (
                <div className="p-3">
                  <Label className="form-label checkbox-label">
                    Dispute <span className="text-secondary">Basis for Traceback</span>
                    <input
                      type="checkbox"
                      onChange={() => {
                        setDisputeInfo((v) => ({ ...v, disputeVisible: !v.disputeVisible }));
                      }}
                      value="disputeVisible"
                      checked={disputeInfo.disputeVisible}
                    />
                    <span className="checkmark" />
                  </Label>
                </div>
              )}
          </Col>
          <Col lg="8">
            <Label className="telecom-label">Steps Taken</Label>
            <InputFormGroup
              isReadonly={false}
              isTextarea
              inputName="stepsTaken"
              inputId="stepsTaken"
              inputClassName="input-hop"
              inputValue={callSource.stepsTaken}
              inputOnChange={handleCallSourceInputChange}
              inputPlaceholder="Please describe the steps you have taken with your customer / end user / subscriber. Please note that blocking the Calling Number / ANI is insufficient. If not providing all customer information please explain."
              inputAutoComplete="off"
            />
          </Col>
        </Row>
        <Row>
          {disputeInfo.disputeVisible && (
            <Dispute
              campaignAttribute={campaignAttribute}
              info={disputeInfo}
              setDispute={setDisputeInfo}
            />
          )}
        </Row>
        {campaignAttribute.isAllowDispute &&
          Object.values(campaignAttribute).filter((v) => !!v).length > 1 &&
          actionTaken != null &&
          (actionTaken.value === 1 || actionTaken.value === 2) && (
            <Row className="me-5 ms-5">
              <InputError className="me-5 ms-5 text-center">
                If you believe you may ultimately dispute one or more basis for traceback, you may
                select ‘Dispute Basis for Traceback' now and include a comment that you are seeking
                additional information from your customer. The window to ‘Dispute Basis for
                Traceback' is limited to two business days after you submit your traceback response.
              </InputError>
            </Row>
          )}
      </div>
    </Fragment>
  );
};
export default CallSourceCustomerForm;
