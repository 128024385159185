import React, { useState } from 'react';
import { Button, CardHeader, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { userTypes } from '../../enum/userTypes';
import { Hop } from '../../interfaces/hop';
import { PersonalInfo } from '../../interfaces/user';

interface IProps {
  hop: Hop;
  user: PersonalInfo;

  renotifyProvider: Function;
}

const HopCardHeader: React.FC<IProps> = ({ hop, user, renotifyProvider }) => {
  const [isRenotify, setIsRenotify] = useState(false);
  // Open Hops
  const isOpen = hop.status === 1;

  const renotifyToggle = () => {
    setIsRenotify((v) => !v);
  };

  const renotify = async () => {
    await renotifyProvider(hop.hopProvider.providerId);
    renotifyToggle();
  };

  return (
    <CardHeader className="telecom-card-header">
      <div className="d-flex justify-content-between m-0">
        <span style={{ display: 'flex text-nowrap', alignItems: 'center' }}>
          {`Hop ${hop.hopSequence || 'Unknown'} for Traceback ${hop.tracebackId}`}
          {/*Terminating | Originating | Intermediate*/}
          {(hop.hopSequence !== 1 && hop.status === 2 && !hop.upstreamHopId) ||
          hop.status === 3 ||
          hop.status === 4 ? (
            <i className="fa fa-check green" />
          ) : !hop.downstreamProvider ? (
            <i className="fa fa-map-marker red" />
          ) : null}
        </span>
        {isOpen && user.roleType === userTypes.Admin && (
          <Button color="primary h-50" onClick={renotifyToggle}>
            Renotify
          </Button>
        )}
      </div>
      <Modal centered isOpen={isRenotify} className="submit-confirm-modal" toggle={renotifyToggle}>
        <ModalHeader toggle={renotifyToggle} />
        <ModalBody>
          <h5 className="traceback-sub-title">
            Do you want to send a reminder to this Provider about this Hop?
          </h5>
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <Button className="btn-default telecom-btn" color="light" onClick={renotifyToggle}>
            Cancel
          </Button>
          <Button className="telecom-btn" style={{ background: '#d61827' }} onClick={renotify}>
            Send
          </Button>
        </ModalFooter>
      </Modal>
    </CardHeader>
  );
};

export default HopCardHeader;
