import { StirShaken } from './actionEnum';
const meta = {
  TotalCount: 0,
  PageSize: 0,
  CurrentPage: 0,
  TotalPages: 1
};
const defaultState: any = {
  stirShaken: {},
  stirShakens: [],
  attestationStats: {},
  originStats: {},
  loading: 0,
  error: '',
  meta
};
const reducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case StirShaken.GET_STIRSHAKENS:
      return {
        ...state,
        stirShakens: action.payload.data,
        meta: action.payload.paginationMeta || meta
      };
    case StirShaken.GET_ATTESTATION_STATS:
      return {
        ...state,
        attestationStats: action.payload.data
      };
    case StirShaken.GET_ORIGIN_STATS:
      return {
        ...state,
        originStats: action.payload.data
      };
    case StirShaken.SET_STIRSHAKEN_LOADING_STATUS:
      if (action.payload) return { ...state, loading: state.loading + 1 };
      else return { ...state, loading: state.loading === 0 ? state.loading : state.loading - 1 };
    case StirShaken.SET_STIRSHAKEN_ERROR_MESSAGE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
export default reducer;
