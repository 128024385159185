import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Button, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { Comment, CommentPostData } from '../interfaces/comment';
import { getCampaignNamesApiCall } from '../redux/appinfo/apiCalls';
import { addCommentObject, editCommentWithAttachments } from '../redux/comment/thunks';
import CustomCreatable from './CustomCreatable';
import DropZone from './DropZone';
import ProvidersSelect from './ProvidersSelect';
import InputFormGroup from './inputFormGroup';

interface IProps {
  isEdit?: boolean;
  comment?: Comment;
  setCommentObject?: Function;
  clearCommentsList: Function;
}

interface IError {
  providerId: string;
  callSummary: string;
}

interface OptionType {
  label: string;
  value: any;
}

const CommentEditor: React.FC<IProps> = ({
  isEdit = false,
  comment,
  setCommentObject,
  clearCommentsList
}) => {
  const navigate = useNavigate();

  //form related state
  const [providerId, setProviderId] = useState(-1);
  const [selectedCampaigns, setSelectedCampaigns] = useState<OptionType[]>([]);
  const [selectedTracebacks, setSelectedTracebacks] = useState<OptionType[]>([]);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [callSummary, setCallSummary] = useState('');

  //misc state
  const [campaignsOptions, setCampaignsOptions] = useState<OptionType[]>([]);
  const [errors, setErrors] = useState<IError>({
    providerId: '',
    callSummary: ''
  });

  //event handlers

  const handleProviderChange = (option: OnChangeValue<OptionType, boolean>) => {
    const { value } = option as OptionType;
    setProviderId(value);
  };

  const handleCampaignsChange = (option: OnChangeValue<OptionType, boolean>) => {
    const selectedOption = option as OptionType[];
    setSelectedCampaigns(selectedOption);
  };

  const handleTracebacksChange = (option: OnChangeValue<OptionType, boolean>) => {
    const selectedOption = option as OptionType[];
    setSelectedTracebacks(selectedOption);
  };

  const handleRemoveFile = (file: any) => {
    const filteredAttachments = attachments.filter((item: any) => item.name !== file.name);
    setAttachments(filteredAttachments);
  };

  const handleAddFile = (files: any[]) => {
    const filelist = files.filter(
      (file) => attachments && !attachments.map((item: any) => item.name).includes(file.name)
    );

    setAttachments([...attachments, ...filelist]);
  };

  const handleStringChange = (e: any) => {
    setCallSummary(e.target.value);
  };

  //validation
  const isFormValid = () => {
    let errors: IError = {
      providerId: '',
      callSummary: ''
    };
    let hasErrors = false;

    if (callSummary.trim() === '') {
      errors.callSummary = 'Please provide a call summary';
      hasErrors = true;
    } else if (callSummary.length <= 10) {
      errors.callSummary = 'Call summary must be longer than 10 characters.';
      hasErrors = true;
    }

    if (providerId <= 0) {
      errors.providerId = 'Please select a provider';
      hasErrors = true;
    }

    setErrors(errors);
    return !hasErrors;
  };

  //handle submit / cancel
  const addComment = async () => {
    let campaigns = [];
    let tracebacks = [];

    if (selectedCampaigns) {
      campaigns = selectedCampaigns.map((item: OptionType) => item.value);
    }

    if (selectedTracebacks) {
      tracebacks = selectedTracebacks.map((item: OptionType) => item.value);
    }

    const commentObject = {
      commentType: 'gov',
      relatedObjectId: providerId,
      callRecordCampaigns: campaigns.join(','),
      callRecordTracebacks: tracebacks.join(','),
      contentText: callSummary
    };

    await addCommentObject(commentObject, attachments);
    await clearCommentsList();
    navigate('/comments');
  };

  const editComment = async () => {
    let campaigns = [];
    let tracebacks = [];

    if (selectedCampaigns) {
      campaigns = selectedCampaigns.map((item: OptionType) => item.value);
    }

    if (selectedTracebacks) {
      tracebacks = selectedTracebacks.map((item: OptionType) => item.value);
    }

    const updatedComment: CommentPostData = {
      ...comment,
      callRecordCampaigns: campaigns.join(','),
      callRecordTracebacks: tracebacks.join(','),
      contentText: callSummary,
      relatedProviderId: providerId,
      relatedObjectId: providerId
    };

    await editCommentWithAttachments(updatedComment, attachments);
    if (setCommentObject) setCommentObject(null);
    navigate('/comments');
  };

  const handleCancel = () => {
    if (setCommentObject) setCommentObject(null);
    navigate('/comments');
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!isFormValid()) return;

    if (isEdit) {
      editComment();
    } else {
      await addComment();
    }
  };

  useEffect(() => {
    getCampaignNamesApiCall()
      .then((result: any) => {
        let items = result.data.data
          .sort((a: any, b: any) => {
            return a.name > b.name ? 1 : -1;
          })
          .map((item: any) => {
            return { label: item.name, value: item.campaignId };
          });
        if (typeof items !== 'undefined') {
          setCampaignsOptions(items);
        }
      })
      .catch((error: any) => {
        console.log('Error:', error);
      });
  }, []);

  useEffect(() => {
    if (!isEdit || !comment || !campaignsOptions) return;

    if (comment.callRecordTracebacks !== '' && comment.callRecordTracebacks) {
      const tracebacksArray = comment.callRecordTracebacks.split(',');
      const tracebacksOptions = tracebacksArray.map((tb: string) => {
        return { label: tb, value: tb };
      }) as OptionType[];
      setSelectedTracebacks(tracebacksOptions);
    }

    if (comment.callRecordCampaigns !== '' && comment.callRecordCampaigns) {
      const previouslySelectedCampaigns = campaignsOptions.filter((item: OptionType) => {
        return comment.callRecordCampaigns
          .split(',')
          .some((element: any) => Number(element) === item.value);
      });
      setSelectedCampaigns(previouslySelectedCampaigns);
    }

    if (comment.attachments && comment.attachments.length) {
      const convertStateToFileType = comment.attachments.map(
        (attachment: any) => new File(['test'], attachment.fileName)
      );
      setAttachments(convertStateToFileType);
    }
    setProviderId(comment.relatedProviderId);
    setCallSummary(comment.contentText);
  }, [comment, campaignsOptions]);

  return (
    <Fragment>
      <Form className="form-traceback traceback-add">
        <FormGroup>
          <h5 className="col-md-12 p-0 traceback-sub-title">Comment Details</h5>
          <Row style={{ justifyContent: 'center' }} className="mb-2">
            <Col md="5">
              <label className="telecom-label">
                Provider
                <i className="fa fa-asterisk asterisk" />
              </label>
              <ProvidersSelect
                option={providerId}
                className={'customselect-large'}
                onChange={handleProviderChange}
                placeholder="Choose Existing Provider..."
                isSearchable={true}
                includeInactive
              />
              <Label className="text-red ps-2">{errors.providerId}</Label>
            </Col>
            <Col md="5">
              <Label className="telecom-label">About Campaign</Label>
              <CreatableSelect
                isClearable
                isMulti
                onChange={handleCampaignsChange}
                options={campaignsOptions}
                value={selectedCampaigns}
                isSearchable
                classNamePrefix={'customselect'}
                className={'customselect-large'}
                placeholder={'Select Campaigns'}
              />
            </Col>
          </Row>

          <Row style={{ justifyContent: 'center' }}>
            <Col md="5">
              <Label className="telecom-label">About Traceback</Label>
              <CustomCreatable
                onChange={handleTracebacksChange}
                placeholder="Enter Traceback IDs"
                value={selectedTracebacks}
                classNamePrefix={'customselect'}
                className={'customselect-large'}
              />
            </Col>
            <Col md="5">
              <Label className="telecom-label" style={{ textAlign: 'left' }}>
                Attachment
              </Label>

              <DropZone
                attachments={attachments}
                removeFile={handleRemoveFile}
                addFile={handleAddFile}
                alignment={'right'}
              />
            </Col>
          </Row>

          <Row style={{ justifyContent: 'center' }}>
            <Col md="10">
              <Label className="telecom-label mt-3">
                Summary
                <i className="fa fa-asterisk asterisk" />
              </Label>
              <p className=" mt-1 ps-2">Your summary should be at least 10 characters</p>
              <InputFormGroup
                style={{ marginBottom: '0' }}
                isReadonly={false}
                isTextarea
                inputName="callSummary"
                inputId="callSummary"
                inputClassName="input-comment"
                inputStyle={{}}
                inputValue={callSummary}
                inputOnChange={handleStringChange}
                inputPlaceholder=""
                inputAutoComplete="off"
              />
              <Label className="text-red ps-2">{errors.callSummary}</Label>
            </Col>
          </Row>

          <div className="text-center" style={{ marginTop: '25px' }}>
            <Button className="btn-default telecom-btn" onClick={handleCancel} color="light">
              Cancel
            </Button>
            <Button className="telecom-btn" onClick={(e) => handleSubmit(e)}>
              Save
            </Button>
          </div>
        </FormGroup>
      </Form>
    </Fragment>
  );
};

export default CommentEditor;
