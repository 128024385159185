import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Form, FormGroup, Row } from 'reactstrap';
import Dot from '../components/dot';
import InputFormGroup from '../components/inputFormGroup';
import { PersonalInfo } from '../interfaces/user';
import {
  validLengthRegex,
  validLowercaseRegex,
  validNumberRegex,
  validSpecialRegex,
  validUppercaseRegex
} from '../lib/regex';
import { stateMappings } from '../redux/stateMappings';
import { resetPasswordAction } from '../redux/user/thunks';

interface IProps {
  resetPasswordAction: Function;
  isAuthenticated: boolean;
  user: PersonalInfo;
  resetPasswordError: boolean;
}

interface Error {
  pin: string;
  password: PasswordError;
  retypePassword: string;
}

interface PasswordError {
  lowercase: boolean;
  uppercase: boolean;
  special: boolean;
  minimum: boolean;
  number: boolean;
}

const getPasswordError = (password: string) => ({
  lowercase: validLowercaseRegex.test(password),
  uppercase: validUppercaseRegex.test(password),
  special: validSpecialRegex.test(password),
  minimum: validLengthRegex.test(password),
  number: validNumberRegex.test(password)
});

const CreateNewPassword: FC<IProps> = ({
  isAuthenticated,
  user,
  resetPasswordAction,
  resetPasswordError
}) => {
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();
  const pin = searchParams.get('pin');
  const [passcode, setPasscode] = useState(pin || '');
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [passwordValid, setPasswordValid] = useState(false);
  const [error, setError] = useState<Error>({
    pin: '',
    password: getPasswordError(password),
    retypePassword: ''
  });
  const { lowercase, uppercase, minimum, special, number } = error.password;

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/hops');
    }
  }, []);

  useEffect(() => {
    setError({
      ...error,
      password: getPasswordError(password)
    });
  }, [password, retypePassword]);

  useEffect(() => {
    const { lowercase, uppercase, minimum, special, number } = error.password;
    setPasswordValid(
      password === retypePassword && lowercase && uppercase && minimum && special && number
    );
  }, [error]);

  useEffect(() => {
    if (resetPasswordError) {
      setError({
        pin: 'Please provide a valid passcode',
        password: getPasswordError(password),
        retypePassword: ''
      });
    }
  }, [resetPasswordError]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (retypePassword) {
      error.retypePassword = password === retypePassword ? '' : 'Re-type password does not match.';
    } else {
      error.retypePassword = 'Please provide a re-type password.';
    }

    setError(error);
    resetPasswordAction(user.id, { passcode, password });
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.currentTarget;
    switch (name) {
      case 'pin': {
        setError({ ...error, pin: '' });
        setPasscode(value.replace(/\D/g, ''));
        break;
      }
      case 'password': {
        setPassword(value);
        break;
      }
      case 'retypePassword': {
        setError({ ...error, retypePassword: '' });
        setRetypePassword(value);
        break;
      }
    }
  };

  return (
    <Card className="card-new-password">
      <CardBody>
        <div className="d-flex justify-content-center">
          <Col xs="12" sm="4" lg="3">
            <Form className="card-auth-form" onSubmit={onSubmit}>
              <FormGroup style={{ textAlign: 'center', marginBottom: '35px' }}>
                <p className="reset-password-text">Create a new password</p>
              </FormGroup>
              <InputFormGroup
                containerClassName="login-form-group"
                isPassword
                inputName="pin"
                inputClassName="login-control"
                inputId="pin"
                inputValue={passcode}
                inputOnChange={handleChange}
                inputPlaceholder="PIN"
                inputAutoComplete="off"
                errorMessage={error.pin}
                label="PIN"
              />
              <InputFormGroup
                containerClassName="login-form-group"
                isPassword
                inputName="password"
                inputClassName="login-control"
                inputId="password"
                inputValue={password}
                inputOnChange={handleChange}
                inputPlaceholder="New password"
                inputAutoComplete="off"
                errorMessage={
                  !passwordValid && password && password.length > 0
                    ? 'Please provide a valid new password.'
                    : ''
                }
                label="New password"
              />
              <InputFormGroup
                containerClassName="login-form-group"
                isPassword
                inputName="retypePassword"
                inputClassName="login-control"
                inputId="retype-password"
                inputValue={retypePassword}
                inputOnChange={handleChange}
                inputPlaceholder="Re-type password"
                inputAutoComplete="off"
                errorMessage={error.retypePassword}
                label="Re-type password"
              />
              <FormGroup style={{ textAlign: 'center' }}>
                <a className="btn-link forgot-password" href="/support">
                  Contact Support
                </a>
              </FormGroup>
              <FormGroup className="password-validation">
                <Row>
                  <div className={lowercase ? 'deactive' : ''}>
                    <Dot color={lowercase ? '#e4e4e4' : '#54b9cf'} />
                    <span>{` One lowercase character`}</span>
                  </div>
                  <div className={uppercase ? 'deactive' : ''}>
                    <Dot color={uppercase ? '#e4e4e4' : '#54b9cf'} />
                    <span>{` One uppercase character`}</span>
                  </div>
                  <div className={special ? 'deactive' : ''}>
                    <Dot color={special ? '#e4e4e4' : '#54b9cf'} />
                    <span>{` One special character`}</span>
                  </div>
                  <div className={minimum ? 'deactive' : ''}>
                    <Dot color={minimum ? '#e4e4e4' : '#54b9cf'} />
                    <span>{` 8 characters minimum`} </span>
                  </div>
                  <div className={number ? 'deactive' : ''}>
                    <Dot color={number ? '#e4e4e4' : '#54b9cf'} />
                    <span>{` One number`}</span>
                  </div>
                </Row>
              </FormGroup>
              <FormGroup style={{ textAlign: 'center', marginBottom: '80px' }}>
                <Button type="submit" className="btn-login" disabled={!passwordValid}>
                  Submit
                </Button>
              </FormGroup>
            </Form>
          </Col>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    isAuthenticated: sm.isAuthenticated,
    resetPasswordError: sm.users.resetPasswordError,
    user: sm.user
  };
};

const mapActionsToProps = {
  resetPasswordAction: resetPasswordAction
};

export default connect(mapStateToProps, mapActionsToProps)(CreateNewPassword);
