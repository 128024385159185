import React, { useState } from 'react';
import { ProviderContact } from '../../interfaces/provider';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface IProps {
  contact: ProviderContact;
  handleResend: Function;
}

const ConfirmResendEmail: React.FC<IProps> = ({ contact, handleResend }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleAction = async () => {
    setLoading(true);
    await handleResend(contact.email);
    setLoading(false);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <span
        onClick={() => setOpen(true)}
        className="fa fa-paper-plane"
        style={{
          color: 'black',
          cursor: 'pointer'
        }}
      />
      <Modal centered className="modal-template" isOpen={open}>
        <ModalHeader>Resend confirmation email</ModalHeader>
        <ModalBody>
          Are you sure that you want to resend the confirmation email to the following contact
          <br />
          <div style={{ paddingTop: '15px' }}>
            <b>Name:</b>
            {' ' + contact.name}
            <br />
            <b>Email:</b>
            {' ' + contact.email}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={loading} onClick={() => handleAction()}>
            {loading ? 'Loading...' : 'Send'}
          </Button>{' '}
          <Button color="danger" disabled={loading} onClick={() => setOpen(false)}>
            Cancel
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default ConfirmResendEmail;
