import * as sessionManager from '../../lib/sessionManager';
import {
  authenticate,
  changePassword,
  deactivateAccount,
  editUserInfo,
  getUserInfo,
  logout,
  register,
  resetPasswordEmailGenerate
} from './apiCalls';

import {
  deactivateUser,
  getUser,
  registerUser,
  rememberLastPage,
  setChangePasswordErrorMessage,
  setErrorMessage,
  setLoadingStatus,
  signInUser,
  signOutUser
} from './actions';

import { PersonalInfo } from '../../interfaces/user';
import { logOut, logoutOnAuthError, removeToken } from '../../lib/apiRequest';

export const signUp = (candidate: any) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const user = await register(candidate);
    if (user && user.status === 200) {
      dispatch(registerUser(user));
    }
    dispatch(setStatus(false, ''));
    window.location.href = '/login';
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const rememberPage = (lastPage: string) => async (dispatch: any) => {
  if (!lastPage.startsWith('/zendesk/logout')) dispatch(rememberLastPage(lastPage));
};

export const staySignedIn = () => async (dispatch: any) => {
  console.log('[AUTH] Attempting to stay signed in');
  sessionManager.start({
    signOut: () => {
      console.log('[AUTH] Signout Hook');
      dispatch(signOutUser());
    }
  });
};

export const signIn = (credentials: any) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  console.log('[AUTH] Signing In');

  try {
    const auth = await authenticate(credentials);

    if (auth && auth.data && auth.data.data) {
      const { id } = auth.data.data;
      localStorage.setItem('lastUserId', id);

      const user = await getUserInfo(auth.data.data.id);

      if (user && user.data && user.data.data) {
        localStorage.setItem('user', JSON.stringify(user.data.data));
        localStorage.removeItem('magicLinkLogIn');
        localStorage.removeItem('locationPermitted');
      }

      if (credentials.isRemember) {
        localStorage.setItem('USTELECOMEUSERNAME', credentials.username);
      }

      dispatch(signInUser(auth.data));
      dispatch(getUser(user.data));
      dispatch(setStatus(false, ''));
    }
  } catch (error: any) {
    //look at edit account info and follow trail
    dispatch(setStatus(false, error.response.data.error));
  }
};

export const signOut = () => async (dispatch: any) => {
  //This is the manual sign out by the user

  localStorage.removeItem('providersSearchParams');
  localStorage.removeItem('hopsSearchParams');

  dispatch(signOutUser());
  dispatch(setStatus(false, ''));

  try {
    await logout();
    console.log('signOut');
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
  }

  logOut();
};

export const getAccountInfo = (userId: Number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const user = await getUserInfo(userId);
    if (user && user.data) {
      dispatch(getUser(user.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const editAccountInfo =
  (candidate: PersonalInfo, oldPassword: string, newPassword: string) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));
    dispatch(setChangePasswordErrorMessage(''));

    try {
      const userResponse = await editUserInfo(candidate);
      if (userResponse.status === 204 || userResponse.status === 200) {
        const user = await getUserInfo(candidate.id);
        if (user && user.data && user.data.data) {
          localStorage.setItem('user', JSON.stringify(user.data.data));
          dispatch(getUser(user.data));
        }
      }

      if (oldPassword && newPassword) {
        let passwordResponse = await changePassword(candidate, oldPassword, newPassword);
        if (passwordResponse.status === 200) {
          dispatch(setStatus(false, ''));
          window.location.href = '/account';
        } else {
          dispatch(setStatus(false, passwordResponse.statusText));
        }
      } else {
        dispatch(setStatus(false, ''));
        window.location.href = '/account';
      }
    } catch (error: any) {
      dispatch(setChangePasswordErrorMessage(error.response.data.error));
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const deactivateAccountInfo = (userId: Number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const response = await deactivateAccount(userId);
    dispatch(deactivateUser(response));

    removeToken();
    localStorage.removeItem('user');

    dispatch(setStatus(false, ''));
    window.location.href = '/';
  } catch (error: any) {
    removeToken();
    localStorage.removeItem('user');

    dispatch(setStatus(false, error.message));
    window.location.href = '/';
  }
};

export const generateResetPasswordEmail = (emailAddress: string) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const response = await resetPasswordEmailGenerate(emailAddress);

    dispatch(setStatus(false, ''));
    window.location.href = `/sent-email` + (response.data.data === 'bounce' ? '?error=bounce' : '');
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    window.location.href = `/sent-email`;
  }
};

const setStatus = (status: boolean, message: string) => async (dispatch: any) => {
  dispatch(setErrorMessage(message));
  dispatch(setLoadingStatus(status));
};
