export enum Insight {
  GET_INSIGHTS = 'GET_INSIGHTS',
  GET_INSIGHTS_FOR_PROVIDER = 'GET_INSIGHTS_FOR_PROVIDER',
  GET_INSIGHT_FINAL_SCORE_PROVIDER = 'GET_INSIGHT_FINAL_SCORE_PROVIDER',
  SET_INSIGHT_LOADING_STATUS = 'SET_INSIGHT_LOADING_STATUS',
  GET_INSIGHT_WEIGHTS = 'GET_INSIGHTS_WEIGHTS',
  SET_INSIGHT_WEIGHT = 'SET_INSIGHT_WEIGHT',
  SET_INSIGHT_ERROR_MESSAGE = 'SET_INSIGHT_ERROR_MESSAGE',
  GET_HONEYBADGERHQ = 'GET_HONEYBADGERHQ'
}
