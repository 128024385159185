import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import Breadcrumb from '../components/Breadcrumbs';
import FollowProviderCheckbox from '../components/shared/FollowProviderCheckbox';
import { Provider } from '../interfaces/provider';

import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import HoneybadgerCard from '../components/AdminDashboard/Insights/HoneybadgerCard';
import QuarterCard from '../components/Charts/QuarterCard';
import CustomDataTable from '../components/CustomDataTable';
import Legend from '../components/shared/Legend';
import ZendeskWebWidget from '../components/shared/ZendeskWebWidget';
import { DateFormat } from '../enum/DateFormat';
import { userTypes } from '../enum/userTypes';
import { InsightFinalScoreResult, InsightProviderScore } from '../interfaces/insights';
import { PersonalInfo } from '../interfaces/user';
import { getClientFormattedDate } from '../lib/utilities';
import { getInsightFinalScoreForProvider, getInsightsForProvider } from '../redux/insights/thunks';
import { getProviderObject } from '../redux/provider/thunks';
import { stateMappings } from '../redux/stateMappings';
import { setUserInsightAgreed } from '../redux/user/thunks';
import { useIntersection } from '../lib/hooks';

const roundTo = (n: number | undefined, digits: number) => {
  if (!n) return n;
  if (!digits) digits = 0;
  const negative = n < 0;
  if (negative) n = n * -1;
  var multiplicator = Math.pow(10, digits);
  n = parseFloat((n * multiplicator).toFixed(11));
  n = Number((Math.round(n) / multiplicator).toFixed(digits));
  if (negative) n = Number((n * -1).toFixed(digits));
  return n;
};

interface IProps {
  getProviderObject: Function;
  provider: Provider;
  insight: InsightFinalScoreResult;
  getInsightFinalScoreForProvider: Function;
  error: string;
  user: PersonalInfo;
  getInsightsForProvider: Function;
  insightScore: InsightProviderScore[];
  setUserInsightAgreed: Function;
}
const columns = [
  {
    name: '',
    width: '20px',
    cell: () => <div />
  },
  {
    name: 'Quarter',
    sortable: false,
    grow: 1,
    cell: (row: InsightProviderScore) => (
      <div className="table-cell text-center">
        <span>{row.quarter}</span>
      </div>
    )
  },
  {
    name: 'Cumulative Total',
    sortable: false,
    grow: 1,
    cell: (row: InsightProviderScore) => (
      <div className="table-cell text-center">
        <span>{roundTo(row.finalScore, 5)}</span>
      </div>
    )
  },
  {
    name: 'Quarter Total',
    sortable: false,
    grow: 1,
    cell: (row: InsightProviderScore) => (
      <div className="table-cell text-center">
        <span>{roundTo(row.rawScore, 5)}</span>
      </div>
    )
  },
  {
    name: 'Mean Fraud Score',
    sortable: false,
    grow: 1,
    cell: (row: InsightProviderScore) => (
      <div className="table-cell text-center">
        <span>{roundTo(row.meanFraudScore, 5)}</span>
      </div>
    )
  },
  {
    name: 'Sum Fraud Score',
    sortable: false,
    grow: 1,
    cell: (row: InsightProviderScore) => (
      <div className="table-cell text-center">
        <span>{roundTo(row.sumFraudScore, 5)}</span>
      </div>
    )
  },
  {
    name: 'End Non-Resposive',
    sortable: false,
    grow: 1,
    cell: (row: InsightProviderScore) => (
      <div className="table-cell text-center">
        <span>{roundTo(row.nonResponsive, 5)}</span>
      </div>
    )
  },
  {
    name: <div>Downstream Non-Responsiv</div>,
    sortable: false,
    grow: 1,
    cell: (row: InsightProviderScore) => (
      <div className="table-cell text-center">
        <span>{roundTo(row.downNonResponsive, 5)}</span>
      </div>
    )
  },
  {
    name: <div>Early vs. Late in Flow</div>,
    sortable: false,
    grow: 1,
    cell: (row: InsightProviderScore) => (
      <div className="table-cell text-center">
        <span>{roundTo(row.earlyVSLateInFlow, 5)}</span>
      </div>
    )
  },
  {
    name: <div>Downstream Dispersion</div>,
    sortable: false,
    grow: 1,
    cell: (row: InsightProviderScore) => (
      <div className="table-cell text-center">
        <span>{roundTo(row.downstreamDispersion, 5)}</span>
      </div>
    )
  }
];

const ProviderInsight: FC<IProps> = ({
  provider,
  user,
  getProviderObject,
  error,
  insight,
  insightScore,
  getInsightFinalScoreForProvider,
  getInsightsForProvider,
  setUserInsightAgreed
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const ref = useRef<HTMLDivElement>(null);
  const providerId = Number(id || 0);
  const [modalOpen, setModalOpen] = useState(!user.insightAgreed);

  useEffect(() => {
    if (error) {
      navigate('/404');
    }
    getProviderObject(providerId);
    getInsightFinalScoreForProvider(providerId);
    if (user.roleType === userTypes.Admin) getInsightsForProvider(providerId);
  }, [providerId, error]);
  const handleSummaryClick = () => {
    navigate(`/providers/provider/summaries/${provider.providerId}`);
  };
  const handleDetailsClick = () => {
    navigate(`/providers/provider/${provider.providerId}`);
  };
  return (
    <Fragment>
      <FollowProviderCheckbox
        className="d-flex provider-title-container justify-content-between"
        providerId={Number(providerId)}
      >
        <Breadcrumb title={'Insights for ' + provider.name} className="table-breadcrumbs" />
      </FollowProviderCheckbox>
      <Card className="telecom-card" style={{ marginBottom: -5 }}>
        <CardHeader className="telecom-card-header">
          <div className="d-flex justify-content-between m-0">
            {provider.name || 'Unknown Provider'}
            {user.roleType === userTypes.Admin && (
              <div>
                <Button className="me-2" color="primary ms-3" onClick={handleDetailsClick}>
                  Details
                </Button>
                <Button color="primary" onClick={handleSummaryClick}>
                  Upstream Summary
                </Button>
              </div>
            )}
          </div>
        </CardHeader>
        <CardBody className="card-detail-provider">
          {user.roleType === userTypes.Admin && (
            <div className="mb-3">
              <div className="ms-5">
                <h4>Insights</h4>
              </div>
              <CustomDataTable
                columns={columns}
                tableData={insightScore}
                defaultSortFieldId={''}
                pagination={false}
              />
            </div>
          )}
          <Label className="telecom-label">Traceback Insights Risk Score</Label>
          <p>
            Important: The Traceback Insights Risk Score is based on a provider's traceback history
            relative to other providers but alone should not be understood to indicate whether a
            given provider is “good” or “bad,” including due to limitations in the data available to
            the ITG. See{' '}
            <a href="https://industrytracebackgroup.zendesk.com/hc/en-us/articles/20620282118295-What-is-the-Traceback-Insights-Risk-Score">
              Traceback Insights FAQs for more
            </a>
            . To better understand what may be contributing to your Traceback Risk Score you can
            refer to your details about your traceback history in the{' '}
            <a href={`/providers/provider/summaries/${providerId}`}>Upstream Provider Summary</a>,
            which may be contributing to your score.
          </p>
          <div className="quarter-cards d-flex justify-content-around">
            {insight.quarters && insight.quarters.length > 0
              ? insight.quarters.map((quarter, index) => (
                  <QuarterCard
                    key={`QCard-${quarter.name}`}
                    subTitle={
                      index === 0
                        ? 'Current Quarter'
                        : !quarter.value && index == insight.quarters.length - 1
                          ? 'No prior Activity'
                          : ''
                    }
                    name={quarter.name}
                    value={quarter.value}
                  />
                ))
              : 'Provider is new to ITG, score will be calculated at end of current quarter'}
          </div>
          <div>
            {insight.firstHop &&
              `Provider first identified in tracebacks on ` +
                getClientFormattedDate(insight.firstHop, DateFormat.ShortDate)}
          </div>
          {user.roleType === userTypes.Admin && (
            <Fragment>
              <Label className="telecom-label">Business Risk</Label>
              <HoneybadgerCard providerId={providerId} />
            </Fragment>
          )}
        </CardBody>
      </Card>
      <Row style={{ padding: '20px 30px', justifyContent: 'center' }}>
        <Legend />
      </Row>
      <ZendeskWebWidget />
      <Modal centered className="modal-template" isOpen={modalOpen}>
        <ModalHeader>Important Information about the Insights Initiative</ModalHeader>
        <ModalBody>
          <Card className="acceptInsight">
            <p className="m-2">
              The ITG's Traceback Insights Initiative is intended to help voice service providers in
              their responsibility to prevent and mitigate illegal calls, including illegal
              robocalls. Industry best practices and FCC regulations call for providers in the call
              path to perform “Know Your Upstream Provider” due diligence before accepting traffic
              from that provider. If a provider carries or transmits a high-volume of illegal
              traffic that primarily originates from one or more specific upstream providers, the
              steps it has taken are not effective and must be modified, according to the FCC. See{' '}
              <a href="https://docs.fcc.gov/public/attachments/FCC-23-37A1.pdf">
                FCC 23-37 para. 50
              </a>
              .
            </p>
            <p className="m-2">
              Traceback Insights affords providers actionable data based on traceback history,
              including about their own traceback history relative to other providers as well as
              about existing and potential upstream partners, to incorporate into risk management
              and robocall mitigation practices. Traceback Insights, however, should not be
              understood to indicate whether a given provider is “good” or “bad,” including due to
              limitations in the data available to the ITG. Rather, Traceback Insights should be
              viewed as informative data that can be incorporated into a broader Know Your Upstream
              Provider program.
            </p>
            <p className="m-2">
              Please see the ITG's{' '}
              <a href="https://industrytracebackgroup.zendesk.com/hc/en-us/articles/20620282118295-What-is-the-Traceback-Insights-Risk-Scorelink">
                Traceback Insights FAQs
              </a>{' '}
              for more information about the initiative and what scores may indicate.
            </p>
            <p className="m-2" ref={ref}>
              On behalf of my company, I understand and accept that the Traceback Insights program
              is not intended to direct which voice service providers my company will accept traffic
              from and that although my company may use Traceback Insights in our robocall
              mitigation program, Know Your Upstream Provider and due diligence efforts, in every
              case, our company makes our own determinations about the upstream providers from whom
              we accept traffic.
            </p>
          </Card>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button
            color="primary"
            disabled={!useIntersection(ref, '0px')}
            onClick={() => {
              setModalOpen(!modalOpen);
              setUserInsightAgreed(user.id);
            }}
          >
            Accept
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    provider: sm.provider.provider,
    user: sm.user,
    insight: sm.insight.insightFinalScore,
    insightScore: sm.insight.insight,
    error: sm.provider.error
  };
};

const mapActionsToProps = {
  getProviderObject,
  getInsightFinalScoreForProvider,
  getInsightsForProvider,
  setUserInsightAgreed
};

export default connect(mapStateToProps, mapActionsToProps)(ProviderInsight);
