import React, { FC, Fragment } from 'react';
import { DateFormat } from '../../enum/DateFormat';
import { Comment } from '../../interfaces/comment';
import { getClientFormattedDate } from '../../lib/utilities';

interface IProps {
  comments: Comment[] | undefined;
}

const AdminsComments: FC<IProps> = ({ comments }) => {
  return (
    <Fragment>
      {comments &&
        comments.map(
          (item: Comment, index) =>
            item.commentType === 'gov' && (
              <div className="col mb-2 mt-4" key={index}>
                <div className="row telecom-label">
                  <p className="h5" style={{ paddingLeft: '2rem' }}>
                    DATE {getClientFormattedDate(item.create_date, DateFormat.ShortDate)}
                  </p>
                </div>
                <div className="row">
                  <p className="row" style={{ paddingLeft: '3.5rem' }}>
                    {item.contentText}
                  </p>
                </div>
                {(item.callRecordTracebacks || item.callRecordCampaigns) && (
                  <div className="row telecom-label">
                    <p className="ps-2">
                      {item.callRecordCampaigns && 'Campaign '}
                      {item.callRecordTracebacks && item.callRecordCampaigns && 'and '}
                      {item.callRecordTracebacks && 'Tracebacks '} indentified
                    </p>
                  </div>
                )}
              </div>
            )
        )}
    </Fragment>
  );
};
export default AdminsComments;
