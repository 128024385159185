import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { userTypes } from '../../enum/userTypes';
import { DNO } from '../../interfaces/dno';
import { PersonalInfo } from '../../interfaces/user';

export const dnoColumns = (user: PersonalInfo, editDno: (dno: DNO) => void): TableColumn<DNO>[] => {
  return [
    {
      name: '',
      width: '20px',
      cell: () => <div />
    },
    {
      name: 'DNO name',
      selector: () => 'name',
      id: 'column-name',
      sortable: true,
      grow: 3,
      cell: (dno: DNO) => (
        <div className="table-cell">
          <span>{dno.name}</span>
        </div>
      )
    },
    {
      name: <div>Requesting Entity</div>,
      sortable: true,
      selector: () => 'requesting_entity',
      grow: 2,

      cell: (dno: DNO) => (
        <div className="table-cell">
          <span>{dno.requestingEntity}</span>
        </div>
      )
    },
    {
      name: 'Addressed To',
      sortable: true,
      selector: () => 'letter_addressed_to',
      grow: 3,

      cell: (dno: DNO) => (
        <div className="table-cell text-center">
          <span>{dno.letterAddressedTo}</span>
        </div>
      )
    },
    {
      name: 'Request date ',
      sortable: true,
      selector: () => 'date_of_request',
      grow: 2,

      cell: (dno: DNO) => (
        <div className="table-cell">
          <span>{dno.dateOfRequest}</span>
        </div>
      )
    },
    {
      name: <div>Received by ITG</div>,
      sortable: true,
      selector: () => 'date_received_by_itg',
      grow: 2,

      cell: (dno: DNO) => (
        <div className="table-cell">
          <span>{dno.dateReceivedByItg}</span>
        </div>
      )
    },
    {
      name: 'Sent to ITG ',
      sortable: true,
      selector: () => 'date_sent_to_itg',
      grow: 2,

      cell: (dno: DNO) => (
        <div className="table-cell">
          <span>{dno.dateSentToItg}</span>
        </div>
      )
    },
    {
      name: 'LOA ',
      sortable: true,
      selector: () => 'loa_on_hand',
      grow: 1,

      cell: (dno: DNO) => (
        <div className="table-cell text-center">
          <span>
            {dno.loaOnHand ? (
              <span>
                {dno.loaLink ? (
                  <a
                    href={dno.loaLink}
                    style={{ color: '#0f72b1 ' }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Yes
                  </a>
                ) : (
                  'Yes'
                )}
              </span>
            ) : (
              'No'
            )}
          </span>
        </div>
      )
    },
    {
      name: 'Phone Number ',
      sortable: true,
      selector: () => 'phone_number',
      grow: 2,

      cell: (dno: DNO) => (
        <div className="table-cell text-center">
          <span>{dno.phoneNumber}</span>
        </div>
      )
    },
    {
      name: 'Remarks ',
      sortable: true,
      selector: () => 'remarks',
      grow: 3,

      cell: (dno: DNO) => (
        <div className="table-cell">
          <span>{dno.remarks}</span>
        </div>
      )
    },
    {
      name: 'CreateDate ',
      sortable: true,
      selector: () => 'create_date',
      grow: 2,

      cell: (dno: DNO) => (
        <div className="table-cell">
          <span>{dno.createDate}</span>
        </div>
      )
    },
    {
      name: 'UpdateDate ',
      sortable: true,
      selector: () => 'update_date',
      grow: 2,

      cell: (dno: DNO) => (
        <div className="table-cell">
          <span>{dno.updateDate}</span>
        </div>
      )
    },
    {
      name: 'Status ',
      selector: () => 'status',
      grow: 1,

      cell: (dno: DNO) => (
        <div className="table-cell text-center">
          <span>{dno.active ? 'active' : 'removed'}</span>
        </div>
      )
    },
    {
      name: 'ApplyToSMS ',
      selector: () => 'sms_option',
      grow: 1,
      cell: (dno: DNO) => (
        <div className="table-cell text-center">
          <span>{dno.smsOption}</span>
        </div>
      )
    },
    {
      name: 'Edit ',
      selector: () => 'edit',
      grow: 1,
      omit: user.roleType !== userTypes.Admin,
      cell: (dno: DNO) => (
        <div className="table-cell text-center">
          <a
            style={{ cursor: 'pointer' }}
            onClick={() => {
              editDno(dno);
            }}
          >
            <i className="fa-solid fa-pencil blue"></i>
          </a>
        </div>
      )
    }
  ];
};
