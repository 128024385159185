import axios, { AxiosError, AxiosResponse, CancelTokenSource } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { Auth } from '../interfaces/auth';
import * as sessionManager from './sessionManager';

export const setAuthInfo = (auth: Auth) => {
  localStorage.setItem(
    'auth',
    JSON.stringify({
      ...auth,
      expiresDuration: String(+new Date() + 60 * 1000)
    })
  );

  refreshInstance();
};

export const removeToken = () => {
  localStorage.removeItem('auth');
};

export let ApiRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true
});

const UUID_KEY = 'device_uuid';

const getUUID = (): string => {
  let uuid = localStorage.getItem(UUID_KEY);

  if (!uuid) {
    uuid = uuidv4();
    localStorage.setItem(UUID_KEY, uuid);
  }

  return uuid;
};

ApiRequest.interceptors.request.use(
  (config) => {
    config.headers['X-Device-UUID'] = getUUID();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const withCancelRequestFunctionality = <T, R = AxiosResponse<T>>() => {
  let source: CancelTokenSource;
  return (api: (cancelToken: CancelTokenSource) => Promise<R>) => {
    if (source) {
      // Cancel the previous request before making a new request
      source.cancel();
    }
    // Create a new CancelToken
    source = axios.CancelToken.source();
    return api(source);
  };
};

export let ApiRequestWithoutToken = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false
});

ApiRequestWithoutToken.interceptors.request.use(
  (config) => {
    config.headers['X-Device-UUID'] = getUUID();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export async function refreshToken() {
  try {
    const response = await ApiRequest.request({
      method: 'POST',
      url: '/Token/refresh'
    });

    const { data = {} } = response;

    if (data && data.data !== undefined) {
      console.log('[AUTH] Refresh Token Successful');
      // setAuthInfo(data.data)
      return data.data;
    } else if (data.data === undefined) {
      console.log('[AUTH] Refresh token not necessary');
      return true;
    }
  } catch (error: any) {
    return false;
  }

  return false;
}

export const refreshInstance = () => {
  console.log('[AUTH] Refreshing Axios Instance');
  return (ApiRequest = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  }));
};

export const buildParams = (params: any) => {
  let queryParams: string = '';

  if (params) {
    queryParams = Object.keys(params)
      .filter((key) => params[key])
      .map((key) => key + '=' + params[key])
      .join('&');
  }

  return queryParams;
};

export const logoutOnAuthError = (error: AxiosError) => {
  if (error.response && (error.response.status === 401 || error.response.status === 403)) {
    logOut();
    window.location.href = '/login';
  }
};

export const logOut = () => {
  removeToken();
  localStorage.removeItem('user');
  sessionManager.stop();
};

Object.assign(window, { ApiRequest, refreshToken });
