import { convertToTimeZone } from 'date-fns-timezone';
import React, { FC, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { OnChangeValue } from 'react-select';
import {
  Button,
  CardBody,
  CardHeader,
  Collapse,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { userTypes } from '../../enum/userTypes';
import { Pagination, defaultPagination } from '../../interfaces/pagination';
import {
  ExistingRequest,
  ExistingRequests,
  IncidentChangePhoneNumbers,
  RequestIncident
} from '../../interfaces/request';
import { FilterElement, getFilter, newConditionalFilterElement } from '../../lib/FilterElement';
import { requestColumns } from '../../lib/dataTableUtils/requestsColumns';
import {
  checkExistingRequestsApiCall,
  createTracebackFromRequests,
  rejectRequests,
  searchRequests
} from '../../redux/request/apiCalls';
import { selectUser, stateMappings } from '../../redux/stateMappings';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import CampaignsSelect from '../CampaignsSelect';
import CustomDataTable from '../CustomDataTable';
import { SelectOption } from '../CustomSelect';
import InputFormGroup from '../inputFormGroup';
import ExpandableRequest from './ExpandableRequest';
import RequestorSelect from './RequestorSelect';
import { validatePhoneNumber } from '../../lib/phoneNumber';
import {
  decodeQueryParams,
  encodeQueryParams,
  getFromLocalStorage,
  saveToLocalStorage
} from '../../lib/history-utils';
import { SerializedError } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router';
import { checkDupicatesBetweenSelected } from '../../lib/utilities';

export const setRequestsFiltersFromHistory = (savedSearchDetails: any) => {
  let filterableArray = [];
  const { filterElements } = savedSearchDetails;
  let startDate;
  let endDate;
  let requestor = 0;
  let campaignId = 0;
  let isPending = false;
  let isDeclined = false;
  let tbStatus: SelectOption[] = [];

  if (!filterElements) {
    return {
      startDate,
      endDate,
      requestor,
      campaignId,
      isPending,
      isDeclined,
      tbStatus
    };
  }

  if (!Array.isArray(filterElements.conditionals)) {
    filterableArray.push(filterElements);
  } else {
    filterableArray = filterElements.conditionals;
  }

  filterableArray.forEach(
    (condition: {
      comparator: string;
      conditionals: any;
      name: string;
      value: any;
      label?: string;
    }) => {
      const { conditionals, name, value, comparator } = condition;

      switch (name) {
        case 'startDate':
          startDate = value;
          break;
        case 'endDate':
          endDate = value;
          break;
        case 'requestorId':
          requestor = Number(value);
          break;
        case 'campaignId':
          campaignId = Number(value);
          break;
        case 'status':
          switch (value) {
            case '2':
              tbStatus.push({ label: 'Completed', value: value });
              break;
            case '3':
              tbStatus.push({ label: 'Not Found', value: value });
              break;
            case '4':
              tbStatus.push({ label: 'No Response', value: value });
              break;
            case 'PENDING':
              isPending = true;
              break;
            case 'REJECTED':
              isDeclined = true;
              break;
            default:
              break;
          }
          break;
        case undefined:
          if (Array.isArray(conditionals)) {
            conditionals.forEach(
              (condition: { comparator: string; name: string; value: string }) => {
                const { name, value } = condition;
                if (condition.name === 'startDate') {
                  startDate = value;
                }

                if (condition.name === 'endDate') {
                  endDate = value;
                }

                if (condition.name === 'status') {
                  switch (value) {
                    case '2':
                      tbStatus.push({ label: 'Completed', value: value });
                      break;
                    case '3':
                      tbStatus.push({ label: 'Not Found', value: value });
                      break;
                    case '4':
                      tbStatus.push({ label: 'No Response', value: value });
                      break;
                    case 'PENDING':
                      isPending = true;
                      break;
                    case 'REJECTED':
                      isDeclined = true;
                      break;
                    default:
                      break;
                  }
                }
              }
            );
          }

        default:
          break;
      }
    }
  );
  return { startDate, endDate, requestor, campaignId, isDeclined, isPending, tbStatus };
};
const PAGENAME = 'requests';

const Requests: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(selectUser);
  const requests = useAppSelector((state) => stateMappings(state).request.incidents);
  const paginationTotalRows = useAppSelector(
    (state) => stateMappings(state).request.meta.TotalCount
  );
  const savedSearchDetails = useMemo(
    () => decodeQueryParams(PAGENAME, getFromLocalStorage(PAGENAME) || ''),
    []
  );
  const filters = useMemo(
    () => setRequestsFiltersFromHistory(savedSearchDetails),
    [savedSearchDetails]
  );

  const [collapseFilters, setCollapseFilters] = useState(false);
  const [endDate, setEndDate] = useState<string>(filters.endDate || '');
  const [startDate, setStartDate] = useState<string>(filters.startDate || '');
  const [isPending, setIsPending] = useState(filters.isPending);
  const [isDeclined, setIsDeclined] = useState(filters.isDeclined);
  const [requestorOption, setRequestorOption] = useState<number>(filters.requestor);
  const [pagination, setPagination] = useState(
    savedSearchDetails.paginationParams || {
      ...defaultPagination(),
      sort: 'create_date',
      order: 'desc'
    }
  );
  const [filterElements, setFilterElements] = useState<FilterElement>(
    savedSearchDetails.filterElements || newConditionalFilterElement('')
  );
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [campaignId, setCampaignId] = useState();
  const [decline, setDecline] = useState('');
  const [acceptWrongNumberFormat, setAcceptWrongNumberFormat] = useState(false);
  const phoneNumbersAreWrong = useMemo(
    () =>
      requests &&
      requests.length &&
      requests
        .filter((request: RequestIncident) =>
          selectedRows.some((row: number) => row === request.id)
        )
        .some(
          (selectedRequest: RequestIncident) =>
            !validatePhoneNumber(selectedRequest.destinationPhoneNumber) ||
            !validatePhoneNumber(selectedRequest.originatorPhoneNumber)
        ),
    [selectedRows]
  );
  const [existingRequestModal, setExistingRequestModal] = useState(false);
  const [existingRequests, setExistRequests] = useState<ExistingRequests[]>([]);
  const [singleCampaignId, setSingleCampaignId] = useState<number>();
  const [singleProviderId, setSingleProviderId] = useState<number>();
  const [selectedData, setSelectedData] = useState<IncidentChangePhoneNumbers[]>();
  const [selectedDuplicates, setSelectedDuplicates] = useState<string[]>([]);

  useEffect(() => {
    const searchParams = encodeQueryParams(
      PAGENAME,
      {
        filterElements: filterElements,
        paginationParams: pagination
      },
      {}
    );
    saveToLocalStorage(PAGENAME, searchParams);
    dispatch(searchRequests({ pagination, filterElements })).then((data) => {
      const error = (data as any).error as SerializedError;
      if (error && error.message === "user doesn't have requestors") {
        navigate('/');
      }
    });
  }, [pagination, filterElements]);

  useEffect(() => {
    setFilterElements(
      getFilter({
        startDate: startDate,
        endDate: endDate,
        requestorId: requestorOption,
        statusRequest: { isDeclined: isDeclined, isPending: isPending }
      })
    );
  }, [startDate, endDate, requestorOption, isPending, isDeclined]);

  const handleChange = (e: any, key: string) => {
    let date = new Date(Date.parse(e));
    switch (key) {
      case 'startDate':
        if (e) {
          const startDate = new Date(
            Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
          );
          setStartDate(startDate.toISOString());
        } else setStartDate(e);
        break;
      case 'endDate':
        if (e) {
          const endDate = new Date(
            Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
          );
          setEndDate(endDate.toISOString());
        } else setEndDate(e);
        break;
    }
  };

  const toggleFilters = () => {
    setCollapseFilters((v) => !v);
  };

  const updatePagination = (params: Pagination) => {
    setPagination({ ...pagination, ...params });
  };

  const updateSelectedRows = (checked: boolean, id: number) => {
    if (checked) setSelectedRows((v) => [...v, id]);
    else setSelectedRows(selectedRows.filter((select: number) => select !== id));
  };

  const handleCampaignChange = (selectedOption: OnChangeValue<SelectOption, boolean>) => {
    if (selectedOption) setCampaignId((selectedOption as SelectOption).value);
    else setCampaignId(undefined);
  };

  const createTracebacksFromRequests = (
    requests: IncidentChangePhoneNumbers[],
    campaignId?: number,
    providerId?: number
  ) => {
    dispatch(
      createTracebackFromRequests({
        requestincidents: requests,
        tracebackCampaignId: campaignId || 0,
        tracebackProviderId: providerId || 0
      })
    ).then(() => updateFilter());

    setSelectedData(undefined);
    setSingleCampaignId(undefined);
    setSingleProviderId(undefined);
  };

  const createTracebacks = async (
    request?: IncidentChangePhoneNumbers,
    campaign?: number,
    provider?: number
  ) => {
    const selectedRequests = requests
      .filter((request: RequestIncident) => selectedRows.some((row: number) => row === request.id))
      .map(
        (request: RequestIncident): IncidentChangePhoneNumbers => ({
          id: request.id,
          incidentCampaignId: request.incidentCampaignId,
          originatorPhoneNumber: request.originatorPhoneNumber,
          destinationPhoneNumber: request.destinationPhoneNumber,
          callTime: request.callTime || ''
        })
      );
    const checkData = request ? [request] : selectedRequests;
    let duplicates: string[] = [];
    setSelectedData(checkData);
    if (request) {
      setSingleCampaignId(campaign);
      setSingleProviderId(provider);
    } else {
      setSingleCampaignId(campaignId);
      setSingleProviderId(0);
      duplicates = checkDupicatesBetweenSelected(selectedRequests);
    }
    try {
      const {
        data: { data }
      } = await checkExistingRequestsApiCall(checkData);

      if ((data && data.length > 0) || duplicates.length) {
        setExistRequests(data || []);
        setSelectedDuplicates(duplicates);
        setExistingRequestModal(true);
        return;
      }
    } catch (error) {}
    if (request) createTracebacksFromRequests(checkData, campaign, provider);
    else createTracebacksFromRequests(checkData, campaignId, 0);
  };
  const rejectRequest = () => {
    dispatch(rejectRequests({ ids: selectedRows, reason: decline })).then(() => updateFilter());
  };

  const updateFilter = () => {
    setPagination({ ...pagination, page: 1 });
    setDecline('');
    setFilterElements(
      getFilter({
        startDate: startDate,
        endDate: endDate,
        requestorId: requestorOption,
        statusRequest: { isDeclined: isDeclined, isPending: isPending }
      })
    );
    setSelectedRows([]);
  };

  const toggleExistingRequestModal = () => {
    setExistingRequestModal((v) => !v);
    setSelectedData(undefined);
    setSingleCampaignId(undefined);
    setSingleProviderId(undefined);
  };
  return (
    <div>
      <CardHeader className="card-header-requests">
        <CardHeader
          style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: 20 }}
          onClick={() => toggleFilters()}
          data-type="collapseBanner"
        >
          Filters
          <i
            style={{ position: 'absolute', right: 0, marginRight: 35, marginTop: 5 }}
            className={`fa-solid ${collapseFilters ? 'fa-arrow-up' : 'fa-arrow-down'}`}
          ></i>
        </CardHeader>
        <Collapse isOpen={collapseFilters}>
          <CardBody>
            <div className="row">
              <div className="fixed-column p-0">
                <Label className="telecom-label">Start Date</Label>
              </div>
              <div className="fixed-column p-0">
                <Label className="telecom-label">End Date</Label>
              </div>
              {user.roleType === userTypes.Admin && (
                <div className="fixed-column p-0">
                  <Label className="telecom-label">Partner/Requestor</Label>
                </div>
              )}
            </div>
            <div className="row m-0">
              <div className="fixed-column">
                <DatePicker
                  maxDate={endDate ? convertToTimeZone(endDate, { timeZone: 'GMT' }) : new Date()}
                  selected={
                    startDate ? convertToTimeZone(startDate, { timeZone: 'GMT' }) : undefined
                  }
                  onChange={(option) => handleChange(option, 'startDate')}
                  placeholderText="&#xf133; mm/dd/yyyy"
                  isClearable
                />
              </div>
              <div className="fixed-column">
                <DatePicker
                  maxDate={new Date()}
                  selected={endDate ? convertToTimeZone(endDate, { timeZone: 'GMT' }) : undefined}
                  minDate={
                    startDate ? convertToTimeZone(startDate, { timeZone: 'GMT' }) : undefined
                  }
                  onChange={(option) => handleChange(option, 'endDate')}
                  placeholderText="&#xf133; mm/dd/yyyy"
                  isClearable
                />
              </div>
              {user.roleType === userTypes.Admin ? (
                <div className="fixed-column">
                  <RequestorSelect
                    isSearchable
                    isClearable
                    className={'customselect-small'}
                    option={requestorOption}
                    onChange={(option: any) => setRequestorOption(option ? option.value : 0)}
                  />
                </div>
              ) : (
                <div className="col">
                  <div className="d-flex flex-wrap media-query-wrap">
                    <FormGroup className="mt-3">
                      <label className="form-label checkbox-label">
                        Pending
                        <input
                          type="checkbox"
                          onChange={() => {
                            setIsPending((v) => !v);
                          }}
                          defaultChecked={isPending}
                        />
                        <span className="checkmark" />
                      </label>
                    </FormGroup>
                    <FormGroup className="mt-3">
                      <label className="form-label checkbox-label">
                        Declined
                        <input
                          type="checkbox"
                          onChange={() => {
                            setIsDeclined((v) => !v);
                          }}
                          defaultChecked={isDeclined}
                        />
                        <span className="checkmark" />
                      </label>
                    </FormGroup>
                  </div>
                </div>
              )}
            </div>
          </CardBody>
        </Collapse>
        {user.roleType === userTypes.Admin && selectedRows && !!selectedRows.length && (
          <div className="row mt-4 ms-5">
            <div className="col-5">
              <div className="row">
                <label className="telecom-label">Traceback Campaign</label>
              </div>
              <div className="row">
                <div className="col">
                  <CampaignsSelect
                    value={campaignId}
                    onChange={handleCampaignChange}
                    isClearable
                    selectClass="customselect-small"
                    placeholder="Select Campaign"
                  />
                </div>
                <div className="col">
                  <Button
                    color="primary"
                    disabled={
                      !campaignId ||
                      !selectedRows.length ||
                      (phoneNumbersAreWrong && !acceptWrongNumberFormat)
                    }
                    onClick={async () => await createTracebacks()}
                  >
                    Create Tracebacks
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-5">
              <div className="row mt-1">
                <label className="telecom-label">Decline Reason</label>
              </div>
              <div className="row">
                <div className="col">
                  <InputFormGroup
                    inputName="decline"
                    inputId="decline"
                    inputValue={decline}
                    inputOnChange={(e) => setDecline(e.currentTarget.value)}
                    inputPlaceholder=""
                    inputAutoComplete="off"
                  />
                </div>
                <div className="col mb-3">
                  <Button
                    color="primary"
                    disabled={!decline || !selectedRows.length}
                    onClick={() => rejectRequest()}
                  >
                    Decline
                  </Button>
                </div>
              </div>
            </div>
            {phoneNumbersAreWrong && (
              <div className="row mt-2">
                <div className="col-4">
                  <FormGroup>
                    <label className="form-label checkbox-label">
                      Are the phone numbers correct?
                      <input
                        type="checkbox"
                        onChange={() => setAcceptWrongNumberFormat((v) => !v)}
                        checked={acceptWrongNumberFormat}
                      />
                      <span className="checkmark" />
                    </label>
                  </FormGroup>
                </div>
              </div>
            )}
          </div>
        )}
      </CardHeader>
      <div className="mt-4 p-4">
        <CustomDataTable
          tableData={requests}
          columns={requestColumns(user, selectedRows, updateSelectedRows)}
          defaultSortFieldId={pagination.sort}
          defaultSortAsc={pagination.order === 'asc'}
          defaultPage={pagination.page}
          defaultPageSize={pagination.pageSize}
          useExpandableRows={true}
          expandableRowExpanded={() => true}
          expandableRowsHideExpander={true}
          pagination
          paginationTotalRows={paginationTotalRows}
          updatePaginationParams={updatePagination}
          useExpandableRowsComponent={({ data }) => (
            <ExpandableRequest incident={data} createTracebacks={createTracebacks} />
          )}
          customStyles={{ responsiveWrapper: { style: { overflow: 'scroll' } } }}
        />
      </div>
      <Modal
        centered
        className="modal-template"
        isOpen={existingRequestModal}
        toggle={() => toggleExistingRequestModal()}
      >
        <ModalBody style={{ overflow: 'scroll', maxHeight: '500px' }}>
          <ul>
            {existingRequests.map((value) => (
              <li>
                Request incident #{value.requestId} already exists:
                {value.existingRequests.map((request: ExistingRequest) => (
                  <ul>
                    {`request incident #${request.existingRequestId} with traceback `}
                    <a href={`tracebacks/traceback/${request.tracebackId}`}>
                      {request?.tracebackId}
                    </a>
                  </ul>
                ))}
              </li>
            ))}
          </ul>
          <ul>
            {selectedDuplicates.map((s: string) => (
              <li>{`Request incidents ${s} are duplicates.`}</li>
            ))}
          </ul>
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <div className="d-flex">
            <Button
              className="btn-default telecom-btn"
              color="light"
              onClick={() => {
                toggleExistingRequestModal();
              }}
            >
              Cancel
            </Button>
            <Button
              className="telecom-btn ms-2"
              onClick={() => {
                setExistingRequestModal((v) => !v);
                createTracebacksFromRequests(
                  selectedData || [],
                  singleCampaignId,
                  singleProviderId
                );
              }}
            >
              Submit Anyway
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Requests;
