import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CardBody, Col, Label, Row } from 'reactstrap';
import { ConfigurationSettings } from '../../../interfaces/configuration';
import { getConfiguration, updateConfiguration } from '../../../redux/configuration/thunks';
import { stateMappings } from '../../../redux/stateMappings';

interface IProps {
  configuration: ConfigurationSettings[];
  getConfiguration: Function;
  updateConfiguration: Function;
}

const ReputationSettings: React.FC<IProps> = ({
  configuration,
  getConfiguration,
  updateConfiguration
}) => {
  const [reputationAdminVisible, setReputationAdminVisible] = useState(false);
  const [individualReputationItgVisible, setdIndividualReputationItgVisible] = useState(false);
  const [othersReputationItgVisible, setOthersReputationItgVisible] = useState(false);
  const [individualReputationNonItgVisible, setIndividualReputationNonItgVisible] = useState(false);
  const [othersReputationNonItgVisible, setOthersReputationNonItgVisible] = useState(false);
  useEffect(() => {
    getConfiguration();
  }, []);

  useEffect(() => {
    configuration.forEach((config) => {
      switch (config.key) {
        case 'REPUTATION_ADMIN_VISIBLE':
          setReputationAdminVisible(config.value === 'true');
          break;
        case 'INDIVIDUAL_REPUTATION_ITG_VISIBLE':
          setdIndividualReputationItgVisible(config.value === 'true');
          break;
        case 'OTHERS_REPUTATION_ITG_VISIBLE':
          setOthersReputationItgVisible(config.value === 'true');
          break;
        case 'INDIVIDUAL_REPUTATION_NON_ITG_VISIBLE':
          setIndividualReputationNonItgVisible(config.value === 'true');
          break;
        case 'OTHERS_REPUTATION_NON_ITG_VISIBLE':
          setOthersReputationNonItgVisible(config.value === 'true');
          break;
        //TODO: IMPLEMENT THESE IN THE FUTURE
        case 'REPUTATION_THRESHOLDS': {
          /*  let reputationThresholds = JSON.parse(config.value) as ReputationThreshold[];
          // Clear out categories and colors
          reputationCategories.length = 0;
          reputationColors.length = 0;
          // Add the updated categories and colors
          reputationThresholds.forEach((reputationThreshold: ReputationThreshold) => {
            reputationCategories.push({
              value: reputationThreshold.reputationThresholdId,
              label: reputationThreshold.name
            });
            reputationColors.push({
              value: reputationThreshold.symbolColor,
              label: reputationThreshold.symbolColor
            });
          });
          // Set the state if there are changes
          if (reputationCategories !== prevState.reputationCategories) {
            this.setState({
              reputationCategories
            });
          }
          if (reputationColors !== prevState.reputationColors) {
            this.setState({
              reputationColors
            });
          }*/
          break;
        }
        case 'INDIVIDUAL_REPUTATION_ITG_EMAILS':
          break;
        case 'DOWNSTREAM_REPUTATION_ITG_EMAILS':
          break;
        case 'INDIVIDUAL_REPUTATION_NON_ITG_EMAILS':
          break;
        case 'DOWNSTREAM_REPUTATION_NON_ITG_EMAILS':
          break;
      }
    });
  }, [configuration]);
  const handleCheck = (key: string) => {
    switch (key) {
      case 'REPUTATION_ADMIN_VISIBLE':
        updateConfiguration({
          key: 'REPUTATION_ADMIN_VISIBLE',
          value: String(!reputationAdminVisible)
        } as ConfigurationSettings);
        setReputationAdminVisible((v) => !v);
        break;
      case 'INDIVIDUAL_REPUTATION_ITG_VISIBLE':
        updateConfiguration({
          key: 'INDIVIDUAL_REPUTATION_ITG_VISIBLE',
          value: String(!individualReputationItgVisible)
        } as ConfigurationSettings);
        setdIndividualReputationItgVisible((v) => !v);
        break;
      case 'OTHERS_REPUTATION_ITG_VISIBLE':
        updateConfiguration({
          key: 'OTHERS_REPUTATION_ITG_VISIBLE',
          value: String(!othersReputationItgVisible)
        } as ConfigurationSettings);
        setOthersReputationItgVisible((v) => !v);
        break;
      case 'INDIVIDUAL_REPUTATION_NON_ITG_VISIBLE':
        updateConfiguration({
          key: 'INDIVIDUAL_REPUTATION_NON_ITG_VISIBLE',
          value: String(!individualReputationNonItgVisible)
        } as ConfigurationSettings);
        setIndividualReputationNonItgVisible((v) => !v);
        break;
      case 'OTHERS_REPUTATION_NON_ITG_VISIBLE':
        updateConfiguration({
          key: 'OTHERS_REPUTATION_NON_ITG_VISIBLE',
          value: String(!othersReputationNonItgVisible)
        } as ConfigurationSettings);
        setOthersReputationNonItgVisible((v) => !v);
        break;
      //TODO: IMPLEMENT THESE IN THE FUTURE
      case 'REPUTATION_THRESHOLDS':
        break;
      case 'INDIVIDUAL_REPUTATION_ITG_EMAILS':
        break;
      case 'DOWNSTREAM_REPUTATION_ITG_EMAILS':
        break;
      case 'INDIVIDUAL_REPUTATION_NON_ITG_EMAILS':
        break;
      case 'DOWNSTREAM_REPUTATION_NON_ITG_EMAILS':
        break;
    }
  };
  return (
    <Fragment>
      <Label className="pt-5 traceback-heading" style={{ fontSize: '36px' }}>
        Status Controls
      </Label>
      <CardBody>
        <Row>
          <Col className="padding-3">
            <Label className="telecom-label ps-0">Admin Sees Status</Label>
            <label className="form-label tile-label" style={{ fontSize: '15px' }}>
              Yes
              <input
                type="checkbox"
                onChange={() => handleCheck('REPUTATION_ADMIN_VISIBLE')}
                checked={reputationAdminVisible}
              />
              <span className="checkmark tile-checkmark" />
            </label>
          </Col>
          <Col className="padding-5">
            <Label className="telecom-label ps-0">ITG Members See</Label>
            <label className="form-label tile-label" style={{ fontSize: '15px' }}>
              Individual Status
              <input
                type="checkbox"
                onChange={() => handleCheck('INDIVIDUAL_REPUTATION_ITG_VISIBLE')}
                checked={individualReputationItgVisible}
              />
              <span className="checkmark tile-checkmark" />
            </label>
            <label className="form-label tile-label" style={{ fontSize: '15px' }}>
              Other Providers' Status
              <input
                type="checkbox"
                onChange={() => handleCheck('OTHERS_REPUTATION_ITG_VISIBLE')}
                checked={othersReputationItgVisible}
              />
              <span className="checkmark tile-checkmark" />
            </label>
          </Col>
          <Col className="padding-5">
            <Label className="telecom-label ps-0">Non-ITG Members See</Label>
            <label className="form-label tile-label" style={{ fontSize: '15px' }}>
              Individual Status
              <input
                type="checkbox"
                onChange={() => handleCheck('INDIVIDUAL_REPUTATION_NON_ITG_VISIBLE')}
                checked={individualReputationNonItgVisible}
              />
              <span className="checkmark tile-checkmark" />
            </label>
            <label className="form-label tile-label" style={{ fontSize: '15px' }}>
              Other Providers' Status
              <input
                type="checkbox"
                onChange={() => handleCheck('OTHERS_REPUTATION_NON_ITG_VISIBLE')}
                checked={othersReputationNonItgVisible}
              />
              <span className="checkmark tile-checkmark" />
            </label>
          </Col>
        </Row>
      </CardBody>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return { configuration: sm.configuration.configuration };
};

const mapActionsToProps = {
  getConfiguration,
  updateConfiguration
};

export default connect(mapStateToProps, mapActionsToProps)(ReputationSettings);
