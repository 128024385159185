import React, { Fragment, useEffect, useState } from 'react';
import Select from 'react-select';
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { userTypes } from '../../../enum/userTypes';
import { CreateAdminPostData, PersonalInfo, emptyPersonalInfo } from '../../../interfaces/user';
import {
  addAdminApiCall,
  deleteUserApiCall,
  editUserApiCall,
  getAllUsersApiCall
} from '../../../redux/user/apiCalls';
import InputError from '../../inputError';

interface IProps {
  isEditMode: boolean;
  user: PersonalInfo;
  deactivateAccountInfo: Function;
}

const sOptions = [
  {
    value: true,
    label: 'Yes'
  },
  {
    value: false,
    label: 'No'
  }
];

const AdminTable: React.FC<IProps> = ({ user, isEditMode, deactivateAccountInfo }) => {
  const [admin, setAdmin] = useState<PersonalInfo>(emptyPersonalInfo());
  const [modalOpen, setModalOpen] = useState(false);
  const [adminUsers, setAdminUsers] = useState<PersonalInfo[]>([]);
  const [error, setError] = useState('');

  useEffect(() => {
    if (user.roleType === userTypes.Admin) populateAdminTables();
  }, [user]);

  useEffect(() => {
    clearAdmin();
  }, [isEditMode]);

  const populateAdminTables = () => {
    getAllUsersApiCall(
      {
        sort: '',
        order: 'asc',
        page: 1,
        pageSize: 1000
      },
      {
        name: 'userRole',
        comparator: 'EQ',
        value: 'admin'
      }
    ).then((result) => {
      setAdminUsers(
        result.data.data.filter(
          (users: PersonalInfo) => users.roleType === userTypes.Admin && users.id !== user.id
        )
      );
    });
  };

  const setEditable = (user: PersonalInfo) => {
    if (user.id !== 0) {
      setAdmin({
        ...admin,
        email: user.email,
        id: user.id,
        isEscalationEmail: user.isEscalationEmail,
        isTBEmail: user.isTBEmail,
        name: user.name,
        phoneNumber: user.phoneNumber,
        roleType: user.roleType
      });
    }
  };

  const toggleModal = () => {
    setModalOpen((v) => !v);
  };

  const clearAdmin = () => {
    setAdmin(emptyPersonalInfo());
    setAdminUsers(adminUsers.filter((admin) => admin.id));
    setError('');
  };

  const deleteAdmin = (user: PersonalInfo) => {
    deleteUserApiCall(user.id).then(() => {
      populateAdminTables();
      clearAdmin();
    });
  };

  const additionalContactInfoChanges = (e: any) => {
    switch (e.currentTarget.name) {
      case 'additionalContactName': {
        setAdmin({
          ...admin,
          name: e.currentTarget.value
        });
        break;
      }
      case 'additionalContactEmail': {
        setAdmin({
          ...admin,
          email: e.currentTarget.value
        });
        break;
      }
      case 'additionalContactPhone': {
        setAdmin({
          ...admin,
          phoneNumber: e.currentTarget.value
        });
        break;
      }
      default:
        break;
    }
  };

  const setAdditionalContactInfo = (option: any, emailType: string) => {
    if (emailType === 'isTBEmail')
      setAdmin({
        ...admin,
        isTBEmail: option.value
      });
    else if (emailType === 'isEscalationEmail')
      setAdmin({
        ...admin,
        isEscalationEmail: option.value
      });
  };

  const addAdmin = (user: PersonalInfo) => {
    addAdminApiCall({
      email: user.email,
      isEscalationEmail: user.isEscalationEmail,
      isTBEmail: user.isTBEmail,
      name: user.name,
      phoneNumber: user.phoneNumber,
      roleType: user.roleType
    } as CreateAdminPostData)
      .then(() => {
        populateAdminTables();
      })
      .catch((error) => {
        if (error.response && error.response.data)
          setError(error.response.data.error || 'Uncknown erorr');
      });
  };

  const editAdmin = (user: PersonalInfo) => {
    editUserApiCall(user.id, {
      email: user.email,
      isEscalationEmail: user.isEscalationEmail,
      isTBEmail: user.isTBEmail,
      name: user.name,
      phoneNumber: user.phoneNumber
    } as CreateAdminPostData).then(() => {
      populateAdminTables();
    });
  };

  return (
    <Fragment>
      {isEditMode && (
        <CustomModal
          isOpen={modalOpen}
          toggleModal={toggleModal}
          message={'Are you sure you want to delete this admin? This action cannot be undone.'}
          action={() => {
            deleteAdmin(admin);
          }}
        />
      )}
      <Col lg="8" className="pt-5 pt-lg-0">
        <Row>
          <Col md="12" className="ps-3 pe-3">
            <h5 className="mb-0 p-0 traceback-sub-title">Admin Contacts</h5>
            <div className="table-responsive additional-contact-table-responsive">
              <table className="additional-contact-table">
                <thead className="additional-contact-header">
                  <tr>
                    <td style={{ minWidth: 180 }}>Name</td>
                    <td style={{ minWidth: 300 }}>Email</td>
                    <td style={{ minWidth: 150 }}>Phone</td>
                    <td style={{ minWidth: 110 }}>Traceback Emails</td>
                    <td style={{ minWidth: 110 }}>Escalation Emails</td>
                  </tr>
                </thead>
                <tbody className={`additional-contact-body`}>
                  {adminUsers.map((user, index) => (
                    <tr
                      key={`additional-contact${index}`}
                      className={user.id === admin.id ? 'active-additional-row' : ''}
                    >
                      {!isEditMode || user.id !== admin.id ? (
                        <Fragment>
                          <td>{user.name || 'None'}</td>
                          <td>{user.email || 'None'}</td>
                          <td>{user.phoneNumber || 'None'}</td>
                          <td>{user.isTBEmail ? 'Yes' : 'No'}</td>
                          <td>{user.isEscalationEmail ? 'Yes' : 'No'}</td>
                          {isEditMode && (
                            <Fragment>
                              <td
                                onClick={() => {
                                  setEditable(user);
                                }}
                                className="pe-2"
                              >
                                <span
                                  className="fa fa-pencil"
                                  style={{ color: 'black', cursor: 'pointer' }}
                                />
                              </td>
                              <td
                                onClick={() => {
                                  setAdmin(user);
                                  toggleModal();
                                }}
                              >
                                <span
                                  className="fa fa-trash"
                                  style={{ color: 'black', cursor: 'pointer' }}
                                />
                              </td>
                            </Fragment>
                          )}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <td>
                            <Input
                              value={user.name}
                              name="additionalContactName"
                              id="additional-contact-name"
                              className={''}
                              autoComplete="off"
                              onChange={(e: any) => additionalContactInfoChanges(e)}
                            />
                          </td>
                          <td>
                            <Input
                              value={user.email}
                              name="additionalContactEmail"
                              className={''}
                              id="additional-contact-email"
                              autoComplete="off"
                              onChange={(e: any) => additionalContactInfoChanges(e)}
                            />
                          </td>
                          <td>
                            <Input
                              value={user.phoneNumber}
                              name="additionalContactPhone"
                              id="additional-contact-phone"
                              autoComplete="off"
                              onChange={(e: any) => additionalContactInfoChanges(e)}
                            />
                          </td>
                          <td>
                            <Select
                              menuPosition="fixed"
                              className={`customselect-large`}
                              classNamePrefix="customselect"
                              value={sOptions.find((item) => item.value === user.isTBEmail)}
                              placeholder=""
                              onChange={(option: any) =>
                                setAdditionalContactInfo(option, 'isTBEmail')
                              }
                              options={sOptions}
                              isSearchable={false}
                            />
                          </td>
                          <td>
                            <Select
                              menuPosition="fixed"
                              className={`customselect-large`}
                              classNamePrefix="customselect"
                              value={sOptions.find((item) => item.value === user.isEscalationEmail)}
                              placeholder=""
                              onChange={(option: any) =>
                                setAdditionalContactInfo(option, 'isEscalationEmail')
                              }
                              options={sOptions}
                              isSearchable={false}
                            />
                          </td>
                          <td
                            onClick={() => {
                              user.id === 0 ? addAdmin(user) : editAdmin(user);
                              clearAdmin();
                            }}
                            className="pe-3"
                          >
                            <span
                              className="fa fa-check"
                              style={{ color: 'green', cursor: 'pointer' }}
                            />
                          </td>
                          <td
                            onClick={() => {
                              clearAdmin();
                            }}
                            className="pe-3"
                          >
                            <span
                              className="fa fa-close"
                              style={{ color: 'red', cursor: 'pointer' }}
                            />
                          </td>
                        </Fragment>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <br />
          </Col>
        </Row>
        {error && <InputError className="telecom-input-error">{error}</InputError>}
      </Col>
    </Fragment>
  );
};

export default AdminTable;

interface MProps {
  isOpen: boolean;
  toggleModal: () => void;
  message: string;
  action: Function;
  cancel?: Function;
}
export const CustomModal: React.FC<MProps> = ({ isOpen, toggleModal, message, action, cancel }) => {
  return (
    <>
      <Modal centered isOpen={isOpen} className="submit-confirm-modal" toggle={toggleModal}>
        <ModalHeader toggle={toggleModal} />
        <ModalBody>
          <h5 className="traceback-sub-title">
            <span>{message}</span>
          </h5>
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <Row>
            <Button
              className="btn-default telecom-btn"
              color="light"
              onClick={() => {
                toggleModal();
                if (cancel) cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              className="telecom-btn"
              style={{ background: '#d61827' }}
              onClick={() => {
                action();
                toggleModal();
              }}
            >
              Proceed
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </>
  );
};
