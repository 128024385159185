import { convertToTimeZone } from 'date-fns-timezone';
import React, { Fragment, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { Button, Card, CardBody, CardHeader, Col, Collapse, FormGroup, Row } from 'reactstrap';
import Breadcrumb from '../components/Breadcrumbs';
import CampaignsSelect from '../components/CampaignsSelect';
import CustomDataTable from '../components/CustomDataTable';
import { SelectOption } from '../components/CustomSelect';
import HopInvalidAlert from '../components/Hops/HopInvalidAlert';
import ZerobounceAlert from '../components/Hops/ZerobounceAlert';
import Legend from '../components/shared/Legend';
import ZendeskWebWidget from '../components/shared/ZendeskWebWidget';
import { DateFormat } from '../enum/DateFormat';
import { userTypes } from '../enum/userTypes';
import { Pagination, defaultPagination } from '../interfaces/pagination';
import { Provider } from '../interfaces/provider';
import { TfHop } from '../interfaces/tfhop';
import { Traceforward } from '../interfaces/traceforward';
import { PersonalInfo } from '../interfaces/user';
import {
  addCondition,
  addConditionGroup,
  newConditionalFilterElement,
  newLeafFilterElement,
  removeFilterLeaf,
  simplifyFilterElement
} from '../lib/FilterElement';
import { tfhopsColumns } from '../lib/dataTableUtils/tfhopsColumns';
import {
  decodeQueryParams,
  encodeQueryParams,
  getFromLocalStorage,
  saveToLocalStorage
} from '../lib/history-utils';
import { getClientFormattedDate, getElapsedTime } from '../lib/utilities';
import { getProviderObject } from '../redux/provider/thunks';
import { stateMappings } from '../redux/stateMappings';
import { getAllTfHopsList, getTfHopsCSV, getTfHopsCountNumber } from '../redux/tfhop/thunks';
import { getTraceforwardObject } from '../redux/traceforward/thunks';

const PAGENAME = 'tfhops';

const setFiltersFromHistory = (savedSearchDetails: any) => {
  let filterableArray = [];
  const { filterElements } = savedSearchDetails;
  let campaignId: number = 0;
  let status: SelectOption[] = [];
  let startDate: Date | undefined;
  let endDate: Date | undefined;
  if (!filterElements) {
    return {
      status,
      campaignId,
      startDate,
      endDate
    };
  }

  if (!Array.isArray(filterElements.conditionals)) {
    filterableArray.push(filterElements);
  } else {
    filterableArray = filterElements.conditionals;
  }

  filterableArray.forEach(
    (condition: { comparator: string; conditionals: any; name: string; value: any }) => {
      const { conditionals, name, value } = condition;

      switch (name) {
        case 'campaignId':
          campaignId = Number(value);
          break;
        case 'startDate':
          startDate = new Date(value);
          break;
        case 'endDate':
          endDate = new Date(value);
          break;
        case 'status':
          switch (value) {
            case '1':
              status.push({ label: 'Open', value: value });
              break;
            case '2':
              status.push({ label: 'Completed', value: value });
              break;
            case '3':
              status.push({ label: 'Not Found', value: value });
              break;
            case '4':
              status.push({ label: 'No Response', value: value });
              break;
            case '7':
              status.push({ label: 'Pending Dispute', value: value });
              break;
            default:
              status.push({ label: 'Pending Approval', value: value });
              break;
          }
          break;
        case undefined:
          if (Array.isArray(conditionals)) {
            conditionals.forEach((condition: { name: string; value: string }) => {
              const { name, value } = condition;

              if (name === 'status') {
                switch (value) {
                  case '1':
                    status.push({ label: 'Open', value: value });
                    break;
                  case '2':
                    status.push({ label: 'Completed', value: value });
                    break;
                  case '3':
                    status.push({ label: 'Not Found', value: value });
                    break;
                  case '4':
                    status.push({ label: 'No Response', value: value });
                    break;
                  case '7':
                    status.push({ label: 'Pending Dispute', value: value });
                    break;
                  default:
                    status.push({ label: 'Pending Approval', value: value });
                    break;
                }
              }

              if (condition.name === 'startDate') {
                startDate = new Date(value);
              }

              if (condition.name === 'endDate') {
                endDate = new Date(value);
              }

              if (name === 'campaignId') {
                campaignId = Number(value);
              }
            });
          }
          break;
        default:
          break;
      }
    }
  );

  return {
    status,
    campaignId,
    startDate,
    endDate
  };
};

const getHopStatuses = (role: userTypes) => {
  const statuses = [
    { label: 'Open', value: '1' },
    { label: 'Completed', value: '2' },
    { label: 'Not Found', value: '3' },
    { label: 'No Response', value: '4' }
  ];
  return role === userTypes.Admin
    ? statuses.concat([
        { label: 'Pending Approval', value: '6' },
        { label: 'Pending Dispute', value: '7' }
      ])
    : statuses;
};

const getFilter = (
  status: SelectOption[],
  providerId: number,
  traceforwardId: number,
  campaignId: number,
  startDate: any,
  endDate: any
) => {
  let filterElements = newConditionalFilterElement('AND');

  if (status && status.length > 0) {
    let statusElements = newConditionalFilterElement('OR');
    status.forEach((item) => {
      addCondition(statusElements, newLeafFilterElement('status', 'EQ', item.value));
    });
    addCondition(filterElements, simplifyFilterElement(statusElements));
  }

  if (!!campaignId)
    addCondition(filterElements, newLeafFilterElement('campaignId', 'EQ', campaignId.toString()));

  if (startDate || endDate) {
    addConditionGroup('AND', filterElements, [
      { key: startDate ? 'startDate' : '', value: startDate },
      { key: endDate ? 'endDate' : '', value: endDate }
    ]);
  }

  if (providerId)
    addCondition(filterElements, newLeafFilterElement('providerId', 'EQ', providerId.toString()));
  if (traceforwardId)
    addCondition(
      filterElements,
      newLeafFilterElement('traceforwardId', 'EQ', traceforwardId.toString())
    );

  return simplifyFilterElement(filterElements);
};

interface IProps {
  isAuthenticated: boolean;
  user: PersonalInfo;
  provider: Provider;
  tfhops: Array<TfHop>;
  paginationTotalRows: number;
  statuses: { [key: number]: string };
  traceforward: Traceforward;
  tfhopInvalidId: number;
  getTraceforwardObject: Function;
  getProviderObject: Function;
  getTfHopsCountNumber: Function;
  getAllTfHopsList: Function;
  getTfHopsCSV: Function;
}

const TfHops: React.FC<IProps> = ({
  isAuthenticated,
  user,
  provider,
  tfhops,
  paginationTotalRows,
  statuses,
  traceforward,
  tfhopInvalidId,
  getTraceforwardObject,
  getProviderObject,
  getTfHopsCountNumber,
  getAllTfHopsList,
  getTfHopsCSV
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const savedSearchDetails = decodeQueryParams(PAGENAME, getFromLocalStorage(PAGENAME) || '');
  const filters = setFiltersFromHistory(savedSearchDetails);
  const [hopStatuses, sethopStatuses] = useState(() => getHopStatuses(user.roleType));
  const [status, setStatus] = useState(() => {
    const statusNum = searchParams.get('status');
    const statusOption = hopStatuses.find((status) => status.value === statusNum);
    return statusOption ? [statusOption] : filters.status || [];
  });
  const [startDate, setStartDate] = useState(filters.startDate);
  const [endDate, setEndDate] = useState(filters.endDate);

  const [campaignId, setCampaignId] = useState(
    Number(searchParams.get('campaignId')) || filters.campaignId
  );
  const traceforwardId = Number(searchParams.get('traceforwardId'));
  const providerId =
    user.roleType !== userTypes.Admin ? user.providerId : Number(searchParams.get('providerId')); // Override the provided providerId with the users providerId

  const [collapseFilters, setCollapseFilters] = useState(false);

  const [filterElements, setFilterElements] = useState(
    getFilter(status, providerId, traceforwardId, campaignId, startDate, endDate)
  );
  const [paginationParams, setPaginationParams] = useState<Pagination>(
    savedSearchDetails.paginationParams || {
      ...defaultPagination(),
      sort: 'completed',
      order: 'desc'
    }
  );

  // this should make the page more responsive
  const [tfhopColumns, setTfHopColumns] = useState(() =>
    tfhopsColumns(user.roleType === userTypes.Admin, !!traceforwardId, !!providerId, statuses)
  );

  useEffect(() => {
    sethopStatuses(getHopStatuses(user.roleType));
  }, [user.roleType]);

  useEffect(() => {
    setTfHopColumns(
      tfhopsColumns(user.roleType === userTypes.Admin, !!traceforwardId, !!providerId, statuses)
    );
  }, [user.roleType, traceforwardId, providerId, statuses]);

  useEffect(() => {
    if (traceforwardId) getTraceforwardObject(traceforwardId);
    if (providerId) getProviderObject(providerId);
  }, [traceforwardId, providerId]);

  useEffect(() => {
    setFilterElements(
      getFilter(status, providerId, traceforwardId, campaignId, startDate, endDate)
    );
  }, [status, providerId, traceforwardId, campaignId, startDate, endDate]);

  useEffect(() => {
    getData();
  }, [filterElements, paginationParams]);

  const getFilterWithoutSearchParams = () => {
    let filterElement = filterElements;
    if (traceforwardId)
      filterElement = removeFilterLeaf(filterElements, 'traceforwardId', traceforwardId.toString());
    if (searchParams.get('campaignId'))
      filterElement = removeFilterLeaf(filterElements, 'campaignId', campaignId.toString());
    if (providerId)
      filterElement = removeFilterLeaf(filterElements, 'providerId', providerId.toString());
    return filterElement;
  };
  const getData = () => {
    const searchParams = encodeQueryParams(
      PAGENAME,
      {
        filterElements: getFilterWithoutSearchParams(),
        paginationParams
      },
      { traceforwardId: traceforwardId, providerId: providerId }
    );

    saveToLocalStorage(PAGENAME, searchParams);

    getAllTfHopsList(
      paginationParams,
      !traceforwardId
        ? filterElements
        : newLeafFilterElement('traceforwardId', 'EQ', traceforwardId.toString())
    );

    if (user.roleType === userTypes.Admin) {
      let hcFilterElements = newConditionalFilterElement('AND');
      addCondition(hcFilterElements, newLeafFilterElement('status', 'EQ', '1'));

      if (providerId)
        addCondition(
          hcFilterElements,
          newLeafFilterElement('providerId', 'EQ', providerId.toString())
        );

      if (traceforwardId)
        addCondition(
          hcFilterElements,
          newLeafFilterElement('traceforwardId', 'EQ', traceforwardId.toString())
        );

      getTfHopsCountNumber(simplifyFilterElement(hcFilterElements));
    }
    window.scrollTo(0, 0);
  };

  const handleChange = (e: any, key: string) => {
    switch (key) {
      case 'startDate':
        if (e) {
          let date = new Date(Date.parse(e));
          setStartDate(
            new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0))
          );
        } else setStartDate(e);
        break;
      case 'endDate':
        if (e) {
          let date = new Date(Date.parse(e));
          setEndDate(
            new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59))
          );
        } else setEndDate(e);
        break;
      case 'campaignId':
        if (typeof e === 'object')
          if ('label' in e) setCampaignId(e.value);
          else setCampaignId(Array.from(e).length >= 1 ? Number(Array.from(e)[0]) : 0);
        break;
    }
  };

  const updatePagination = (params: Pagination) => {
    setPaginationParams((v) => ({ ...v, ...params }));
  };

  const selectStatusOptions = (value: any) => {
    setStatus(value);
  };

  const toggleFilters = () => {
    setCollapseFilters((v) => !v);
  };

  const clearFilters = () => {
    setPaginationParams({
      ...defaultPagination(),
      sort: 'completed',
      order: 'desc'
    });
    setStatus([]);
    setStartDate(undefined);
    setEndDate(undefined);
    setCampaignId(0);
    setFilterElements({});
    setSearchParams('');
  };

  return isAuthenticated && (user.roleType === userTypes.Admin || providerId) ? (
    <Fragment>
      <Breadcrumb
        title={
          !!traceforwardId
            ? `All Hops for Traceforward ${traceforwardId}`
            : providerId
              ? `All Traceforward Hops - ${providerId ? (provider ? provider.name : '-') : ''} `
              : 'TfHops'
        }
        className="table-breadcrumbs"
        reputation={providerId ? provider.reputation : undefined}
      />
      <Card className="table-card hide-border" style={{ marginBottom: -5 }}>
        {!!traceforwardId && (
          <CardHeader className="card-header-hops">
            <div className="d-flex flex-row justify-content-around">
              <div className="mb-0">
                <label className="telecom-label ps-0">Called Number</label>
                <i className="fa fa-phone" style={{ color: 'red' }} />
                <span>{traceforward.calledTN}</span>
              </div>
              <div className="mb-0">
                <label className="telecom-label ps-0">Calling Number</label>
                <i className="fa fa-phone" style={{ color: 'green' }} />
                <span>{traceforward.callingTN}</span>
              </div>
              <div className="mb-0">
                <label className="telecom-label ps-0">Date & Time of Call</label>
                <span>
                  {getClientFormattedDate(traceforward.traceforwardTime, DateFormat.ShortBoth)}
                </span>
              </div>
              <div className="mb-0">
                <label className="telecom-label ps-0">Campaign Name</label>
                <span className="span-cell blue">
                  <a href={`/campaigns/campaign/${traceforward.campaignId}`}>
                    {traceforward.campaignName}
                  </a>
                </span>
              </div>
              <div className="mb-0">
                <label className="telecom-label ps-0">Traceforward</label>
                <span className="span-cell blue">
                  <a href={`/traceforwards/traceforward/${traceforwardId}`}>{traceforwardId}</a>
                </span>
              </div>
              <div className="mb-0">
                <label className="telecom-label ps-0">Total Elapsed</label>
                <span className="table-cell-gray">
                  {traceforward.elapsed ? getElapsedTime(traceforward.elapsed) : 'None'}
                </span>
              </div>
            </div>
          </CardHeader>
        )}
        {!traceforwardId && (
          <CardHeader className="card-header-hops">
            <CardHeader
              style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: 20 }}
              onClick={() => toggleFilters()}
              data-type="collapseBanner"
            >
              Filters
              <i
                style={{ position: 'absolute', right: 0, marginRight: 35, marginTop: 5 }}
                //className="fa-solid fa-arrow-up"
                className={`fa-solid ${collapseFilters ? 'fa-arrow-up' : 'fa-arrow-down'}`}
              ></i>
            </CardHeader>
            <Collapse isOpen={collapseFilters}>
              <CardBody>
                <div className="row">
                  <Col className="fixed-column">
                    <label className="telecom-label">Start Date</label>
                  </Col>
                  <Col className="fixed-column">
                    <label className="telecom-label">End Date</label>
                  </Col>
                  <Col className="fixed-column">
                    <label className="telecom-label">Status</label>
                  </Col>
                  {searchParams.get('campaignId') === null && (
                    <Col className="fixed-column">
                      <label className="telecom-label">Campaigns</label>
                    </Col>
                  )}
                  <Col className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => {
                        clearFilters();
                      }}
                    >
                      Clear
                    </button>
                  </Col>
                </div>
                <div className="row">
                  <Col className="fixed-column">
                    <DatePicker
                      maxDate={
                        endDate ? convertToTimeZone(endDate, { timeZone: 'GMT' }) : new Date()
                      }
                      selected={
                        startDate ? convertToTimeZone(startDate, { timeZone: 'GMT' }) : undefined
                      }
                      onChange={(option) => handleChange(option, 'startDate')}
                      placeholderText="&#xf133; mm/dd/yyyy"
                      clearButtonTitle={'Clear'}
                      isClearable
                    />
                  </Col>
                  <Col className="fixed-column">
                    <DatePicker
                      maxDate={new Date()}
                      selected={
                        endDate ? convertToTimeZone(endDate, { timeZone: 'GMT' }) : undefined
                      }
                      minDate={
                        startDate ? convertToTimeZone(startDate, { timeZone: 'GMT' }) : undefined
                      }
                      onChange={(option) => handleChange(option, 'endDate')}
                      placeholderText="&#xf133; mm/dd/yyyy"
                      clearButtonTitle={'Clear'}
                      isClearable
                    />
                  </Col>
                  <Col className="fixed-column">
                    <Select
                      className="customselect-small"
                      classNamePrefix="customselect"
                      options={hopStatuses}
                      isMulti
                      isSearchable={false}
                      placeholder="All statuses"
                      onChange={selectStatusOptions}
                      value={status}
                    />
                  </Col>
                  {searchParams.get('campaignId') === null && (
                    <Col className="fixed-column">
                      <CampaignsSelect
                        value={campaignId}
                        isSearchable
                        addAllItem
                        onChange={(option) => handleChange(option, 'campaignId')}
                      />
                    </Col>
                  )}
                </div>

                {user.roleType !== userTypes.Admin && (
                  <Row className="mt-2 me-5">
                    <Col md="8" />
                    <Col md="4">
                      <FormGroup>
                        <Button
                          onClick={() => getTfHopsCSV(filterElements, paginationParams)}
                          style={{
                            height: '28px',
                            padding: '5px',
                            fontSize: '14px',
                            float: 'right'
                          }}
                        >
                          <i className="fa fa-download" />
                          {` Download CSV`}
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Collapse>
          </CardHeader>
        )}
        <CardBody className={'card-body-hop'}>
          <CustomDataTable
            tableData={tfhops}
            columns={tfhopColumns}
            defaultSortFieldId={paginationParams.sort}
            defaultSortAsc={paginationParams.order === 'asc'}
            defaultPage={paginationParams.page}
            defaultPageSize={paginationParams.pageSize}
            paginationTotalRows={paginationTotalRows}
            pagination
            updatePaginationParams={updatePagination}
          />
        </CardBody>
      </Card>
      <Row style={{ padding: '20px 30px', justifyContent: 'center' }}>
        <Legend />
      </Row>
      <ZerobounceAlert />
      <ZendeskWebWidget />
      <HopInvalidAlert hopId={tfhopInvalidId} />
    </Fragment>
  ) : null;
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    user: sm.user,
    tfhops: sm.tfhop.tfhops,
    traceforward: sm.traceforward.traceforward,
    provider: sm.provider.provider,
    tbStats: sm.traceforward.tbStats,
    statuses: sm.tfhop.statuses,
    paginationTotalRows: sm.tfhop.meta.TotalCount,
    isAuthenticated: sm.isAuthenticated,
    tfhopInvalidId: sm.tfhop.tfhopInvalidId
  };
};

//TODO: Replace AllProvidersListAPI call with /api/AppInfo/System/ProviderNames
const mapActionsToProps = {
  getAllTfHopsList,
  getTfHopsCountNumber,
  getTraceforwardObject,
  getProviderObject,
  getTfHopsCSV: getTfHopsCSV
};

export default connect(mapStateToProps, mapActionsToProps)(TfHops);
