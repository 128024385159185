import { StirShaken } from './actionEnum';

export const getStirShakens = (response: any) => ({
  type: StirShaken.GET_STIRSHAKENS,
  payload: response
});
export const getAttestationStatsAction = (response: any) => ({
  type: StirShaken.GET_ATTESTATION_STATS,
  payload: response
});

export const getOriginStatsAction = (response: any) => ({
  type: StirShaken.GET_ORIGIN_STATS,
  payload: response
});

export const setLoadingStatus = (status: boolean) => ({
  type: StirShaken.SET_STIRSHAKEN_LOADING_STATUS,
  payload: status
});
export const setErrorMessage = (message: string) => ({
  type: StirShaken.SET_STIRSHAKEN_ERROR_MESSAGE,
  payload: message
});
