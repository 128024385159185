import React, { useState } from 'react';
import { Button, CardHeader, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { userTypes } from '../../enum/userTypes';
import { TfHop } from '../../interfaces/tfhop';
import { PersonalInfo } from '../../interfaces/user';

interface IProps {
  tfhop: TfHop;
  user: PersonalInfo;
  renotifyProvider: Function;
}

const TfHopCardHeader: React.FC<IProps> = ({ tfhop, user, renotifyProvider }) => {
  const [isRenotify, setIsRenotify] = useState(false);
  // Open Hops
  const isOpen = tfhop.status === 1;

  const renotifyToggle = () => {
    setIsRenotify((v) => !v);
  };

  const renotify = async () => {
    await renotifyProvider(tfhop.tfhopProvider.providerId);
    renotifyToggle();
  };

  return (
    <CardHeader className="telecom-card-header">
      <div className="d-flex justify-content-between m-0">
        <span style={{ display: 'flex text-nowrap', alignItems: 'center' }}>
          {`Hop ${tfhop.tfhopSequence || 'Unknown'} for Traceforward ${tfhop.traceforwardId}`}
          {/*Terminating | Originating | Intermediate*/}
          {(tfhop.tfhopSequence !== 1 && tfhop.status === 2 && !tfhop.upstreamTfHopId) ||
          tfhop.status === 3 ||
          tfhop.status === 4 ? (
            <i className="fa fa-check green" />
          ) : !tfhop.upstreamProvider ? (
            <i className="fa fa-map-marker red" />
          ) : null}
        </span>
        {isOpen && user.roleType === userTypes.Admin && (
          <Button
            color="primary"
            onClick={renotifyToggle}
            style={{ padding: '0 10px', minWidth: '150px' }}
          >
            Renotify
          </Button>
        )}
      </div>
      <Modal centered isOpen={isRenotify} className="submit-confirm-modal" toggle={renotifyToggle}>
        <ModalHeader toggle={renotifyToggle} />
        <ModalBody>
          <h5 className="traceforward-sub-title">
            Do you want to send a reminder to this Provider about this Hop?
          </h5>
        </ModalBody>
        <ModalFooter style={{ margin: 'auto' }}>
          <Row>
            <Button className="btn-default telecom-btn" color="light" onClick={renotifyToggle}>
              Cancel
            </Button>
            <Button className="telecom-btn" style={{ background: '#d61827' }} onClick={renotify}>
              Send
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </CardHeader>
  );
};

export default TfHopCardHeader;
