import { Comment } from './comment';

export interface Campaign {
  active: boolean;
  campaignId: number;
  comments: Comment[];
  create_date: string;
  createdBy: string;
  description: string;
  name: string;
  numOfAttachments: number;
  numOfCompleteTracebacks: number;
  numOfNotFoundTracebacks: number;
  numOfNoInfoTracebacks: number;
  numOfPendingTracebacks: number;
  numOfTracebacks: number;
  reference: string;
  language: string;
  source: string;
  sources: Array<CampaignSource>;
  labels: Array<CampaignLabel>;
  systemComments: string;
  update_date: string;
  updateFlag: number;
  workflow: string;
  isLowVolume: boolean;
  campaignAttributes: CampaignAttributes;
}

export interface CampaignPostData {
  campaignId: number;
  create_date?: string;
  createdBy: string;
  description: string;
  name: string;
  reference: string;
  language: string | null;
  source: string;
  sources: Array<CampaignSource>;
  labels: Array<CampaignLabel>;
  update_date?: string;
  updateFlag?: number;
  workflow: string;
  isLowVolume: boolean;
  campaignAttributes: CampaignAttributes;
}

export interface CampaignAttributes {
  fraud: boolean;
  brandImpersonation: boolean;
  numberSpoofing: boolean;
  dialing: boolean;
  eHighVolume: boolean;
  eCalledPartyConsent: boolean;
  eComplaints: boolean;
  eFlawedConsent: boolean;
  doNotCall: boolean;
  noName: boolean;
  noNumber: boolean;
  noOptOut: boolean;
  annoyanceAbuse: boolean;
  isAllowDispute: boolean;
}

export interface CampaignSource {
  sourceId: number;
  name: string;
  active?: boolean;
  isProvider: boolean;
  isSystemDefined: boolean;
  create_date?: string;
  update_date?: string;
}

export interface CampaignLabel {
  labelID: number;
  name: string;
}

export interface Option {
  value: number;
  label: string;
}

export const emptyCampaign = (): Campaign => ({
  active: false,
  campaignId: 0,
  comments: [],
  create_date: '',
  createdBy: '',
  description: '',
  name: '',
  numOfAttachments: 0,
  numOfCompleteTracebacks: 0,
  numOfNotFoundTracebacks: 0,
  numOfNoInfoTracebacks: 0,
  numOfPendingTracebacks: 0,
  numOfTracebacks: 0,
  reference: '',
  language: '',
  source: '',
  sources: [],
  labels: [],
  systemComments: '',
  update_date: '',
  updateFlag: 0,
  workflow: '',
  isLowVolume: false,
  campaignAttributes: emptyCampaignAttributes()
});

export const emptyCampaignAttributes = (): CampaignAttributes => ({
  fraud: false,
  brandImpersonation: false,
  numberSpoofing: false,
  dialing: false,
  eHighVolume: false,
  eCalledPartyConsent: false,
  eComplaints: false,
  eFlawedConsent: false,
  doNotCall: false,
  noName: false,
  noNumber: false,
  noOptOut: false,
  annoyanceAbuse: false,
  isAllowDispute: true
});

export interface DisputeInfo {
  disputeVisible: boolean;
  attributes: CampaignAttributes;
  comment: string;
  consentGiven: boolean;
  attachments: File[];
}
