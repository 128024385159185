import React, { FC } from 'react';
import { TableColumn } from 'react-data-table-component';
import { CardBody } from 'reactstrap';
import { DateFormat } from '../../../enum/DateFormat';
import { AccessLogEntry } from '../../../interfaces/accessLogEntry';
import { Pagination } from '../../../interfaces/pagination';
import { getClientFormattedDate } from '../../../lib/utilities';
import CustomDataTable from '../../CustomDataTable';

interface IProps {
  updatePagination: Function;
  paginationParams: Pagination;
  paginationTotalRows: number;
  accessLogs: AccessLogEntry[];
}

const columns: TableColumn<AccessLogEntry>[] = [
  {
    name: '',
    width: '20px',
    cell: () => <div />
  },
  {
    name: 'Date and Time',
    selector: () => 'accessDate',
    id: 'column-accessDate',
    sortable: true,
    grow: 1,
    cell: (logEntry: AccessLogEntry) => (
      <div className="table-cell gray">
        {logEntry.accessDate
          ? getClientFormattedDate(logEntry.accessDate, DateFormat.ShortBoth)
          : 'None'}
      </div>
    )
  },
  {
    name: 'Provider',
    selector: () => 'providerName',
    id: 'column-providerName',
    sortable: true,
    grow: 1,
    cell: (logEntry: AccessLogEntry) => (
      <div className="table-cell gray">{logEntry.user?.providerName || 'None'}</div>
    )
  },
  {
    name: 'Email',
    selector: () => 'email',
    id: 'column-email',
    sortable: true,
    grow: 1.5,
    cell: (logEntry: AccessLogEntry) => (
      <div className="table-cell gray d-flex justify-content-center text-wrap">
        {logEntry.user?.email || 'None'}
      </div>
    )
  },
  {
    name: 'Origin',
    selector: () => 'origin',
    id: 'column-origin',
    sortable: true,
    grow: 2,
    cell: (logEntry: AccessLogEntry) => (
      <div className="table-cell gray center" title={logEntry.origin}>
        {logEntry.origin || 'None'}
      </div>
    )
  },
  {
    name: 'Activity',
    selector: () => 'description',
    id: 'column-description',
    sortable: false,
    grow: 2,
    cell: (logEntry: AccessLogEntry) => (
      <div
        className="table-cell gray left"
        style={{ color: logEntry.responseCode > 299 ? 'red' : '' }}
      >
        {logEntry.description || 'None'}
      </div>
    )
  }
];

const IPHistoryBasic: FC<IProps> = ({
  paginationParams,
  paginationTotalRows,
  accessLogs,
  updatePagination
}) => (
  <CardBody className="card-body-traceback">
    <CustomDataTable
      columns={columns}
      defaultSortFieldId={paginationParams.sort}
      defaultSortAsc={paginationParams.order === 'asc'}
      defaultPage={paginationParams.page}
      defaultPageSize={paginationParams.pageSize}
      pagination={true}
      paginationTotalRows={paginationTotalRows}
      tableData={accessLogs}
      updatePaginationParams={updatePagination}
    />
  </CardBody>
);

export default IPHistoryBasic;
