import React, { Fragment } from 'react';
import { userTypes } from '../../enum/userTypes';
import { CampaignAttributes } from '../../interfaces/campaign';
import { PersonalInfo } from '../../interfaces/user';
import { campaignAttributes } from '../../lib/campaign';
import CustomToolTip from '../CustomToolTip';

interface IProps {
  source: CampaignAttributes;
  isCampaign?: boolean;
  user: PersonalInfo;
}
const BasisForTraceback: React.FC<IProps> = ({ source, isCampaign, user }) =>
  source &&
  (source.isAllowDispute || user.roleType === userTypes.Admin) &&
  Object.values(source).some((v) => !!v) ? (
    <Fragment>
      {user.roleType === userTypes.Admin && (
        <Fragment>
          <label className="label-bold" style={{ marginTop: '10px' }}>
            Allow Origin To Dispute
          </label>
          <p className="telecom-text mb-3" style={{ padding: '5px 8px' }}>
            {source.isAllowDispute ? 'True' : 'False'}
          </p>
        </Fragment>
      )}
      <h5 className={`${isCampaign ? 'label-bold' : 'p-0 traceback-sub-title mb-3'}`}>
        Basis for Traceback
      </h5>
      <div className="d-flex flex-row">
        <ul style={{ listStyleType: 'none' }}>
          {campaignAttributes
            .filter(({ tag }) => source[tag as keyof CampaignAttributes] === true)
            .map(({ name, toolMessage }, index) => {
              return (
                <li key={index}>
                  <div className="d-flex flex-row pb-2">
                    <div>
                      <input
                        type="checkbox"
                        id={`custom-checkbox-${index}`}
                        name={'attributes'}
                        checked
                        disabled
                      />
                    </div>
                    <div className="ps-2">
                      <CustomToolTip tooltipVisible message={toolMessage}>
                        <label className="text-dark">{name}</label>
                      </CustomToolTip>
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </Fragment>
  ) : null;

export default BasisForTraceback;
