import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { OnChangeValue } from 'react-select';
import { Button, Card, CardBody } from 'reactstrap';
import {
  IncidentTableRow,
  PostTracebackIncidentRequest,
  emptyIncidentTableRow
} from '../../../interfaces/incident';
import { DnoToolTip } from '../../../lib/dno';
import {
  callingNumberIsAllowed,
  checkInternationalPhoneNumber,
  validatePhoneNumber
} from '../../../lib/phoneNumber';
import { getClientFormattedDate } from '../../../lib/utilities';
import CampaignsSelect from '../../CampaignsSelect';
import { SelectOption } from '../../CustomSelect';
import CustomToolTip from '../../CustomToolTip';
import ProvidersSelect from '../../ProvidersSelect';
import StirShakenIncidentRow from '../../StirShakenIncidentRow';

interface Props {
  data?: IncidentTableRow;
  campaignSelected?: boolean;
  setSelectedIncident?: (inc: PostTracebackIncidentRequest) => void;
}

const IncidentsExpandableRow: FC<Props> = ({ data, campaignSelected, setSelectedIncident }) => {
  const [edit, setEdit] = useState(false);
  const [incident, setIncident] = useState<IncidentTableRow>(emptyIncidentTableRow());

  const [campaignId, setCampaignId] = useState<number>();
  const [providerId, setProviderId] = useState<number>();

  const phoneNumbersText = useMemo(() => {
    if (!data) return '';
    const originatorPhoneNumber = checkInternationalPhoneNumber(data.originatorPhoneNumber);
    const destinationPhoneNumber = checkInternationalPhoneNumber(data.destinationPhoneNumber);
    if (originatorPhoneNumber && destinationPhoneNumber)
      return 'Originator and destination numbers are international';
    else if (originatorPhoneNumber && !destinationPhoneNumber)
      return 'Originator number is international';
    else if (!originatorPhoneNumber && destinationPhoneNumber)
      return 'Destination number is international';
    return '';
  }, [data]);

  const phoneNumbersAreWrong = useMemo(
    () =>
      !validatePhoneNumber(incident.destinationPhoneNumber) ||
      (!validatePhoneNumber(incident.originatorPhoneNumber) &&
        !callingNumberIsAllowed(incident.originatorPhoneNumber)),
    [incident.originatorPhoneNumber, incident.destinationPhoneNumber]
  );
  const [acceptWrongNumberFormat, setAcceptWrongNumberFormat] = useState(false);

  const editable = useMemo(
    () => campaignSelected && !data?.tracebackId && setSelectedIncident,
    [campaignSelected, data?.tracebackId, setSelectedIncident]
  );

  useEffect(() => {
    if (data) setIncident(data);
  }, [data]);

  const handlePhoneNumbersChange = (e: React.FormEvent<HTMLInputElement>, key: string) => {
    switch (key) {
      case 'originator':
        setIncident({ ...incident, originatorPhoneNumber: e.currentTarget.value });
        break;
      case 'destination':
        setIncident({ ...incident, destinationPhoneNumber: e.currentTarget.value });
        break;
      default:
        break;
    }
  };

  const setSelectedPhoneNumbers = () => {
    setSelectedIncident &&
      setSelectedIncident({
        incidents: [
          {
            id: incident.id,
            incidentCampaignId: incident.incidentCampaignId,
            originatorPhoneNumber: incident.originatorPhoneNumber,
            destinationPhoneNumber: incident.destinationPhoneNumber,
            callTime: incident.callTime
          }
        ],
        tracebackCampaignId: campaignId,
        tracebackProviderId: providerId
      });
    setEdit(false);
  };

  const handleClose = () => {
    setIncident({
      ...incident,
      originatorPhoneNumber: data ? data?.originatorPhoneNumber : '',
      destinationPhoneNumber: data ? data?.destinationPhoneNumber : ''
    });
    setCampaignId(undefined);
    setProviderId(undefined);
    setEdit(false);
  };

  const handleCampaignChange = (selectedOption: OnChangeValue<SelectOption, boolean>) => {
    if (selectedOption) setCampaignId((selectedOption as SelectOption).value);
    else setCampaignId(undefined);
  };

  return (
    <Card className="expandedRow">
      <CardBody style={{ color: '#797979', fontSize: '1rem', padding: '0.5rem 1rem' }}>
        {phoneNumbersText && <div className="text-danger">{phoneNumbersText}</div>}
        <div className="d-flex justify-content-start mt-1">
          <span className="ms-4">
            <span className="font-calibri-bold">Caller Phone Number</span>{' '}
            {edit ? (
              <div className="d-inline">
                <input
                  onChange={(event: React.FormEvent<HTMLInputElement>) =>
                    handlePhoneNumbersChange(event, 'originator')
                  }
                  className="input-comment form-control"
                  value={incident.originatorPhoneNumber}
                />
                {!validatePhoneNumber(incident.originatorPhoneNumber) &&
                  !callingNumberIsAllowed(incident.originatorPhoneNumber) && (
                    <span className="text-danger">Not a valid Phone</span>
                  )}
              </div>
            ) : (
              data?.originatorPhoneNumber
            )}
          </span>
          <span className="ms-4">
            <span className="font-calibri-bold">Caller ID</span> {data?.originatorCnam}
          </span>
          <span className="ms-4">
            <span className="font-calibri-bold">Caller Carrier</span> {data?.originatorCarrier}
          </span>
        </div>
        <div className="d-flex justify-content-start mt-1">
          <span className="ms-4">
            <span className="font-calibri-bold">Call Time</span>{' '}
            {getClientFormattedDate(data?.callTime)}
          </span>
          <span className="ms-4">
            <span className="font-calibri-bold">Call Result</span> {data?.callResult}
          </span>
        </div>
        <div className="d-flex justify-content-start mt-1">
          <span className="ms-4">
            <span className="font-calibri-bold mt-1">Callee Phone Number</span>{' '}
            {edit ? (
              <div className="d-inline ms-4">
                <input
                  onChange={(event: React.FormEvent<HTMLInputElement>) =>
                    handlePhoneNumbersChange(event, 'destination')
                  }
                  className="input-comment form-control"
                  value={incident.destinationPhoneNumber}
                />
                {!validatePhoneNumber(incident.destinationPhoneNumber) && (
                  <span className="text-danger">Not a valid Phone</span>
                )}
              </div>
            ) : (
              data?.destinationPhoneNumber
            )}
          </span>
          <span className="ms-4">
            <span className="font-calibri-bold">Callee Carrier</span> {data?.destinationCarrier}
          </span>
          <span className="ms-4">
            <span className="font-calibri-bold">Do Not Call</span> {data?.doNotCall}
          </span>
          <span className="ms-4">
            <span className="font-calibri-bold">Do Not Origin</span>{' '}
            <CustomToolTip
              message={
                DnoToolTip.find((v) => v.type === data?.dnoType)?.description || data?.dnoType || ''
              }
              tooltipVisible={!!data?.dnoType}
            >
              <span className="text-secondary">{data?.dno ? 'Industry DNO' : 'Not Listed'}</span>
            </CustomToolTip>
          </span>
        </div>
        <div className="d-flex justify-content-start mt-1">
          <span className="ms-4">
            <span className="font-calibri-bold">Transcription</span> {data?.transcriptBody}
          </span>
        </div>
        <div className="d-flex justify-content-start mt-1">
          <span className="ms-4">
            <span className="font-calibri-bold">More Information</span>{' '}
            {data?.audioUrl ? (
              <Fragment>
                <br />
                <audio controls>
                  <source src={data.audioUrl} />
                </audio>
                <br />
                <a
                  href={data.audioUrl}
                  target={'audio'}
                  rel={'noopener noreferrer'}
                  className="telecom-text "
                  style={{ paddingTop: '5px', fontSize: '0.9em' }}
                  type={'audio/mp3'}
                >
                  {data.audioUrl}
                </a>
              </Fragment>
            ) : (
              'None'
            )}
          </span>
        </div>
        {data?.stirShaken && <StirShakenIncidentRow stirShaken={data?.stirShaken} />}
        {editable &&
          (edit ? (
            <>
              {phoneNumbersAreWrong && (
                <div className="row mt-2 ms-2">
                  <div className="col-4">
                    <input
                      type="checkbox"
                      onChange={() => setAcceptWrongNumberFormat((v) => !v)}
                      checked={acceptWrongNumberFormat}
                    />
                    <span className="ms-1">Are the phone numbers correct?</span>
                  </div>
                </div>
              )}
              <div className="row mt-2 ms-2">
                <div className="col-2">
                  <CampaignsSelect
                    value={campaignId}
                    onChange={handleCampaignChange}
                    isClearable
                    selectClass="customselect-small"
                    placeholder="Select Campaign"
                    maxMenuHeight={150}
                  />
                </div>
                <div className="col-2">
                  <ProvidersSelect
                    noneSelectedByDefault={true}
                    className={'customselect-small'}
                    onChange={(option: any) => {
                      setProviderId(option.value);
                    }}
                    placeholder="Choose Existing Provider..."
                    isSearchable={true}
                    option={providerId}
                  />
                </div>
                <div className="col-1">
                  <Button size="sm" color="primary" onClick={() => handleClose()}>
                    Cancel
                  </Button>
                </div>
                <div className="col-2">
                  <Button
                    size="sm"
                    color="primary"
                    disabled={
                      !campaignSelected || (phoneNumbersAreWrong && !acceptWrongNumberFormat)
                    }
                    onClick={() => setSelectedPhoneNumbers()}
                  >
                    Create Traceback
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <Button size="sm" color="primary" onClick={() => setEdit(true)} className="ms-4">
              Edit
            </Button>
          ))}
      </CardBody>
    </Card>
  );
};

export default IncidentsExpandableRow;
