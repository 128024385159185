import React, { Dispatch, Fragment, SetStateAction } from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import { CampaignAttributes, DisputeInfo } from '../../interfaces/campaign';
import { campaignAttributes } from '../../lib/campaign';
import CustomToolTip from '../CustomToolTip';
import DropZone from '../DropZone';
import InputFormGroup from '../inputFormGroup';

interface IProps {
  campaignAttribute: CampaignAttributes;
  info: DisputeInfo;
  setDispute: Dispatch<SetStateAction<DisputeInfo>>;
}
export const attributesExistAndAllowed = (campaignAttributes: CampaignAttributes): boolean => {
  return (
    campaignAttributes &&
    campaignAttributes.isAllowDispute &&
    Object.values(campaignAttributes).filter((v) => !!v).length > 1
  );
};
const Dispute: React.FC<IProps> = ({ campaignAttribute, info, setDispute }) => {
  const handleAttributes = (event: React.FormEvent<HTMLInputElement>, tag: string) => {
    const checked = event.currentTarget.checked;
    setDispute((v) => ({
      ...v,
      attributes: {
        ...v.attributes,
        [tag as keyof CampaignAttributes]: checked
      }
    }));
  };

  const addFile = (files: File[]) => {
    setDispute((v) => ({
      ...v,
      attachments: [
        ...v.attachments,
        ...files.filter((file) => !v.attachments.find((item) => item.name === file.name))
      ]
    }));
  };

  const removeFile = (file: File) => {
    setDispute((v) => ({
      ...v,
      attachments: v.attachments.filter((item) => item.name !== file.name)
    }));
  };

  const handleCommentChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      const value = e.currentTarget.value;
      setDispute((v) => ({ ...v, comment: value }));
    }
  };

  return (
    <Fragment>
      <Col>
        <FormGroup>
          <div className="d-flex flex-column justify-content-center ms-5">
            <InputFormGroup
              isTextarea
              inputName="relatedComment"
              inputId="relatedComment"
              inputClassName="input-comment"
              inputValue={info.comment}
              inputOnChange={handleCommentChange}
              inputPlaceholder="Please provide explantion here of why some or all Basic for Traceback are beeing disputed. Upload supporting documentation and proof of consent."
              inputAutoComplete="off"
              errorMessage={
                info.consentGiven &&
                !(info.comment || (info.attachments && info.attachments.length > 0))
                  ? 'Please provide a comment or add attachment for the given consent'
                  : undefined
              }
              inputErrorClassName="col-lg-10 text-center"
            />
          </div>
          <div className="pt-3 mx-auto d-flex flex-row justify-content-center">
            <Label className="form-label checkbox-label col-5">
              Consent for called party
              <input
                type="checkbox"
                onChange={() => {
                  setDispute((v) => ({ ...v, consentGiven: !v.consentGiven }));
                }}
                value="consent"
                checked={info.consentGiven}
              />
              <span className="checkmark" />
            </Label>
            <div className="d-flex flex-column col-5">
              <Label className="telecom-label">Attachments</Label>
              <DropZone attachments={info.attachments} removeFile={removeFile} addFile={addFile} />
            </div>
          </div>
        </FormGroup>
      </Col>
      <Col>
        <ul style={{ listStyleType: 'none' }}>
          {campaignAttributes.map(({ name, toolMessage, tag }, index) => {
            return (
              campaignAttribute[tag as keyof CampaignAttributes] && (
                <li key={index}>
                  <div className="d-flex flex-row">
                    <div>
                      <input
                        type="checkbox"
                        id={`custom-checkbox-${index}`}
                        name={'attributes'}
                        defaultChecked={info.attributes[tag as keyof CampaignAttributes]}
                        onChange={(e) => handleAttributes(e, tag)}
                      />
                    </div>
                    <div className="col ps-2">
                      <CustomToolTip tooltipVisible message={toolMessage}>
                        <label className="text-dark">{name}</label>
                      </CustomToolTip>
                    </div>
                  </div>
                </li>
              )
            );
          })}
        </ul>
      </Col>
    </Fragment>
  );
};
export default Dispute;
