import React, { Fragment, RefObject, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { Collapse, Nav, NavItem, Navbar, Popover, PopoverBody } from 'reactstrap';
import { userTypes } from '../enum/userTypes';
import { NotificationNewComment } from '../interfaces/comment';
import { Configuration } from '../interfaces/configuration';
import { defaultPaginationNotification } from '../interfaces/pagination';
import { PersonalInfo } from '../interfaces/user';
import { useOnOutsideClickWithRefs } from '../lib/hooks';
import { signOut } from '../redux/auth/thunks';
import { getAllNotifications } from '../redux/notification/thunks';
import { stateMappings } from '../redux/stateMappings';
import NotificationPopover from './NotificationPopover';
import { getHopsCountNumber } from '../redux/hop/thunks';
import { getTfHopsCountNumber } from '../redux/tfhop/thunks';
import {
  addCondition,
  newConditionalFilterElement,
  newLeafFilterElement,
  simplifyFilterElement
} from '../lib/FilterElement';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { getIncidentRequestsCount } from '../redux/request/apiCalls';
import IconCounterDisplay from './IconCounterDisplay';

interface IProps {
  signOut: Function;
  isAuthenticated: boolean;
  fullName: string;
  user: PersonalInfo;
  newCommentsNotificationList: NotificationNewComment[];
  getAllNotifications: Function;
  configuration: Configuration;
  numOpenHops: number;
  numOfOpenTfHops: number;
  getHopsCountNumber: Function;
  getTfHopsCountNumber: Function;
}

const hops = (
  popoverOpen: boolean,
  togglePopOver: () => void,
  ref: RefObject<HTMLDivElement>,
  hopCount: number,
  tfHopCount: number
) => (
  <NavItem className="d-flex">
    <NavLink className="nav-link" style={{ paddingRight: '5px' }} to="/hops" end>
      Hops
    </NavLink>
    <i
      className="fa fa-angle-down"
      id="hop-dropdown"
      onMouseOver={togglePopOver}
      style={{ paddingTop: '12px', fontSize: '20px' }}
    />
    <Popover
      placement="bottom-end"
      isOpen={popoverOpen}
      target="hop-dropdown"
      className="hop-dropdown"
      toggle={togglePopOver}
    >
      <PopoverBody>
        <div ref={ref}>
          {' '}
          <NavItem>
            <NavLink className="nav-link" to="/hops" end onClick={() => togglePopOver()}>
              Hops
            </NavLink>
            {hopCount !== 0 && (
              <NavLink
                className="nav-link position-absolute"
                style={{ top: '8px', left: '88px' }}
                to="/hops?status=1"
                end
                onClick={() => togglePopOver()}
              >
                <IconCounterDisplay count={hopCount} />
              </NavLink>
            )}
          </NavItem>
          <NavItem>
            <NavLink className="nav-link" to="/tfhops" end onClick={() => togglePopOver()}>
              TfHops
            </NavLink>
            {tfHopCount !== 0 && (
              <NavLink
                className="nav-link position-absolute"
                style={{ top: '45px', left: '88px' }}
                to="/tfhops?status=1"
                end
                onClick={() => togglePopOver()}
              >
                <IconCounterDisplay count={tfHopCount} />
              </NavLink>
            )}
          </NavItem>
        </div>
      </PopoverBody>
    </Popover>
  </NavItem>
);

const NavMenu: React.FC<IProps> = ({
  signOut,
  isAuthenticated,
  fullName,
  user,
  newCommentsNotificationList,
  getAllNotifications,
  configuration,
  numOpenHops,
  numOfOpenTfHops,
  getHopsCountNumber,
  getTfHopsCountNumber
}) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const requestsPendingCount = useAppSelector((state) => stateMappings(state).request.pendingCount);
  const [providerPopoverOpen, setProviderPopoverOpen] = useState(false);
  const [tracebackPopoverOpen, setTracebackPopoverOpen] = useState(false);
  const [hopPopoverOpen, setHopPopoverOpen] = useState(false);
  const [isTogglerOpen, setIsTogglerOpen] = useState(false);
  const cleanPopovers = () => {
    setProviderPopoverOpen(false);
    setTracebackPopoverOpen(false);
    setHopPopoverOpen(false);
  };
  const navbarRef = useRef<HTMLDivElement>(null);
  const providerPopoverRef = useRef<HTMLDivElement>(null);
  const tracebackPopoverRef = useRef<HTMLDivElement>(null);
  const hopPopoverRef = useRef<HTMLDivElement>(null);
  useOnOutsideClickWithRefs(cleanPopovers, [
    navbarRef,
    providerPopoverRef,
    tracebackPopoverRef,
    hopPopoverRef
  ]);

  const handleClickLogOut = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    signOut();
  };

  useEffect(() => {
    if (
      isAuthenticated &&
      user.roleType !== userTypes.GovUser &&
      user.roleType !== userTypes.PartnerUser &&
      user.roleType !== userTypes.DnoUser
    ) {
      getAllNotifications(defaultPaginationNotification());
      if (user.roleType === userTypes.Admin) dispatch(getIncidentRequestsCount());
      else {
        const openFilter = newConditionalFilterElement('AND');
        addCondition(openFilter, newLeafFilterElement('status', 'EQ', '1'));
        getHopsCountNumber(simplifyFilterElement(openFilter));
        getTfHopsCountNumber(simplifyFilterElement(openFilter));
      }
    }
  }, [isAuthenticated, newCommentsNotificationList.length, location.pathname]);

  const toggleProviderPopover = () => setProviderPopoverOpen((v) => !v);
  const toggleTracebackPopover = () => setTracebackPopoverOpen((v) => !v);
  const toggleHopPopover = () => setHopPopoverOpen((v) => !v);
  const providerId = user.providerId;

  const toggleNavbar = () => {
    setIsTogglerOpen((v) => !v);
    cleanPopovers();
  };

  const showLinks = !(
    location.pathname === '/reset-password' ||
    location.pathname === '/create-password' ||
    location.pathname === '/setup-account'
  );

  useEffect(() => {
    if (tracebackPopoverOpen) setHopPopoverOpen(false);
  }, [tracebackPopoverOpen]);

  useEffect(() => {
    if (hopPopoverOpen) {
      setTracebackPopoverOpen(false);
      setProviderPopoverOpen(false);
    }
  }, [hopPopoverOpen]);

  useEffect(() => {
    if (providerPopoverOpen) setHopPopoverOpen(false);
  }, [providerPopoverOpen]);

  return (
    <div ref={navbarRef}>
      <Navbar className="navbar-expand-xl bg-white shadow-sm" container={false}>
        {location.pathname === '/create-password' ? (
          <img className="logo" src={require('../assets/images/USTelecomLogo.jpg')} alt="logo" />
        ) : (
          <a
            className="navbar-brand"
            href={
              user.roleType === userTypes.PartnerUser
                ? '/requests'
                : location.pathname === '/dno-providers'
                  ? '/dno-providers'
                  : '/'
            }
          >
            <img className="logo" src={require('../assets/images/USTelecomLogo.jpg')} alt="logo" />
          </a>
        )}
        {isAuthenticated && showLinks && (
          <Fragment>
            <button
              className="navbar-toggler border-0 shadow-none"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={toggleNavbar}
            >
              <span
                className="navbar-toggler-icon"
                style={{ background: '50%/100% 100% no-repeat' }}
              >
                <i className="fa fa-navicon text-ust-blue" />
              </span>
            </button>
            <Collapse
              isOpen={isTogglerOpen}
              className="navbar-collapse navMenu"
              id="navbarSupportedContent"
            >
              {user.roleType === userTypes.Admin ? (
                <Nav className="m-auto">
                  <NavItem>
                    <NavLink className="nav-link" to="/campaigns" end>
                      Campaigns
                    </NavLink>
                  </NavItem>

                  <NavItem className="d-flex">
                    <NavLink
                      className="nav-link"
                      style={{ paddingRight: '5px' }}
                      to="/tracebacks"
                      end
                    >
                      Tracebacks
                    </NavLink>
                    <i
                      className="fa fa-angle-down"
                      id="traceback-dropdown"
                      onMouseOver={toggleTracebackPopover}
                      style={{ paddingTop: '12px', fontSize: '20px' }}
                    />
                    <Popover
                      placement="bottom-end"
                      isOpen={tracebackPopoverOpen}
                      target="traceback-dropdown"
                      className="traceback-dropdown"
                      toggle={toggleTracebackPopover}
                    >
                      <PopoverBody>
                        <div ref={tracebackPopoverRef}>
                          <NavItem>
                            <NavLink
                              className="nav-link"
                              to="/tracebacks"
                              end
                              onClick={() => setTracebackPopoverOpen(false)}
                            >
                              Tracebacks
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className="nav-link"
                              to="/traceforwards"
                              end
                              onClick={() => setTracebackPopoverOpen(false)}
                            >
                              Traceforwards
                            </NavLink>
                          </NavItem>
                        </div>
                      </PopoverBody>
                    </Popover>
                  </NavItem>
                  {hops(hopPopoverOpen, toggleHopPopover, hopPopoverRef, 0, 0)}
                  <NavItem>
                    <NavLink className="nav-link" to="/providers" end>
                      Providers
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="nav-link" to="/comments" end>
                      Comments
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="nav-link" to="/partners" end>
                      Partners
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="nav-link position-relative" to="/requests" end>
                      Requests
                      {!!requestsPendingCount && (
                        <div className="position-absolute" style={{ top: '1%', left: '90%' }}>
                          <IconCounterDisplay count={requestsPendingCount} smallIcon />
                        </div>
                      )}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="nav-link" to="/admin-dashboard">
                      Admin
                    </NavLink>
                  </NavItem>
                </Nav>
              ) : user.roleType === userTypes.GovUser ? (
                <Nav className="m-auto">
                  <NavItem>
                    <NavLink className="nav-link" to="/providers" end>
                      Providers
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="nav-link" to="/tracebacks" end>
                      Tracebacks
                    </NavLink>
                  </NavItem>
                  {user.isRequestor && (
                    <NavItem>
                      <NavLink className="nav-link" to="/requests" end>
                        Requests
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
              ) : user.roleType === userTypes.Provider ||
                user.roleType === userTypes.ProviderManager ? (
                <Nav className="m-auto">
                  {hops(
                    hopPopoverOpen,
                    toggleHopPopover,
                    hopPopoverRef,
                    numOpenHops,
                    numOfOpenTfHops
                  )}

                  {user.roleType === userTypes.Provider &&
                  configuration &&
                  !configuration.insightVisible ? (
                    <NavLink
                      className="nav-link"
                      to={'/providers/provider/summaries/' + providerId}
                      end
                    >
                      Providers
                    </NavLink>
                  ) : (
                    <NavItem className="d-flex">
                      <NavLink
                        className="nav-link"
                        style={{ paddingRight: '5px' }}
                        to={'/providers/provider/summaries/' + providerId}
                        end
                      >
                        Providers
                      </NavLink>
                      <i
                        className="fa fa-angle-down"
                        id="provider-dropdown"
                        onMouseOver={toggleProviderPopover}
                        style={{
                          paddingTop: '12px',
                          fontSize: '20px'
                        }}
                      />
                      <Popover
                        placement="bottom-end"
                        isOpen={providerPopoverOpen}
                        target="provider-dropdown"
                        className="provider-dropdown"
                        toggle={toggleProviderPopover}
                      >
                        <PopoverBody>
                          <div ref={providerPopoverRef}>
                            <NavLink
                              className="nav-link"
                              to={'/providers/provider/summaries/' + providerId}
                              end
                              onClick={() => setProviderPopoverOpen(false)}
                            >
                              SUMMARIES
                            </NavLink>
                            {user.roleType === userTypes.ProviderManager && (
                              <NavLink
                                className="nav-link"
                                to={'/providers/provider/' + providerId}
                                style={{ marginBottom: '1rem' }}
                                end
                                onClick={() => setProviderPopoverOpen(false)}
                              >
                                DETAILS
                              </NavLink>
                            )}
                            {configuration && configuration.insightVisible && (
                              <NavLink
                                className="nav-link"
                                to={'/providers/provider/insights/' + providerId}
                                end
                                onClick={() => setProviderPopoverOpen(false)}
                              >
                                INSIGHTS
                              </NavLink>
                            )}
                          </div>
                        </PopoverBody>
                      </Popover>
                    </NavItem>
                  )}

                  <NavItem>
                    <NavLink className="nav-link" to="/comments" end>
                      Comments
                    </NavLink>
                  </NavItem>
                  {user.dnoAccess && (
                    <NavItem>
                      <NavLink className="nav-link" to="/dno-providers" end>
                        DNO
                      </NavLink>
                    </NavItem>
                  )}
                  {user.isRequestor && (
                    <NavItem>
                      <NavLink className="nav-link" to="/requests" end>
                        Requests
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
              ) : user.roleType === userTypes.DnoUser ? (
                <Nav className="m-auto">
                  <NavItem>
                    <NavLink className="nav-link" to="/dno-providers" end>
                      DNO
                    </NavLink>
                  </NavItem>
                </Nav>
              ) : user.roleType === userTypes.PartnerUser ? (
                user.isRequestor && (
                  <Nav className="m-auto">
                    <NavItem>
                      <NavLink className="nav-link" to="/requests" end>
                        Requests
                      </NavLink>
                    </NavItem>
                  </Nav>
                )
              ) : null}

              <Nav className="top-right-links">
                {user.roleType !== userTypes.GovUser &&
                  user.roleType !== userTypes.DnoUser &&
                  user.roleType !== userTypes.PartnerUser && (
                    <Fragment>
                      <NotificationPopover />
                      <span>|</span>
                    </Fragment>
                  )}
                <p className="account-text">
                  {`Welcome, ${fullName ? fullName.split(' ')[0] : ''}`}
                  <span>|</span>
                </p>
                <NavLink
                  className={({ isActive }) => 'account-text-link ' + (isActive ? ' active' : '')}
                  to="/account"
                  end
                >
                  My Account
                </NavLink>
                <span>|</span>
                <NavLink
                  className={() => 'account-text-link'}
                  to="/#"
                  end
                  onClick={handleClickLogOut}
                >
                  Log Out
                </NavLink>
              </Nav>
            </Collapse>
          </Fragment>
        )}
      </Navbar>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    isAuthenticated: sm.isAuthenticated,
    fullName: sm.isAuthenticated ? sm.user.name : 'Unknown user',
    user: sm.user,
    newCommentsNotificationList: sm.comment.newCommentsNotificationList,
    configuration: sm.appinfo.configuration,
    numOpenHops: sm.hop.hopCount,
    numOfOpenTfHops: sm.tfhop.tfhopCount
  };
};

const mapActionsToProps = {
  signOut,
  getAllNotifications,
  getHopsCountNumber,
  getTfHopsCountNumber
};

export default connect(mapStateToProps, mapActionsToProps)(NavMenu);
