import React, { useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { Button, Card, CardBody, Input, Label } from 'reactstrap';
import { CampaignLabel } from '../../../../interfaces/campaign';
import { NoteType } from '../../../../interfaces/hop';
import {
  HopTypes,
  ReportArchiveObj,
  ReportFormat,
  ReportPostData,
  ReportType
} from '../../../../interfaces/report';
import {
  getClientFormattedDate,
  getEndOfTheDay,
  getStartOfTheDay
} from '../../../../lib/utilities';
import { deleteReportArchiveApiCall } from '../../../../redux/report/apiCalls';
import { getReportObject } from '../../../../redux/report/thunks';
import { stateMappings } from '../../../../redux/stateMappings';
import { SelectOption } from '../../../CustomSelect';
import { ProviderItem } from '../../../ProvidersSelect';
import { Notes } from '../../../shared/Notes';

interface IProps {
  data?: ReportArchiveObj;
  providerNameList: ProviderItem[];
  campaignsNameList: SelectOption[];
  labels: CampaignLabel[];
  requestors: SelectOption[];
  getReportObject: Function;
}

const getHopNotesString = (hopNotes: HopTypes | undefined) => {
  if (hopNotes === undefined) return 'None';

  let resultArray: NoteType[] = [];

  if (hopNotes.includeForeignPointOfDeparture) {
    resultArray.push(NoteType.ForeignPointDepartureHop);
  }

  if (hopNotes.includeInternationalOrigin) {
    resultArray.push(NoteType.InternationOriginHop);
  }

  if (hopNotes.includeUsOriginator) {
    resultArray.push(NoteType.OriginHop);
  }

  if (hopNotes.includeNoResponsive) {
    resultArray.push(NoteType.NoResponseHop);
  }

  if (hopNotes.includeUsPointOfEntry) {
    resultArray.push(NoteType.USPointOfEntryHop);
  }

  return resultArray.length > 0 ? <Notes types={resultArray} /> : 'None';
};

const ExpandableReport: React.FC<IProps> = ({
  data,
  providerNameList,
  campaignsNameList,
  labels,
  requestors,
  getReportObject
}) => {
  const filter: ReportPostData = useMemo(
    () => JSON.parse(data?.reportFilter || '{}'),
    [data?.reportFilter]
  );
  const initialStartDate = useMemo(
    () => new Date(filter?.startDate ? filter.startDate : ''),
    [filter.startDate]
  );
  const initialEndDate = useMemo(
    () => new Date(filter.endDate ? filter.endDate : ''),
    [filter.endDate]
  );
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [zendeskTicketId, setZendeskTicketId] = useState(filter.zendeskTicketId?.toString());
  const [generate, setGenerate] = useState(false);

  const chosenLabels = useMemo(
    () =>
      filter.chosenLabels && filter.chosenLabels.length > 0
        ? (data?.reportType == ReportType.SixtyDaysReport
            ? (filter.chosenLabels as number[]).map(
                (v) => labels.find((i) => i.labelID === v)?.name || ''
              )
            : filter.chosenLabels.map((v) => v.name)
          ).filter((v) => v)
        : [],
    [filter.chosenLabels, data?.reportType]
  );

  const requestor = useMemo(
    () =>
      requestors.find((requestor: SelectOption) => requestor.value === filter.requestorId)?.label,
    [filter.requestorId, requestors]
  );
  const provider = useMemo(
    () =>
      providerNameList.find((provider: ProviderItem) => provider.id === filter.providerId)?.name,
    [filter.providerId, providerNameList]
  );
  const handleCheck = (e: any, key: string) => {
    switch (key) {
      case 'startDate':
        setStartDate(e);
        break;
      case 'endDate':
        setEndDate(e);
        break;
      case 'zenDesk':
        setZendeskTicketId(e.target.value.replace(/[^\d#]/g, ''));
        break;
    }
  };
  const regenerateReport = async () => {
    const reportPostData: ReportPostData = {
      ...filter,
      startDate: getStartOfTheDay(startDate).toISOString(),
      endDate: getEndOfTheDay(endDate).toISOString(),
      zendeskTicketId: zendeskTicketId ? parseInt(zendeskTicketId.replace('#', '')) : undefined
    };
    getReportObject(reportPostData, data?.reportType, ReportFormat.Zip);
    if (
      startDate.getTime() === initialStartDate.getTime() &&
      endDate.getTime() === initialEndDate.getTime() &&
      zendeskTicketId === filter.zendeskTicketId?.toString() &&
      data?.id
    ) {
      await deleteReportArchiveApiCall(data?.id);
    }
  };

  return (
    <Card className="expandedRow">
      <CardBody style={{ color: '#797979', fontSize: '1rem', padding: '0.5rem 1rem' }}>
        {!generate && data?.reportType === ReportType.SubpoenaReport && (
          <div className="d-flex justify-content-end">
            <Button className="generateReportStyle" onClick={() => setGenerate((v) => !v)}>
              New Report Date Range
            </Button>
          </div>
        )}
        {generate && data?.reportType === ReportType.SubpoenaReport && (
          <div className="d-flex justify-content-end">
            <Button
              className="generateReportStyle ms-3"
              style={{ backgroundColor: 'gray' }}
              onClick={() => setGenerate((v) => !v)}
            >
              Cancel
            </Button>
            <Button className="generateReportStyle" onClick={regenerateReport}>
              Generate
            </Button>
          </div>
        )}
        {filter.name && (
          <div className="d-flex justify-content-start mt-1 me-2">
            <span className="ms-4">
              <span className="font-calibri-bold">Name: </span>
              {filter.name}
            </span>
          </div>
        )}
        {filter.narrativeIntro && (
          <div className="d-flex justify-content-start mt-1 me-2">
            <span className="ms-4">
              <span className="font-calibri-bold">Narrative: </span>
              {filter.narrativeIntro}
            </span>
          </div>
        )}
        {generate && data?.reportType === ReportType.SubpoenaReport && (
          <div>
            <Label className="telecom-label" style={{ fontSize: '20px' }}>
              Run {data?.fileName} with New date range
            </Label>
            <div className="d-flex justify-content-start">
              <div className="d-flex flex-column">
                <Label className="telecom-label">Date Range *</Label>
                <div className="d-flex flex-row">
                  <DatePicker
                    maxDate={endDate || new Date()}
                    selected={startDate}
                    onChange={(option) => handleCheck(option, 'startDate')}
                    placeholderText="&#xf133; mm/dd/yyyy"
                    clearButtonTitle={'Clear'}
                    isClearable
                    showYearDropdown
                    yearDropdownItemNumber={15}
                  />
                  <span className="ps-2" />
                  <DatePicker
                    maxDate={new Date()}
                    selected={endDate}
                    minDate={startDate}
                    onChange={(option) => handleCheck(option, 'endDate')}
                    placeholderText="&#xf133; mm/dd/yyyy"
                    clearButtonTitle={'Clear'}
                    isClearable
                    showYearDropdown
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                  />
                </div>
              </div>
              <span className="ps-2" />
              <div className="d-flex flex-column">
                <Label className="telecom-label">Zendesk Ticket</Label>
                <Input
                  value={zendeskTicketId}
                  onChange={(option) => handleCheck(option, 'zenDesk')}
                  style={{ width: '200px' }}
                  className="csd-search"
                  placeholder="Enter Zendesk Ticket ID"
                />
              </div>
            </div>
          </div>
        )}
        {data?.tracebacksUpdated && (
          <div className="mt-1 me-2">
            <div>
              <span className="font-calibri-bold">Changes Tracebacks: </span>
              {data.tracebacksUpdated.map((element) => (
                <a className="table-cell blue" href={`/tracebacks/traceback/${element}`}>
                  {element}{' '}
                </a>
              ))}
            </div>
          </div>
        )}
        <div className="mt-1 me-2">
          <div>
            <span className="ms-4">
              {data?.reportType === ReportType.SixtyDaysReport ? (
                <>
                  <span className="font-calibri-bold">Start Date: </span>
                  <span>{getClientFormattedDate(filter.startDate)}</span>
                </>
              ) : (
                <>
                  <span className="font-calibri-bold">Date Range: </span>
                  <span>
                    {getClientFormattedDate(filter.startDate)} -{' '}
                    {getClientFormattedDate(filter.endDate)}{' '}
                  </span>
                </>
              )}
            </span>
          </div>
        </div>
        {filter.keywordSearch && (
          <div className="d-flex justify-content-start mt-1 me-2">
            <span className="ms-4">
              <span className="font-calibri-bold">Keyword Search: </span>
              {filter.keywordSearch}
            </span>
          </div>
        )}
        {data?.reportType === ReportType.SixtyDaysReport && (
          <div className="d-flex justify-content-start me-2">
            <span className="ms-4">
              <span className="font-calibri-bold">Campaign Labels: </span>
              {chosenLabels.length ? chosenLabels.join(', ') : 'None'}
            </span>
          </div>
        )}
        {data?.reportType === ReportType.PartnerReport && (
          <>
            <div className="d-flex justify-content-start me-2">
              <span className="ms-4">
                <span className="font-calibri-bold">Requestor: </span>
                {requestor}
              </span>
            </div>
            <div className="d-flex justify-content-start me-2">
              <span className="ms-4">
                <span className="font-calibri-bold">Include StirShaken: </span>
                {filter.includeStirShaken ? 'True' : 'False'}
              </span>
            </div>
          </>
        )}
        {data?.reportType === ReportType.PartnerSourceReport && (
          <div className="d-flex justify-content-start me-2">
            <span className="ms-4">
              <span className="font-calibri-bold">Source: </span>
              {filter.sourceName}
            </span>
          </div>
        )}
        {data?.reportType === ReportType.IPReport && (
          <>
            <div className="d-flex justify-content-start me-2">
              <span className="ms-4">
                <span className="font-calibri-bold">Provider: </span>
                {provider}
              </span>
            </div>
            {filter.origin && (
              <div className="d-flex justify-content-start me-2">
                <span className="ms-4">
                  <span className="font-calibri-bold">IP Address: </span>
                  {filter.origin}
                </span>
              </div>
            )}
          </>
        )}
        {data?.reportType === ReportType.SubpoenaReport && (
          <>
            <div className="d-flex justify-content-start mt-1">
              <span className="telecom-label">Traceback-level Filters</span>
            </div>

            <div className="d-flex justify-content-start me-2">
              <span className="ms-4">
                <span className="font-calibri-bold">Tracebacks: </span>
                {filter.tracebacks != undefined && filter.tracebacks.length > 0
                  ? filter.tracebacks.join(', ')
                  : 'None'}
              </span>
            </div>
            <div className="d-flex justify-content-start me-2">
              <span className="ms-4">
                <span className="font-calibri-bold">Campaigns: </span>
                {filter.campaigns != undefined && filter.campaigns.length > 0
                  ? filter.campaigns
                      .map((v) => campaignsNameList.find((i) => v === i.value)?.label)
                      .filter((v) => !!v)
                      .join(', ')
                  : 'None'}
              </span>
            </div>
            <div className="d-flex justify-content-start me-2">
              <span className="ms-4">
                <span className="font-calibri-bold">Labels: </span>
                {chosenLabels.length ? chosenLabels.join(', ') : 'None'}
              </span>
            </div>
            <div className="d-flex justify-content-start me-2">
              <span className="ms-4">
                <span className="font-calibri-bold">Calling Party Name: </span>
                {filter.callingParties != undefined && filter.callingParties.length > 0
                  ? filter.callingParties.join(', ')
                  : 'None'}
              </span>
            </div>
            <div className="d-flex justify-content-start me-2">
              <span className="ms-4">
                <span className="font-calibri-bold">
                  Exclude Tracebacks that Include Not Found Status:{' '}
                </span>
                {filter.excludeTracebacksWithNotFoundStatus ? 'True' : 'False'}
              </span>
              <span className="ms-4">
                <span className="font-calibri-bold">Exclude Open Tracebacks: </span>
                {filter.excludeOpenTracebacks ? 'True' : 'False'}
              </span>
            </div>
            <div className="d-flex justify-content-start mt-1">
              <span className="telecom-label">Provider-level Filters</span>
            </div>

            <div className="d-flex justify-content-start me-2">
              <span className="ms-4">
                <span className="font-calibri-bold">Providers: </span>
                {filter.providers != undefined && filter.providers.length > 0
                  ? filter.providers
                      .map((v) => providerNameList.find((i) => i.id === v)?.name)
                      .filter((v) => !!v)
                      .join(', ')
                  : 'None'}
              </span>
            </div>
            <div className="d-flex justify-content-start me-2">
              <span className="ms-4">
                <span className="font-calibri-bold">Hop Notes: </span>
                {getHopNotesString(filter.hopTypes)}
              </span>
            </div>

            <div className="d-flex justify-content-start me-2">
              <span className="ms-4">
                <span className="font-calibri-bold">Include All Hops in Call Path: </span>
                {filter.includeAllHops ? 'True' : 'False'}
              </span>
              <span className="ms-4">
                <span className="font-calibri-bold">Include Upstream & Downstream Summaries: </span>
                {filter.includeProviderUpstreamDownstreamSummaries ? 'True' : 'False'}
              </span>
            </div>
            <div className="d-flex justify-content-start mt-1">
              <span className="telecom-label">Other Filters</span>
            </div>
            <div className="d-flex justify-content-start me-2">
              <span className="ms-4">
                <span className="font-calibri-bold">Notification Emails: </span>
                {filter.includeNotifications ? 'True' : 'False'}
              </span>
              <span className="ms-4">
                <span className="font-calibri-bold">Comments: </span>
                {filter.includeComments ? 'True' : 'False'}
              </span>
              <span className="ms-4">
                <span className="font-calibri-bold">Provider Call Records: </span>
                {filter.includeProviderCallRecords ? 'True' : 'False'}
              </span>
              <span className="ms-4">
                <span className="font-calibri-bold">Other Related Attachments: </span>
                {filter.includeOtherAttachments ? 'True' : 'False'}
              </span>
              <span className="ms-4">
                <span className="font-calibri-bold">Include Admin Data: </span>
                {filter.includeAdminData ? 'True' : 'False'}
              </span>
              <span className="ms-4">
                <span className="font-calibri-bold">Confidential: </span>
                {filter.markConfidential ? 'True' : 'False'}
              </span>
            </div>
          </>
        )}
        {data?.reportType === ReportType.TfSubpoenaReport && (
          <>
            <div className="d-flex justify-content-start mt-1">
              <span className="telecom-label">Traceforward-level Filters</span>
            </div>

            <div className="d-flex justify-content-start me-2">
              <span className="ms-4">
                <span className="font-calibri-bold">Traceforwards: </span>
                {filter.traceforwards != undefined && filter.traceforwards.length > 0
                  ? filter.traceforwards.join(', ')
                  : 'None'}
              </span>
            </div>
            <div className="d-flex justify-content-start me-2">
              <span className="ms-4">
                <span className="font-calibri-bold">Campaigns: </span>
                {filter.campaigns != undefined && filter.campaigns.length > 0
                  ? filter.campaigns
                      .map((v) => campaignsNameList.find((i) => v === i.value)?.label)
                      .filter((v) => !!v)
                      .join(', ')
                  : 'None'}
              </span>
            </div>
            <div className="d-flex justify-content-start me-2">
              <span className="ms-4">
                <span className="font-calibri-bold">Labels: </span>
                {chosenLabels.length ? chosenLabels.join(', ') : 'None'}
              </span>
            </div>
            <div className="d-flex justify-content-start me-2">
              <span className="ms-4">
                <span className="font-calibri-bold">Calling Party Name: </span>
                {filter.callingParties != undefined && filter.callingParties.length > 0
                  ? filter.callingParties.join(', ')
                  : 'None'}
              </span>
            </div>
            <div className="d-flex justify-content-start me-2">
              <span className="ms-4">
                <span className="font-calibri-bold">
                  Exclude Traceforwards that Include Not Found Status:{' '}
                </span>
                {filter.excludeTraceforwardsWithNotFoundStatus ? 'True' : 'False'}
              </span>
              <span className="ms-4">
                <span className="font-calibri-bold">Exclude Open Traceforwards: </span>
                {filter.excludeOpenTraceforwards ? 'True' : 'False'}
              </span>
            </div>
            <div className="d-flex justify-content-start mt-1">
              <span className="telecom-label">Provider-level Filters</span>
            </div>

            <div className="d-flex justify-content-start me-2">
              <span className="ms-4">
                <span className="font-calibri-bold">Providers: </span>
                {filter.providers != undefined && filter.providers.length > 0
                  ? filter.providers
                      .map((v) => providerNameList.find((i) => i.id === v)?.name)
                      .filter((v) => !!v)
                      .join(', ')
                  : 'None'}
              </span>
            </div>
            <div className="d-flex justify-content-start me-2">
              <span className="ms-4">
                <span className="font-calibri-bold">TFHop Notes: </span>
                {getHopNotesString(filter.tfhopTypes)}
              </span>
            </div>

            <div className="d-flex justify-content-start me-2">
              <span className="ms-4">
                <span className="font-calibri-bold">Include All TfHops in Call Path: </span>
                {filter.includeAllTfHops ? 'True' : 'False'}
              </span>
              <span className="ms-4">
                <span className="font-calibri-bold">Include Upstream & Downstream Summaries: </span>
                {filter.includeProviderUpstreamDownstreamSummaries ? 'True' : 'False'}
              </span>
            </div>
            <div className="d-flex justify-content-start mt-1">
              <span className="telecom-label">Other Filters</span>
            </div>
            <div className="d-flex justify-content-start me-2">
              <span className="ms-4">
                <span className="font-calibri-bold">Notification Emails: </span>
                {filter.includeNotifications ? 'True' : 'False'}
              </span>
              <span className="ms-4">
                <span className="font-calibri-bold">Comments: </span>
                {filter.includeComments ? 'True' : 'False'}
              </span>
              <span className="ms-4">
                <span className="font-calibri-bold">Provider Call Records: </span>
                {filter.includeProviderCallRecords ? 'True' : 'False'}
              </span>
              <span className="ms-4">
                <span className="font-calibri-bold">Other Related Attachments: </span>
                {filter.includeOtherAttachments ? 'True' : 'False'}
              </span>
              <span className="ms-4">
                <span className="font-calibri-bold">Include Admin Data: </span>
                {filter.includeAdminData ? 'True' : 'False'}
              </span>
              <span className="ms-4">
                <span className="font-calibri-bold">Confidential: </span>
                {filter.markConfidential ? 'True' : 'False'}
              </span>
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    labels: sm.campaign.labels,
    requestors: (sm.traceback.requestors || []).map((requestor: any) => ({
      value: requestor.requestorId,
      label: requestor.name
    }))
  };
};

const mapActionsToProps = {
  getReportObject
};
export default connect(mapStateToProps, mapActionsToProps)(ExpandableReport);
