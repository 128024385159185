import { convertToTimeZone } from 'date-fns-timezone';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Select, { StylesConfig } from 'react-select';
import { Button, Card, CardBody, CardHeader, Collapse, Row } from 'reactstrap';
import {
  getDefaultEndDate,
  getDefaultStartDate
} from '../components/AdminDashboard/Reporting/ReportsArchive/data';
import Breadcrumb from '../components/Breadcrumbs';
import CustomDataTable from '../components/CustomDataTable';
import { SelectOption } from '../components/CustomSelect';
import CountryDropdown from '../components/HopDetail/CountryDropdown';
import ProviderExpandableRow from '../components/ProviderExpandableRow';
import ProvidersSelect from '../components/ProvidersSelect';
import LabelSelect from '../components/shared/LabelSelect';
import Legend from '../components/shared/Legend';
import RegionSelect from '../components/shared/RegionSelect';
import ZendeskWebWidget from '../components/shared/ZendeskWebWidget';
import { userTypes } from '../enum/userTypes';
import { Configuration } from '../interfaces/configuration';
import { Pagination, defaultPagination } from '../interfaces/pagination';
import { ProviderSummaryRow } from '../interfaces/provider';
import { PersonalInfo } from '../interfaces/user';
import { FilterElement, getFilter, newConditionalFilterElement } from '../lib/FilterElement';
import { providerColumns } from '../lib/dataTableUtils/providerColumns';
import { decodeQueryParams, getFromLocalStorage, saveToLocalStorage } from '../lib/history-utils';
import { parseDateToISOString, reputationStatuses } from '../lib/utilities';
import { getClientConfigurationInfo } from '../redux/appinfo/thunks';
import { getProviderList, getProvidersCSV } from '../redux/provider/thunks';
import { stateMappings } from '../redux/stateMappings';

interface IProps {
  user: PersonalInfo;
  providers: ProviderSummaryRow[];
  getProviderList: Function;
  getClientConfigurationInfo: Function;
  getProvidersCSV: Function;
  paginationTotalRows: number;
  configuration: Configuration;
}

const colourStyles: StylesConfig<any, boolean> = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  multiValueLabel: (styles) => ({
    ...styles,
    marginTop: 7,
    width: 90,
    textAlign: 'center'
  })
};

const getDefaultStartDateRole = (userRole: userTypes): string =>
  userRole === userTypes.Admin ? '2012-01-01' : getDefaultStartDate();

const Providers: React.FC<IProps> = ({
  user,
  providers,
  getProviderList,
  getClientConfigurationInfo,
  getProvidersCSV,
  paginationTotalRows,
  configuration
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchQueryParams = useMemo(() => {
    let search = searchParams.toString();
    if (!search) {
      search = getFromLocalStorage('Providers') || '';
      setSearchParams(search);
    }
    return decodeQueryParams('providers', search);
  }, [searchParams]);
  const reputationsValuesArray = (searchParams: any): SelectOption[] => {
    if (searchParams.reputations && searchParams.reputations.length) {
      const filteredReputationsValues = searchParams.reputations
        .split('-')
        .map((reputation: any) => {
          if (reputation > 0 && reputation < 4) {
            return reputationStatuses[parseInt(reputation) - 1];
          }
        })
        .filter((item: any) => item !== undefined);
      return Array.from(new Set<SelectOption>(filteredReputationsValues));
    } else {
      return [];
    }
  };
  const labelIdValuesArray = (searchParams: any): number[] =>
    searchParams.labelId
      ? searchParams.labelId.split(',').map((item: string): number => Number(item))
      : [];
  const [collapseFilters, setCollapseFilters] = useState(false);
  const [paginationParams, setPaginationParams] = useState(
    searchQueryParams.paginationParams || {
      ...defaultPagination(),
      sort: 'ps.score',
      order: 'desc'
    }
  );

  const [providerRegion, setProviderRegion] = useState<string>(searchQueryParams.providerRegion);
  const [termState, setTermState] = useState<string>(searchQueryParams.termState);
  const [labelIds, setLabelIds] = useState(labelIdValuesArray(searchQueryParams));
  const [reputations, setReputations] = useState(reputationsValuesArray(searchQueryParams));
  const [isNotDomestic, setIsNotDomestic] = useState(
    searchQueryParams.isNotDomestic === 'true' && user.roleType === userTypes.Admin
  );
  const [isItgMember, setIsItgMember] = useState(
    searchQueryParams.isItgMember === 'true' && user.roleType === userTypes.Admin
  );
  const [isLowVolume, setIsLowVolume] = useState(
    searchQueryParams.isLowVolume === 'true' && user.roleType === userTypes.Admin
  );
  const [providerId, setSelectedProvider] = useState(Number(searchQueryParams.providerId) || 0);
  const [startDate, setStartDate] = useState(
    parseDateToISOString(searchQueryParams.startDate, 1) || getDefaultStartDateRole(user.roleType)
  );
  const [endDate, setEndDate] = useState(
    parseDateToISOString(searchQueryParams.endDate, 2) || getDefaultEndDate()
  );
  const [country, setCountry] = useState<string>(searchQueryParams.country || 'All Countries');
  const [isInternational, setIsInternational] = useState(
    searchQueryParams.isInternational === 'true' && user.roleType === userTypes.Admin
  );
  const [filterElements, setFilterElements] = useState<FilterElement>(
    newConditionalFilterElement('')
  );

  const getData = () => {
    if (filterElements.conditionals) {
      getProviderList(paginationParams, filterElements);
    }
    window.scrollTo(0, 0);
  };

  const updateFilter = () => {
    setPaginationParams({ ...paginationParams, page: 1 });
    setFilterElements(() =>
      getFilter({
        isItgMember,
        isNotDomestic,
        isLowVolume,
        providerId,
        terminalRegion: providerRegion,
        termState,
        labelId: labelIds,
        reputations,
        startDate,
        endDate,
        country,
        isInternational
      })
    );
  };

  useEffect(() => {
    updateFilter();
  }, [
    startDate,
    endDate,
    isItgMember,
    isNotDomestic,
    isLowVolume,
    providerId,
    reputations,
    providerRegion,
    termState,
    labelIds,
    country,
    isInternational
  ]);

  useEffect(() => {
    getClientConfigurationInfo();
  }, []);

  useEffect(() => {
    const reputationsToString =
      reputations && reputations.map((reputation: SelectOption) => reputation.value).join('-');
    const urlQueryParams = new URLSearchParams({
      startDate,
      endDate
    });
    urlQueryParams.set('startDate', startDate.slice(0, 10));
    urlQueryParams.set('endDate', endDate.slice(0, 10));
    if (providerId && providerId !== 0 && !isNaN(providerId)) {
      urlQueryParams.set('providerId', providerId.toString());
    } else {
      urlQueryParams.delete('providerId');
    }
    if (isLowVolume && user.roleType === userTypes.Admin) {
      urlQueryParams.set('isLowVolume', isLowVolume.toString());
    } else {
      urlQueryParams.delete('isLowVolume');
    }
    if (isItgMember && user.roleType === userTypes.Admin) {
      urlQueryParams.set('isItgMember', isItgMember.toString());
    } else {
      urlQueryParams.delete('isItgMember');
    }
    if (isNotDomestic && user.roleType === userTypes.Admin) {
      urlQueryParams.set('isNotDomestic', isNotDomestic.toString());
    } else {
      urlQueryParams.delete('isNotDomestic');
    }
    if (providerRegion) {
      urlQueryParams.set('providerRegion', providerRegion);
    } else {
      urlQueryParams.delete('providerRegion');
    }
    if (termState) {
      urlQueryParams.set('termState', termState);
    } else {
      urlQueryParams.delete('termState');
    }
    if (country && country !== 'All Countries') {
      urlQueryParams.set('country', country);
    } else {
      urlQueryParams.delete('country');
    }
    if (labelIds.length > 0) {
      urlQueryParams.set('labelId', labelIds.join(','));
    } else {
      urlQueryParams.delete('labelId');
    }
    if (reputations && reputations.length) {
      urlQueryParams.set('reputations', reputationsToString);
    } else {
      urlQueryParams.delete('reputations');
    }
    if (isInternational && user.roleType === userTypes.Admin) {
      urlQueryParams.set('isInternational', isInternational.toString());
    } else {
      urlQueryParams.delete('isInternational');
    }

    setSearchParams(urlQueryParams.toString());
    saveToLocalStorage('Providers', urlQueryParams.toString());
  }, [
    startDate,
    endDate,
    isItgMember,
    providerId,
    isLowVolume,
    isNotDomestic,
    providerRegion,
    termState,
    labelIds,
    reputations,
    country,
    isInternational
  ]);

  useEffect(() => {
    getData();
  }, [paginationParams, filterElements]);

  const updatePagination = (params: Pagination) => {
    setPaginationParams({ ...paginationParams, ...params });
  };

  const selectReputationOptions = (option: any) => {
    setReputations(option);
  };

  const handleCheck = (e: any, key: string) => {
    switch (key) {
      case 'isItgMember':
        setIsItgMember(!isItgMember);
        break;
      case 'isNotDomestic':
        setIsNotDomestic(!isNotDomestic);
        break;
      case 'isLowVolume':
        setIsLowVolume(!isLowVolume);
        break;
      case 'providerRegion':
        setProviderRegion(e.value);
        break;
      case 'termState':
        setTermState(e.value);
        break;
      case 'labelId':
        if (Array.isArray(e)) setLabelIds(e.map((item: SelectOption) => item.value));
        break;
      case 'providerId':
        setSelectedProvider(e.value);
        break;
      case 'isInternational':
        setIsInternational(!isInternational);
        break;
    }
  };

  const handleChange = (e: any, key: string) => {
    let date = new Date(Date.parse(e));
    switch (key) {
      case 'startDate':
        if (e) {
          const startDate = new Date(
            Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
          );
          setStartDate(startDate.toISOString());
        } else {
          setStartDate(getDefaultStartDateRole(user.roleType));
        }
        break;
      case 'endDate':
        if (e) {
          const endDate = new Date(
            Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
          );
          setEndDate(endDate.toISOString());
        } else {
          setEndDate(getDefaultEndDate());
        }
        break;
    }
  };

  const getIconOption = (e: any) => {
    return (
      <div>
        {e.value === '2' ? (
          <span className="fas fa-triangle-exclamation" style={{ color: '#FFAE00' }}></span>
        ) : e.value === '3' ? (
          <span className="fa-solid fa-circle-stop" style={{ color: '#ff6400' }}></span>
        ) : null}
        <span className="me-2">{e.label}</span>
      </div>
    );
  };

  const clearFilters = () => {
    setStartDate(getDefaultStartDateRole(user.roleType));
    setEndDate(getDefaultEndDate());
    setPaginationParams({ ...defaultPagination(), sort: 'ps.score', order: 'desc' });
    setIsNotDomestic(false);
    setIsItgMember(false);
    setIsLowVolume(false);
    setSelectedProvider(0);
    setFilterElements(newConditionalFilterElement(''));
    setReputations([]);
    setProviderRegion('');
    setTermState('');
    setLabelIds([]);
    setCountry('All Countries');
    setIsInternational(false);
  };

  const setCountryFunction = (label: string) => {
    setCountry(label);
    setProviderRegion('');
  };

  return (
    <Fragment>
      <Breadcrumb
        title={
          user.roleType === userTypes.GovUser
            ? 'Provider High Volume Traceback Overview'
            : 'Providers'
        }
        className="table-breadcrumbs"
        btnText={user.roleType === userTypes.GovUser ? '' : 'Add Provider'}
      />
      <Card className="table-card hide-border" style={{ marginBottom: -5 }}>
        <CardHeader className="card-header-provider">
          <CardHeader
            style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: 20 }}
            onClick={() => setCollapseFilters(!collapseFilters)}
            data-type="collapseBanner"
          >
            Filters
            <i
              style={{ position: 'absolute', right: 0, marginRight: 35, marginTop: 5 }}
              className={`fa-solid ${collapseFilters ? 'fa-arrow-up' : 'fa-arrow-down'}`}
            ></i>
          </CardHeader>
          <Collapse isOpen={collapseFilters}>
            <CardBody>
              <div className="row">
                <div className="col-2">
                  <DatePicker
                    maxDate={endDate ? convertToTimeZone(endDate, { timeZone: 'GMT' }) : new Date()}
                    selected={
                      startDate ? convertToTimeZone(startDate, { timeZone: 'GMT' }) : undefined
                    }
                    onChange={(option) => handleChange(option, 'startDate')}
                    showYearDropdown
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    placeholderText="&#xf133; startDate"
                    clearButtonTitle={'Clear'}
                    isClearable
                  />
                </div>
                <div className="col-2">
                  <DatePicker
                    maxDate={new Date()}
                    selected={endDate ? convertToTimeZone(endDate, { timeZone: 'GMT' }) : undefined}
                    minDate={convertToTimeZone(startDate, { timeZone: 'GMT' })}
                    onChange={(option) => handleChange(option, 'endDate')}
                    showYearDropdown
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    placeholderText="&#xf133; endDate"
                    clearButtonTitle={'Clear'}
                    isClearable
                  />
                </div>
                <div className="col-2">
                  <ProvidersSelect
                    onChange={(option) => handleCheck(option, 'providerId')}
                    isSearchable
                    addAllItem
                    option={providerId}
                    includeInactive
                  />
                </div>
                <div className="col-2">
                  <Select
                    className="customselect-small"
                    classNamePrefix="customselect"
                    options={reputationStatuses}
                    value={reputations}
                    isMulti
                    isSearchable={false}
                    onChange={selectReputationOptions}
                    styles={colourStyles}
                    placeholder="Provider Status"
                    getOptionLabel={getIconOption as any}
                    isClearable
                  ></Select>
                </div>
                <div className="col-2">
                  <CountryDropdown
                    className="customselect-small"
                    classNamePrefix="customselect"
                    value={country}
                    setCountry={setCountryFunction}
                    allSelection
                  />
                </div>
                {country === 'United States' && (
                  <div className="col-2">
                    <RegionSelect
                      onChange={(option) => handleCheck(option, 'providerRegion')}
                      value={providerRegion}
                      placeholder={providerRegion}
                      typeLabel="Call Origin"
                      onlyUsedStates
                    />
                  </div>
                )}
                <div className="col-2">
                  <RegionSelect
                    onChange={(option) => handleCheck(option, 'termState')}
                    value={termState}
                    placeholder={termState}
                    typeLabel="Terminating State"
                  />
                </div>
                {user.roleType === userTypes.GovUser && (
                  <div className="col-6">
                    <div className="row">
                      <div className="col-4">
                        <LabelSelect
                          onChange={(option) => {
                            handleCheck(option, 'labelId');
                          }}
                          placeholder="Campaings Labels"
                          addAllItem
                          isMulti
                          value={labelIds}
                          numberDisplayed={2}
                        />
                      </div>
                      <div className="col-2">
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() => {
                            clearFilters();
                          }}
                        >
                          Clear
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="row mt-2">
                <div className="col">
                  <div className="row">
                    <div className="col">
                      <div className="checkboxContainer d-flex align-items-center">
                        {user.roleType === userTypes.Admin && (
                          <Fragment>
                            <label className="form-label checkbox-label">
                              Low Volume
                              <input
                                type="checkbox"
                                onChange={(option) => handleCheck(option, 'isLowVolume')}
                                value="Low Volume"
                                checked={isLowVolume}
                              />
                              <span className="checkmark" />
                            </label>
                            <label className="form-label checkbox-label">
                              International
                              <input
                                type="checkbox"
                                onChange={(option) => handleCheck(option, 'isInternational')}
                                value="Low Volume"
                                checked={isInternational}
                              />
                              <span className="checkmark" />
                            </label>
                            <label className="form-label checkbox-label">
                              ITG
                              <input
                                type="checkbox"
                                onChange={(option) => handleCheck(option, 'isItgMember')}
                                value="ITG"
                                checked={isItgMember}
                              />
                              <span className="checkmark" />
                            </label>
                            <label className="form-label checkbox-label">
                              Non-US
                              <input
                                type="checkbox"
                                onChange={(option) => handleCheck(option, 'isNotDomestic')}
                                value="PoE"
                                checked={isNotDomestic}
                              />
                              <span className="checkmark" />
                            </label>
                            <button
                              type="button"
                              className="btn btn-link"
                              onClick={() => {
                                clearFilters();
                              }}
                            >
                              Clear
                            </button>
                          </Fragment>
                        )}
                      </div>
                    </div>
                    <div className="col">
                      <div className="d-flex justify-content-xl-end">
                        <Button
                          onClick={() => getProvidersCSV(filterElements, paginationParams)}
                          style={{
                            height: '28px',
                            padding: '5px',
                            fontSize: '14px'
                          }}
                        >
                          <i className="fa fa-download" />
                          {` Download CSV`}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Collapse>
        </CardHeader>

        <CardBody className="card-body-provider">
          <CustomDataTable
            tableData={providers}
            columns={providerColumns(user.roleType)}
            defaultSortFieldId={paginationParams.sort}
            defaultSortAsc={false}
            defaultPage={paginationParams.page}
            defaultPageSize={paginationParams.pageSize}
            pagination={true}
            paginationTotalRows={paginationTotalRows}
            useExpandableRows={user.roleType === userTypes.Admin && configuration.reputationVisible}
            useExpandableRowsComponent={({ data }) => (
              <ProviderExpandableRow
                data={data}
                includeLinks
                startDate={startDate}
                endDate={endDate}
                termState={termState}
              />
            )}
            updatePaginationParams={updatePagination}
          />
        </CardBody>
      </Card>
      <Row style={{ padding: '20px 30px', justifyContent: 'center' }}>
        <Legend />
      </Row>
      <ZendeskWebWidget />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    user: sm.user,
    providers: sm.provider.providers,
    paginationTotalRows: sm.provider.meta.TotalCount,
    configuration: sm.appinfo.configuration
  };
};

const mapActionsToProps = {
  getProviderList,
  getClientConfigurationInfo,
  getProvidersCSV
};

export default connect(mapStateToProps, mapActionsToProps)(Providers);
