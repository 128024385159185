import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import HopsComments from '../components//comments/HopsComments';
import NotificationComments from '../components//comments/NotificationComments';
import ProviderCallRecordsComments from '../components//comments/ProviderCallRecordsComments';
import Breadcrumb from '../components/Breadcrumbs';
import GovProviderComments from '../components/comments/GovProviderComments';
import ProvidersComments from '../components/comments/ProvidersComments';
import TracebacksComments from '../components/comments/TracebacksComments';
import ZendeskWebWidget from '../components/shared/ZendeskWebWidget';
import { userTypes } from '../enum/userTypes';
import { Provider } from '../interfaces/provider';
import { PersonalInfo } from '../interfaces/user';
import { getFromLocalStorage } from '../lib/history-utils';
import { clearCommentsList } from '../redux/comment/thunks';
import { getProviderObject } from '../redux/provider/thunks';
import { stateMappings } from '../redux/stateMappings';

interface IProps {
  user: PersonalInfo;
  provider: Provider;
  isLoading: boolean;
  getProviderObject: Function;
  clearCommentsList: Function;
}

const commentOptions = [
  {
    value: 1,
    label: 'Hops',
    component: <HopsComments />
  },
  {
    value: 2,
    label: 'Notifications',
    component: <NotificationComments />
  },
  {
    value: 7,
    label: 'Gov',
    component: <GovProviderComments />
  },
  {
    value: 3,
    label: 'Reputation'
  },
  {
    value: 4,
    label: 'Provider Call Records',
    component: <ProviderCallRecordsComments />
  },
  {
    value: 5,
    label: 'Tracebacks',
    component: <TracebacksComments />
  },
  {
    value: 6,
    label: 'Providers',
    component: <ProvidersComments />
  }
];

const Comments: React.FC<IProps> = ({
  user,
  provider,
  getProviderObject,
  clearCommentsList,
  isLoading
}) => {
  const { search } = useLocation();
  const [commentType, setCommentType] = useState(
    user.roleType === userTypes.Admin
      ? Number(new URLSearchParams(search).get('commentType')) ||
          Number(getFromLocalStorage('commentType'))
      : 1
  );

  useEffect(() => {
    if (user && user.roleType !== userTypes.Admin && user.providerId) {
      getProviderObject(user.providerId);
    }
  }, []);

  const handleCommentType = (option: any) => {
    clearCommentsList().then(() => {
      setCommentType(option.value);
    });
  };
  return (
    <Fragment>
      <Breadcrumb
        title={
          user.roleType === userTypes.Admin
            ? commentType == 5
              ? 'traceback-comments'
              : commentType === 7
                ? 'gov-comments'
                : 'comments'
            : `comments - ${provider.name}`
        }
        className="table-breadcrumbs"
        btnText={(commentType === 4 || commentType === 5 || commentType === 7) && 'Add Comment'}
        reputation={user.roleType === userTypes.Admin && provider.reputation}
      />
      <Card className="table-card hide-border">
        {user.roleType === userTypes.Admin && (
          <CardHeader className="card-header-hops">
            <Row className="justify-content-between align-items">
              <Col md="5" style={{ maxWidth: '250px' }}>
                <Label className="telecom-label ps-0" style={{ fontSize: '20px' }}>
                  Comment Type
                </Label>
                <Select
                  className="customselect-small"
                  classNamePrefix="customselect"
                  isDisabled={isLoading}
                  value={commentOptions.find(
                    (item) => item.component && item.value === commentType
                  )}
                  onChange={handleCommentType}
                  options={commentOptions.filter((item) => !!item.component)}
                  isSearchable={false}
                />
              </Col>
            </Row>
          </CardHeader>
        )}
        <CardBody className={'card-body-hop'}>
          {commentOptions.find((item) => item.component && item.value === commentType)?.component}
        </CardBody>
      </Card>
      <ZendeskWebWidget />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    isLoading: sm.comment.loading,
    provider: sm.provider.provider,
    user: sm.user
  };
};

const mapActionsToProps = {
  clearCommentsList,
  getProviderObject
};

export default connect(mapStateToProps, mapActionsToProps)(Comments);
