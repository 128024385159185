import React, { FC, Fragment, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Button, CardBody, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { DNO, DnoErrorMessage } from '../../../interfaces/dno';
import { validatePhoneForE164 } from '../../../lib/regex';
import {
  addNewDno,
  associateLoa,
  getDno,
  getLoaAttachment,
  setDno,
  updateDno
} from '../../../redux/dno/thunk';
import { stateMappings } from '../../../redux/stateMappings';
import DropZone from '../../DropZone';
import InputError from '../../inputError';
import InputFormGroup from '../../inputFormGroup';

interface IProp {
  addNewDno: Function;
  getLoaAttachment: Function;
  associateLoa: Function;
  getDno: Function;
  setDno: Function;
  updateDno: Function;
  dno: DNO;
  dnoError: string;
}

const dateOrNull = (date?: string) => (date ? new Date(date) : null);

const SMSOptions = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
  { label: 'Unsure', value: 'Unsure' }
];

const AddDno: FC<IProp> = ({
  dno,
  addNewDno,
  updateDno,
  getLoaAttachment,
  getDno,
  setDno,
  associateLoa,
  dnoError
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dnoName, setDnoName] = useState('');
  const [requestDate, setRequestDate] = useState<Date | null>(null);
  const [loaAttachmentId, setLoaAttachmentId] = useState<number>(0);
  const [loaRadio, setLoaRadio] = useState('loaAttachment');
  const [attachments, setAttachments] = useState<any[]>([]);
  const [requestEntity, setRequestEntity] = useState('');
  const [receivedByItg, setReceivedByItg] = useState<Date | null>(null);
  const [addressedTo, setAddressedTo] = useState('');
  const [toItg, sentToItg] = useState<Date | null>(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [remarks, setRemarks] = useState('');
  const [active, setActive] = useState(false);
  const [applyToSMS, setApplyToSMS] = useState('Unsure');
  const [errorMessage, setErrorMessage] = useState<DnoErrorMessage>({
    dnoNameError: '',
    requestDateError: '',
    requestEntityError: '',
    receivedByItgError: '',
    addressedToError: '',
    toItgError: '',
    phoneNumberError: '',
    remarksError: '',
    loaAttachmentId: ''
  });

  const editPrefix = '#dno/edit/';
  useEffect(() => {
    if (location.hash.startsWith(editPrefix)) {
      const id = Number(location.hash.slice(editPrefix.length));
      if (!dno || dno.id !== id) getDno(id);
    } else {
      setDno(null);
    }
  }, [location, dno]);

  const isDuplicateError = dnoError.includes('Duplicate');
  useEffect(() => {
    if (isDuplicateError)
      setErrorMessage((v) => ({
        ...v,
        phoneNumberError: 'This number is already present in the dno list!'
      }));
  }, [isDuplicateError]);

  useEffect(() => {
    if (dno) {
      setDnoName(dno.name);
      setRequestDate(dateOrNull(dno.dateOfRequest));
      if (dno.loaAttachmentId) {
        setLoaAttachmentId(dno.loaAttachmentId);
        setLoaRadio('loaId');
      } else {
        setLoaRadio('loaAttachment');
      }
      setRequestEntity(dno.requestingEntity);
      setReceivedByItg(dateOrNull(dno.dateReceivedByItg));
      setAddressedTo(dno.letterAddressedTo);
      sentToItg(dateOrNull(dno.dateSentToItg));
      setPhoneNumber(dno.phoneNumber);
      setRemarks(dno.remarks);
      setActive(dno.active);
      setApplyToSMS(dno.smsOption);
    } else {
      setDnoName('');
      setRequestDate(null);
      setLoaRadio('loaAttachment');
      setRequestEntity('');
      setLoaAttachmentId(0);
      setReceivedByItg(null);
      setAddressedTo('');
      sentToItg(null);
      setPhoneNumber('');
      setRemarks('');
      setActive(false);
      setApplyToSMS('Unsure');
    }
  }, [dno]);

  const handleChange = (option: any, key: string) => {
    switch (key) {
      case 'dnoName':
        setDnoName(option.value);
        setErrorMessage({ ...errorMessage, dnoNameError: '' });
        if (option.value === '') {
          setErrorMessage({ ...errorMessage, dnoNameError: 'Please provide a DNO name!' });
        }
        break;
      case 'requestDate':
        setRequestDate(option);
        setErrorMessage({ ...errorMessage, requestDateError: '' });
        break;
      case 'loaRadio':
        setLoaRadio(option.value);
        if (option.value === 'loaAttachment') {
          setLoaAttachmentId(0);
          setErrorMessage({ ...errorMessage, loaAttachmentId: '' });
        } else if (option.value === 'loaId') {
          setAttachments([]);
        }
        break;
      case 'loaAttachmentId':
        if (option.value !== '' && !Number(option.value)) {
          setErrorMessage({ ...errorMessage, loaAttachmentId: 'Attachment Id must be a number!' });
        } else if (option.value === '') {
          setLoaAttachmentId(option.value);
          setErrorMessage({
            ...errorMessage,
            loaAttachmentId: 'Please provide an existing attachment Id!'
          });
        } else {
          setLoaAttachmentId(option.value);
          setErrorMessage({ ...errorMessage, loaAttachmentId: '' });
        }
        break;
      case 'requestEntity':
        setRequestEntity(option.value);
        setErrorMessage({
          ...errorMessage,
          requestEntityError: ''
        });
        if (option.value === '') {
          setErrorMessage({
            ...errorMessage,
            requestEntityError: 'Please provide a request entity!'
          });
        }
        break;
      case 'receivedByItg':
        setReceivedByItg(option);
        setErrorMessage({
          ...errorMessage,
          receivedByItgError: ''
        });
        break;
      case 'adressedTo':
        setAddressedTo(option.value);
        setErrorMessage({ ...errorMessage, addressedToError: '' });
        if (option.value === '') {
          setErrorMessage({
            ...errorMessage,
            addressedToError: 'Please provide a recipient!'
          });
        }
        break;
      case 'toItg':
        sentToItg(option);
        setErrorMessage({ ...errorMessage, toItgError: '' });
        break;
      case 'phoneNumber':
        setPhoneNumber(option.value);
        setErrorMessage({
          ...errorMessage,
          phoneNumberError: ''
        });
        if (option.value === '') {
          setErrorMessage({
            ...errorMessage,
            phoneNumberError: 'Please provide a valid phone number!'
          });
        }
        break;
      case 'remarks':
        setRemarks(option.value);
        setErrorMessage({ ...errorMessage, remarksError: '' });
        break;
      case 'active':
        setActive(!active);
        break;
    }
  };

  const getDnoObj = () => ({
    id: dno?.id,
    name: dnoName,
    active: active,
    dateOfRequest: requestDate,
    loaOnHand: loaAttachmentId !== 0 || attachments.length > 0,
    dateReceivedByItg: receivedByItg,
    dateSentToItg: toItg,
    requestingEntity: requestEntity,
    letterAddressedTo: addressedTo,
    phoneNumber,
    loaAttachmentId: loaAttachmentId === 0 ? null : loaAttachmentId,
    remarks,
    smsOption: applyToSMS
  });

  const getErrorMessages = async () => {
    const errorArray: DnoErrorMessage = {};
    if (dnoName === '') {
      errorArray.dnoNameError = 'Please provide a DNO name!';
    }
    if (!requestDate) {
      errorArray.requestDateError = 'Please provide a request date!';
    }
    if (loaRadio === 'loaId' && loaAttachmentId === 0) {
      errorArray.loaAttachmentId = 'Please provide an existing attachment Id!';
    }
    if (requestEntity === '') {
      errorArray.requestEntityError = 'Please provide a request entity!';
    }
    if (!receivedByItg) {
      errorArray.receivedByItgError = 'Please provide a received date!';
    }
    if (addressedTo === '') {
      errorArray.addressedToError = 'Please provide a recipient!';
    }
    if (!toItg) {
      errorArray.toItgError = 'Please provide a sent date!';
    }
    if (phoneNumber === '' || !validatePhoneForE164(phoneNumber)) {
      errorArray.phoneNumberError = 'Please provide a valid phone number!';
    }
    if (loaRadio === 'loaId') {
      let attachment: any;
      try {
        attachment = await getLoaAttachment(loaAttachmentId);
      } catch (error: any) {
        attachment = null;
      }
      if (!attachment) {
        errorArray.loaAttachmentId = 'No match for this attachment Id!';
      }
    }
    setErrorMessage({
      ...errorArray
    });
    return errorArray;
  };

  const handleDno = async (handle: Function) => {
    const errorArray = await getErrorMessages();

    if (Object.values(errorArray).length !== 0) {
      return;
    }
    try {
      const dnoId = await handle(getDnoObj());
      if (!dnoId) {
        return;
      }

      if (loaRadio === 'loaAttachment' && attachments.length > 0) {
        const response = await associateLoa(dnoId, attachments);
        if (response !== 'success') {
          return;
        }
      }
    } catch (error: any) {
      return;
    }

    navigate('/admin-dashboard#dno');
  };
  const saveDno = async () => handleDno(addNewDno);

  const editDno = async () =>
    handleDno(async (dno: DNO): Promise<Number> => {
      const response = await updateDno(dno.id, dno);
      if (response !== 'success') {
        return 0;
      }
      return dno.id || 0;
    });

  const addFile = (files: any[]) => {
    const filelist = files.filter(
      (file) => !attachments.map((item) => item.name).includes(file.name)
    );
    setAttachments([...attachments, ...filelist]);
  };

  const removeFile = (file: any) => {
    const deletedAttachments = attachments.filter((item) => item.name !== file.name);
    setAttachments(deletedAttachments);
  };

  return (
    <Fragment>
      <CardBody>
        <Label className="traceback-heading mb-3" style={{ fontSize: '36px' }}>
          {dno ? 'Edit DNO' : 'New DNO'}
        </Label>
        <Form className="form-traceback traceback-add">
          <FormGroup className="col-lg-8 mb-0  m-auto">
            <h5 className="p-0 traceback-sub-title">Authorization</h5>
            <Row className="ps-3 justify-content-center">
              <Col md="6">
                <label className="label-bold">
                  DNO Name
                  <i className="fa fa-asterisk asterisk" />
                  <InputFormGroup
                    inputName="dnoName"
                    inputId="dnoName"
                    inputClassName="input-traceback"
                    inputAutoComplete="off"
                    inputOnChange={(option) => handleChange(option.target, 'dnoName')}
                    inputValue={dnoName}
                    errorMessage={errorMessage.dnoNameError}
                  />
                </label>
              </Col>
              <Col md="6">
                <label className="label-bold">
                  RequestDate
                  <i className="fa fa-asterisk asterisk" />
                  <DatePicker
                    className={errorMessage.requestDateError && 'control-error'}
                    maxDate={new Date()}
                    selected={requestDate}
                    onChange={(option) => handleChange(option, 'requestDate')}
                    placeholderText="&#xf133; mm/dd/yyyy"
                  />
                </label>
                <Label className="input-error text-fadein ps-2">
                  {errorMessage.requestDateError}
                </Label>
              </Col>
            </Row>
            <Row className="ps-3 justify-content-center">
              <Col md="6">
                <label className="label-bold">
                  LOA Attachment
                  <div className="d-flex align-items-enter">
                    <div style={{ marginTop: '35px' }}>
                      <input
                        name="loaRadio"
                        type="radio"
                        value="loaAttachment"
                        className="ms-2 p-0"
                        onChange={(option) => handleChange(option.target, 'loaRadio')}
                        checked={loaRadio === 'loaAttachment'}
                      />
                    </div>
                    <div className="pe-2" style={{ marginTop: '-3px' }}>
                      <DropZone
                        attachments={attachments}
                        removeFile={removeFile}
                        addFile={addFile}
                        disabled={loaRadio === 'loaId'}
                      />
                    </div>
                  </div>
                </label>
              </Col>
              <Col md="6">
                <label className="label-bold">
                  LOA Attachment Id
                  {loaRadio === 'loaId' && <i className="fa fa-asterisk asterisk" />}
                  <div className="d-flex align-items-center">
                    <input
                      name="loaRadio"
                      type="radio"
                      value="loaId"
                      className="ms-2"
                      style={{ marginBottom: '1.2rem' }}
                      onChange={(option) => handleChange(option.target, 'loaRadio')}
                      checked={loaRadio === 'loaId'}
                    />
                    <InputFormGroup
                      inputName="loaAttachmentId"
                      inputId="loaAttachmentId"
                      inputClassName={`input-traceback ${
                        errorMessage.loaAttachmentId && 'control-error'
                      }`}
                      style={{ width: '100%' }}
                      inputPlaceholder="Attachment Id"
                      inputAutoComplete="off"
                      inputOnChange={(option) => handleChange(option.target, 'loaAttachmentId')}
                      inputValue={loaAttachmentId ? loaAttachmentId.toString() : ''}
                      disabled={loaRadio === 'loaAttachment'}
                    />
                  </div>
                  {errorMessage.loaAttachmentId && (
                    <InputError className="telecom-input-error">
                      {errorMessage.loaAttachmentId}
                    </InputError>
                  )}
                </label>
              </Col>
            </Row>
            <Row className="ps-3 justify-content-center">
              <Col md="6">
                <label className="label-bold">
                  Request Entity
                  <i className="fa fa-asterisk asterisk" />
                  <InputFormGroup
                    inputName="requestEntity"
                    inputId="requestEntity"
                    inputClassName="input-traceback"
                    inputAutoComplete="off"
                    inputOnChange={(option) => handleChange(option.target, 'requestEntity')}
                    inputValue={requestEntity}
                    errorMessage={errorMessage.requestEntityError}
                  />
                </label>
              </Col>
              <Col md="6">
                <label className="label-bold">
                  ReceivedDate by ITG
                  <i className="fa fa-asterisk asterisk" />
                  <DatePicker
                    className={errorMessage.receivedByItgError && 'control-error'}
                    maxDate={new Date()}
                    selected={receivedByItg}
                    onChange={(option) => handleChange(option, 'receivedByItg')}
                    placeholderText="&#xf133; mm/dd/yyyy"
                  />
                </label>
                <Label className=" input-error text-fadein ps-2">
                  {errorMessage.receivedByItgError}
                </Label>
              </Col>
            </Row>
            <Row className="ps-3 justify-content-center">
              <Col md="6">
                <label className="label-bold">
                  Adressed to
                  <i className="fa fa-asterisk asterisk" />
                  <InputFormGroup
                    inputName="adressedTo"
                    inputId="adressedTo"
                    inputClassName="input-traceback"
                    inputAutoComplete="off"
                    inputOnChange={(option) => handleChange(option.target, 'adressedTo')}
                    inputValue={addressedTo}
                    errorMessage={errorMessage.addressedToError}
                  />
                </label>
              </Col>
              <Col md="6">
                <label className="label-bold">
                  SentDate to ITG
                  <i className="fa fa-asterisk asterisk" />
                  <DatePicker
                    className={errorMessage.toItgError && 'control-error'}
                    maxDate={new Date()}
                    selected={toItg}
                    onChange={(option) => handleChange(option, 'toItg')}
                    placeholderText="&#xf133; mm/dd/yyyy"
                  />
                </label>
                <Label className=" input-error text-fadein ps-2">{errorMessage.toItgError}</Label>
              </Col>
            </Row>
            <Row className="ps-3 justify-content-center">
              <Col md="6">
                <label className="label-bold">
                  Phone Number
                  <i className="fa fa-asterisk asterisk" />
                  <InputFormGroup
                    inputName="phoneNumber"
                    inputId="phoneNumber"
                    inputPlaceholder="14155552671"
                    inputClassName="input-traceback"
                    inputAutoComplete="off"
                    inputOnChange={(option) => handleChange(option.target, 'phoneNumber')}
                    inputValue={phoneNumber}
                    errorMessage={errorMessage.phoneNumberError}
                  />
                </label>
                <Fragment>
                  <Label className="telecom-label">Applies to SMS?</Label>
                  <Select
                    className="pb-3 ps-2 inputStyle"
                    value={SMSOptions.find((v) => v.value === applyToSMS) || null}
                    onChange={(e) => {
                      setApplyToSMS(e?.value || '');
                    }}
                    options={SMSOptions}
                    placeholder="Select sms option"
                  />
                </Fragment>
              </Col>
              <Col md="6">
                <label className="label-bold">
                  Remarks
                  <InputFormGroup
                    isTextarea
                    inputName="remarks"
                    inputId="remarks"
                    inputClassName="input-traceback"
                    inputAutoComplete="off"
                    inputOnChange={(option) => handleChange(option.target, 'remarks')}
                    inputValue={remarks}
                    errorMessage={errorMessage.remarksError}
                  />
                </label>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="col-lg-8 mb-0 mt-3 me-auto ms-auto">
            <h5 className="col-md-12 p-0 traceback-sub-title">Status</h5>
            <Row className="ps-3">
              <Col className="d-flex flex-column">
                <div className="d-flex align-items-center pb-5 ">
                  <input
                    onChange={(option) => handleChange(option, 'active')}
                    style={{ width: '15px', height: '15px' }}
                    type="checkbox"
                    checked={active}
                  />
                  <label className="ps-2 m-0 label-bold">Active</label>
                </div>

                {dno && (
                  <div>
                    <p className="mb-1 label-bold">Create date: {dno.createDate}</p>
                    <p className="label-bold">Updated date: {dno.updateDate}</p>
                  </div>
                )}
              </Col>
            </Row>
          </FormGroup>
          <div className="text-center mt-5">
            <Button
              className="btn-default telecom-btn"
              color="light"
              onClick={() => navigate('/admin-dashboard#dno')}
            >
              Cancel
            </Button>
            <Button
              className="telecom-btn"
              onClick={() => {
                if (dno) {
                  editDno();
                } else {
                  saveDno();
                }
              }}
            >
              {dno ? 'Save' : 'Submit'}
            </Button>
          </div>
        </Form>
      </CardBody>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    dno: sm.dno.dno,
    dnoError: sm.dno.error
  };
};

const mapActionToProps = {
  getDno,
  addNewDno,
  updateDno,
  setDno,
  getLoaAttachment,
  associateLoa
};

export default connect(mapStateToProps, mapActionToProps)(AddDno);
