import { convertToTimeZone } from 'date-fns-timezone';
import React, { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import {
  Button,
  CardBody,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { Message } from '../../../interfaces/messaging';
import {
  addCondition,
  newConditionalFilterElement,
  newLeafFilterElement
} from '../../../lib/FilterElement';
import { getMessageFilterInfo } from '../../../lib/utilities';
import {
  addMessage,
  editMessage,
  getMessageById,
  setMessage
} from '../../../redux/messaging/thunks';
import { stateMappings } from '../../../redux/stateMappings';
import CustomSelect, { SelectOption } from '../../CustomSelect';
import InputFormGroup from '../../inputFormGroup';

interface IProps {
  addMessage: Function;
  editMessage: Function;
  setMessage: Function;
  message: Message;
  getMessageById: Function;
}

const defaultFilters = {
  isITG: false,
  isNonITG: false,
  isUS: false,
  isNonUS: false,
  isDno: false,
  isGov: false
};

const AddMessage: FC<IProps> = ({
  addMessage,
  editMessage,
  setMessage,
  message,
  getMessageById
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [sendDate, setSendDate] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [selectedPeriod, setSelectedPeriod] = useState(periodOptions[0]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [filters, setFilters] = useState(defaultFilters);
  useEffect(() => {
    if (message) {
      const date = new Date(message.sendDate);
      const localDate = date.toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      });
      setSendDate(localDate);
      setBody(message.body);
      setSubject(message.subject);
      const { isUS, isITG, startDate, isDno, isGov } = getMessageFilterInfo(
        JSON.parse(message.filters)
      );
      if (startDate) {
        for (let i = 0; i < periodOptions.length; i++)
          if (periodOptions[i].value === Number(startDate)) {
            setSelectedPeriod(periodOptions[i]);
            break;
          }
      }
      setFilters({
        isITG: isITG !== undefined && !!Number(isITG),
        isNonITG: isITG !== undefined && !Number(isITG),
        isUS: isUS !== undefined && !!isUS,
        isNonUS: isUS !== undefined && !isUS,
        isDno: isDno !== undefined && !!Number(isDno),
        isGov: isGov !== undefined && !!Number(isGov)
      });
    } else {
      setSendDate('');
      setBody('');
      setSubject('');
      setFilters(defaultFilters);
    }
  }, [message]);

  useEffect(() => {
    const prefix = '#messaging/edit/';
    if (location.hash.startsWith(prefix)) {
      const id = Number(location.hash.slice(prefix.length));
      if (!message || message.id !== id) {
        getMessageById(id);
      }
    } else if (message) {
      setMessage(null);
    }
  }, [message, location]);

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    switch (name) {
      case 'DNO':
        setFilters((prev) => ({
          ...prev,
          isDno: !prev.isDno,
          isGov: false,
          isITG: false,
          isNonITG: false,
          isUS: false,
          isNonUS: false
        }));
        break;
      case 'GOV':
        setFilters((prev) => ({
          ...prev,
          isDno: false,
          isGov: !prev.isGov,
          isITG: false,
          isNonITG: false,
          isUS: false,
          isNonUS: false
        }));
        break;
      case 'ITG':
        setFilters((prev) => ({
          ...prev,
          isITG: !prev.isITG,
          isNonITG: false,
          isDno: false,
          isGov: false
        }));
        break;
      case 'Non-ITG':
        setFilters((prev) => ({
          ...prev,
          isNonITG: !prev.isNonITG,
          isITG: false,
          isDno: false,
          isGov: false
        }));
        break;
      case 'US':
        setFilters((prev) => ({
          ...prev,
          isUS: !prev.isUS,
          isNonUS: false,
          isDno: false,
          isGov: false
        }));
        break;
      case 'Non-US':
        setFilters((prev) => ({
          ...prev,
          isNonUS: !prev.isNonUS,
          isUS: false,
          isDno: false,
          isGov: false
        }));
        break;
      default:
        break;
    }
  };

  const isFormValid = () =>
    sendDate.trim() !== '' &&
    subject.trim() !== '' &&
    body.trim() !== '' &&
    (filters.isDno || filters.isGov || selectedPeriod.value !== undefined) &&
    body.length > 10;

  const handleSubmit = () => {
    let filterElements = newConditionalFilterElement('AND');
    if (!filters.isITG || !filters.isNonITG) {
      if (filters.isITG) {
        addCondition(filterElements, newLeafFilterElement('isITG', 'EQ', '1'));
      }
      if (filters.isNonITG) {
        addCondition(filterElements, newLeafFilterElement('isITG', 'EQ', '0'));
      }
    }
    if (filters.isDno) {
      addCondition(filterElements, newLeafFilterElement('isDno', 'EQ', '1'));
    }
    if (filters.isGov) {
      addCondition(filterElements, newLeafFilterElement('isGov', 'EQ', '1'));
    }
    if (!filters.isUS || !filters.isNonUS) {
      if (filters.isUS) {
        addCondition(filterElements, newLeafFilterElement('isUS', 'EQ', 'true'));
      }
      if (filters.isNonUS) {
        addCondition(filterElements, newLeafFilterElement('isNotUS', 'EQ', 'false'));
      }
    }

    if (selectedPeriod.value !== 0) {
      addCondition(
        filterElements,
        newLeafFilterElement('startDate', 'GE', String(selectedPeriod.value))
      );
    }

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const formattedDate = convertToTimeZone(sendDate, { timeZone: timeZone }).toISOString();

    const data = {
      sendDate: formattedDate,
      subject,
      body,
      filters: filterElements
    };
    if (message) {
      editMessage(message.id, data);
    } else {
      addMessage(data);
      setOpen(() => false);
    }
    navigate('/admin-dashboard#messaging');
  };

  useEffect(() => {
    setIsDisabled(() => !isFormValid());
  }, [sendDate, subject, body, selectedPeriod]);

  return (
    <Fragment>
      <CardBody>
        <Label className="traceback-heading mb-3" style={{ fontSize: '36px' }}>
          {message ? 'Edit Message' : 'Schedule New Message'}
        </Label>
        <CardBody>
          <Label className="telecom-label ps-0">
            Date & Time
            <i className="fa fa-asterisk asterisk" />
          </Label>
          <p className="mt-1">Enter local time to send, not UTC</p>
          <Row>
            <Col md="4">
              <InputFormGroup
                inputName="time"
                inputId="time"
                inputClassName="input-traceback"
                inputValue={sendDate}
                inputPlaceholder="2020-03-07 19:55"
                inputOnChange={(e) => setSendDate(e.currentTarget.value)}
                inputAutoComplete="off"
              />
            </Col>
          </Row>
          {!filters.isDno && !filters.isGov && (
            <div>
              <Label className="telecom-label ps-0">
                Select Period
                <i className="fa fa-asterisk asterisk" />
              </Label>
              <Row className="mb-4">
                <Col md="4">
                  <CustomSelect
                    className={'customselect-large'}
                    placeholder={'Select Period'}
                    typeLabel={'All'}
                    getOptions={periodOptions}
                    selectedOption={selectedPeriod}
                    setSelectedOption={(option) => setSelectedPeriod(option as SelectOption)}
                  />
                </Col>
              </Row>{' '}
            </div>
          )}
          <Label className="telecom-label ps-0">
            Subject
            <i className="fa fa-asterisk asterisk" />
          </Label>
          <Row className="mb-4">
            <Col md="4">
              <InputFormGroup
                style={{ margin: 0 }}
                isReadonly={false}
                inputName="subject"
                inputId="subject"
                inputValue={subject}
                inputPlaceholder="Email Subject..."
                inputOnChange={(e) => setSubject(e.currentTarget.value)}
                inputAutoComplete="off"
              />
            </Col>
          </Row>
          <Label className="telecom-label ps-0">
            Body
            <i className="fa fa-asterisk asterisk" />
          </Label>
          <p className="mt-1">Your summary should be at least 10 characters</p>
          <Row className="mb-4">
            <Col md="10">
              <InputFormGroup
                isReadonly={false}
                isTextarea
                inputName="body"
                inputId="body"
                inputValue={body}
                inputOnChange={(e) => setBody(e.currentTarget.value)}
                inputPlaceholder="Input the email body here..."
                inputAutoComplete="off"
              />
            </Col>
          </Row>
          <Label className="telecom-label ps-0">Filters</Label>
          <div className="d-flex">
            <div className="col-1">
              <label className="form-label tile-label" style={{ fontSize: '15px' }}>
                ITG
                <input
                  type="checkbox"
                  name="ITG"
                  onChange={(e) => handleFilterChange(e)}
                  checked={filters.isITG}
                />
                <span className="checkmark tile-checkmark" />
              </label>
            </div>
            <div className="col-1">
              <label className="form-label tile-label" style={{ fontSize: '15px' }}>
                Non-ITG
                <input
                  type="checkbox"
                  name="Non-ITG"
                  onChange={(e) => handleFilterChange(e)}
                  checked={filters.isNonITG}
                />
                <span className="checkmark tile-checkmark" />
              </label>
            </div>
            <div className="col-1">
              <label className="form-label tile-label" style={{ fontSize: '15px' }}>
                US
                <input
                  type="checkbox"
                  name="US"
                  onChange={(e) => handleFilterChange(e)}
                  checked={filters.isUS}
                />
                <span className="checkmark tile-checkmark" />
              </label>
            </div>
            <div className="col-1">
              <label className="form-label tile-label" style={{ fontSize: '15px' }}>
                Non-US
                <input
                  type="checkbox"
                  name="Non-US"
                  onChange={(e) => handleFilterChange(e)}
                  checked={filters.isNonUS}
                />
                <span className="checkmark tile-checkmark" />
              </label>
            </div>
            <div className="col-1">
              <label className="form-label tile-label" style={{ fontSize: '15px' }}>
                Dno User
                <input
                  type="checkbox"
                  name="DNO"
                  onChange={(e) => handleFilterChange(e)}
                  checked={filters.isDno}
                />
                <span className="checkmark tile-checkmark" />
              </label>
            </div>
            <div className="col-1">
              <label className="form-label tile-label" style={{ fontSize: '15px' }}>
                Gov User
                <input
                  type="checkbox"
                  name="GOV"
                  onChange={(e) => handleFilterChange(e)}
                  checked={filters.isGov}
                />
                <span className="checkmark tile-checkmark" />
              </label>
            </div>
          </div>
          <div className="d-flex w-100 pt-4 justify-content-center">
            {message ? (
              <Button
                color="primary"
                className="mt-4"
                disabled={isDisabled}
                onClick={() => handleSubmit()}
              >
                Save Message
              </Button>
            ) : (
              <Button
                color="primary"
                className="mt-4"
                disabled={isDisabled}
                onClick={() => setOpen(true)}
              >
                Schedule Message
              </Button>
            )}
          </div>
        </CardBody>
      </CardBody>
      <Modal
        centered
        isOpen={open}
        className="submit-confirm-modal"
        toggle={() => setOpen((prev) => !prev)}
      >
        <ModalHeader toggle={() => setOpen((prev) => !prev)}>
          Confirm Schedule Message !
        </ModalHeader>
        <ModalBody>
          Are you sure you want to schedule this message ? <br />
        </ModalBody>
        <ModalFooter className="m-auto">
          <div style={{ gap: '12px' }}>
            <Button
              className="btn-default telecom-btn"
              color="light"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button className="telecom-btn ms-2" onClick={() => handleSubmit()}>
              Proceed
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

const periodOptions = [
  {
    value: 0,
    label: 'All'
  },
  {
    value: 30,
    label: 'Last 30 days'
  },
  {
    value: 60,
    label: 'Last 60 days'
  },
  {
    value: 90,
    label: 'Last 90 days'
  }
];

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    message: sm.messaging.message
  };
};

const mapActionsToProps = {
  addMessage,
  editMessage,
  setMessage,
  getMessageById
};

export default connect(mapStateToProps, mapActionsToProps)(AddMessage);
