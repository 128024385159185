import React, { useState } from 'react';
import { OnChangeValue } from 'react-select';
import { getCampaignNamesApiCall } from '../redux/appinfo/apiCalls';
import CustomPicky from './CustomPicky';
import CustomSelect, { ApiLookup, SelectOption } from './CustomSelect';
import { stateMappings } from '../redux/stateMappings';
import { connect } from 'react-redux';
import { PersonalInfo } from '../interfaces/user';
import { userTypes } from '../enum/userTypes';
import { useDidUpdateEffect } from '../lib/hooks';

interface IProps {
  value?: number | number[] | SelectOption | SelectOption[] | undefined | null;
  onChange: (value: OnChangeValue<SelectOption, boolean>) => void;
  addAllItem?: boolean;
  isSearchable?: boolean;
  addLink?: boolean;
  selectClass?: string;
  placeholder?: string;
  isClearable?: boolean;
  isMulti?: boolean;
  numberDisplayed?: number;
  maxMenuHeight?: number;
  user: PersonalInfo;
}

interface CampaignItem {
  campaignId: any;
  name: string;
  isLowVolume: boolean;
}

const getApi = (user: PersonalInfo): ApiLookup => ({
  apiCall: getCampaignNamesApiCall,
  nameLookupFunction: (item: CampaignItem) => {
    return user.roleType === userTypes.Admin
      ? `${item.name} ${item.isLowVolume ? '(low vol)' : ''}`
      : item.name;
  },
  valueLookupFunction: (item: CampaignItem) => {
    return item.campaignId;
  }
});

const CampaignsSelect: React.FC<IProps> = ({
  addAllItem,
  addLink,
  onChange,
  isSearchable,
  placeholder,
  selectClass,
  value,
  isClearable,
  isMulti,
  numberDisplayed,
  maxMenuHeight,
  user
}) => {
  const [api, setApi] = useState(getApi(user));
  useDidUpdateEffect(() => {
    setApi(getApi(user));
  }, [user]);
  return isMulti ? (
    <CustomPicky
      selectedOption={value}
      setSelectedOption={onChange}
      getOptions={api}
      className={selectClass ? selectClass : 'customselect-small'}
      isSearchable={isSearchable}
      isClearable={isClearable}
      addAllItem={addAllItem}
      typeLabel={'All Campaigns'}
      placeholder={placeholder}
      numberDisplayed={numberDisplayed ? numberDisplayed : 3}
      maxMenuHeight={maxMenuHeight}
    />
  ) : (
    <CustomSelect
      addAllItem={addAllItem}
      addLink={!addLink ? undefined : '/campaigns/add'}
      getOptions={api}
      setSelectedOption={onChange}
      isSearchable={isSearchable}
      placeholder={placeholder}
      className={selectClass ? selectClass : 'customselect-small'}
      classNamePrefix={'customselect'}
      typeLabel={'All Campaigns'}
      selectedOption={value}
      isClearable={isClearable}
      maxMenuHeight={maxMenuHeight}
      //can always set that to null if you want page to start that way
    />
  );
};
const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    user: sm.user
  };
};
export default connect(mapStateToProps)(CampaignsSelect);
