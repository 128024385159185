import React, { useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';
import { deleteNotification, getAllNotifications } from '../../redux/notification/thunks';
import { stateMappings } from '../../redux/stateMappings';

import { Col, Row } from 'reactstrap';

import { DateFormat } from '../../enum/DateFormat';
import { Comment, NotificationNewComment } from '../../interfaces/comment';
import { defaultPaginationNotification } from '../../interfaces/pagination';

import { getClientFormattedDate } from '../../lib/utilities';

interface IProps {
  comment: Comment;
  getAllNotifications: Function;
  deleteNotification: Function;
  newCommentsNotificationList: NotificationNewComment[];
  className: string;
  id?: string;
  hash?: string;
}
const BasicHopComment: React.FC<IProps> = ({
  hash,
  id,
  comment,
  className,
  newCommentsNotificationList,
  deleteNotification,
  getAllNotifications
}) => {
  const [status, setStatus] = useState(true);
  const commentRef = useRef<HTMLDivElement | null>(null);

  const notification = newCommentsNotificationList.filter(
    (item) => item.objectId.toString() === id?.slice(1)
  )[0];

  useEffect(() => {
    const isNewComment = newCommentsNotificationList.some(
      (item) => item.objectId.toString() === id?.slice(1)
    );
    if (hash && commentRef.current?.id === hash && isNewComment) {
      setStatus(false);
      commentRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      setTimeout(() => {
        deleteNotification(notification.id);
      }, 3000);
    } else if (hash && commentRef.current?.id === hash && !isNewComment) {
      setStatus(true);
      commentRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
    } else if (!hash && isNewComment) {
      setStatus(false);
      commentRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
    } else {
      setStatus(true);
    }
  }, [hash, newCommentsNotificationList.length]);

  const handleCheckRead = async () => {
    setStatus((prevState) => !prevState);
    if (notification) {
      await deleteNotification(notification.id);
      await getAllNotifications(defaultPaginationNotification());
    }
  };

  return (
    <div className={`${className} ${!status ? 'newHopComment' : ''}`} ref={commentRef} id={id}>
      <div className="row">
        <div className="col-md-6">
          <div className="d-flex">
            <p className="m-0">
              <span className="span-cell bold">Date/Time: </span>
              {getClientFormattedDate(comment.create_date, DateFormat.MediumBoth)}
            </p>
            <p className="ps-3 m-0">
              <span className="span-cell bold">From: </span>
              {comment.userName}
            </p>
          </div>
          <p className="m-0">
            <span className="span-cell bold">Comment: </span>
            {comment.contentText}
          </p>
        </div>
        <div className="col-md-3">
          <p className="m-0">
            <span className="span-cell bold">Provider:</span> {comment.userProvider?.name}
          </p>
          <p className="m-0">
            <span className="span-cell bold">Attachments: </span>
            {(comment.attachments && comment.attachments.length) > 0
              ? comment.attachments.map((attachment) => (
                  <a
                    key={comment.commentId + '-' + attachment.attachmentId}
                    style={{ fontWeight: 'bold', color: '#0f72b1' }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`/api/attachments/${attachment.attachmentId}`}
                  >
                    {attachment.fileName}
                  </a>
                ))
              : 'none'}
          </p>
        </div>
        <div className="col-md-3">
          <div className="markAsRead d-flex align-items-center justify-content-start flex-row-reverse pe-2">
            <label htmlFor="read" className="span-cell bold m-0 font-italic ps-1">
              {status ? 'Marked as read' : 'Mark as read'}
            </label>
            <input
              type="checkbox"
              id="low_volume"
              name="read"
              checked={status}
              className="checkbox"
              disabled={status}
              onChange={handleCheckRead}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return { newCommentsNotificationList: sm.comment.newCommentsNotificationList };
};

const mapActionsToProps = {
  getAllNotifications,
  deleteNotification
};

export default connect(mapStateToProps, mapActionsToProps)(BasicHopComment);
