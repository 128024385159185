import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { OnChangeValue } from 'react-select';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Breadcrumb from '../components/Breadcrumbs';
import CustomDataTable from '../components/CustomDataTable';
import CustomSelect, { SelectOption } from '../components/CustomSelect';
import { Pagination } from '../interfaces/pagination';
import {
  addOrEditEventColumns,
  customCellStylesEvent,
  defaultPaginationParamsEvents
} from '../lib/utilities';
import { createEvent, getAllPartnersList, getPartner } from '../redux/partners/thunks';
import { stateMappings } from '../redux/stateMappings';

interface IProps {
  redirectTo: string;
  partnersList: any[];
  partnerStats: any[];
  getAllPartnersList: Function;
  getPartner: Function;
  createEvent: Function;
}

const formatOptions = (array: any[]): SelectOption[] => {
  return array.map((element: any) => ({
    value: element.requestorId,
    label: element.requestorName
  }));
};

const AddEvent: React.FC<IProps> = ({
  redirectTo,
  partnersList,
  partnerStats,
  getAllPartnersList,
  getPartner,
  createEvent
}) => {
  const options = partnersList ? formatOptions(partnersList) : [];
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState<any>({});
  const [paginationParams, setPaginationParams] = useState(defaultPaginationParamsEvents);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const handlePaginationChange = (params: Pagination) => {
    const newParams = { ...paginationParams, ...params };
    setPaginationParams(newParams);
  };

  const handleChangeSelect = (opt: OnChangeValue<SelectOption, boolean>) => {
    setSelectedOption(opt);
    setSelectedRows([]);
  };

  const handleSelectedRows = (currentSelected: any) => {
    const mappedSelected = currentSelected.map((entry: any) => entry.tracebackId);
    setSelectedRows(mappedSelected);
  };

  const handleCreateEvent = () => {
    createEvent(selectedOption.value, selectedRows);
  };

  useEffect(() => {
    getAllPartnersList();
  }, []);

  useEffect(() => {
    if (!Object.keys(selectedOption).length) return;

    getPartner(selectedOption.value, paginationParams);
  }, [selectedOption, paginationParams]);

  useEffect(() => {
    if (redirectTo !== '') navigate(redirectTo);
  }, [redirectTo]);

  return (
    <Fragment>
      <Breadcrumb title="Partners" destination={'/partners'} detail="Add Event" />
      <Card className="telecom-card">
        <CardHeader className="telecom-card-header">Add Event</CardHeader>
        <CardBody className="justify-content-center">
          <Row className="p-4">
            <Col xs="4">
              <label className="label-bold">
                Partner Name
                <i className="fa fa-asterisk asterisk" />
              </label>
              <CustomSelect
                className={'customselect-large'}
                getOptions={options}
                placeholder={'Select Partner'}
                typeLabel={''}
                selectedOption={selectedOption}
                setSelectedOption={handleChangeSelect}
              />
            </Col>
          </Row>
          {partnerStats && !!partnerStats.length && (
            <Fragment>
              <Row>
                <CustomDataTable
                  tableData={partnerStats}
                  columns={addOrEditEventColumns}
                  defaultSortFieldId={'campaignName'}
                  defaultSortAsc
                  updatePaginationParams={handlePaginationChange}
                  selectableRow
                  onSelectedRowsChange={({ selectedRows: currentSelected }: any) =>
                    handleSelectedRows(currentSelected)
                  }
                  selectableRowsHighlight
                  customStyles={customCellStylesEvent}
                />
              </Row>
              <div className="d-flex justify-content-center">
                <Button color="primary" className="mt-4" onClick={() => handleCreateEvent()}>
                  Create Event
                </Button>
              </div>
            </Fragment>
          )}
        </CardBody>
      </Card>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    partnersList: sm.partners.partnersList,
    partnerStats: sm.partners.partnerStats,
    redirectTo: sm.partners.redirectTo
  };
};

const mapActionsToProps = {
  getAllPartnersList,
  getPartner,
  createEvent
};

export default connect(mapStateToProps, mapActionsToProps)(AddEvent);
