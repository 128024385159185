import { Pagination } from '../../interfaces/pagination';
import { logoutOnAuthError } from '../../lib/apiRequest';
import { FilterElement } from '../../lib/FilterElement';
import {
  createEventAction,
  deleteEventAction,
  getAllPartners,
  getAllPartnerStatsAction,
  getPartnersList,
  getPartnerStats,
  resetRedirectAndError,
  setErrorMessage,
  setLoadingStatus,
  updateEventAction
} from './actions';
import {
  createPartnerEventApiCall,
  deleteEventApiCall,
  getAllPartnersApiCall,
  getAllPartnerStatsApiCall,
  getPartnerApiCall,
  getPartnersApiCall,
  updateEventApiCall
} from './apiCalls';

export const getPartners =
  (params: Pagination, filterElements: FilterElement) => async (dispatch: any) => {
    dispatch(setStatus(true, ''));

    try {
      const partners = await getPartnersApiCall(params, filterElements);
      if (partners && partners.data) {
        dispatch(getPartnersList(partners.data));
      }
      dispatch(setStatus(false, ''));
    } catch (error: any) {
      dispatch(setStatus(false, error.message));
      logoutOnAuthError(error);
    }
  };

export const getAllPartnersList = () => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const partnersList = await getAllPartnersApiCall();
    if (partnersList && partnersList.data) {
      dispatch(getAllPartners(partnersList.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getPartner = (id: number, params: Pagination) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const partnerStats = await getPartnerApiCall(id, params);

    if (partnerStats && partnerStats.data) {
      dispatch(getPartnerStats(partnerStats.data));
    }

    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getAllPartnerStats = (id: number, params: Pagination) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const partnerStats = await getAllPartnerStatsApiCall(id, params);

    if (partnerStats && partnerStats.data) {
      dispatch(getAllPartnerStatsAction(partnerStats.data));
    }

    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const createEvent = (requestorId: number, tracebacks: number[]) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const eventCreated = await createPartnerEventApiCall(requestorId, tracebacks);
    if (eventCreated && eventCreated.data) {
      dispatch(createEventAction(eventCreated.data));
    }

    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const updateEvent = (eventId: number, tracebackIds: number[]) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const updatedEvent = await updateEventApiCall(eventId, tracebackIds);
    if (updatedEvent && updatedEvent.data) {
      dispatch(updateEventAction());
    }

    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const deleteEvent = (eventId: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));

  try {
    const deletedEvent = await deleteEventApiCall(eventId);
    if (deletedEvent && deletedEvent.data) {
      dispatch(deleteEventAction());
    }

    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

const setStatus = (status: boolean, message: string) => async (dispatch: any) => {
  dispatch(setErrorMessage(message));
  dispatch(setLoadingStatus(status));
};

export const resetErrorAndRedirect = () => async (dispatch: any) => {
  dispatch(resetRedirectAndError());
};
