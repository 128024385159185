import {
  differenceInDays,
  format,
  formatDistanceToNow,
  getYear,
  intervalToDuration,
  isMatch,
  isValid,
  lastDayOfMonth,
  lastDayOfYear,
  setHours,
  setMinutes,
  setSeconds,
  sub,
  subDays,
  subSeconds,
  subYears
} from 'date-fns';
import { parseFromTimeZone } from 'date-fns-timezone';
import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { SelectOption } from '../components/CustomSelect';
import { DateFormat } from '../enum/DateFormat';
import { ProviderType } from '../enum/ProviderType';
import { StirShaken, StirShakenStatus } from '../enum/StirShaken';
import { userTypes } from '../enum/userTypes';
import { Hop, NoteType } from '../interfaces/hop';
import { defaultPagination } from '../interfaces/pagination';
import { Provider } from '../interfaces/provider';
import { StirShakenInfo, StirShakenModalErrors, StirShakenRequest } from '../interfaces/stirShaken';
import {
  FilterElement,
  addCondition,
  newConditionalFilterElement,
  newLeafFilterElement,
  simplifyFilterElement
} from './FilterElement';
import { IPRegex, isBase64Regex } from './regex';

export const generateRandomString = (length: number) => {
  const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }
  return result;
};

export const getElapsedTime = (time: number) => {
  const end = new Date();
  const start = subSeconds(end, time ? time : 0);
  const duration = intervalToDuration({
    start,
    end
  });

  switch (true) {
    case !!duration.years:
      return (
        duration.years +
        ` Year${duration.years !== 1 ? 's' : ''} ${!duration.months ? '0' : duration.months}M`
      );
    case !!duration.months:
      return (
        duration.months +
        ` Month${duration.months !== 1 ? 's' : ''} ${!duration.days ? '0' : duration.days}d`
      );
    case !!duration.days:
      return (
        duration.days +
        ` Day${duration.days !== 1 ? 's' : ''} ${!duration.hours ? '0' : duration.hours}h`
      );
    case !!duration.hours:
      return (
        duration.hours +
        ` Hour${duration.hours !== 1 ? 's' : ''} ${!duration.minutes ? '0' : duration.minutes}m`
      );
    case !!duration.minutes:
      return (
        duration.minutes +
        ` Minute${duration.minutes !== 1 ? 's' : ''} ${!duration.seconds ? '0' : duration.seconds}s`
      );
    case !!duration.seconds:
      return duration.seconds + ` Second${duration.seconds !== 1 ? 's' : ''}`;
    default:
      return '-';
  }
};

const isISOString = (val: any) => {
  const d = new Date(val);
  return !Number.isNaN(d.valueOf()) && d.toISOString() === val;
};

const isMatchFormat = (date: string) =>
  isMatch(date, "yyyy-MM-dd'T'HH:mm:ss'Z'") ||
  isMatch(date, 'yyyy-MM-dd HH:mm:ss') ||
  isMatch(date, 'yyyy-MM-dd HH:mm') ||
  isMatch(date, 'yyyy-MM-dd') ||
  isMatch(date, 'yyyy-MM') ||
  isMatch(date, 'yyyy');

export const isValidDateTime = (date: string) =>
  isISOString(date) || (isValid(Date.parse(date)) && isMatchFormat(date) && date.length > 3);

export const onlyNumbersAndDash = (phoneNumber: string) =>
  phoneNumber.toString().replace(/[^0-9:\- ]/g, '');

const toUTCDate = (d: string) => {
  const date = new Date(d);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  switch (true) {
    case isMatch(d, 'yyyy-MM-dd HH:mm:ss') || isMatch(d, "yyyy-MM-dd'T'HH:mm:ss'Z'"):
      return new Date(Date.UTC(year, month, day, hours, minutes, seconds, 0));
    case isMatch(d, 'yyyy-MM-dd HH:mm'):
      return new Date(Date.UTC(year, month, day, hours, minutes, 0, 0));
    case isMatch(d, 'yyyy-MM-dd'):
      return new Date(Date.UTC(year, month, day, 0, 0, 0, 0));
    case isMatch(d, 'yyyy-MM'):
      return new Date(Date.UTC(year, month, 1, 0, 0, 0, 0));
    case isMatch(d, 'yyyy'):
      return new Date(Date.UTC(year, 0, 1, 0, 0, 0, 0));
  }
  return date;
};

export const getApiFormattedDate = (date: string) => {
  if (!date || !isValidDateTime(date)) return '';
  const utc = isISOString(date) ? date : toUTCDate(date).toISOString();
  return !utc ? '' : utc.slice(0, -4) + '369Z';
};

export const getClientFormattedDate = (date: any, dateFormat?: DateFormat) => {
  if (!dateFormat) {
    dateFormat = DateFormat.ShortBoth;
  }
  return date ? format(new Date(date.slice(0, -1)), dateFormat) : '';
};

export const getStartOfTheDay = (date: Date): Date =>
  new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
export const getEndOfTheDay = (date: Date): Date =>
  new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59));

export const parseDateToISOString = (date: string, option: number) => {
  if (date && !isNaN(Number(new Date(date)))) {
    const parsedDate = new Date(date);
    switch (option) {
      case 1:
        return getStartOfTheDay(parsedDate).toISOString();
      case 2:
        return getEndOfTheDay(parsedDate).toISOString();
    }
  } else {
    return undefined;
  }
};

export const reputationStatuses = [
  {
    label: 'No Warnings',
    value: '1'
  },
  {
    label: 'Yellow',
    value: '2'
  },
  {
    label: 'Orange',
    value: '3'
  }
];

export const getEndOfDayAsString = (date: Date, dateFormat: DateFormat): string | undefined =>
  date ? format(setSeconds(setMinutes(setHours(date, 23), 59), 59), dateFormat) : date;

export const getStartOfDayAsString = (date: Date, dateFormat: DateFormat): string | undefined =>
  date ? format(setSeconds(setMinutes(setHours(date, 0), 0), 0), dateFormat) : date;

export const getDateFromPeriod = (period: any) => getDateFromPeriodNumber(period.value);
export const getDateFromPeriodNumber = (period: number) => ({
  startDate: getStartDateFromPeriodNumber(period) || '',
  endDate: getEndDateFromPeriodNumber(period) || ''
});

export const getStartDateFromPeriod = (period: any) => getStartDateFromPeriodNumber(period.value);
export const getStartDateFromPeriodNumber = (period: number) => {
  switch (period) {
    case 1: {
      return format(
        subDays(setSeconds(setMinutes(setHours(new Date(), 0), 0), 0), 29),
        'yyyy-MM-dd HH:mm:ss'
      );
    }
    case 2: {
      return format(
        subDays(setSeconds(setMinutes(setHours(new Date(), 0), 0), 0), 59),
        'yyyy-MM-dd HH:mm:ss'
      );
    }
    case 3: {
      return format(setSeconds(setMinutes(setHours(new Date(), 0), 0), 0), 'yyyy-MM-01 HH:mm:ss');
    }
    case 4: {
      return format(
        sub(setSeconds(setMinutes(setHours(new Date(), 0), 0), 0), { months: 1 }),
        'yyyy-MM-01 HH:mm:ss'
      );
    }
    case 5: {
      return format(setHours(new Date(getYear(new Date()).toString()), 0), 'yyyy-MM-dd HH:mm:ss');
    }
    case 6: {
      return format(
        setHours(new Date(getYear(subYears(new Date(), 1)).toString()), 0),
        'yyyy-MM-dd HH:mm:ss'
      );
    }
    case 7: {
      return format(
        setHours(new Date(getYear(subYears(new Date(), 10)).toString()), 0),
        'yyyy-MM-dd HH:mm:ss'
      );
    }
  }
};

export const getEndDateFromPeriod = (period: any) => getEndDateFromPeriodNumber(period.value);
export const getEndDateFromPeriodNumber = (period: number) => {
  switch (period) {
    case 1: {
      return format(
        setSeconds(setMinutes(setHours(new Date(), 23), 59), 59),
        'yyyy-MM-dd HH:mm:ss'
      );
    }
    case 2: {
      return format(
        setSeconds(setMinutes(setHours(new Date(), 23), 59), 59),
        'yyyy-MM-dd HH:mm:ss'
      );
    }
    case 3: {
      return format(
        setSeconds(setMinutes(setHours(lastDayOfMonth(new Date()), 23), 59), 59),
        'yyyy-MM-dd HH:mm:ss'
      );
    }
    case 4: {
      return format(
        setSeconds(
          setMinutes(setHours(lastDayOfMonth(sub(new Date(), { months: 1 })), 23), 59),
          59
        ),
        'yyyy-MM-dd HH:mm:ss'
      );
    }
    case 5: {
      return format(
        setSeconds(setMinutes(setHours(new Date(), 23), 59), 59),
        'yyyy-MM-dd HH:mm:ss'
      );
    }
    case 6: {
      return format(
        setSeconds(setMinutes(setHours(lastDayOfYear(subYears(new Date(), 1)), 23), 59), 59),
        'yyyy-MM-dd HH:mm:ss'
      );
    }
    case 7: {
      return format(
        setSeconds(setMinutes(setHours(new Date(), 23), 59), 59),
        'yyyy-MM-dd HH:mm:ss'
      );
    }
  }
};

export const dateOptions = [
  {
    value: 1,
    label: 'Last 30 Days'
    // startDate:"",
    // endDate:""
  },
  {
    value: 2,
    label: 'Last 60 Days'
  },
  {
    value: 3,
    label: format(new Date(), 'MMMM')
  },
  {
    value: 4,
    label: format(sub(new Date(), { months: 1 }), 'MMMM')
  },
  {
    value: 5,
    label: format(new Date(), 'yyyy')
  },
  {
    value: 6,
    label: format(subYears(new Date(), 1), 'yyyy')
  },
  {
    value: 7,
    label: 'All'
  }
];

export const buildCsdFilterElement = (filterElements: FilterElement, value: string) => {
  let csdElements = newConditionalFilterElement('OR');
  addCondition(csdElements, newLeafFilterElement('address', 'RLIKE', value));
  addCondition(csdElements, newLeafFilterElement('city', 'RLIKE', value));
  addCondition(csdElements, newLeafFilterElement('state', 'RLIKE', value));
  addCondition(csdElements, newLeafFilterElement('zipCode', 'RLIKE', value));
  addCondition(csdElements, newLeafFilterElement('contactEmail', 'RLIKE', value));
  addCondition(csdElements, newLeafFilterElement('contactName', 'RLIKE', value));
  addCondition(csdElements, newLeafFilterElement('contactPhone', 'RLIKE', value));
  addCondition(csdElements, newLeafFilterElement('country', 'RLIKE', value));
  addCondition(csdElements, newLeafFilterElement('customer', 'RLIKE', value));
  addCondition(csdElements, newLeafFilterElement('ipAddress', 'RLIKE', value));
  addCondition(csdElements, newLeafFilterElement('customerType', 'RLIKE', value));
  addCondition(csdElements, newLeafFilterElement('averageDailyTraffic', 'RLIKE', value));
  addCondition(csdElements, newLeafFilterElement('actionTaken', 'RLIKE', value));
  addCondition(csdElements, newLeafFilterElement('refuseReason', 'RLIKE', value));

  addCondition(filterElements, simplifyFilterElement(csdElements));
};

export const defaultPaginationParamsEvents = {
  ...defaultPagination(),
  sort: 'campaignName',
  order: 'asc',
  pageSize: 10000
};

export const customCellStylesEvent = {
  cells: {
    style: {
      justifyContent: 'center !important'
    }
  }
};

export const addOrEditEventColumns: TableColumn<any>[] = [
  {
    name: '',
    width: '20px',
    cell: () => <div />
  },
  {
    name: 'Campaign',
    selector: () => 'campaignName',
    id: 'column-campaignName',
    sortable: true,
    grow: 3,
    width: '300px',
    cell: (stats: any) => (
      <span className="table-cell blue">
        <a href={`/campaigns/campaign/${stats.campaignId}`}>{stats.campaignName}</a>
      </span>
    )
  },
  {
    name: 'Traceback',
    selector: () => 'tracebackId',
    sortable: true,
    grow: 1,
    cell: (stats: any) => (
      <span className="table-cell blue center">
        <a href={`/tracebacks/traceback/${stats.tracebackId}`}>{stats.tracebackId}</a>
      </span>
    )
  },
  {
    name: 'Create Date',
    selector: () => 'create_date',
    sortable: true,
    grow: 2,
    cell: (stats: any) => <span className="table-cell gray center">{stats.create_date}</span>
  },
  {
    name: 'Completed Date',
    selector: () => 'completed_date',
    sortable: true,
    grow: 2,
    cell: (stats: any) =>
      stats.status === 'Pending' ? (
        <span className="table-cell gray center">-</span>
      ) : (
        <span className="table-cell gray center">{stats.completed_date}</span>
      )
  },
  {
    name: 'Status',
    selector: () => 'status',
    sortable: true,
    grow: 1,
    cell: (stats: any) => <span className="table-cell gray center">{stats.status}</span>
  },
  {
    name: 'Elapsed',
    selector: () => 'elapsed',
    sortable: true,
    grow: 2,
    cell: (stats: any) => <span className="table-cell gray center">{stats.elapsed}</span>
  }
];

export const getProviderTypeName = (poviderObj: Provider) => {
  switch (poviderObj.providerType) {
    case 1:
      return ProviderType.US_Voice_Service_Provider_FCC_Filer;
    case 2:
      return ProviderType.US_Voice_Service_Provider_Non_FCC_Filer;
    case 3:
      return ProviderType.US_Voice_Broadcaster;
    case 4:
      return ProviderType.US_Call_Center;
    case 5:
      return ProviderType.US_Lead_Generator;
    case 6:
      return ProviderType.US_Based_Other;
    case 7:
      return ProviderType.Non_US_Voice_Service_Provider_FCC_Filer;
    case 8:
      return ProviderType.Non_US_Voice_Service_Provider_Non_FCC_Filer;
    case 9:
      return ProviderType.Non_US_Voice_Broadcaster;
    case 10:
      return ProviderType.Non_US_Lead_Generator;
    case 11:
      return ProviderType.Non_US_Other;
  }
};

export const providerCircleType = (provider: Provider) => {
  if (provider.isITGMember && provider.isDomestic) {
    return <span className="fa fa-circle" style={{ color: '#8DD920' }} title={'ITG US'} />;
  } else if (provider.isITGMember && !provider.isDomestic) {
    return <span className="fa fa-circle" style={{ color: '#349FFA' }} title={'ITG Non-US'} />;
  } else if (!provider.isITGMember && provider.isDomestic) {
    return <span className="fa fa-circle" style={{ color: '#C6C9D0' }} title={'Non-ITG US'} />;
  } else {
    return <span className="fa fa-circle" style={{ color: '#797E8C' }} title={'Non-ITG Non-US'} />;
  }
};

export const signedStirShakenOptions = [
  { label: 'Yes, I have the SHAKEN header', value: 'Y' },
  { label: 'Yes, I have the underlying information but not the SHAKEN header', value: 'M' },
  { label: 'Call not signed', value: 'N' },
  { label: 'Unsure / data unavailable', value: 'U' }
];

export const clearStirShakenValues = (array: any) => {
  let tempObj = {};
  array.forEach((item: any) => {
    switch (item) {
      case StirShaken.toggleStirShaken:
        tempObj = {
          ...tempObj,
          toggleStirShaken: false
        };
        break;
      case StirShaken.stirShakenToken:
        tempObj = {
          ...tempObj,
          stirShakenToken: ''
        };
        break;
      case StirShaken.signedStirShaken:
        tempObj = {
          ...tempObj,
          signedStirShaken: null
        };
        break;
      case StirShaken.attestationRadio:
        tempObj = {
          ...tempObj,
          attestationRadio: ''
        };
        break;
      case StirShaken.destinationNumber:
        tempObj = {
          ...tempObj,
          destinationNumber: ''
        };
        break;
      case StirShaken.callingNumber:
        tempObj = {
          ...tempObj,
          callingNumber: ''
        };
        break;
      case StirShaken.timeStamp:
        tempObj = {
          ...tempObj,
          timeStamp: ''
        };
        break;
      case StirShaken.originationIdentifier:
        tempObj = {
          ...tempObj,
          originationIdentifier: ''
        };
        break;
      case StirShaken.isCallSigner:
        tempObj = {
          ...tempObj,
          isCallSigner: false
        };
        break;
    }
  });
  return tempObj;
};

const headerVerify = (header: string): boolean => {
  const prefixes = [':', 'Identity:', 'Signed:Identity:', 'dentity:', 'Andsigned:Identity:'];
  if (prefixes.some((prefix: string) => header.startsWith(prefix))) return false;
  const jwtTokenSplitted = header.split(';')[0].split('.');
  return (
    jwtTokenSplitted.length === 3 &&
    jwtTokenSplitted.slice(0, -1).every((str: string) => isBase64Regex.test(str))
  );
};

export const validateStirShakenInputFields = (
  errorObj: StirShakenModalErrors,
  stirShakenObj: StirShakenInfo
): StirShakenModalErrors | null => {
  let errors = { ...errorObj };
  if (!stirShakenObj.signedStirShaken) {
    errors = {
      ...errors,
      signedStirShaken: true
    };
  } else if (stirShakenObj.signedStirShaken.value === 'Y') {
    errors = {
      ...errors,
      stirShakenToken:
        !stirShakenObj.stirShakenToken || !headerVerify(stirShakenObj.stirShakenToken)
    };
  } else if (stirShakenObj.signedStirShaken.value === 'M') {
    if (
      !stirShakenObj.callingNumber &&
      !stirShakenObj.destinationNumber &&
      !stirShakenObj.timeStamp
    ) {
      // if the provider doesn't have any information regarding the stirShaken but the atestation
      errors = {
        ...errors,
        callingNumber: false,
        destinationNumber: false,
        timeStamp: false,
        attestation: !stirShakenObj.attestationRadio
      };
    } else {
      errors = {
        ...errors,
        callingNumber: !stirShakenObj.callingNumber,
        destinationNumber: !stirShakenObj.destinationNumber,
        timeStamp: !isValidDateTime(stirShakenObj.timeStamp.trim()),
        attestation: false
      };
    }
  }

  if (
    errors.signedStirShaken ||
    errors.stirShakenToken ||
    errors.callingNumber ||
    errors.destinationNumber ||
    errors.timeStamp ||
    errors.attestation
  ) {
    return errors;
  } else {
    return null;
  }
};

export const gerenerateStirShakenRequestObj = (
  stirShakenInfo: StirShakenInfo
): StirShakenRequest | undefined => {
  if (stirShakenInfo.signedStirShaken && stirShakenInfo.signedStirShaken.value === 'Y') {
    return {
      token: stirShakenInfo.stirShakenToken,
      isCallSigner: stirShakenInfo.isCallSigner
    };
  } else if (stirShakenInfo.signedStirShaken && stirShakenInfo.signedStirShaken.value === 'M') {
    return {
      attest: stirShakenInfo.attestationRadio,
      dest: stirShakenInfo.destinationNumber,
      iat: getApiFormattedDate(stirShakenInfo.timeStamp.trim()) || undefined,
      orig: stirShakenInfo.callingNumber,
      origid: stirShakenInfo.originationIdentifier,
      isCallSigner: stirShakenInfo.isCallSigner
    };
  } else if (stirShakenInfo.signedStirShaken && stirShakenInfo.signedStirShaken.value === 'N') {
    return {
      notSigned: true,
      isCallSigner: stirShakenInfo.isCallSigner
    };
  }
};

export const getStirShakenExplanation = (status: StirShakenStatus) =>
  StirShakenStatus.StirShakenSigned === status
    ? 'Stir Shaken Signed'
    : StirShakenStatus.StirShakenManual === status
      ? 'Stir Shaken Manual'
      : StirShakenStatus.StirShakenNotSigned === status
        ? 'Stir Shaken Not Signed'
        : StirShakenStatus.StirShakenMissing === status
          ? 'Stir Shaken Missing'
          : StirShakenStatus.StirShakenNotValid === status
            ? 'Stir Shaken Not Valid'
            : StirShakenStatus.NoStirShaken === status
              ? 'No Stir Shaken'
              : 'No Info Available';

export const getMessageFilterInfo = (filters: any) => {
  if (!filters) {
    return {};
  }

  let filterableArray = [];
  if (!Array.isArray(filters.conditionals)) {
    filterableArray.push(filters);
  } else {
    filterableArray = filters.conditionals;
  }
  let isITG = undefined;
  let isUS = undefined;
  let startDate = undefined;
  let isDno = undefined;
  let isGov = undefined;

  filterableArray.forEach(
    (condition: { conditionals: any; name: any; value: any; label?: string }) => {
      const { conditionals, name, value } = condition;
      switch (name) {
        case 'isDno':
          isDno = value;
          break;
        case 'isGov':
          isGov = value;
          break;
        case 'isITG':
          isITG = value;
          break;
        case 'isUS':
          isUS = true;
          break;
        case 'isNotUS':
          isUS = false;
          break;
        case 'startDate':
          startDate = value;
          break;
        case undefined:
          if (Array.isArray(conditionals)) {
            conditionals.forEach((condition: { name: string; value: string }) => {
              switch (condition.name) {
                case 'isDno':
                  isDno = value;
                  break;
                case 'isGov':
                  isGov = value;
                  break;
                case 'isITG':
                  isITG = value;
                  break;
                case 'isUS':
                  isUS = true;
                  break;
                case 'isNotUS':
                  isUS = false;
                  break;
                case 'startDate':
                  startDate = value;
                  break;
              }
            });
          }
          break;
      }
    }
  );
  return { isITG, isUS, startDate, isDno, isGov };
};

export const trimPrefix = (s: string, prefix: string): string =>
  s.startsWith(prefix) ? s.substring(prefix.length) : s;

export const downloadFile = (data: any, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};
const uniqSelectOption = (array: (string | undefined)[]): SelectOption[] =>
  Array.from(
    new Set<string>(
      array.map((item: string | undefined) => item || '').filter((item: string) => item.length != 0)
    )
  ).map((item: string): SelectOption => ({ value: item, label: item }));

export const generateValueLabelArray = (array: Hop[], key: string) =>
  key === 'termState'
    ? uniqSelectOption(array.map((item: Hop) => item.tracebackTermState))
    : key === 'notes'
      ? uniqSelectOption(array.map((item: Hop): string[] => item.notes).flat())
      : [];

export const stirshakenErrors = (error: string, userType: number, display: boolean) => {
  switch (true) {
    case error ===
      'token is malformed: token is malformed:token contains an invalid number of segments':
      return {
        explanation:
          "the stir-shaken doesn't have the required number of segments(header,payload, and signature)",
        valid: userType === userTypes.Admin && display
      };
    case error ===
      'token is unverifiable: invalid key: Key must be a PEM encoded PKCS1 or PKCS8 key':
      return { explanation: 'the certificate is not a valid stir-shaken key', valid: true };
    case error === 'token signature is invalid: crypto/ecdsa: verification error':
      return { explanation: 'a verification error for the signature', valid: true };
    case error.startsWith('headers do not match'):
      return {
        explanation:
          "the information from the header segment doesn't match the one from the idents",
        valid: userType === userTypes.Admin && display
      };
    case error.startsWith('token is malformed: illegal base64 data at input byte'):
      return {
        explanation: 'the stir-shaken header contains characters that are not base64 complaint',
        valid: userType === userTypes.Admin && display
      };
    case error.startsWith('token signature is invalid: illegal base64 data at input byte'):
      return {
        explanation:
          'the stir-shaken header contains characters that are not base64 complaint(more specificaly the signature)',
        valid: true
      };
    case error.startsWith('token is malformed: invalid character') &&
      (error.endsWith('looking for beginning of value') || error.endsWith('in string literal')):
      return {
        explanation:
          'the header/payload segment is not a valid json becauseof the mentioned character',
        valid: true
      };
    case error.startsWith('token is unverifiable: Get'):
      return { explanation: 'can not download the certificate', valid: true };
    default:
      return { explanation: '', valid: false };
  }
};

export const certificateExpiredError = 'x509: certificate has expired or is not yet valid:';

export const getDuration = (date: string) => {
  if (differenceInDays(Date.parse(date), Date.now())) {
    return getClientFormattedDate(date, DateFormat.ShortDate);
  } else {
    const textDistance = formatDistanceToNow(Date.parse(date));
    switch (true) {
      case textDistance === 'less than a minute':
        return 'a few seconds ago';
      case textDistance === '1 minute':
        return 'a minute ago';
      case textDistance === 'about 1 hour':
        return 'an hour ago';
      case textDistance.includes('minutes'):
        return textDistance + ' ago';
      case textDistance.includes('about'):
        return textDistance.replace('about ', '') + ' ago';
      default:
        return '';
    }
  }
};

export const convertUtcFromTimezone = (date: string, timezone: string) => {
  let localZone;
  try {
    localZone = parseFromTimeZone(date, { timeZone: timezone });
    return getApiFormattedDate(localZone.toISOString());
  } catch (error: any) {
    return getApiFormattedDate(date);
  }
};

export const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
};

export const getTimeFromString = (s: string) => {
  const split = s.split(':');
  return {
    seconds: split.length >= 3 ? Number(split[2]) : 0,
    minutes: split.length >= 2 ? Number(split[1]) : 0,
    hours: split.length >= 1 ? Number(split[0]) : 0
  };
};
export const isNotReservedIPv4 = (ip: string): boolean => {
  if (!IPRegex.test(ip)) {
    return true; // Not a valid IP address
  }

  if (ip.split('.').length === 1 || ip === '0.0.0.0') {
    return true;
  }
  // Reserved IP address ranges
  const reservedRanges: [bigint, bigint][] = [
    [BigInt(0x00000000), BigInt(0xff000000)], // Network address
    [BigInt(0x0a000000), BigInt(0xff000000)], // Private IP address
    [BigInt(0x7f000000), BigInt(0xff000000)], // Loopback
    [BigInt(0xa9fe0000), BigInt(0xffff0000)], // Link-local
    [BigInt(0xac100000), BigInt(0xfff00000)], // Private IP address
    [BigInt(0xc0000000), BigInt(0xffffff00)], // Reserved
    [BigInt(0xc0000200), BigInt(0xffffff00)], // Test-Net
    [BigInt(0xc0586300), BigInt(0xffffff00)], // 6to4 Relay Anycast
    [BigInt(0xc0a80000), BigInt(0xffff0000)], // Private IP address
    [BigInt(0xc6120000), BigInt(0xfffe0000)], // Benchmarking
    [BigInt(0xc6336400), BigInt(0xffffff00)], // Test-Net
    [BigInt(0xcb007100), BigInt(0xffffff00)], // Test-Net
    [BigInt(0xe0000000), BigInt(0xf0000000)], // Multicast
    [BigInt(0xf0000000), BigInt(0xf0000000)], // Reserved
    [BigInt(0xffffffff), BigInt(0xffffffff)] // Broadcast
  ];

  // Convert IP address octets to BigInt
  const octets: bigint[] = ip.split('.').map((octet) => BigInt(parseInt(octet, 10)));

  // Combine octets into a single BigInt IP address
  const ipBigInt: bigint =
    (octets[0] << BigInt(24)) + (octets[1] << BigInt(16)) + (octets[2] << BigInt(8)) + octets[3];

  // Check if IP falls within any reserved range
  for (const [rangeStart, subnetMask] of reservedRanges) {
    if ((ipBigInt & subnetMask) === rangeStart) {
      return false; // Reserved IP address
    }
  }

  return true; // Not a reserved IP address
};

export const processTracebackError = (tracebackError: string) =>
  tracebackError.startsWith('failedToFindProviderByOCN: Unexpected OCN length')
    ? tracebackError.substring('failedToFindProviderByOCN: '.length)
    : tracebackError.startsWith('failedToFindProviderByOCN')
      ? `Provider for OCN[${tracebackError.substring('failedToFindProviderByOCN: OcnDoesNotExistError['.length).split(']:')[0]}] not found`
      : tracebackError.startsWith('failedToFindProviderByNnid')
        ? `Provider for nnid[${tracebackError.substring('failedToFindProviderByNnid: '.length)}] not found`
        : tracebackError;

export const checkDupicatesBetweenSelected = <
  T extends {
    id: number;
    incidentCampaignId: number;
    originatorPhoneNumber: string;
    destinationPhoneNumber: string;
    callTime: string;
  }
>(
  incidents: T[]
): string[] => {
  const incidentMap = incidents.reduce(
    (acc, incident) => {
      const key = `${incident.incidentCampaignId}-${incident.originatorPhoneNumber}-${incident.destinationPhoneNumber}-${incident.callTime}`;

      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(incident);

      return acc;
    },
    {} as { [key: string]: T[] }
  );

  return Object.values(incidentMap)
    .filter((group) => group.length > 1)
    .map((group) => group.map((incident) => `#${incident.id}`).join(', '));
};
