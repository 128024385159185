import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { CardTitle } from 'reactstrap';
import GovProviderDetail from '../components/GovProviderDetails';
import { userTypes } from '../enum/userTypes';
import { Provider, ProviderIpInfo, Summary } from '../interfaces/provider';
import { PersonalInfo } from '../interfaces/user';
import {
  addCondition,
  addConditionGroup,
  newConditionalFilterElement,
  newLeafFilterElement,
  simplifyFilterElement
} from '../lib/FilterElement';
import { providerSummaryColumns } from '../lib/dataTableUtils/providerSummaryColumns';
import {
  getProviderObject,
  getProviderSummaryObject,
  getProvidersIpInfo
} from '../redux/provider/thunks';
import { stateMappings } from '../redux/stateMappings';
import CustomDataTable from './CustomDataTable';
import ProviderSummaryHopsRow from './ProviderSummaryHopsRow';

interface IProps {
  getProviderObject: Function;
  getProviderSummaryObject: Function;
  provider: Provider;
  providerId: number;
  summaryLow: Summary;
  summaryHigh: Summary;
  summaryInt: Summary;
  startDate: string | undefined;
  endDate: string | undefined;
  user: PersonalInfo;
  labelId?: number;
  termState?: string | null;
  note?: string | null;
  setHighVolumeNotesList?: Function;
  setHighVolumeTermStateList?: Function;
  setLowVolumeNotesList?: Function;
  setLowVolumeTermStateList?: Function;
  ipInfos: ProviderIpInfo[];
  getProvidersIpInfo: Function;
}
const getFilter = ({
  isInternational,
  isLowVolume,
  endDate,
  startDate,
  labelId
}: {
  isInternational: boolean;
  isLowVolume: boolean;
  startDate: string | undefined;
  endDate: string | undefined;
  labelId?: number;
}) => {
  let filterElements = newConditionalFilterElement('AND');
  if (startDate || endDate) {
    addConditionGroup('AND', filterElements, [
      { key: startDate ? 'startDate' : '', value: startDate },
      { key: endDate ? 'endDate' : '', value: endDate }
    ]);
  }
  if (isInternational)
    addCondition(filterElements, newLeafFilterElement('isInternational', 'EQ', '1'));
  else
    addCondition(
      filterElements,
      newLeafFilterElement('isLowVolume', 'EQ', isLowVolume ? '1' : '0')
    );
  if (labelId) {
    if (Array.isArray(labelId)) {
      if (labelId.length > 1)
        addCondition(filterElements, newLeafFilterElement('labelId', 'IN', labelId.join(',')));
      else if (labelId.length === 1 && labelId[0])
        addCondition(filterElements, newLeafFilterElement('labelId', 'EQ', labelId[0].toString()));
    } else addCondition(filterElements, newLeafFilterElement('labelId', 'EQ', labelId.toString()));
  }
  return simplifyFilterElement(filterElements);
};

const ProviderSummaryPanel: React.FC<IProps> = ({
  getProviderObject,
  getProviderSummaryObject,
  provider,
  providerId,
  summaryLow,
  summaryHigh,
  summaryInt,
  startDate,
  endDate,
  user,
  labelId,
  termState,
  note,
  setHighVolumeNotesList,
  setHighVolumeTermStateList,
  setLowVolumeNotesList,
  setLowVolumeTermStateList,
  ipInfos,
  getProvidersIpInfo
}) => {
  useEffect(() => {
    getProviderObject(providerId);
  }, [providerId]);

  useEffect(() => {
    getProviderSummaryObject(
      getFilter({ endDate, isInternational: false, isLowVolume: false, startDate, labelId }),
      providerId
    );
    getProviderSummaryObject(
      getFilter({ endDate, isInternational: false, isLowVolume: true, startDate, labelId }),
      providerId
    );
    if (user.roleType !== userTypes.GovUser)
      getProviderSummaryObject(
        getFilter({ endDate, isInternational: true, isLowVolume: false, startDate, labelId }),
        providerId
      );
    if (user.roleType === userTypes.Admin) getProvidersIpInfo({ startDate, endDate, providerId });
  }, [startDate, endDate, labelId, providerId]);

  return (
    <Fragment>
      {user.roleType === userTypes.Admin && ipInfos && ipInfos.length !== 0 && (
        <div className="mb-2">
          <CardTitle tag="h5">
            Provider Stated Location: {provider.state}, {provider.country}
          </CardTitle>
          {ipInfos.map((ip: ProviderIpInfo) => (
            <div>
              Accessed portal with IP Address <span className="text-primary">{ip.query}</span>;
              located in {ip.regionName}, {ip.country}
            </div>
          ))}
        </div>
      )}
      <CustomDataTable
        columns={providerSummaryColumns(user.roleType)}
        defaultSortAsc={true}
        defaultSortFieldId={'Provider'}
        tableData={[{ provider: provider, summary: summaryHigh }]}
        useExpandableRows={user.roleType === userTypes.GovUser}
        useExpandableRowsComponent={() => <GovProviderDetail provider={provider} />}
      />
      <ProviderSummaryHopsRow
        data={{ provider: provider, summary: summaryHigh }}
        includeLinks={user.roleType === userTypes.Admin}
        startDate={startDate}
        endDate={endDate}
        labelId={labelId}
        termState={termState}
        note={note}
        setNotesList={setHighVolumeNotesList}
        setTermStateList={setHighVolumeTermStateList}
      />
      <div className="me-2">
        <h3>Low Volume Calls</h3>
      </div>
      <div>
        <h6 style={{ color: '#07335e' }} className="me-2">
          Targeted calls may include but not be limited to: Vishing, Abusive, TDOS, and other
          fraudulent calls with intent to harm
        </h6>
      </div>
      <CustomDataTable
        columns={providerSummaryColumns(user.roleType)}
        defaultSortAsc={true}
        defaultSortFieldId={'Provider'}
        tableData={[{ provider: provider, summary: summaryLow }]}
        useExpandableRows={user.roleType === userTypes.GovUser}
        useExpandableRowsComponent={() => <GovProviderDetail provider={provider} />}
      />
      <ProviderSummaryHopsRow
        data={{ provider: provider, summary: summaryLow }}
        includeLinks={user.roleType === userTypes.Admin}
        startDate={startDate}
        endDate={endDate}
        lowVolume
        labelId={labelId}
        termState={termState}
        note={note}
        setNotesList={setLowVolumeNotesList}
        setTermStateList={setLowVolumeTermStateList}
      />

      {summaryInt &&
        Object.values(summaryInt).length &&
        summaryInt.numOfDownstreamNoResponseHops +
          summaryInt.numOfDownstreamOriginHops +
          summaryInt.numOfOriginHops +
          summaryInt.numOfNoResponseHops +
          summaryInt.numOfNotFoundHops >
          0 && (
          <Fragment>
            <div className="me-2">
              <h3>International Tracebacks</h3>
            </div>
            <div>
              <h6 style={{ color: '#07335e' }} className="me-2">
                Calls may originate from or terminate at an non US number
              </h6>
            </div>
            <CustomDataTable
              columns={providerSummaryColumns(user.roleType, true)}
              defaultSortAsc={true}
              defaultSortFieldId={'Provider'}
              tableData={[{ provider: provider, summary: summaryInt }]}
              useExpandableRows={user.roleType === userTypes.GovUser}
              useExpandableRowsComponent={() => <GovProviderDetail provider={provider} />}
            />
            <ProviderSummaryHopsRow
              data={{ provider: provider, summary: summaryInt }}
              includeLinks={user.roleType === userTypes.Admin}
              startDate={startDate}
              endDate={endDate}
              isInternational
              labelId={labelId}
              termState={termState}
              note={note}
              setNotesList={setLowVolumeNotesList}
              setTermStateList={setLowVolumeTermStateList}
            />
          </Fragment>
        )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    user: sm.user,
    provider: sm.provider.provider,
    summaryHigh: sm.provider.summaryHigh,
    summaryLow: sm.provider.summaryLow,
    summaryInt: sm.provider.summaryInternational,
    ipInfos: sm.provider.providersIpInfo
  };
};

const mapActionsToProps = {
  getProviderObject,
  getProviderSummaryObject,
  getProvidersIpInfo
};

export default connect(mapStateToProps, mapActionsToProps)(ProviderSummaryPanel);
