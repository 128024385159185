import React, { FC, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { CardBody, Row } from 'reactstrap';
import { userTypes } from '../enum/userTypes';
import { Hop } from '../interfaces/hop';
import { ProviderSummaryRow } from '../interfaces/provider';
import { PersonalInfo } from '../interfaces/user';
import { govExpandedRowColumns } from '../lib/dataTableUtils/govExpandedRowColumns';
import { expandedRowColumns } from '../lib/dataTableUtils/providerSummaryHopColumns';
import { generateValueLabelArray } from '../lib/utilities';
import { getReputationHopsList } from '../redux/reputation/thunks';
import { stateMappings } from '../redux/stateMappings';

interface Props {
  data?: ProviderSummaryRow;
  interestingHopsHigh: Hop[];
  interestingHopsLow: Hop[];
  interestingHopsInt: Hop[];
  includeLinks?: boolean;
  startDate: string | undefined;
  endDate: string | undefined;
  termState?: string | null;
  lowVolume?: boolean;
  isInternational?: boolean;
  labelId?: number;
  note?: string | null;
  setNotesList?: Function;
  setTermStateList?: Function;
  getReputationHopsList: Function;
  user: PersonalInfo;
}

const ProviderSummaryHopsRow: FC<Props> = ({
  includeLinks,
  data,
  startDate,
  endDate,
  termState,
  lowVolume,
  isInternational,
  labelId,
  note,
  setNotesList,
  setTermStateList,
  user,
  getReputationHopsList,
  interestingHopsHigh,
  interestingHopsLow,
  interestingHopsInt
}) => {
  const [negativeTracebacksImpact, setNegativeTracebacksImpact] = useState<number>(0);
  const { id } = useParams();
  const providerId = Number(id || 0);
  const tableData = useMemo(
    () =>
      isInternational ? interestingHopsInt : lowVolume ? interestingHopsLow : interestingHopsHigh,
    [interestingHopsHigh, interestingHopsLow, interestingHopsInt, lowVolume, isInternational]
  );

  useEffect(() => {
    if (!startDate || !endDate) return;
    getReputationHopsList({
      startDate,
      endDate,
      providerId: providerId,
      termState: termState || undefined,
      labelId: labelId,
      notes: note || undefined,
      lowVolume,
      isInternational
    });
  }, [termState, labelId, note, startDate, endDate, providerId, isInternational]);

  useEffect(() => {
    if (!note && !termState && !labelId) {
      if (setNotesList && setTermStateList) {
        setNotesList(generateValueLabelArray(tableData, 'notes'));
        setTermStateList(generateValueLabelArray(tableData, 'termState'));
      }
    }

    setNegativeTracebacksImpact(
      tableData.length
        ? tableData.length
        : data && data.summary && Object.values(data.summary).length
          ? (isInternational ? 0 : data.summary.numOfUSPoEHops) +
            data.summary.numOfDownstreamNoResponseHops +
            data.summary.numOfDownstreamOriginHops +
            data.summary.numOfOriginHops +
            data.summary.numOfNoResponseHops +
            data.summary.numOfNotFoundHops
          : 0
    );
  }, [tableData, data]);

  return (
    <div className="data-table">
      <CardBody
        style={{
          color: '#797979',
          fontSize: '1rem',
          padding: '0.5rem 0'
        }}
      >
        {user.roleType !== userTypes.GovUser &&
          (lowVolume || isInternational ? (
            <div className="d-flex">
              <h5 style={{ color: '#07335e', paddingRight: '10px' }}>
                <span
                  style={{
                    color: '#47996f',
                    fontWeight: 800
                  }}
                >
                  {negativeTracebacksImpact}
                </span>{' '}
                {`${negativeTracebacksImpact !== 1 ? 'tracebacks' : 'traceback'}`} in the selected
                period
              </h5>
            </div>
          ) : (
            <Row className="justify-content-between m-0">
              <div className="d-flex">
                <h5 style={{ color: '#07335e', paddingRight: '10px' }}>
                  In the selected period,{' '}
                  <span
                    style={{
                      color: '#47996f',
                      fontWeight: 800
                    }}
                  >
                    {negativeTracebacksImpact}
                  </span>{' '}
                  {`${negativeTracebacksImpact !== 1 ? 'tracebacks' : 'traceback'}`} may contribute
                  to status and score
                </h5>
              </div>
            </Row>
          ))}

        <DataTable
          data={tableData}
          columns={
            user.roleType === userTypes.GovUser
              ? govExpandedRowColumns
              : expandedRowColumns(!!includeLinks)
          }
          defaultSortFieldId={'campaignName'}
          defaultSortAsc={false}
          noHeader
          className={'providers-expanded'}
        />
      </CardBody>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    user: sm.user,
    interestingHopsHigh: sm.reputation.highHops,
    interestingHopsLow: sm.reputation.lowHops,
    interestingHopsInt: sm.reputation.internationalHops
  };
};
const mapActionsToProps = {
  getReputationHopsList
};
export default connect(mapStateToProps, mapActionsToProps)(ProviderSummaryHopsRow);
