import React, { Fragment } from 'react';
import ReputationDetails from './stateConverter';

import Select from 'react-select';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import DropZone from './DropZone';
import ProviderRmdInfo from './ProviderRmdInfo';
import InputError from './inputError';
import InputFormGroup from './inputFormGroup';

import { DateFormat } from '../enum/DateFormat';
import { ViewMode } from '../enum/ViewMode';
import { Option } from '../interfaces/campaign';
import { MemberType } from '../interfaces/memberType';
import { Provider } from '../interfaces/provider';
import { RMDEntry } from '../interfaces/rmd';
import { Error } from './providerEditor';

import { getClientFormattedDate } from '../lib/utilities';

interface IProps {
  provider: Provider;
  comment: string;
  handleProviderTypeChange: (option: any) => void;
  handleInputChange: (e: React.FormEvent<HTMLInputElement>) => void;
  handleAddOCN: () => void;
  handleAddTenXMapping: () => void;
  deleteOCN: (OCN: any) => void;
  deleteTenXMapping: (nnid: string) => void;
  handleAddRMD: (option: any) => void;
  deleteRMD: (rmd: RMDEntry) => void;
  handleITGMemberChange: (option: any) => void;
  handleDnoAcces: (option: any) => void;
  handleRejectDispute: (option: any) => void;
  handleActive: (option: any) => void;
  handleTBEmailFormatChange: (option: any) => void;
  removeFile: (file: any) => void;
  addFile: (files: any[]) => void;
  providerTypes: Option[];
  TBEmailFormats: Option[];
  memberTypes: MemberType[];
  attachments: any[];
  isProviderTypeDetail: boolean;
  viewMode?: ViewMode;
  isAdd?: boolean;
  error: Error;
}

const ProviderEditorForUserAdmin: React.FC<IProps> = ({
  provider,
  viewMode,
  providerTypes,
  comment,
  handleProviderTypeChange,
  handleInputChange,
  deleteOCN,
  handleAddOCN,
  handleAddRMD,
  deleteRMD,
  deleteTenXMapping,
  handleAddTenXMapping,
  handleITGMemberChange,
  handleDnoAcces,
  handleRejectDispute,
  handleActive,
  handleTBEmailFormatChange,
  removeFile,
  addFile,
  isProviderTypeDetail,
  TBEmailFormats,
  attachments,
  memberTypes,
  isAdd,
  error
}) => {
  return (
    <Row>
      <Col md="6">
        <h5 className="p-0 traceback-sub-title">Provider details</h5>
        {provider.reputation && (
          <Fragment>
            <Label className="telecom-label">Status</Label>
            <p className="telecom-text mb-5" style={{ padding: '5px 8px' }}>
              {provider.reputation ? (
                <Fragment>
                  <span>
                    <ReputationDetails reputation={provider.reputation} />
                  </span>
                </Fragment>
              ) : (
                'None'
              )}
            </p>
          </Fragment>
        )}
        <Label className="telecom-label">
          Provider Type
          {viewMode !== ViewMode.VIEW && <i className="fa fa-asterisk asterisk" />}
        </Label>
        {viewMode !== ViewMode.VIEW && (
          <Select
            className={`customselect-large col-md-10 ps-0 pe-0 ${
              error.providerType ? 'traceback-select-error' : ''
            }`}
            classNamePrefix="customselect"
            value={providerTypes.find((type) => type.value === provider.providerType)}
            onChange={handleProviderTypeChange}
            placeholder=""
            isSearchable={false}
            options={providerTypes}
          />
        )}
        {viewMode === ViewMode.VIEW && (
          <p className="telecom-text mb-0" style={{ padding: '5px 8px' }}>
            {
              (
                providerTypes.find((type) => type.value === provider.providerType) || {
                  label: 'None'
                }
              ).label
            }
          </p>
        )}
        {isProviderTypeDetail ? (
          viewMode === ViewMode.VIEW ? (
            <p
              className="telecom-text mb-0"
              style={{
                padding: '5px 8px 16px 8px'
              }}
            >
              <a
                href={`https://apps.fcc.gov/cgb/form499/499detail.cfm?FilerNum=${provider.providerTypeDetail}`}
                target="_blank"
              >
                FCC 499 Filer {provider.providerTypeDetail}
              </a>
            </p>
          ) : (
            <InputFormGroup
              inputName="providerTypeDetail"
              inputId="provider-type-detail"
              inputClassName="input-provider col-md-10 p-0 mt-3"
              inputValue={provider.providerTypeDetail}
              inputOnChange={handleInputChange}
              inputPlaceholder=""
              inputAutoComplete="off"
              errorMessage={error.providerTypeDetail}
            />
          )
        ) : (
          <InputError className="telecom-input-error">{error.providerType}</InputError>
        )}
        {viewMode === ViewMode.VIEW && provider.providerTaxId ? (
          <Fragment>
            <Label className="telecom-label">Tax ID</Label>
            <p
              className="telecom-text mb-3"
              style={{
                padding: '5px 8px'
              }}
            >
              {provider.providerTaxId}
            </p>
          </Fragment>
        ) : (
          viewMode !== ViewMode.VIEW && (
            <Fragment>
              <Label className="telecom-label">Tax ID</Label>
              <InputFormGroup
                inputName="providerTaxId"
                inputId="provider-tax-id"
                inputClassName="input-provider col-md-10 p-0 mt-1"
                inputValue={provider.providerTaxId}
                inputOnChange={handleInputChange}
                inputPlaceholder=""
                inputAutoComplete="off"
                errorMessage={error.providerTaxId}
              />
            </Fragment>
          )
        )}
        {!isAdd && (
          <Fragment>
            <tr>
              <Label className="telecom-label">OCNs</Label>
            </tr>
            <div className={'ocn-container'}>
              {provider.OCNs && provider.OCNs.length > 0 ? (
                <Fragment>
                  {provider.OCNs.map((OCN: any) => (
                    <div className="flex-row" key={OCN.OCN}>
                      <span className="px-2">{OCN.OCN}</span>
                      {viewMode === ViewMode.EDIT && (
                        <i
                          className="fa fa-close"
                          style={{ color: 'red', cursor: 'pointer' }}
                          onClick={() => deleteOCN(OCN.OCN)}
                        />
                      )}
                    </div>
                  ))}
                </Fragment>
              ) : (
                <span className="px-2">None</span>
              )}
              <div className={'add-ocn'}>
                {viewMode === ViewMode.EDIT && (
                  <span onClick={handleAddOCN} className="telecom-text add-contact">
                    + Add OCN
                  </span>
                )}
              </div>
            </div>
          </Fragment>
        )}

        <Label className="telecom-label pt-3">DNO Access</Label>

        {viewMode !== ViewMode.VIEW ? (
          <FormGroup>
            <Select
              className={`customselect-large`}
              classNamePrefix="customselect"
              onChange={handleDnoAcces}
              value={
                provider.dnoAccess
                  ? {
                      value: true,
                      label: 'Yes'
                    }
                  : { value: false, label: 'No' }
              }
              options={memberTypes}
              isSearchable={false}
            />
          </FormGroup>
        ) : (
          <p className="telecom-text mb-0" style={{ padding: '5px 8px' }}>
            {provider.dnoAccess ? 'Yes' : 'No'}
          </p>
        )}
        <Label className="telecom-label pt-3">Automatically Reject Dispute</Label>

        {viewMode !== ViewMode.VIEW ? (
          <FormGroup>
            <Select
              className={`customselect-large`}
              classNamePrefix="customselect"
              onChange={handleRejectDispute}
              value={
                provider.rejectDispute
                  ? {
                      value: true,
                      label: 'Yes'
                    }
                  : { value: false, label: 'No' }
              }
              options={memberTypes}
              isSearchable={false}
            />
          </FormGroup>
        ) : (
          <p className="telecom-text mb-0" style={{ padding: '5px 8px' }}>
            {provider.rejectDispute ? 'Yes' : 'No'}
          </p>
        )}

        <Label className="telecom-label pt-3">Active</Label>

        {viewMode !== ViewMode.VIEW ? (
          <FormGroup>
            <Select
              className={`customselect-large`}
              classNamePrefix="customselect"
              onChange={handleActive}
              value={
                provider.active
                  ? {
                      value: true,
                      label: 'Yes'
                    }
                  : { value: false, label: 'No' }
              }
              options={memberTypes}
              isSearchable={false}
            />
          </FormGroup>
        ) : (
          <p className="telecom-text mb-0" style={{ padding: '5px 8px' }}>
            {provider.active ? 'Yes' : 'No'}
          </p>
        )}

        <ProviderRmdInfo
          viewMode={viewMode}
          rmdEntries={provider.RMDs}
          handleAddRMD={handleAddRMD}
          deleteRMD={deleteRMD}
        />

        {!isAdd && (
          <Fragment>
            <tr>
              <Label className="telecom-label">TenX International Mappings</Label>
            </tr>
            <div className={'ocn-container'}>
              {provider.tenXInternationalMapping && provider.tenXInternationalMapping.length > 0 ? (
                <Fragment>
                  {provider.tenXInternationalMapping.map((m) => (
                    <div className="flex-row" key={m.nnid}>
                      <span className="px-2">
                        {m.nn_sp_name ? m.nn_sp_name + '[' + m.nnid + ']' : m.nnid}
                      </span>
                      {viewMode === ViewMode.EDIT && (
                        <i
                          className="fa fa-close"
                          style={{ color: 'red', cursor: 'pointer' }}
                          onClick={() => deleteTenXMapping(m.nnid)}
                        />
                      )}
                    </div>
                  ))}
                </Fragment>
              ) : (
                <span className="px-2">None</span>
              )}
              <div className={'add-ocn'}>
                {viewMode === ViewMode.EDIT && (
                  <span onClick={handleAddTenXMapping} className="telecom-text add-contact">
                    + Add TenX International Mapping
                  </span>
                )}
              </div>
            </div>
          </Fragment>
        )}
      </Col>
      <Col md="6" className="pb-4 mb-5 provider-admin-board">
        <h5 className="p-0 traceback-sub-title">System Admin Only</h5>
        <Label className="telecom-label">
          ITG Member
          {viewMode !== ViewMode.VIEW && <i className="fa fa-asterisk asterisk" />}
        </Label>
        {viewMode !== ViewMode.VIEW && (
          <FormGroup>
            <Select
              className={`customselect-large ${error.isITGMember ? 'traceback-select-error' : ''}`}
              classNamePrefix="customselect"
              onChange={handleITGMemberChange}
              value={
                provider.isITGMember
                  ? {
                      value: true,
                      label: 'Yes'
                    }
                  : { value: false, label: 'No' }
              }
              options={memberTypes}
              isSearchable={false}
            />
          </FormGroup>
        )}
        {viewMode === ViewMode.VIEW && (
          <FormGroup>
            <p className="telecom-text mb-0" style={{ padding: '5px 8px' }}>
              {provider.isITGMember ? 'Yes' : 'No'}
            </p>
          </FormGroup>
        )}
        {error.isITGMember && (
          <InputError className="telecom-input-error">{error.isITGMember}</InputError>
        )}
        <Label className="telecom-label">
          Traceback Email Format
          {viewMode !== ViewMode.VIEW && <i className="fa fa-asterisk asterisk" />}
        </Label>
        {viewMode !== ViewMode.VIEW && (
          <FormGroup>
            <Select
              className={`customselect-large ${
                error.tbEmailFormat ? 'traceback-select-error' : ''
              }`}
              classNamePrefix="customselect"
              value={
                provider.isITGMember && !provider.tbEmailFormat
                  ? TBEmailFormats[0]
                  : TBEmailFormats.find((item) => item.value === provider.tbEmailFormat)
              }
              placeholder=""
              onChange={handleTBEmailFormatChange}
              options={TBEmailFormats}
              isSearchable={false}
            />
          </FormGroup>
        )}
        {viewMode === ViewMode.VIEW && (
          <FormGroup>
            <p className="telecom-text mb-0" style={{ padding: '5px 8px' }}>
              {provider.isITGMember && !provider.tbEmailFormat
                ? TBEmailFormats[0].label
                : (
                    TBEmailFormats.find((item) => item.value === provider.tbEmailFormat) || {
                      label: 'None'
                    }
                  ).label}
            </p>
          </FormGroup>
        )}
        {error.tbEmailFormat && (
          <InputError className="telecom-input-error">{error.tbEmailFormat}</InputError>
        )}
        <Fragment>
          <Label className="telecom-label">Comment</Label>
          {viewMode === ViewMode.VIEW &&
            (provider.comments && provider.comments.length > 0 ? (
              provider.comments.map((item) => {
                return (
                  <div key={item.commentId}>
                    <Row style={{ padding: '0 10px' }}>
                      <Col md={'7'}>
                        <span style={{ fontWeight: 'bold' }}>{`Date/Time: `}</span>
                        {getClientFormattedDate(item.create_date, DateFormat.MediumBoth)}
                      </Col>
                      <Col md={'5'}>
                        <span style={{ fontWeight: 'bold' }}>{`From: `}</span>
                        {item.userName}
                      </Col>
                    </Row>
                    <Row style={{ padding: '0 10px' }}>
                      <Col md="12">{item.contentText}</Col>
                    </Row>
                    <Row
                      style={{
                        padding: '0 10px',
                        marginBottom: '10px'
                      }}
                    >
                      <Col md="12">
                        <span style={{ fontWeight: 'bold' }}>{`Attachments: `}</span>
                        {item.attachments && item.attachments.length > 0 ? (
                          item.attachments.map((attachment) => (
                            <a
                              key={attachment.attachmentId}
                              style={{ fontWeight: 'bold' }}
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`/api/attachments/${attachment.attachmentId}`}
                            >
                              {attachment.fileName}
                            </a>
                          ))
                        ) : (
                          <span>None</span>
                        )}
                      </Col>
                    </Row>
                  </div>
                );
              })
            ) : (
              <p className="telecom-text mb-0" style={{ padding: '5px 8px' }}>
                None
              </p>
            ))}
          {viewMode !== ViewMode.VIEW && (
            <InputFormGroup
              isReadonly={false}
              isTextarea
              inputName="comment"
              inputId="comment"
              inputClassName="input-textarea"
              inputStyle={
                !comment
                  ? {
                      minHeight: '25px',
                      fontSize: '18px'
                    }
                  : {}
              }
              inputValue={comment}
              inputOnChange={handleInputChange}
              inputPlaceholder=""
              inputAutoComplete="off"
              errorMessage={error.comment}
            />
          )}
          <Label className="telecom-label">Attachments</Label>
          {attachments &&
            attachments.map((attachment: any, index: number) => (
              <a
                key={`provider-attached-${index}`}
                href={attachment.urlPath}
                target="_self"
                className="telecom-text d-block ps-2"
              >
                {attachment.fileName}
              </a>
            ))}
          <FormGroup>
            {viewMode !== ViewMode.VIEW && (
              <Fragment>
                <DropZone attachments={attachments} removeFile={removeFile} addFile={addFile} />
              </Fragment>
            )}

            {viewMode === ViewMode.VIEW && attachments && attachments.length === 0 && (
              <p
                className="telecom-text mb-0"
                style={{
                  padding: '5px 8px'
                }}
              >
                None
              </p>
            )}
          </FormGroup>
        </Fragment>
        {!isAdd && (
          <Fragment>
            <Label className="telecom-label">Provider No.</Label>
            <p className="telecom-text mb-0" style={{ padding: '5px 8px' }}>
              {provider.providerId}
            </p>
          </Fragment>
        )}
      </Col>
    </Row>
  );
};

export default ProviderEditorForUserAdmin;
