import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { Breadcrumb, Card, CardHeader, Nav, NavItem, Navbar, TabPane } from 'reactstrap';
import CronJobs from '../components/AdminDashboard/CronJobs';
import AddDno from '../components/AdminDashboard/Dno/AddDno';
import Dno from '../components/AdminDashboard/Dno/Dno';
import EmailEvents from '../components/AdminDashboard/Events/EmailEvents';
import IPHistory from '../components/AdminDashboard/History/IPHistory';
import Insights from '../components/AdminDashboard/Insights/Insights';
import AddMessage from '../components/AdminDashboard/Messaging/AddMessage';
import Messaging from '../components/AdminDashboard/Messaging/Messaging';
import NavIncidents from '../components/AdminDashboard/NavMenu/NavIncidents';
import NavReporting from '../components/AdminDashboard/NavMenu/NavReporting';
import NavSettings from '../components/AdminDashboard/NavMenu/NavSettings';
import StirShakenSigners from '../components/AdminDashboard/StirShakenSigners/StirShakensSigners';
import UserAdd from '../components/AdminDashboard/Users/UserAdd';
import Users from '../components/AdminDashboard/Users/Users';
import { userTypes } from '../enum/userTypes';
import { PersonalInfo } from '../interfaces/user';
import { stateMappings } from '../redux/stateMappings';

interface IProps {
  user: PersonalInfo;
}

interface NavData {
  tab: string;
  message?: string;
  iconClassName?: string;
  page: JSX.Element;
  isActive: (activeNav: string) => boolean;
}
const navTabs: NavData[] = [
  {
    tab: 'settings',
    message: 'Settings',
    iconClassName: 'fa fa-flag',
    page: <NavSettings />,
    isActive: (activeNav: string) =>
      ['settings', 'reputation-settings', 'insights-settings', 'stirshaken-settings'].some(
        (v) => v === activeNav
      )
  },
  {
    tab: 'reporting',
    page: <NavReporting />,
    message: 'Reporting',
    iconClassName: 'fa fa-bar-chart',
    isActive: (activeNav: string) =>
      !!['reporting', 'rollup-reporting', '60days-report', 'reportarchive'].find(
        (v) => v === activeNav
      )
  },
  {
    tab: 'incidents',
    page: <NavIncidents />,
    message: 'Incidents',
    iconClassName: 'fa fa-arrow-circle-o-right',
    isActive: (activeNav: string) => activeNav.startsWith('incidents')
  },
  {
    tab: 'events',
    page: <EmailEvents />,
    message: 'Sent Email',
    iconClassName: 'fa fa-paper-plane',
    isActive: (activeNav: string) => activeNav.startsWith('events')
  },
  {
    tab: 'history',
    page: <IPHistory />,
    message: 'History',
    iconClassName: 'fa fa-history',
    isActive: (activeNav: string) => activeNav === 'history'
  },
  {
    tab: 'messaging',
    page: <Messaging />,
    message: 'Messaging',
    iconClassName: 'fa fa-message',
    isActive: (activeNav: string) => activeNav === 'messaging'
  },
  {
    tab: 'messaging',
    page: <AddMessage />,
    isActive: (activeNav: string) =>
      activeNav === 'messaging/new' || activeNav.startsWith('messaging/edit')
  },
  {
    tab: 'users',
    page: <Users />,
    message: 'Users',
    iconClassName: 'fa-solid fa-user-group',
    isActive: (activeNav: string) => activeNav === 'users'
  },
  {
    tab: 'users',
    page: <UserAdd />,
    isActive: (activeNav: string) => activeNav === 'users/add'
  },
  {
    tab: 'dno',
    page: <Dno />,
    message: 'DNO',
    iconClassName: 'fa-solid fa-hashtag',
    isActive: (activeNav: string) => activeNav === 'dno'
  },
  {
    tab: 'dno',
    page: <AddDno />,
    isActive: (activeNav: string) => activeNav === 'dno/add' || activeNav.startsWith('dno/edit')
  },
  {
    tab: 'stir_shaken_signers',
    page: <StirShakenSigners />,
    message: 'Signers',
    iconClassName: 'fa fa-certificate',
    isActive: (activeNav: string) => activeNav === 'stir_shaken_signers'
  },
  {
    tab: 'insights',
    page: <Insights />,
    message: 'Insights',
    iconClassName: 'fa fa-line-chart',
    isActive: (activeNav: string) => activeNav === 'insights'
  },
  {
    tab: 'cronjobs',
    page: <CronJobs />,
    message: 'CronJobs',
    iconClassName: 'fa fa-tasks',
    isActive: (activeNav: string) => activeNav === 'cronjobs'
  }
];

const reportingTab = navTabs[1]; //change the index if a new tab is added before reporting

const AdminDashboard: React.FC<IProps> = ({ user }) => {
  const { hash } = useLocation();
  const [activeNavTab, seAactiveNavTab] = useState(
    navTabs.find((v) => v.isActive(hash.slice(1))) || reportingTab
  );

  useEffect(() => {
    seAactiveNavTab(navTabs.find((v) => v.isActive(hash.slice(1))) || reportingTab);
  }, [hash]);

  return user.roleType === userTypes.Admin ? (
    <Fragment>
      <Breadcrumb className="table-breadcrumbs" />
      <Card className="table-card-short">
        <CardHeader className="table-card-header ps-3">
          <span className="traceback-label-invert mb-0" style={{ fontSize: '36px' }}>
            Admin Dashboard
          </span>
        </CardHeader>
        <Navbar className="navbar-expand-xl bg-dark shadow-sm">
          <Nav className="m-auto">
            {navTabs.map(
              (navTab) =>
                !!navTab.message && (
                  <NavItem key={`navItm-${navTab.tab}`}>
                    <NavLink
                      className="nav-link d-flex flex-column"
                      to={`#${navTab.tab}`}
                      onClick={() => seAactiveNavTab(navTab)}
                    >
                      <i
                        className={`${navTab.iconClassName} text-center`}
                        style={{
                          color: activeNavTab.tab == navTab.tab ? 'white' : 'gray',
                          fontSize: '20px'
                        }}
                      />
                      <span
                        className={'d-none d-sm-inline'}
                        style={{ color: activeNavTab.tab === navTab.tab ? 'white' : 'gray' }}
                      >
                        {navTab.message}
                      </span>
                    </NavLink>
                  </NavItem>
                )
            )}
          </Nav>
        </Navbar>
      </Card>
      <Card className="table-card">
        <TabPane>{activeNavTab.page}</TabPane>
      </Card>
    </Fragment>
  ) : (
    <Fragment></Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return { user: sm.user };
};

export default connect(mapStateToProps)(AdminDashboard);
