import React, { CSSProperties, FormEventHandler, Fragment } from 'react';
import { FormGroup, Input, InputGroup, InputGroupText } from 'reactstrap';
import { validUrlRegex } from '../lib/regex';
import InputError from './inputError';

interface IProps {
  containerClassName?: string;
  style?: CSSProperties;
  isPassword?: boolean;
  isTextarea?: boolean;
  isReadonly?: boolean;
  inputName?: string;
  inputId?: string;
  inputClassName?: string;
  inputStyle?: any;
  inputPlaceholder?: string;
  inputValue?: string;
  inputOnChange?: FormEventHandler<HTMLInputElement>;
  inputAutoComplete?: string;
  isAppend?: boolean;
  inputIcon?: string;
  inputErrorClassName?: string;
  inputErrorStyle?: any;
  errorMessage?: any;
  invalid?: boolean;
  valid?: boolean;
  disabled?: boolean;
  label?: string | React.ReactNode;
  isRequired?: boolean;
}

const InputFormGroup: React.FC<IProps> = ({
  containerClassName,
  style,
  isPassword,
  isTextarea,
  isReadonly,
  inputName,
  inputId,
  inputClassName,
  inputStyle,
  inputPlaceholder,
  inputValue,
  inputOnChange,
  inputAutoComplete,
  isAppend,
  inputIcon,
  inputErrorClassName,
  inputErrorStyle,
  errorMessage,
  invalid,
  valid,
  disabled,
  label,
  isRequired
}) => {
  return (
    <Fragment>
      <FormGroup className={containerClassName} style={style}>
        {label && (
          <label className="telecom-label">
            {label}
            {isRequired && <i className="fa fa-asterisk asterisk" />}
          </label>
        )}
        <InputGroup>
          {inputIcon && !isAppend && (
            <InputGroupText>
              <i
                className={`fa fa-${inputIcon}`}
                style={{ color: !errorMessage ? '#06335d' : 'red' }}
              />
            </InputGroupText>
          )}
          {isReadonly && !isPassword ? (
            validUrlRegex.test(inputValue || '') ? (
              <a className="telecom-text mb-0" style={{ padding: '5px 8px' }} href={inputValue}>
                {inputValue}
              </a>
            ) : (
              <p className="telecom-text mb-0" style={{ padding: '5px 8px' }}>
                {inputValue || 'None'}
              </p>
            )
          ) : (
            <Input
              type={isPassword ? 'password' : isTextarea ? 'textarea' : 'text'}
              name={inputName}
              className={`${inputClassName} ${!errorMessage ? '' : 'control-error'}`}
              style={inputStyle || {}}
              id={inputId}
              placeholder={inputPlaceholder}
              value={inputValue || ''}
              onChange={inputOnChange}
              autoComplete={inputAutoComplete}
              invalid={invalid}
              valid={valid}
              disabled={disabled}
              readOnly={isReadonly}
            />
          )}

          {inputIcon && isAppend && (
            <InputGroupText>
              <i
                className={`fa fa-${inputIcon}`}
                style={{ color: !errorMessage ? '#06335d' : 'red' }}
              />
            </InputGroupText>
          )}
        </InputGroup>
      </FormGroup>
      {errorMessage && (
        <InputError
          className={inputErrorClassName}
          style={{ ...inputErrorStyle, margin: '4px 0px' }}
        >
          {errorMessage}
        </InputError>
      )}
    </Fragment>
  );
};

export default InputFormGroup;
